import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {EmailAddress} from '@flexe/ui-components';
import NavigationService from '../shared/services/NavigationService';
import IdleMonitor from '../auth/IdleMonitor';
import {Nav} from './LayoutInterfaces';

/* eslint-disable @typescript-eslint/no-var-requires */
const flexeLogo = require('flexe_white_logo_nav.png');
const flexeShipperLogo = require('flexe_black_logo_nav.png');
const flexeWarehouserLogo = require('flexe_white_logo_nav.png');
/* eslint-enable @typescript-eslint/no-var-requires */

interface NavigationProps extends RouteComponentProps<{application: string}> {
  authenticityToken: string;
  homeUrl: string;
  navigation: Nav[];
  userNavigation: Nav[];
  sessionId?: string;
}

interface NavigationState {
  isShipper: boolean;
  isWarehouse: boolean;
  navigation: Nav[];
  userNavigation: Nav[];
}

function ChevronDownIcon() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="down-chev">
      <title id="title">Down Chevron Icon</title>
      <path
        id="a"
        d="M12.071 14.642a.499.499 0 0 1-.382-.146l-4.243-4.242a.5.5 0 0 1 .708-.708l3.917 3.918 3.918-3.918a.5.5 0 0 1 .707.708l-4.242 4.242a.499.499 0 0 1-.383.146z"
      />
    </svg>
  );
  /* eslint-enable max-len */
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const navService = new NavigationService(props.authenticityToken);
  const PUBLIC_PAGES = ['/users/sign_in', '/users/sign_up', '/users/confirmation/new', '/users/password/new'];
  const [state, setState] = React.useState<NavigationState>({
    isShipper: props.match.params.application === 's',
    isWarehouse: props.match.params.application === 'wh',
    navigation: props.navigation,
    userNavigation: props.userNavigation
  });

  React.useEffect(() => {
    const fetchData = async () => {
      const showNav = !PUBLIC_PAGES.includes(window.location.pathname);
      if (props.sessionId && showNav) {
        const response = await navService.getNavigation();
        setState({
          isShipper: response.isShipper,
          isWarehouse: state.isWarehouse,
          navigation: response.navigation,
          userNavigation: response.userNavigation
        });
      } else {
        //User not logged in, don't bother with API request
        setState({
          isShipper: state.isShipper,
          isWarehouse: state.isWarehouse,
          navigation: [],
          userNavigation: []
        });
      }
    };
    fetchData();
  }, [props.sessionId, state.isWarehouse]);

  const renderMidnightNavigation = () => {
    return (
      <div className={state.isShipper ? 'shipper-nav' : 'warehouse-nav'}>
        <a className="flexe-logo" href={props.homeUrl}>
          <img src={state.isShipper ? flexeShipperLogo : flexeWarehouserLogo} alt="Flexe" />
        </a>
        {state.userNavigation.length > 0 && userNav(state.userNavigation[0])}
        <div className="application-nav">
          {state.navigation.map((nav, index) => {
            if (nav.attributes && nav.attributes.includes('header')) {
              return navHeader(nav, index);
            }
            return navItem(nav, index);
          })}
        </div>
        <div className="contact-panel">
          <div className="contact-nav">
            <span>Contact Us</span> <ChevronDownIcon />
          </div>
          <div id="contact-sub-nav">
            <div className="nav-header">Contact Us</div>
            <a className="nav-item" href="tel:+18557337788">
              855.733.7788
            </a>
            <EmailAddress
              localPart={state.userNavigation.length > 0 ? 'support' : 'new.warehouse'}
              className={'nav-item'}
            />
            {state.isShipper ? null : (
              <a
                href={
                  'https://flexesupport.zendesk.com/hc/en-us/articles/' +
                  '360047776852-On-Call-Support-Full-SOP-External-'
                }
                className="nav-item"
                target="_blank"
              >
                On Call Support SOP
              </a>
            )}
          </div>
        </div>
        {renderIdleMonitor()}
      </div>
    );
  };

  const userNav = (userNavigation: Nav) => {
    return (
      <div className="user-panel">
        <div className="user-nav">
          <span>{userNavigation.name}</span> <ChevronDownIcon />
        </div>
        <div id="user-sub-nav">
          <a href="/users/sign_out" onClick={signOut} className="sign-out">
            Sign Out
          </a>
          {userNavigation.subNav.map((nav, index) => navHeader(nav, index))}
        </div>
      </div>
    );
  };

  const navItem = (nav: Nav, index: number) => {
    const classes = nav.attributes && nav.attributes.length > 0 ? nav.attributes.join(' ') : '';
    const activeClass = determineActiveClass(nav.name, nav.url);

    return (
      <a key={index} className={`nav-item ${classes} ${activeClass}`} href={nav.url}>
        {nav.name}
      </a>
    );
  };

  const determineActiveClass = (name: string, url: string): string => {
    let activeClass = '';
    if (
      props.location.pathname.includes('cycle_counts') ||
      props.location.pathname.includes('movements') ||
      props.location.pathname.includes('ecommerce') ||
      props.location.pathname.includes('omni_reporting')
    ) {
      activeClass = url === props.location.pathname ? 'active' : '';
    } else if (props.location.pathname.includes('waves/templates')) {
      activeClass = name === 'Wave Templates' ? 'active' : '';
    } else {
      activeClass = props.location.pathname.includes(url) ? 'active' : '';
    }

    return activeClass;
  };

  const navHeader = (nav: Nav, index: number) => {
    if (nav.subNav && nav.subNav.length > 0) {
      return (
        <div key={index}>
          <div className="nav-header">{nav.name}</div>
          {nav.subNav.map((subNav, subIndex) => navItem(subNav, subIndex))}
        </div>
      );
    }
    return null;
  };

  const renderIdleMonitor = () => {
    if (state.navigation.length > 0) {
      return <IdleMonitor sessionTimeoutInSeconds={null} logout={signOut} />;
    } else {
      return null;
    }
  };

  const signOut = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    window.location.href = '/users/sign_out';
  };

  return renderMidnightNavigation();
};

export default Navigation;
