import {ActionInfo, ActorInfo, FilterOption, Inventory, WarehouseLocation} from '../../shared/CommonInterfaces';
import {LocationsFlag} from '../../locations/LocationsInterfaces';

export enum CycleCountStatus {
  new = 'new',
  pendingStart = 'pending_start',
  inProgress = 'in_progress',
  inReview = 'in_review',
  completed = 'completed',
  cancelled = 'cancelled',
  pendingCompletion = 'pending_completion'
}

export enum CycleCountStatusDisplay {
  new = 'Requested',
  pending_start = 'Pending',
  in_progress = 'In Progress',
  in_review = 'Under Review',
  completed = 'Completed',
  cancelled = 'Cancelled'
}

export enum CycleCountType {
  item = 'InventoryCycleCount',
  location = 'LocationCycleCount'
}

export enum CycleCountTypeString {
  InventoryCycleCount = 'Item',
  LocationCycleCount = 'Location'
}

export interface CycleCount {
  id: string;
  reference?: string;
  reservation?: {
    id: number;
    name: string;
  };
  warehouse: {
    id: number;
    name: string;
  };
  version: number;
  countType: CycleCountType;
  created: ActionInfo;
  lastTouched: ActionInfo;
  submitted: ActionInfo;
  completed: ActionInfo;
  counted: ActionInfo;
  userComments: string;
  state: string;
  canBeCancelled?: boolean;
  skuCount?: number;
  locCount?: number;
  totalEaches?: number;
}

export interface CycleCountItem {
  id: string;
  version: number;
  inventory: Inventory;
  location: WarehouseLocation;
  counted: ActionInfo;
  expectedQuantities: CycleCountQuantities;
  countedQuantities: CycleCountQuantities;
  lpnBarcode?: string;
  lpnId?: number;
  metadata?: CycleCountItemMetadata;
}

export interface CycleCountItemMetadata {
  created: ActionInfo;
  expected: {
    id: number;
    metadata: {
      lotCode: string;
      expirationDate: string;
      manufactureDate: string;
    };
    uoms: {
      eachesPerInnerPack: string;
      eachesPerOuterCase: string;
    };
  };
  counted?: ActionInfo;
  actual?: {
    id: number;
    metadata: {
      lotCode: string;
      expirationDate: string;
      manufactureDate: string;
    };
    uoms: {
      eachesPerInnerPack: string;
      eachesPerOuterCase: string;
    };
  };
}

export interface CycleCountItemParams {
  id: string;
  version: number;
  inventoryId: string;
  locationId: string;
  countedQuantities: CycleCountQuantities;
  lpnBarcode?: string;
  metadata?: CycleCountItemMetadata;
}

export interface CycleCountQuantities {
  [index: string]: number;
}

export interface CycleCountDetails extends CycleCount {
  cycleCountItems: CycleCountItem[];
}

export interface CycleCountsResponse {
  continuationToken: string;
  totalCycleCountsCount: number;
  cycleCounts: CycleCount[];
}

export interface CycleCountDetailResponse {
  cycleCount: CycleCountDetails;
  cycleCountReasonCodes: CycleCountReasonCode[];
  lpnBarcodeToId?: Map<string, number>;
}

export interface CycleCountWriteResponse {
  cycleCount: {
    id: string;
    state: string;
  };
}

export interface CycleCountBatchesResponse {
  batches: CycleCountBatch[];
}

export interface CycleCountBatch {
  id: string;
  status: string;
  assignedTo?: ActorInfo;
  created: ActionInfo;
  lastTouchedAt: string;
  locations: WarehouseLocation[];
}

export interface CycleCountReasonCode {
  name: string;
  value: string;
}

// see 'item_info' endpoint in CycleCountsController for notes on what the hell i'm doing here
export interface BareBonesItem {
  id: string;
  itemCode: string;
  description: string;
  company: string;
  lotCodeTrackingEnabled: boolean;
  expiryDateTrackingEnabled: boolean;
}
export interface CycleCountItemMasterResponse {
  continuationToken: string;
  items: BareBonesItem[];
}
// see mobile_users endpoint in CycleCountsController for this too
export interface MobileUser {
  id: string;
  name: string;
  username: string;
}
export interface CycleCountMobileUserResponse {
  mobileUsers: MobileUser[];
}

export interface CycleCountListState {
  continuationTokens: string[];
  errors: string[];
  currentPage: number;
  filters: {
    [index: string]: any;
  };
  loading: boolean;
  cycleCounts: CycleCount[];
  totalCounts: number;
  reservations: FilterOption[];
  selectedWarehouse: FilterOption;
}

// Remove after LPNs are no-longer feature flagged or CCs can handle LPNs. See PR for WSE-1742.
export interface ReservationFilterOption extends FilterOption {
  lpnEnabled?: boolean;
  locationsFlag?: LocationsFlag;
}
