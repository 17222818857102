import * as React from 'react';
import {FunctionComponent} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import CardListItem from './CardListItem';
import FlexeButton from './FlexeButton';

export interface ListItem {
  id?: string | number;
  title: string;
  info: string;

  // CardListItem can render with or without a button; it can also render a link styled to look like a button
  // 1. If no value is given, actionHint will not render at all
  // 2. If no value is given, buttonText will render as "Next"
  // 3. If rendering a button, define your event handler in your parent component code, where context exists
  // 3a: Pass your event handler to actionClick; it will trickle down to CardListItem and be called upon button click
  actionHint?: string;
  buttonText?: string;
  actionClick?: any; // an item may have either an actionClick to handle a button click
  actionClickDisabled?: boolean; // whether the action button should be disabled

  // if true, the needsAttention classes will be applied to the CardListItem
  needsAttention?: boolean;
  optionMenu?: React.ReactElement;
}

interface Props {
  listTitle: string;
  listItems: ListItem[];

  // How to use the following optional props:
  // The "create new" button rendered at the top of the CardList will redirect to createRoute
  createRoute?: string;
  // Text to display when the card list has no items
  emptyListText?: string;
}

const CardList: FunctionComponent<Props> = (props) => {
  const CardListClasses = ['card-list'];
  return (
    <section className={classNames(CardListClasses)}>
      <div className="list-header row">
        <h2 data-testid="list-title">{props.listTitle}</h2>
        {/* If the list includes actions, render link or button depending on desired action  */}
        <div data-testid="list-buttons" className="list-buttons">
          {props.createRoute && (
            <>
              <button
                className="btn btn-primary create-button"
                onClick={() => {
                  window.location.assign(props.createRoute);
                }}
              >
                <i className="fa fa-plus" />
                Create New
              </button>
            </>
          )}
        </div>
        {props.listItems.length === 0 && props.emptyListText && <p>{props.emptyListText}</p>}
      </div>
      <ul data-testid="list-items">
        {props.listItems.map((listItem, i) => {
          return (
            <CardListItem
              key={`${listItem.title}-${i}`}
              title={listItem.title}
              info={listItem.info}
              actionHint={listItem.actionHint}
              buttonText={listItem.buttonText}
              actionClick={listItem.actionClick}
              actionClickDisabled={listItem.actionClickDisabled}
              needsAttention={listItem.needsAttention}
              optionMenu={listItem.optionMenu} // this should be a KebabMenu component
              {...props}
            />
          );
        })}
      </ul>
    </section>
  );
};

export default CardList;
