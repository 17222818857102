import * as React from 'react';
import {useEffect, useState} from 'react';
import {isEqual} from 'lodash';
import {Filter, FilterType, FilterOption, WarehouseLocation} from '../../shared/CommonInterfaces';
import DropDown, {DropDownColor, DropDownOption} from '../../shared/DropDown';
import {Mhe, MheSearchErrorResponseCodes} from '../../shared/services/MheService';
import TypeAhead, {TypeAheadOption} from '../../shared/TypeAhead';
import {FilterKeys, FilterSearchTypes} from './WaveBatchInterfaces';
import {WaveContext} from './WaveContext';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const circlePlusIcon = require('../../../../assets/images/common/icon_circle_plus.svg');

interface Props {
  allowedFilterKeys: FilterKeys[];
  filterSearchTypeOverrides?: Map<string, FilterSearchTypes>;
  onFilterChange: (key: FilterKeys, value: string) => void;
}

const WaveFilter: React.FC<Props> = ({allowedFilterKeys, filterSearchTypeOverrides, onFilterChange}) => {
  const {
    batchWavingService,
    pickConsolidationService,
    mheService,
    warehouse,
    carriers,
    filterParams,
    setErrors
  } = React.useContext(WaveContext);
  const [filterValue, setFilterValue] = useState('');
  const [applyFilter, setApplyFilter] = useState(false);
  const [carriersToServiceLevels, setCarriersToServiceLevels] = useState<object>({});
  const [stagingLocations, setStagingLocations] = useState<WarehouseLocation[]>([]);
  const [mhes, setMhes] = useState<Mhe[]>([]);

  useEffect(() => {
    loadCarrierServiceLevel();
    loadStagingLocations();
    loadMhes();
  }, []);

  const loadCarrierServiceLevel = async () => {
    const response = await batchWavingService.getAllCarriersAndServiceTypes();
    if (response.data && response.data.mappedShippingInfo) {
      return setCarriersToServiceLevels(response.data.mappedShippingInfo);
    }
    if (response.errors && response.errors.length > 0) {
      const getCarrierServiceLevelErrors = [];
      response.errors.forEach((e) =>
        getCarrierServiceLevelErrors.push(
          'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.'
        )
      );
      setErrors(getCarrierServiceLevelErrors);
    }
  };

  const loadStagingLocations = async () => {
    try {
      const response = await pickConsolidationService.getStagingLocations(warehouse.id, null, null, 50);
      if (response.locations) {
        setStagingLocations(response.locations);
      }
    } catch (e) {
      setErrors([
        'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.'
      ]);
    }
  };

  const loadMhes = async () => {
    const allMhes = [];
    let continuationToken = null;

    try {
      do {
        const response = await mheService.searchForMhes([], warehouse.id, continuationToken);
        if (response.errors?.length > 0) {
          if (response.errors[0]?.code === MheSearchErrorResponseCodes.NotFound) {
            return setMhes([]);
          } else {
            setErrors([
              `An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.`
            ]);
            return;
          }
        }

        allMhes.push(...response.data.mhes);
        continuationToken = response.data.continuationToken;
      } while (continuationToken != null);

      if (allMhes.length > 0) {
        return setMhes(allMhes);
      }
    } catch (e) {
      setErrors([
        `An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.`
      ]);
    }
  };

  const carrierOptions = Object.keys(carriers).map((carrier) => {
    return {
      displayName: carriers[carrier],
      value: carrier
    };
  });

  let serviceTypes;
  if (filterParams.carrier) {
    serviceTypes = carriersToServiceLevels[filterParams.carrier] || [];
  } else {
    const allServiceTypes = Object.keys(carriersToServiceLevels).map((carrier) => {
      return carriersToServiceLevels[carrier];
    });
    serviceTypes = [].concat(...allServiceTypes);
  }
  const serviceTypeOptions = serviceTypes.map((serviceType) => {
    return {
      displayName: serviceType,
      value: serviceType
    };
  });

  const stagingLocationOptions = stagingLocations.map((stagingLocation) => {
    return {
      displayName: stagingLocation.label,
      value: stagingLocation.id
    };
  });

  const mheOptions = mhes.map((mhe) => {
    return {
      displayName: mhe.label,
      value: mhe.id
    };
  });

  interface WaveFilterInfo extends Filter {
    key: FilterKeys;
  }

  const parentFilter: WaveFilterInfo = {
    displayName: 'Select Filter',
    key: FilterKeys.Parent,
    type: FilterType.Dropdown
  };

  const idFilter: WaveFilterInfo = {
    displayName: 'Wave ID',
    key: FilterKeys.WaveId,
    type: FilterType.String
  };

  const batchFilter: WaveFilterInfo = {
    displayName: 'Batch ID',
    key: FilterKeys.BatchId,
    type: FilterType.String
  };

  const shipmentFilter: WaveFilterInfo = {
    displayName: 'Shipment ID',
    key: FilterKeys.ShipmentId,
    type: FilterType.String
  };

  const orderFilter: WaveFilterInfo = {
    displayName: 'Order ID',
    key: FilterKeys.OrderReferenceId,
    type: FilterType.String
  };

  const carrierFilter: WaveFilterInfo = {
    displayName: 'Carrier',
    key: FilterKeys.Carrier,
    type: FilterType.Dropdown,
    allowMultiple: false,
    placeHolder: 'Choose a Carrier',
    options: carrierOptions
  };

  const serviceTypeFilter: WaveFilterInfo = {
    displayName: 'Service Type',
    key: FilterKeys.ServiceType,
    type: FilterType.Dropdown,
    allowMultiple: true,
    placeHolder: 'Choose a Service Type',
    options: serviceTypeOptions
  };

  const stagingLocationFilter: WaveFilterInfo = {
    displayName: 'Staging Location',
    key: FilterKeys.StagingLocation,
    type: FilterType.Dropdown,
    allowMultiple: false,
    placeHolder: 'Choose a Staging Location',
    options: stagingLocationOptions
  };

  const shipByFilter: WaveFilterInfo = {
    displayName: 'Ship By Date',
    key: FilterKeys.ShipBy,
    type: FilterType.Date,
    placeHolder: 'MM/DD/YYY'
  };

  const purchaseOrderFilter: WaveFilterInfo = {
    displayName: 'Purchase Order',
    key: FilterKeys.PurchaseOrder,
    type: FilterType.String
  };

  const radioFilterOptions = [
    {
      displayName: 'True',
      value: 'true'
    },
    {
      displayName: 'False',
      value: 'false'
    }
  ];
  const printedFilter: WaveFilterInfo = {
    displayName: 'Hide Printed',
    key: FilterKeys.HidePrinted,
    type: FilterType.Radio,
    allowMultiple: false,
    options: [...radioFilterOptions]
  };

  const loadGroupFilter: WaveFilterInfo = {
    displayName: 'Load Group',
    key: FilterKeys.LoadGroup,
    type: FilterType.String
  };

  const destinationTagFilter: WaveFilterInfo = {
    displayName: 'Destination Id',
    key: FilterKeys.DestinationTag,
    type: FilterType.String
  };

  const mheFilter: WaveFilterInfo = {
    displayName: 'MHE',
    key: FilterKeys.Mhe,
    type: FilterType.TypeAhead,
    allowMultiple: false,
    placeHolder: 'Choose an MHE',
    options: mheOptions
  };

  const filterMap = new Map<FilterKeys, WaveFilterInfo>([
    [FilterKeys.WaveId, idFilter],
    [FilterKeys.BatchId, batchFilter],
    [FilterKeys.ShipmentId, shipmentFilter],
    [FilterKeys.OrderReferenceId, orderFilter],
    [FilterKeys.Carrier, carrierFilter],
    [FilterKeys.ServiceType, serviceTypeFilter],
    [FilterKeys.ShipBy, shipByFilter],
    [FilterKeys.HidePrinted, printedFilter],
    [FilterKeys.StagingLocation, stagingLocationFilter],
    [FilterKeys.LoadGroup, loadGroupFilter],
    [FilterKeys.DestinationTag, destinationTagFilter],
    [FilterKeys.PurchaseOrder, purchaseOrderFilter],
    [FilterKeys.Mhe, mheFilter]
  ]);

  const filters = [parentFilter, ...allowedFilterKeys.map((filterKey) => filterMap.get(filterKey))];

  parentFilter.options = filters.map((filter) => ({displayName: filter.displayName, value: filter.key}));

  const [selectedFilter, setSelectedFilter] = useState(parentFilter);

  useEffect(() => {
    if (applyFilter) {
      if (selectedFilter.key === parentFilter.key) {
        const childFilter = filters.find((filter) => filter.key === filterValue);
        setSelectedFilter(childFilter);
      } else {
        onFilterChange(selectedFilter.key, filterValue);
        setSelectedFilter(null);
      }

      setApplyFilter(false);
    }
  }, [applyFilter]);

  const resetFilterInput = () => {
    if (Object.values(filterParams).length === 0) {
      setSelectedFilter(parentFilter);
    } else {
      setSelectedFilter(null);
    }
  };

  const handleChangeAndApplyFilter = (option: DropDownOption) => {
    setFilterValue(`${option.value}`);
    setApplyFilter(true);
  };

  const handleChangeAndApplyRadioFilter = (event) => {
    setFilterValue(event.target.value);
    setApplyFilter(true);
  };

  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleAddFilter = (filter: Filter = selectedFilter, value: string = filterValue) => {
    if (filter.type === FilterType.Date && value === filter.key) {
      setFilterValue('');
      setApplyFilter(true);
    } else {
      setFilterValue(value);
      setApplyFilter(true);
    }
  };

  const handleCloseInputFilter = () => {
    setFilterValue('');
    setSelectedFilter(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddFilter();
    }
  };

  const handleTypeAheadFilterChange = (query: string) => {
    setFilterValue(query);
  };

  const handleTypeAheadChangeAndApplyFilter = (value: string) => {
    setFilterValue(value);
    setApplyFilter(true);
  };

  const submitCancel = (
    <React.Fragment>
      <a className="submit-btn icon" data-testid="check-button" onClick={() => handleAddFilter()}>
        <i className="fa fa-check" aria-hidden="true"></i>
      </a>
      <a className="text-danger icon" data-testid="cancel-button" onClick={() => handleCloseInputFilter()}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </a>
    </React.Fragment>
  );

  const filterInput = () => {
    switch (selectedFilter.type) {
      case FilterType.Dropdown:
        // eslint-disable-next-line no-case-declarations
        const options: DropDownOption[] = selectedFilter.options.map((option) => ({
          name: option.displayName,
          value: `${option.value}`
        }));

        return (
          <li>
            <DropDown
              options={options}
              prefixText={selectedFilter.placeHolder}
              selected={selectedFilter.placeHolder ? null : options[0]}
              onSelect={handleChangeAndApplyFilter}
              color={DropDownColor.white}
            />
          </li>
        );
      case FilterType.TypeAhead: {
        const baseOptions: TypeAheadOption[] = selectedFilter.options.map((option) => ({
          value: option.value.toString(),
          displayName: option.displayName
        }));
        const normalizedValue: string = filterValue.toLowerCase();
        const filterVal: string = filterValue === selectedFilter.key ? '' : filterValue;
        const typeAheadOptions: TypeAheadOption[] = filterVal
          ? baseOptions.filter((option) => option.displayName.toLowerCase().includes(normalizedValue))
          : baseOptions;
        return (
          <React.Fragment>
            <div className="btn-group">
              <li id="filter-dropdown">
                <TypeAhead
                  name={selectedFilter.displayName}
                  placeholder={selectedFilter.placeHolder}
                  value={filterVal}
                  options={typeAheadOptions}
                  onRequestOptions={handleTypeAheadFilterChange}
                  onSelect={handleTypeAheadChangeAndApplyFilter}
                  dropdownCss="typeahead-dropdown-menu"
                />
              </li>
            </div>
            <li>
              <a
                id="add-filter"
                onClick={() => {
                  setApplyFilter(true);
                }}
              >
                Submit
              </a>
            </li>
            <li>
              <a className="text-danger" onClick={resetFilterInput}>
                Cancel
              </a>
            </li>
          </React.Fragment>
        );
      }
      case FilterType.Date:
        return (
          <div className="filter-input-div">
            <input
              name={selectedFilter.key}
              value={filterValue}
              onChange={handleFilterValueChange}
              onKeyPress={handleKeyPress}
              className="form-control filter-input"
              type="date"
              data-testid="filter-input"
              date-format="yyyy-mm-dd"
            />
            {submitCancel}
          </div>
        );
      case FilterType.String:
        return (
          <React.Fragment>
            <li className="input-with-label">
              <label className="custom-label">{checkSelectedFilter(selectedFilter)}</label>
              <input className="custom-input" type="text" onChange={handleFilterValueChange}></input>
            </li>
            <li>
              <a
                onClick={() => {
                  setApplyFilter(true);
                }}
              >
                Add
              </a>
            </li>
            <li>
              <a className="text-danger" onClick={resetFilterInput}>
                Cancel
              </a>
            </li>
          </React.Fragment>
        );
      case FilterType.Radio:
        // eslint-disable-next-line no-case-declarations
        let key = 0;
        return (
          <React.Fragment>
            <li className="form-group filter-input">
              <div className="rbtn-group">
                {selectedFilter.options.map((option, index) => {
                  return [
                    <input
                      key={key++}
                      className="filter-radio"
                      type="radio"
                      name={selectedFilter.key}
                      id={selectedFilter.key + '_' + index}
                      value={option.value}
                      onChange={handleChangeAndApplyRadioFilter}
                    />,
                    <label key={key++} className="filter-radio-label" htmlFor={selectedFilter.key + '_' + index}>
                      {option.displayName}
                    </label>
                  ];
                })}
              </div>
            </li>
          </React.Fragment>
        );
    }
  };

  const checkSelectedFilter = (filter: Filter) => {
    if (filterSearchTypeOverrides && filterSearchTypeOverrides.has(filter.key)) {
      return filterSearchTypeOverrides.get(filter.key);
    }
    switch (filter.key) {
      case carrierFilter.key: {
        return FilterSearchTypes.Contains;
      }
      default: {
        return FilterSearchTypes.Is;
      }
    }
  };

  const removeFilter = (filterKey) => {
    onFilterChange(filterKey, '');

    // Select parentFilter if there are no active filters or if selectedFilter
    // is the only filter (soon to be removed)
    const selectParentFilter =
      Object.values(filterParams).length === 0 || isEqual(Object.keys(filterParams), [filterKey]);

    if (selectParentFilter) {
      setSelectedFilter(parentFilter);
    } else {
      setSelectedFilter(null);
    }
  };

  const activeFilters = Object.entries(filterParams).map(([key, value]) => {
    const displayName = filters.find((filter) => filter.key === key)?.displayName;

    const matchingStagingLocation =
      key === stagingLocationFilter.key
        ? stagingLocations.find((stagingLocation) => stagingLocation.id.toString() === value)
        : undefined;

    let displayValue = matchingStagingLocation ? matchingStagingLocation.label : value;

    const matchingMhe = key === mheFilter.key ? mhes.find((mhe) => mhe.id.toString() === value) : undefined;

    if (matchingMhe != null) {
      displayValue = matchingMhe ? matchingMhe.label : value;
    }

    return (
      displayName && (
        <li key={`${key}-${value}`}>
          <div className="active-filter">
            <span className="active-filter-label">
              {displayName} <strong>"{displayValue}"</strong>
            </span>
            <span
              className="active-filter-action"
              onClick={() => {
                removeFilter(key);
              }}
            >
              x
            </span>
          </div>
        </li>
      )
    );
  });

  const addFilter = (() => {
    if (selectedFilter) {
      const label = selectedFilter.key === parentFilter.key ? 'Filter by:' : `Filter by ${selectedFilter.displayName}:`;

      return (
        <>
          <li>{label}</li>
          {filterInput()}
        </>
      );
    }

    return (
      <li>
        <button
          className="circle-plus-button"
          title="Add Filter"
          onClick={() => {
            setSelectedFilter(parentFilter);
          }}
        >
          <img src={circlePlusIcon} alt="circlePlusIcon" />
        </button>
      </li>
    );
  })();

  return (
    <ul data-testid="wave-filter-control" className="list-inline list-inline-overflow">
      {activeFilters}
      {addFilter}
    </ul>
  );
};

export default WaveFilter;
