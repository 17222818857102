import * as React from 'react';
import {FC} from 'react';
import {LpnsResponse} from '../../../lpns/LpnsInterfaces';
import {LocationCategoryStrings} from '../../../locations/LocationsInterfaces';
import LocationsService from '../../../locations/LocationsService';
import {FocusedMovementLogData} from '../../../locations/MovementLogInterfaces';
import {renderLpnLink} from '../../../../libs/helpers';
import FlexeButton from '../../../shared/FlexeButton';
import {Shipment} from '../ShipmentInterfaces';
import {ShipmentDetailsContext} from './ShipmentDetailsContext';

interface Props {
  lpn: LpnsResponse;
  blockedAction: string;
  toggleModal: () => void;
  shipment: Shipment;
  onEditableChanged: (isEditable: boolean) => void;
}

/**
 * This control is used to enforce that a given LPN on a shipment is in a good state
 * to be modified (ex. replaced or removed from the shipment). If it is in a good state the children
 * elements will be rendered, if it isn't then a message will be displayed to the user.
 *
 * This is a "boundary" because everything inside of the control may or may not be rendered.
 */
export const ShipmentLpnEditBoundary: FC<Props> = (props) => {
  const {locationsService} = React.useContext(ShipmentDetailsContext);
  const [userNameForLocation, setUserNameForLocation] = React.useState<string>('');

  React.useEffect(() => {
    fetchUserName(props.shipment, props.lpn, locationsService, setUserNameForLocation);
  }, []);

  // When not in a transitional location just show the child elements
  if (props.lpn.category !== LocationCategoryStrings.CATEGORY_TRANSITIONAL) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  // Let hosts of this control know they won't be able to edit
  props.onEditableChanged(false);

  // Only show the name if we have it. Don't show a placeholder if it is missing.
  // eslint-disable-next-line no-extra-boolean-cast
  const userNameDom = !!userNameForLocation ? (
    <React.Fragment>
      , <strong>{userNameForLocation}</strong>,{' '}
    </React.Fragment>
  ) : (
    ' '
  );

  return (
    <div className="shipment-lpn-edit-boundary">
      <div>
        <strong>LPN {renderLpnLink(props.lpn.lpnBarcode, false, props.lpn.lpnId)}</strong> is not in the staging
        location. You will be able to {props.blockedAction} the LPN when the mobile user{userNameDom}drops the goods off
        at the staging location.
      </div>
      <div className="spacing" />
      <div className="action-buttons">
        <div className="right-buttons">
          <FlexeButton level="primary" handleClick={props.toggleModal} text="OK" />
        </div>
      </div>
    </div>
  );
};

/**
 * Accumulate all of the movement logs for a given LPN on the client
 * @returns An array of movement logs. An empty array should be treated as ana error
 */
const fetchAllMovementLogs = async (
  shipment: Shipment,
  lpn: LpnsResponse,
  locationsService: LocationsService
): Promise<FocusedMovementLogData[]> => {
  // Start searching 3 months ago just to be safe. The default is a week ago
  const currentDate = new Date();
  const searchStart = new Date(currentDate.setMonth(currentDate.getMonth() - 3)).toISOString();

  let movementLogs = [];
  let done = false;
  let continuationToken = '';
  while (!done) {
    const result = await locationsService.getLocationMovementLog(shipment.reservation.warehouse_id, {
      lpnBarcodes: [lpn.lpnBarcode],
      continuationToken,
      pageSize: 100,
      moveDateAfter: searchStart
    });

    if (result.errors && result.errors.length > 0) {
      return []; // Unable to determine the name
    }

    movementLogs = movementLogs.concat(result.data.logs);
    continuationToken = result.data.continuationToken;

    if (movementLogs.length >= result.data.totalMatchingResults) {
      done = true;
    }
  }
  return movementLogs;
};

/**
 * Get the name of the user with the LPN from the movement logs
 * @returns The name of the user with the LPN. "" when unknown
 */
const fetchUserNameFromMovementLogs = async (
  shipment: Shipment,
  lpn: LpnsResponse,
  locationsService: LocationsService
) => {
  const movementLogs = await fetchAllMovementLogs(shipment, lpn, locationsService);

  movementLogs.sort((log1, log2) => {
    return log1.movedAt < log2.movedAt ? 1 : -1;
  });

  if (movementLogs.length > 0) {
    return movementLogs[0].movedBy;
  }
  return '';
};

/**
 * Get the name of the user with the LPN from the webserver
 */
const fetchUserName = async (
  shipment: Shipment,
  lpn: LpnsResponse,
  locationsService: LocationsService,
  setUserNameForLocation: (name: string) => void
) => {
  try {
    const userName = await fetchUserNameFromMovementLogs(shipment, lpn, locationsService);

    // Don't treat a blank username as an error as it is only helpful, not critical
    setUserNameForLocation(userName);
  } catch (e) {
    // Swallow errors as the user name isn't a workflow blocking issue.
  }
};
