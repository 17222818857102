import * as React from 'react';
import {Loader, Tabs} from '@flexe/ui-components';
import {last} from 'lodash';
import {FilterType, Response, Warehouse} from '../shared/CommonInterfaces';
import ErrorDisplay from '../shared/ErrorDisplay';
import Filters from '../shared/Filters';
import MovementsService from '../movements/MovementsService';
import WarehouseTasksTable from './WarehouseTasksTable';
import {WarehouseTask, WarehouseTaskResponse, WarehouseTaskStatus} from './WarehouseTasksInterfaces';
import WarehouseTasksService from './WarehouseTasksService';

interface WarehouseTasksProps {
  authenticityToken: string;
  warehouse: Warehouse;
}

interface WarehouseTasksState {
  errorResponse: Response;
  filters: Record<string, number | string>;
  warehouseTasks: WarehouseTask[];
  continuationTokens: string[];
  loadPending: boolean;
  selectedTab: WarehouseTaskStatus;
}

/**
 * Parent container for all of the warehouse tasks display components.
 */
class WarehouseTasks extends React.Component<WarehouseTasksProps, WarehouseTasksState> {
  private DEFAULT_PAGE_SIZE: number = 50;
  private warehouseTasksService: WarehouseTasksService;
  private movementsService: MovementsService;

  constructor(props) {
    super(props);

    this.warehouseTasksService = new WarehouseTasksService(props.authenticityToken);
    this.movementsService = new MovementsService(props.authenticityToken);
    this.state = {
      errorResponse: null,
      filters: {},
      warehouseTasks: [],
      continuationTokens: [null],
      loadPending: true,
      selectedTab: WarehouseTaskStatus.IN_PROGRESS
    };
  }

  public render() {
    const {loadPending} = this.state;

    return (
      <div>
        <ErrorDisplay errorResponse={this.state.errorResponse} />

        {
          <Filters
            filters={[
              {
                displayName: 'Batch ID',
                key: 'fulfillment_batch_id',
                type: FilterType.Number
              },
              {
                displayName: 'Assigned to User',
                key: 'user_name',
                type: FilterType.String
              },
              {
                displayName: 'SKU',
                key: 'sku',
                type: FilterType.String
              },
              {
                displayName: 'Stored LPN',
                key: 'warehouse_task_steps.source_lpn',
                type: FilterType.String
              },
              {
                displayName: 'Destination Location',
                key: 'destination_location',
                type: FilterType.String
              }
            ]}
            filterChangeHandler={(values) => {
              const filters = values.reduce(
                (acc, cur) => ({
                  ...acc,
                  [cur.filter.key]: cur.filter.type === FilterType.Number ? Number(cur.value) : cur.value
                }),
                {}
              );
              this.setState({
                continuationTokens: [null],
                filters,
                loadPending: true
              });
              this.fetchWarehouseTasks(this.state.selectedTab, null, filters);
            }}
          />
        }
        <Tabs tabs={this.getTabs()} tabEvent={this.handleTabEvent} />

        <div id="location-movements-table">
          {loadPending && <Loader loading={loadPending} />}
          <div id="controls">
            <button className="pull-right" id="btn-next" onClick={() => this.nextPage()}>
              NEXT
            </button>
            <button className="pull-right" id="btn-prev" onClick={() => this.prevPage()}>
              PREV
            </button>
          </div>

          {this.state.warehouseTasks.length ? (
            <WarehouseTasksTable tasksToDisplay={this.state.warehouseTasks} handlePause={this.handlePauseTasks} />
          ) : (
            this.emptyTable()
          )}
        </div>

        <div id="controls">
          <button className="pull-left" id="btn-prev" onClick={() => this.prevPage()}>
            PREV
          </button>
          <button className="pull-right" id="btn-next" onClick={() => this.nextPage()}>
            NEXT
          </button>
        </div>
      </div>
    );
  }

  public componentDidMount() {
    this.fetchWarehouseTasks(this.state.selectedTab, last(this.state.continuationTokens));
  }

  private getTabs() {
    return [
      {
        key: WarehouseTaskStatus.NEW,
        title: <i className="fa fa-list" aria-hidden="true"></i>,
        subTitle: 'New',
        active: this.state.selectedTab === WarehouseTaskStatus.NEW
      },
      {
        key: WarehouseTaskStatus.IN_PROGRESS,
        title: <i className="fa fa-refresh" aria-hidden="true"></i>,
        subTitle: 'In Progress',
        active: this.state.selectedTab === WarehouseTaskStatus.IN_PROGRESS
      },
      {
        key: WarehouseTaskStatus.COMPLETED_WITH_EXCEPTION,
        title: <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>,
        subTitle: 'Issues',
        active: this.state.selectedTab === WarehouseTaskStatus.COMPLETED_WITH_EXCEPTION
      },
      {
        key: WarehouseTaskStatus.HALTED,
        title: <i className="fa fa-stop-circle" aria-hidden="true"></i>,
        subTitle: 'Halted',
        active: this.state.selectedTab === WarehouseTaskStatus.HALTED
      },
      {
        key: WarehouseTaskStatus.COMPLETED,
        title: <i className="fa fa-check-circle" aria-hidden="true"></i>,
        subTitle: 'Completed',
        active: this.state.selectedTab === WarehouseTaskStatus.COMPLETED,
        pullRight: true
      },
      {
        key: WarehouseTaskStatus.CANCELLED,
        title: <i className="fa fa-ban" aria-hidden="true"></i>,
        subTitle: 'Cancelled',
        active: this.state.selectedTab === WarehouseTaskStatus.CANCELLED,
        pullRight: true
      }
    ];
  }

  private handleTabEvent = (tabKey) => {
    this.setState({
      selectedTab: tabKey,
      loadPending: true,
      continuationTokens: [null]
    });
    this.fetchWarehouseTasks(tabKey, null, this.state.filters);
  };

  private emptyTable() {
    return <p>There are no tasks in this state matching your current selection.</p>;
  }

  private async fetchWarehouseTasks(status: string, continuationToken: string, filters: Record<string, unknown> = {}) {
    try {
      const response: WarehouseTaskResponse = await this.warehouseTasksService.getWarehouseTasks(
        this.props.warehouse.id,
        this.DEFAULT_PAGE_SIZE,
        continuationToken,
        {status, ...filters}
      );

      const loadPending = false;
      const continuationTokens = this.state.continuationTokens.concat([response.continuationToken]);
      this.setState({
        warehouseTasks: response.result,
        loadPending,
        continuationTokens
      });
    } catch (err) {
      const loadPending = false;
      this.setState({loadPending, errorResponse: err});
    }
  }

  private handlePauseTasks = (warehouseId: number, selectedTasks: Set<number>) => {
    if (selectedTasks.size === 0 || warehouseId === undefined) {
      return;
    }

    try {
      this.movementsService.haltMovements(warehouseId, [], Array.from(selectedTasks)).then(() => {
        this.state.continuationTokens.pop();
        const page = this.state.continuationTokens.length - 1;
        this.fetchWarehouseTasks(this.state.selectedTab, this.state.continuationTokens[page]);
      });
    } catch (errorResponse) {
      alert('Movements could not be paused.' + errorResponse);
      return;
    }
  };

  private prevPage() {
    if (this.state.continuationTokens.length > 2) {
      this.state.continuationTokens.pop(); // Next page's token
      this.state.continuationTokens.pop(); // Current page's token
      const page = this.state.continuationTokens.length - 1;
      const contok = this.state.continuationTokens[page];
      this.fetchWarehouseTasks(this.state.selectedTab, contok);
    }
  }

  private nextPage() {
    const page = this.state.continuationTokens.length - 1;
    const contok = this.state.continuationTokens[page];
    if (contok != null) {
      this.fetchWarehouseTasks(this.state.selectedTab, contok);
    }
  }
}

export default WarehouseTasks;
