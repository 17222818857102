import * as React from 'react';
import {useState} from 'react';
import DropDown, {DropDownColor, DropDownOption} from '../DropDown';

/**
 * @prop onDateSelected Callback function that will be called after the user selects a relative date option. Input param is the relative date as of current time of selection.
 * @prop prefixText Optional text supplied by the caller to give the user a reference about what the relative date is for.
 * @prop allowedRelativeDates Allow list of RelativeDates that the control will present as options to the user. When none are provided, the user will have none to select from.
 * @prop initialRelativeDate The relative date to select by default. If none is provided, Last 7 Days is used.
 */
interface RelativeDatePickerProps {
  onDateSelected: (date: Date) => void;
  prefixText: string;
  allowedRelativeDates: RelativeDateOption[];
  initialRelativeDate: RelativeDateOption;
}

const RelativeDatePicker: React.FC<RelativeDatePickerProps> = (props) => {
  const options = OptionConfigs.filter((option) => props.allowedRelativeDates.includes(option.value)).sort(
    (opt1, opt2) => {
      // Order the list most recent to oldest.
      if (opt1.dateOffset === null) {
        return 1;
      }
      if (opt2.dateOffset === null) {
        return -1;
      }
      return opt2.dateOffset - opt1.dateOffset;
    }
  );

  const [selectedOption, setSelectedOption] = useState<DropDownOption>(
    options.find((option) => option.value === props.initialRelativeDate)
  );

  const onSelect = (option: DropDownOption) => {
    setSelectedOption(option);
    props.onDateSelected(convertRelativeDateToActual(option.value));
  };

  return (
    <DropDown
      options={options}
      selected={selectedOption}
      onSelect={onSelect}
      color={DropDownColor.white}
      prefixText={props.prefixText}
    />
  );
};

const convertRelativeDateToActual = (relativeDate: string | number) => {
  const selectedOption = OptionConfigs.filter((option) => option.value === relativeDate)[0];

  // This shouldn't happen but just in case it does, default to today
  if (!selectedOption) {
    return tOffsetDays(-1);
  }

  return tOffsetDays(selectedOption.dateOffset);
};

const tOffsetDays = (offset: number) => {
  if (offset === null) {
    return null;
  }

  const currentDate = new Date();
  const offsetDate = new Date();
  offsetDate.setDate(currentDate.getDate() + offset);
  return offsetDate;
};

export const getDefaultCreatedAfter = (relativeDate: RelativeDateOption) => {
  return convertRelativeDateToActual(relativeDate);
};

/**
 * To expand this list of RelativeDateOption, first add a value to this enum (preferably at the end to maintain assigned keys).
 * Then update the OptionConfigs with an entry for the new enum. It will need a name that is displayed to the user and a dateOffset
 * that is used to compute the actual date.
 *
 * Users of this control will have to opt into using the new RelativeDateOption so it is generally safe to add to this list.
 */
export enum RelativeDateOption {
  today,
  lastSevenDays,
  lastThirtyDays,
  lastNinetyDays,
  anytime,
  yesterday,
  lastThreeDays
}

const OptionConfigs = [
  {name: 'Today', dateOffset: -1, value: RelativeDateOption.today},
  {name: 'Last 7 Days', dateOffset: -7, value: RelativeDateOption.lastSevenDays},
  {name: 'Last 30 Days', dateOffset: -30, value: RelativeDateOption.lastThirtyDays},
  {name: 'Last 90 Days', dateOffset: -90, value: RelativeDateOption.lastNinetyDays},
  {name: 'Anytime', dateOffset: null, value: RelativeDateOption.anytime},
  {name: 'Yesterday', dateOffset: -2, value: RelativeDateOption.yesterday},
  {name: 'Last 3 Days', dateOffset: -4, value: RelativeDateOption.lastThreeDays}
];

export default RelativeDatePicker;
