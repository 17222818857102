import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {OrderType} from '../../ecommerce-batches/BatchInterfaces';

interface OrderProfileProps {
  savedShipmentType?: OrderType;
}

const OrderProfile: React.FC<OrderProfileProps> = ({savedShipmentType}) => {
  const {register} = useFormContext();

  return (
    <div className="form-field" data-testid="shipment-type-field">
      <label>
        Specify Order Profile
        <br />
        <span className="input-hint">Wave only single unit or multi-unit shipments.</span>
      </label>

      <div className="rbtn-group">
        <input
          className="wave-template-input"
          type="radio"
          name="shipmentType"
          id="shipment-type-either"
          data-testid="shipment-type-either"
          value={OrderType.SingleOrMulti}
          defaultChecked={![OrderType.SingleItem, OrderType.MultiItem].includes(savedShipmentType)}
          ref={register}
        />
        <label htmlFor="shipment-type-either">Both Single and Multi-Unit</label>

        <input
          className="wave-template-input"
          type="radio"
          name="shipmentType"
          id="shipment-type-single"
          data-testid="shipment-type-single"
          value={OrderType.SingleItem}
          defaultChecked={savedShipmentType === OrderType.SingleItem}
          ref={register}
        />
        <label htmlFor="shipment-type-single">Single-unit Only</label>

        <input
          className="wave-template-input"
          type="radio"
          name="shipmentType"
          id="shipment-type-multi"
          data-testid="shipment-type-multi"
          value={OrderType.MultiItem}
          defaultChecked={savedShipmentType === OrderType.MultiItem}
          ref={register}
        />
        <label htmlFor="shipment-type-multi">Multi-unit Only</label>
      </div>
    </div>
  );
};

export default OrderProfile;
