import * as React from 'react';
import WaveTemplateServiceV2 from '../../../shared/services/WaveTemplateServiceV2';
import WaveTemplateForm from './WaveTemplateForm';

interface EditWaveTemplateProps {
  templateId: number;
  warehouseId: number;
  carrierLabels: {[key: string]: string};
  serviceLevelMap: {[key: string]: string[]};
  authenticityToken: string;
}

const EditWaveTemplate: React.FC<EditWaveTemplateProps> = ({
  templateId,
  warehouseId,
  carrierLabels,
  serviceLevelMap,
  authenticityToken
}) => {
  const waveTemplateServiceV2 = new WaveTemplateServiceV2();

  return (
    <WaveTemplateForm
      templateId={templateId}
      warehouseId={warehouseId}
      carrierLabels={carrierLabels}
      serviceLevelMap={serviceLevelMap}
      authenticityToken={authenticityToken}
      header={`Editing Wave Template ${templateId}`}
      saveFunc={async (updatedTemplate) => {
        return waveTemplateServiceV2.updateWaveTemplate(updatedTemplate);
      }}
    />
  );
};

export default EditWaveTemplate;
