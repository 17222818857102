import {
  Document,
  DocumentType,
  ItemQuantity,
  Reservation,
  SortOrder,
  TransitionState,
  WarehouseDocument,
  WarehouseLocation
} from '../../shared/CommonInterfaces';
import {Wave} from '../batch-waving/WaveInterfaces';

/*
 * Organized by CONSTANTS, then ENUMS, then EXPORTED INTERFACES, then INTERNAL INTERFACES
 * Entries are alphabetical, please follow this standard when adding types
 */

// CONSTANTS

export const ParameterDisplayMap = {
  reservationId: 'Reservation ID',
  cutoffDate: 'Shipping',
  skus: 'SKUs',
  carriers: 'Carrier',
  serviceTypes: 'Service Types',
  orderType: 'Order Type',
  shipmentType: 'Pick Method',
  purchaseOrder: 'PO (includes)',
  includesHazmat: 'Includes Hazmat',
  packTimeLabels: 'Pack Time Label Generation',
  shipAsIs: 'Ship As Is',
  siteToStoreOnly: 'Site to Store',
  includesMultiShipmentPo: 'Includes Multi-Shipment PO'
};

// END CONSTANTS

// ENUMS

export enum OrderType {
  ShipAlone = 'ship_alone',
  SingleItem = 'single_item',
  MultiItem = 'multi_item',
  SingleOrMulti = 'single_or_multi'
}

export enum SkuVariety {
  SingleSku = 'single_sku',
  MultiSku = 'multi_sku',
  SingleOrMultiSku = 'single_or_multi_sku'
}

export enum PickMethod {
  BULK = 'bulk_pick',
  CLUSTER = 'cluster_pick'
}

// END ENUMS

// EXPORTED INTERFACES

export interface BatchCounts {
  new: number;
  confirmed: number;
  completed: number;
  cancelled: number;
}

export interface CompactBatchCounts {
  not_started: number;
  in_progress: number;
  active: number;
  new: number;
  completed?: number;
  cancelled?: number;
}

export interface BatchData {
  id: number;
  type: string;
  reservation: Reservation;
  shipperCompany: ShipperCompany;
  totalOrderCount: number;
  completedOrderCount: number;
  skuCount: number;
  itemCount: number;
  documents: BatchDocument[];
  batchAttributes: BatchAttribute[];
  created: Date;
  earliestEstimatedShipDate: Date;
  serviceTypes?: string[];
  waveParameters?: WaveParameters;
  status?: TransitionState;
  processStatus?: TransitionState;
  paused?: boolean;
  packStartedAt?: Date;
  metadata?: BatchMetadata;
}

export interface BatchAttribute {
  name: string;
  value: any;
}

export interface BatchMetadata {
  pickStartedAt?: Date;
  pickStartedBy?: string;
}

export interface BatchDetails {
  id: number;
  type: string;
  reservation: Reservation;
  orderCount: number;
  skuCount: number;
  itemCount: number;
  documents: BatchDocument[];
  status: TransitionState;
  batchAttributes: BatchAttribute[];
  metadata: BatchMetadata;
}

export interface BatchDocument {
  id: number;
  name: string;
  type: string;
  documentType: DocumentType;
  views: DocumentView[];
  printed: boolean;
  createdAt?: Date;
}

export interface BatchError {
  id: number;
  msg: string;
}

export interface BatchOrdersRequest {
  filters: OrderFilters;
  continuationToken?: string;
  pageSize?: number;
  sortOrder?: string;
}

export interface BatchPreview {
  inventory: Inventory[];
  orderIds: number[];
  batchAttributes: object;
}

export interface BatchRequest {
  continuationToken?: string;
  wave: WaveParameters;
}

export interface BatchResponse {
  batch: BatchDetails;
}

export interface BatchesRequest {
  continuationToken: string;
  pageSize: number;
  sortBy?: string;
  sortOrder: SortOrder;
  filters: BatchFilters;
}

export interface BatchesResponse {
  continuationToken: string;
  counts: BatchCounts;
  batches: BatchData[];
}

export interface BatchSchedule {
  type: string;
  id: string;
  reservation: Reservation;
  daysOfWeek: string[];
  hourOfDay: number;
  minuteOfHour: number;
}

export interface BatchScheduleResponse {
  batchSchedules: BatchSchedule[];
}

export interface CreateBatchScheduleRequest {
  type: string;
  reservation: Reservation;
  daysOfWeek: string[];
  hourOfDay: number;
  minuteOfHour: number;
}

export interface CreateBatchScheduleResponse {
  batchSchedules: BatchSchedule[];
}

export interface DeleteBatchScheduleRequest {
  id: string;
  reservation: Reservation;
}

export interface DeleteBatchScheduleResponse {
  status: number;
}

export interface CompactBatchesResponse {
  continuationToken: string;
  counts: CompactBatchCounts;
  batches: BatchData[];
}

export interface BulkBatchRequest {
  batchIds: number[];
}

export interface BulkOrderRequest {
  orderIds: number[];
}

export interface BulkCompleteResponse {
  completedBatchIds: number[];
  completedOrderIds: number[];
  ignoredOrderIds: number[];
  failedOrders: FailedOrder[];
}

export interface FailedOrder {
  orderId: number;
  reason: string;
}

export interface BulkConfirmResponse {
  confirmedBatchIds: number[];
  confirmedOrderIds: number[];
  ignoredOrderIds: number[];
}

export interface CancelBatchResponse {
  successfullyCancelled: number[];
  hasCompletedOrders: number[];
  ignored: number[];
}

export interface Carrier {
  id: string;
  displayName: string;
  serviceTypes: string[];
}

export interface CarrierResponse {
  carriers: Carrier[];
}

export interface CarrierCount {
  enum: string;
  name: string;
  total: number;
}

export interface CreateRequest {
  preview: Preview;
}

export interface CreateResponse {
  createdBatches: Batch[];
  reservation: Reservation;
}

export interface Inventory {
  inventoryId: number;
  sku: string;
}

export interface Order {
  id: number;
  carrier: string;
  serviceType: string;
  purchaseOrder: string;
  shipAlone: boolean;
  shipOn: Date;
  type: string;
  address?: string;
  customerOrderUuid?: string;
  isRebinned: boolean;
}

interface OrderCarrier {
  type: string;
  key: string;
  name: string;
}

export interface OrderDetail {
  id: number;
  status: TransitionState;
  itemCount: number;
  customerUuid: string;
  parentCustomerUuid: string;
  purchaseOrder: string;
  shipTo: string;
  shipToPhone?: string;
  shipToEmail?: string;
  addressLines: string[];
  addressLocality: string;
  addressRegion: string;
  addressPostcode: string;
  addressCountry: string;
  carrier: OrderCarrier;
  carrierServiceType: string;
  carrierAssignedId: string;
  carrierShipmentInfoId: number;
  shippingReference1?: string;
  shippingReference2?: string;
  documents?: Document[];
  items: OrderInventory[];
  requiredLabels: OrderRequiredLabel[];
  requiredAttributes: ShipmentRequiredProperty[];
  cartonizations: Cartonization[];
  hasUploadedShippingLabel: boolean;
  isRebinned: boolean;
  splitShipmentRequiredLabels?: OrderRequiredLabel[];
}

export interface OrderFilters {
  reservationId?: number;
  unbatched?: boolean;
  batchId?: number;
  orderStatus?: string;
  orderStatuses?: string[];
  orderId?: number;
  purchaseOrder?: string;
  cutoffDate?: Date;
  skus?: string[];
  carriers?: string[];
  serviceTypes?: string[];
  orderType?: OrderType;
  shipmentType?: OrderType;
  shipAsIs?: boolean;
  includesHazmat?: boolean;
  packTimeLabels?: boolean;
  shipmentProfile?: string;
  siteToStoreOnly?: boolean;
  includesMultiShipmentPo?: boolean;
  address?: string;
  customerOrderUuid?: string;
}

export interface OrderInventory {
  packaging: string;
  quantity: number;
  sku: string;
  type?: string;
  id?: number;
  description?: string;
}

export interface OrderRequiredLabel {
  type: string;
  carrierAssignedId?: string;
  filename: string;
  url?: string;
  isExternalLabel: boolean;
}

export interface Cartonization {
  shipmentPackagingId?: string;
  shipmentPackagingType: string;
  shipmentPackagingDescription: string;
  items: OrderInventory[];
}

export interface ShipmentRequiredProperty {
  key: string;
  presentationKey: string;
  value: string;
  type: string;
}

export interface OrderLocation {
  id: number;
  label: string;
  type: string;
}

export interface SkuSingleLineItem {
  sku: string;
  allShipmentSkus: string[];
  description: string;
  shipAlone?: boolean;
  quantity: number;
  eachesPerPallet: number;
  packaging: string;
  cartonsPerPallet?: number;
  totalShipments?: number;
  captureSerialNumber?: boolean;
  externalShippingLabel?: boolean;
  hazmat?: boolean;
  multipleBatchDocs?: boolean;
  overbox?: boolean;
  packTimeLabelGeneration?: boolean;
  shipAsIs?: boolean;
  siteToStore?: boolean;
  topMoverQuantity?: number;
}

export interface OrderResponse {
  reservation: Reservation;
  shipperCompany: ShipperCompany;
  order: Order;
  locations: OrderLocation[];
  inventory: OrderInventory[];
}

export interface OrderStateCounts {
  new: number;
  confirmed: number;
  packed: number;
  completed: number;
  cancelled: number;
}

export interface OrdersResponse {
  continuationToken: string;
  counts: OrderStateCounts;
  skuSummary: SkuSingleLineItem[];
  orders: OrderResponse[];
}

export interface PartialCompleteResponse {
  successfullyCancelled: number[];
  notApplicable: number[];
  ignored: number[];
}

export interface PreviewResponse {
  preview: WavePreview;
  reservation: Reservation;
}

export interface WaveByShipmentIdsParams {
  reservationId: number;
  shipmentIds: number[];
  batchCount?: number;
  stagingLocationId?: number;
}

export interface WaveByShipmentIdsResponse {
  reservation: {
    type: string;
    id: number;
  };
  result: {
    successfullyBatchedShipments: number[];
    failedShipments: number[];
    warnings: string[];
  };
}

export interface ActiveShipOnDatesResponse {
  shipOnDates: string[];
}

export interface ShipperCompany {
  id: number;
  name: string;
  type: string;
}

export interface SkuData {
  skus: string[];
}

export interface SkuTypeAheadRequest {
  reservationId: number;
  skuSubstring: string;
}

export interface WaveParameters {
  reservationId: number;
  maxOrders: number;
  batchCount?: number;
  cutoffDate?: Date;
  shipByTime?: Date;
  skus?: string[];
  carriers?: string[];
  serviceTypes?: string[];
  orderType?: OrderType;
  shipmentType?: OrderType;
  skuVariety?: SkuVariety;
  purchaseOrder?: string;
  includesHazmat?: boolean;
  packTimeLabels?: boolean;
  shipAsIs?: boolean;
  shipmentProfile?: string;
  siteToStoreOnly?: boolean;
  packaging?: string;
  pickMethod?: PickMethod;
  includesMultiShipmentPo?: boolean;
  postalCodeRange?: PostalRange;
  fullPalletPull?: boolean;
  customAttributes?: object;
}

export interface PostalRange {
  start: string;
  end: string;
}

export interface WavePreview {
  batches: BatchPreview[];
  id: number;
  type: string;
}

export enum BatchErrorType {
  ITEM_EXCEEDS_DIMS = 'item_exceeds_dims',
  ITEM_MISSING_DIMS = 'item_missing_dims',
  SHIPMENT_TOO_BIG = 'shipment_too_big',
  SHIPMENT_NOT_FOUND = 'shipment_not_found',
  NO_REBINS_CONFIGURED = 'no_rebins_configured',
  NO_PICK_CARTS_CONFIGURED = 'no_pick_carts_configured',
  UNABLE_TO_SPLIT_SHIPMENT = 'unable_to_split_shipment',
  ITEM_EXCEEDS_PERCENTAGE_FILL = 'item_exceeds_percentage_fill',
  INTERNAL_ERROR = 'internal_error',
  ITEM_NEEDS_RESTOCK = 'item_needs_restock',
  SHIPMENT_RECARTONIZED = 'shipment_recartonized',
  NO_AVAILABLE_CARTON_FOR_SHIPMENT = 'no_available_carton_for_shipment',
  NO_SUITABLE_PICK_CART_FOUND = 'no_suitable_pick_cart_found',
  LOCATION_LOCKED = 'location_locked',
  INSUFFICIENT_QUANTITY_ERROR = 'insufficient_quantity_error',
  INSUFFICIENT_INVENTORY_ERROR = 'insufficient_inventory_error',
  NO_AVAILABLE_INVENTORY_FOR_SHIPMENT = 'no_available_inventory_for_shipment',
  CONFLICTING_SKU_REQUIREMENTS = 'conflicting_sku_requirements'
}

export enum BatchErrorGroupingMethods {
  SKU = 'sku',
  SHIPMENT_PACKAGING = 'shipmentPackaging',
  LOCATION_ID_AND_LOCK_REASON = 'locationIdAndLockReason',
  INVENTORY_ID = 'inventoryId'
}

export interface WavingAlert {
  id: number;
  reservationId: number;
  waveId?: number;
  batchId?: number;
  shipmentId?: number;
  inventoryId?: number;
  lotCode?: string;
  batchErrorType: BatchErrorType;
  readAt?: Date;
  resolvedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  itemCode?: string;
  details?: string;
  quantity?: number;
  shipmentPackagingId?: string;
  shipmentPackagingDescription?: string;
  locationId?: number;
  locationLabel?: string;
  lockReason?: string;
}

export interface BatchErrorTemplates {
  groupErrorsBy?: BatchErrorGroupingMethods;
  groupIntoOne: boolean;
  getErrorTemplate(alert);
}

export interface ParsedWavingAlert {
  waveId?: number;
  batchIds?: number[];
  sku?: string;
  itemId?: number;
  lotCode?: string;
  batchErrorType: BatchErrorType;
  alertIds: number[];
  shipmentIds?: number[];
  latestTime: number;
  detail?: React.ReactFragment;
  totalQuantity?: number;
  shipmentPackagingDescription?: string;
  locationId?: number;
  locationLabel?: string;
  lockReason?: string;
}

export interface UnresolvedWavingAlertsResponse {
  unresolvedAlerts: WavingAlert[];
}

// END EXPORTED INTERFACES

// INTERNAL INTERFACES

interface Batch {
  id: number;
  orders: BatchedOrder[];
  pickMethod: PickMethod;
  type: string;
}

interface BatchFilters {
  batchStatus: TransitionState;
  warehouseId: number;
  reservationId?: number;
  batchId?: number;
  carrier?: string;
  orderId?: number;
  orderShipOn?: Date;
  purchaseOrder?: string;
  serviceTypes?: string[];
  startDate?: Date;
  endDate?: Date;
}

interface BatchedOrder {
  id: number;
  type: string;
}

interface DocumentView {
  time: Date;
  viewer: string;
}

interface Preview {
  id: number;
  type: string;
}

// END INTERNAL INTERFACES
