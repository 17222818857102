import * as React from 'react';
import {Column} from '@flexe/ui-components';
import UserManagementService from '../UserManagementService';
import {Reservation, Role, User, UserWithRoles, Warehouse} from '../userManagementInterfaces';
import UsersSection from './UsersSection';
import ReservationUsers from './reservations/ReservationUsers';
import WarehouseUsers from './warehouses/WarehouseUsers';

interface Props {
  activeUsers: User[];
  adminUserNames: string;
  allReservationRoles: Role[];
  allWarehouseRoles: Role[];
  authenticityToken: string;
  companyName: string;
  correspondenceOnlyUsers: User[];
  currentUserId: number;
  inactiveUsers: User[];
  isMobileEnabled: boolean;
  isVisible: boolean;
  reservations: Reservation[];
  allCompanyRoles: Role[];
  userManagementService: UserManagementService;
  warehouses: Warehouse[];
  onWarehouseRoleToggleAsync: (
    user: UserWithRoles,
    warehouseId: number,
    role: string,
    enableRole: boolean
  ) => Promise<void>;
  onReservationRoleToggleAsync: (
    user: UserWithRoles,
    reservationId: number,
    role: string,
    enableRole: boolean
  ) => Promise<void>;
  onDeactivateAsync: (user: User) => Promise<void>;
  onReactivateInactiveUserAsync: (user: User) => Promise<void>;
  onReactivateCorrespondenceOnlyUserAsync: (user: User) => Promise<void>;
  onRoleToggleActiveUserAsync: (user: User, role: string, enableRole: boolean) => Promise<void>;
}

const Users = ({
  activeUsers,
  adminUserNames,
  allReservationRoles,
  allWarehouseRoles,
  authenticityToken,
  companyName,
  correspondenceOnlyUsers,
  currentUserId,
  inactiveUsers,
  isMobileEnabled,
  isVisible,
  reservations,
  allCompanyRoles,
  warehouses,
  onWarehouseRoleToggleAsync,
  onReservationRoleToggleAsync,
  onDeactivateAsync,
  onReactivateInactiveUserAsync,
  onReactivateCorrespondenceOnlyUserAsync,
  onRoleToggleActiveUserAsync
}: Props) => {
  const isShipper = warehouses.length === 0;
  return !isVisible ? null : (
    <Column>
      {activeUsers.length !== 0 && (
        <UsersSection
          users={activeUsers}
          companyName={companyName}
          currentUserId={currentUserId}
          onReactivateAsync={onReactivateInactiveUserAsync}
          onDeactivateAsync={onDeactivateAsync}
          onRoleToggleAsync={onRoleToggleActiveUserAsync}
          isActiveUsers={true}
          isShipper={isShipper}
          allCompanyRoles={allCompanyRoles}
          title="Active Users"
          collapseByDefault={false}
        />
      )}

      {correspondenceOnlyUsers.length !== 0 && (
        <UsersSection
          users={correspondenceOnlyUsers}
          currentUserId={currentUserId}
          onReactivateAsync={onReactivateCorrespondenceOnlyUserAsync}
          onDeactivateAsync={onDeactivateAsync}
          onRoleToggleAsync={(user: User, role: string, enableRole: boolean) => Promise.resolve()}
          isActiveUsers={false}
          isShipper={isShipper}
          allCompanyRoles={[]}
          title="Correspondence-only Users (unable to log in)"
          collapseByDefault={false}
        />
      )}

      {reservations.length !== 0 && (
        <ReservationUsers
          activeUsers={activeUsers}
          adminUserNames={adminUserNames}
          allReservationRoles={allReservationRoles}
          authenticityToken={authenticityToken}
          onRoleToggleAsync={onReservationRoleToggleAsync}
          reservations={reservations}
        />
      )}

      {warehouses.length !== 0 && (
        <WarehouseUsers
          activeUsers={activeUsers}
          adminUserNames={adminUserNames}
          allWarehouseRoles={allWarehouseRoles}
          authenticityToken={authenticityToken}
          isMobileEnabled={isMobileEnabled}
          onRoleToggleAsync={onWarehouseRoleToggleAsync}
          warehouses={warehouses}
        />
      )}

      {inactiveUsers.length !== 0 && (
        <UsersSection
          users={inactiveUsers}
          currentUserId={currentUserId}
          onReactivateAsync={onReactivateInactiveUserAsync}
          onDeactivateAsync={onDeactivateAsync}
          onRoleToggleAsync={(user: User, role: string, enableRole: boolean) => Promise.resolve()}
          isActiveUsers={false}
          isShipper={isShipper}
          allCompanyRoles={[]}
          title="Inactive Users"
          collapseByDefault={true}
        />
      )}
    </Column>
  );
};

export default Users;
