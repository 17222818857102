/** @jsxRuntime classic */
/** @jsx jsx */

import * as React from 'react';
import {useState} from 'react';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {Button, Column, Heading, Text, useTable} from '@flexe/ui-components';
import {roleToTitleCase} from '../../helpers';
import UserWithRoles from '../shared/UserWithRoles';
import {Role, SiteType, User, UserWithRoles as UserWithRolesInfo, Warehouse} from '../../userManagementInterfaces';

import {rowStyle} from '../../styles';
import AddUserModal from '../shared/AddUserModal';
import RoleTooltip from '../shared/RoleTooltip';

const nameHeaderStyle = css({width: '40%'});
const noWarehouseUsersStyle = css({backgroundColor: tokens.color.base.red.v50.value});

interface Props {
  activeUsers: User[];
  adminUserNames: string;
  authenticityToken: string;
  onRoleToggleAsync: (user: UserWithRolesInfo, warehouseId: number, role: string, enableRole: boolean) => Promise<void>;
  warehouse: Warehouse;
  warehouseRoles: Role[];
}

const Warehouse = ({
  activeUsers,
  adminUserNames,
  authenticityToken,
  onRoleToggleAsync,
  warehouse,
  warehouseRoles
}: Props) => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [collapseTable, setCollapseTable] = useState(false);
  const {tableStyles} = useTable({});

  const handleRoleToggleAsync = async (user: UserWithRolesInfo, role: string, enableRole: boolean) => {
    await onRoleToggleAsync(user, warehouse.id, role, enableRole);
  };

  const handleAddUserButtonPress = () => {
    setIsAddUserModalOpen(true);
  };

  const handleModalClose = () => {
    setIsAddUserModalOpen(false);
  };

  let localWarehouseRoles = [...warehouseRoles];

  if (!warehouse.isInventoryControlRoleEnabled) {
    localWarehouseRoles = localWarehouseRoles.filter((role) => role.title !== 'inventory_control');
  }

  const usersWhoCanBeAdded = activeUsers.filter((user) => !warehouse.users.some((u) => u.id === user.id));

  return (
    <Column tag="section" alignmentHorizontal="start">
      <Heading level="h3">
        {warehouse.name} <small>{warehouse.address}</small>
      </Heading>
      <div title={usersWhoCanBeAdded.length === 0 ? 'All active users are already assigned to this warehouse' : null}>
        <Button isDisabled={usersWhoCanBeAdded.length === 0} onPress={handleAddUserButtonPress}>
          {'Add Users to Warehouse'}
        </Button>
        <Button onPress={() => setCollapseTable(!collapseTable)}>{collapseTable ? 'Show Users' : 'Hide Users'}</Button>
      </div>
      {isAddUserModalOpen && (
        <AddUserModal
          authenticityToken={authenticityToken}
          onModalClose={handleModalClose}
          roles={localWarehouseRoles.filter((role) => role.canBeToggled).map((role) => role.title)}
          siteId={warehouse.id}
          siteName={warehouse.name}
          siteType={SiteType.Warehouse}
          users={usersWhoCanBeAdded}
        />
      )}
      <table {...tableStyles}>
        <thead>
          <tr css={rowStyle}>
            <th css={nameHeaderStyle}>Name</th>
            {localWarehouseRoles.map((role) => (
              <th key={role.title}>
                {roleToTitleCase(role.title)} <RoleTooltip role={role} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {collapseTable ? (
            <tr>
              <td>Select "Show Users" to display</td>
            </tr>
          ) : warehouse.users.length === 0 ? (
            <tr css={rowStyle}>
              <td colSpan={localWarehouseRoles.length + 1} css={noWarehouseUsersStyle}>
                <Column>
                  <p>No Users Assigned to this Warehouse</p>
                  <Text tag="p">
                    Default Manager, Billing, and Operations roles assigned to company admin users:{' '}
                    <strong>{adminUserNames}</strong>
                  </Text>
                </Column>
              </td>
            </tr>
          ) : (
            <React.Fragment>
              {warehouse.users.map((user) => (
                <UserWithRoles
                  key={user.id}
                  onRoleToggleAsync={handleRoleToggleAsync}
                  user={user}
                  possibleRoles={localWarehouseRoles}
                />
              ))}
              <tr css={rowStyle}>
                <td colSpan={localWarehouseRoles.length + 1}>
                  * For any missing roles to this warehouse, they will default to the admin users:{' '}
                  <strong>{adminUserNames}</strong>
                </td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    </Column>
  );
};

export default Warehouse;
