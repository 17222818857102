import * as React from 'react';
import {FC} from 'react';
import {LegacyModal, Loader} from '@flexe/ui-components';
import {LpnsResponse} from '../../../lpns/LpnsInterfaces';
import {Location, Shipment} from '../ShipmentInterfaces';
import ErrorDisplay from '../../../shared/ErrorDisplay';
import FlexeButton from '../../../shared/FlexeButton';
import {renderLocationLink, renderLpnLink} from '../../../../libs/helpers';
import {ResponseError} from '../../../shared/CommonInterfaces';
import {ShipmentDetailsContext} from './ShipmentDetailsContext';
import {ShipmentLpnEditBoundary} from './ShipmentLpnEditBoundary';

interface Props {
  toggleModal: () => void;
  show: boolean;
  onConfirm: (palletCount: number) => void;
  lpn: LpnsResponse;
  shipment: Shipment;
  stagingLocation: Location;
}

export const ShipmentLpnConfirmRemoveModal: FC<Props> = (props) => {
  const {shipmentService} = React.useContext(ShipmentDetailsContext);
  // Don't create content when not showing. This FC will be redrawn when the prop changes so
  // it will correctly display the given lpn's data.
  if (!props.lpn || !props.show) {
    return null;
  }

  const [errorText, setErrorText] = React.useState<string>();
  const [removing, setRemoving] = React.useState<boolean>(false); // The remove RPC
  const [isRemovable, setIsRemovable] = React.useState<boolean>(true);

  const onConfirmHandler = async () => {
    try {
      setRemoving(true);
      const response = await shipmentService.removeLpnFromShipment({
        shipmentId: props.shipment.id,
        lpnBarcode: props.lpn.lpnBarcode
      });

      if (response.errors && response.errors.length > 0) {
        const detailStrings = response.errors.map((e: ResponseError) => e.detail).join('. & ');
        const errorDetails = ' Received the following errors: ' + detailStrings;
        setErrorText(`An error occurred! The LPN was NOT removed from the shipment.${errorDetails}`);
      } else {
        props.onConfirm(response.data.num_pallets);
      }
    } catch (e) {
      setErrorText(`An error occurred! The LPN may not have been removed from the shipment. ${e}`);
    } finally {
      setRemoving(false);
    }
  };

  const stagingLocationWarning =
    props.lpn.location.id === props.stagingLocation.id
      ? ' The LPN is currently in the staging location for this shipment. ' +
        'Please move it out of the staging location before completing the shipment.'
      : '';

  return (
    <LegacyModal
      id="shipment-lpn-remove-confirmation"
      transitionSpeed="fast"
      toggleModal={props.toggleModal}
      show={props.show}
      title={isRemovable ? 'Removing LPN' : "Can't Remove LPN"}
    >
      <div>
        <ErrorDisplay errorText={errorText} />
        <ShipmentLpnEditBoundary
          shipment={props.shipment}
          lpn={props.lpn}
          blockedAction={'remove'}
          toggleModal={props.toggleModal}
          onEditableChanged={setIsRemovable}
        >
          <div className="msg-body">
            You are about to remove LPN {renderLpnLink(props.lpn.lpnBarcode, false, props.lpn.lpnId)} from this
            shipment. It is in location&nbsp;
            {renderLocationLink(props.lpn.location.id, props.lpn.location.label)}.{stagingLocationWarning}
          </div>
          <div className="loading-container">
            <Loader loading={removing} />
          </div>
          <div className="remove-btn-container">
            <FlexeButton
              level="collapsed-link"
              handleClick={props.toggleModal}
              title="Keep the LPN on this shipment"
              text="Go Back"
            />

            <span className="button-spacing" />

            <FlexeButton
              level="danger"
              handleClick={onConfirmHandler}
              isDisabled={removing || !!errorText}
              title="Remove the LPN from this shipment"
              text="Remove LPN"
            />
          </div>
        </ShipmentLpnEditBoundary>
      </div>
    </LegacyModal>
  );
};
