import {Conversions, Item, SortOrder} from '../../shared/CommonInterfaces';

export interface Destination {
  name: string;
  address_line_1: string;
  address_line_2?: string;
  address_line_3?: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  ship_to_code?: string;
  email?: string;
  phone?: string;
}

export interface Transportation {
  ship_mode?: string;
  carrier?: Carrier;
  service_type?: string;
  tracking_number?: string;
  stop_id?: number;
  load_id?: string;
  pro_number?: string;
  bol_number?: string;
  trailer_number?: string;
  seal_number?: string;
  scheduled_ship_date?: string;
}

interface Carrier {
  display_name: string;
  scac: string;
}

export interface Reservation {
  id: number;
  shipper_name: string;
  warehouse_id: number;
  warehouse_name: string;
  warehouse_timezone: string;
  is_mobile_location_enabled: boolean;
  is_split_waves_enabled: boolean;
  is_freight_consolidation_enabled: boolean;
}

export interface LineItem {
  id: number;
  inventory_id: number;
  description: string;
  sku: string;
  lot_code: string;
  expiration_date: string;
  barcode: string;
  quantity: number;
  packaging: string;
  units_per_packaging?: Conversions;
  ordered_quantity: number;
  ordered_packaging: string;
  ordered_units_per_packaging?: Conversions;
}

export interface Location {
  id: number | null;
  name: string | null;
  is_updatable?: boolean;
}

export interface Shipment {
  id: number;
  uuid: string;
  destination_tag?: string;
  purchase_order?: string;
  customer_reference_number?: string;
  order_id?: number;
  fulfillment_batch_id?: number;
  status: string;
  ship_by: Date;
  ship_after?: Date;
  destination: Destination;
  transportation: Transportation;
  line_items: LineItem[];
  reservation: Reservation;
  num_pallets_built?: number;
  notes?: string;
  current_location?: Location;
  outbound_manifest_id?: number;
  is_warehouse_sfs_enabled?: boolean;
  wave_id?: number;
  freight_load_group?: string;
}

export interface RetailVarianceConfig {
  overage_percentage: number;
  short_percentage: number;
  reservation_id: number;
}

export interface ShipmentDetailsInterface {
  shipment: Shipment;
  retail_variance_config: RetailVarianceConfig;
  recommended_pallet_quantity: number;
}

export interface ShipmentResponse {
  continuation_token: string;
  counts: number;
  shipments: Shipment[];
}

export interface ShipmentOverageResponse {
  overages: Item[];
}

export interface StagingLocationResponseInterface {
  staging_location: Location;
}

export interface InvalidLpnsAtStagingLocation {
  invalid_lpns: string[];
}

export interface UpdateStagingLocationInterface {
  shipmentIds: number[];
  locationId: number;
  warehouseId: number;
}

export enum FilterKeys {
  DestinationTag = 'destination_tag',
  ShipmentId = 'id',
  PurchaseOrder = 'purchase_order',
  Sku = 'sku',
  LotCode = 'lot_code',
  ExpirationDate = 'expiration_date',
  Lpn = 'lpn_barcode',
  Scac = 'scac',
  Bol = 'bol_number',
  ProNumber = 'pro_number',
  OutboundFreightManifest = 'outbound_freight_manifest',
  OrderReferenceId = 'order_reference_id',
  CustomerReferenceNumber = 'customer_reference_number',
  LoadId = 'load_id',
  FreightLoadId = 'freight_load_id',
  FreightLoadGroup = 'freight_load_group'
}

export enum FilterSearchTypes {
  Is = 'Is',
  Contains = 'Contains / Is'
}

export enum SortByColumns {
  CreatedAt = 'created_at',
  ShipmentId = 'id',
  PurchaseOrder = 'purchase_order',
  ShipBy = 'ship_on'
}

export interface FilterParams {
  [FilterKeys.DestinationTag]?: string;
  [FilterKeys.ShipmentId]?: number;
  [FilterKeys.PurchaseOrder]?: string;
  [FilterKeys.Scac]?: string;
  [FilterKeys.Bol]?: string;
  [FilterKeys.Lpn]?: string;
  [FilterKeys.Sku]?: string;
  [FilterKeys.LotCode]?: string;
  [FilterKeys.ExpirationDate]?: Date;
  [FilterKeys.OutboundFreightManifest]?: string; // Note - this is treated as an int on the webserver
  [FilterKeys.OrderReferenceId]?: number;
  [FilterKeys.LoadId]?: string;
  [FilterKeys.FreightLoadId]?: number;
}

export interface GetShipmentsParams extends FilterParams {
  continuation_token?: string;
  mode?: string | null;
  page_size?: number;
  reservation_id?: number;
  ship_by_start?: Date;
  ship_by_end?: Date;
  sort_by: SortByColumns;
  sort_direction: SortOrder;
  status?: string[];
  warehouse_id: number;
  pro_number?: string;
  omni_only?: boolean;
}

export interface SelectedShipment {
  shipmentId: number;
  reservationId: number;
  shipMode: string;
  status: string;
  destinationTag: string;
  freightLoadGroup: string;
  lineItems: LineItem[];
  isSplitWavesEnabled: boolean;
  isFreightConsolidationEnabled: boolean;
}

export const shipmentToSelectedShipment = (shipment: Shipment): SelectedShipment => ({
  shipmentId: shipment.id,
  reservationId: shipment.reservation.id,
  shipMode: shipment.transportation.ship_mode,
  status: shipment.status,
  destinationTag: shipment.destination_tag,
  freightLoadGroup: shipment.freight_load_group,
  lineItems: shipment.line_items,
  isSplitWavesEnabled: shipment.reservation.is_split_waves_enabled,
  isFreightConsolidationEnabled: shipment.reservation.is_freight_consolidation_enabled
});

export interface ShipmentStatus {
  className: string;
  text: string;
}

export interface UpdateShipmentRequest {
  shipmentId: number;
  numPalletsBuilt?: number;
  notes?: string;
  stagingLocationId?: number;
}

export interface RemoveLpnFromShipmentRequest {
  shipmentId: number;
  lpnBarcode: string;
}

export interface RemoveLpnFromShipmentResponse {
  shipment_id: number;
  num_pallets: number;
}
export interface ReplaceLpnsOnShipmentRequest {
  shipmentId: number;
  lpnIdToRemove: number;
  lpnIdToAdd: number;
}

export interface ReplaceLpnsOnShipmentResponse {
  shipment_id: number;
  num_pallets: number;
}

export interface RegenerateFreightPackingList {
  shipment_id: number;
}

export interface ReservationDisplayInfos {
  [reservationId: number]: ReservationDisplayInfo;
}

export interface ReservationDisplayInfo {
  displayLpns: boolean;
}

export interface FilterOptionsForWarehouseResponse {
  freight_load_groups: string[];
  destination_tags: string[];
}

export interface SetShipmentShipOnTimeReasonCodeResponse {
  shipment_ids: number[];
}
