import * as React from 'react';
import {LegacyModal, Modal} from '@flexe/ui-components';

export interface Props {
  title: string;
  toggle: () => void;
}

export const ConfirmationFormModal: React.FC<Props> = (props) => {
  const title = (
    <div>
      <span className="title">{props.title}</span>
    </div>
  );

  return (
    <React.Fragment>
      <div className={'confirmation-form-modal'} data-testid={'confirmation-form-modal'}>
        <LegacyModal id={'complete_load_modal'} size={'small'} show={true} toggleModal={props.toggle} title={title}>
          <div className={'modal-contents'} data-testid={'confirmation-form-modal-contents'}>
            {props.children}
          </div>
        </LegacyModal>
      </div>
    </React.Fragment>
  );
};
