import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {cloneDeep, get, groupBy, isEmpty, toInteger, uniq} from 'lodash';
import classNames from 'classnames';
import {Loader} from '@flexe/ui-components';
import ItemMasterService from '../services/ItemMasterService';
import FileDownloadLink from '../../shared/FileDownloadLink';
import {DropDownOption} from '../DropDown';
import LocationsService from '../../locations/LocationsService';
import CycleCountsService from '../services/CycleCountsService';
import {ActorInfo, FilterOption} from '../CommonInterfaces';
import WarehouseService from '../services/WarehouseService';
import InventoryService, {MaterialStatus, RetreiveMaterialStatusesRequest} from '../services/InventoryService';
import {
  CycleCountBatch,
  CycleCountDetails,
  CycleCountItem,
  CycleCountItemMetadata,
  CycleCountItemParams,
  CycleCountReasonCode,
  CycleCountStatus,
  CycleCountType,
  MobileUser
} from './CycleCountInterfaces';
import CCItemExpando from './CCItemExpando';
import {isMissingLpn, isPlaceholderItem, isUnexpectedLpn} from './helpers';
import CCDetailSidebar from './CCDetailSidebar';
import CycleCountStopActiveWorkersModal from './CycleCountStopActiveWorkersModal';
import CycleCountCannotEditModal from './CycleCountCannotEditModal';
import CycleCountCancelModal from './CycleCountCancelModal';
import CycleCountSubmitModal from './CycleCountSubmitModal';
import CycleCountAssignUserModal from './CycleCountAssignUserModal';
import CycleCountCompleteModal from './CycleCountCompleteModal';

interface Props {
  authenticityToken: string;
  isShipper: boolean;
  cycleCountsService: CycleCountsService;
  locationsService: LocationsService;
  warehouseService: WarehouseService;
  itemMasterService: ItemMasterService;
  inventoryService: InventoryService;
  currentWarehouse: FilterOption;
  warehouses: FilterOption[];
  enableLpnCycleCounts: boolean;
  enableLpnPropertiesCCButton: boolean;
  carryForwardItdIdAfterDecomp: boolean;
  hideAddItemButton: boolean;
}

interface Params {
  id: string;
}

export interface LpnDetailsModalState {
  id: string;
  visible: boolean;
}

export interface ItemPropertiesDetailsModalState {
  itemId: string;
  locationId: string;
  visible: boolean;
}

const CycleCountDetail: React.FC<Props & RouteComponentProps<Params>> = (props) => {
  // used to prevent state updates on unmounted components
  const isMountedRef = React.useRef(null);
  const isShipper = props.isShipper;

  const [pageErrors, setPageErrors] = React.useState<string[]>(null);
  const [cycleCountId, setCycleCountId] = React.useState<number>(toInteger(props.match.params.id));
  const [cycleCount, setCycleCount] = React.useState<{cc: CycleCountDetails; source: string}>({cc: null, source: ''});
  const [metadataChanged, setMetadataChanged] = React.useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
  const [showSubmitModal, setShowSubmitModal] = React.useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = React.useState<boolean>(false);
  const [showCannotEdit, setShowCannotEdit] = React.useState<boolean>(false);
  const [showLotCodeChangedWarning, setLotCodeChangedWarning] = React.useState<string>(null);
  const [showStopWorkersModal, setShowStopWorkersModal] = React.useState<boolean>(false);
  const [showAssignUserModal, setShowAssignUserModal] = React.useState<boolean>(false);
  const [showLpnDetailsModal, setShowLpnDetailsModal] = React.useState<LpnDetailsModalState>({id: '', visible: false});
  const [showItemPropertiesDetailsModal, setShowItemPropertiesDetailsModal] = React.useState<
    ItemPropertiesDetailsModalState
  >({itemId: '', locationId: '', visible: false});
  const [awaitingResponse, setAwaitingResponse] = React.useState<{status: boolean; source: string}>({
    status: false,
    source: ''
  });
  const [usesMobileLocations, setUsesMobileLocations] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [byLocation, setByLocation] = React.useState<boolean>(false);
  const [viewByLocation, setViewByLocation] = React.useState<boolean>(false);
  const [knownBatches, setKnownBatches] = React.useState<CycleCountBatch[]>([]);
  const [knownMobileUsers, setKnownMobileUsers] = React.useState<DropDownOption[]>([]);
  const [assignToUserId, setAssignToUserId] = React.useState<number | string>(null);
  const [cycleCountReasonCodes, setCycleCountReasonCodes] = React.useState<CycleCountReasonCode[]>([]);
  const [reasonCode, setReasonCode] = React.useState<number | string>('');
  const [purchaseOrder, setPurchaseOrder] = React.useState<string>('');
  const [editNotes, setEditNotes] = React.useState<string>('');
  const [missingLpns, setMissingLpns] = React.useState<number>(0);
  const [unexpectedLpns, setUnexpectedLpns] = React.useState<number>(0);
  const [reviewing, setReviewing] = React.useState<boolean>(false);
  const [isLpnReservationPresent, setIsLpnReservationPresent] = React.useState<boolean>(false);
  const [inventoryInfos, setInventoryInfos] = React.useState(null);
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);

  const showCycleCountTable =
    cycleCount &&
    cycleCount.cc &&
    cycleCount.cc.cycleCountItems &&
    cycleCount.cc.cycleCountItems.length > 0 &&
    !loading;
  const reasonsThatRequirePO = ['vendor_damaged', 'vendor_overage', 'adjust_past_receipt'];

  React.useEffect(() => {
    isMountedRef.current = true;
    loadCycleCount();
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    isMountedRef.current = true;
    if (cycleCount.cc) {
      saveCycleCountItemUpdates();
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [metadataChanged]);

  React.useEffect(() => {
    isMountedRef.current = true;
    loadMobileFeatures();
    return () => {
      isMountedRef.current = false;
    };
  }, [usesMobileLocations]);

  React.useEffect(() => {
    isMountedRef.current = true;
    if (cycleCount.source === 'loadCycleCount' && !editing) {
      checkIfLPNEnabled();
      loadAdditionalConfigs();
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [cycleCount]);

  React.useEffect(() => {
    isMountedRef.current = true;
    if (awaitingResponse.status === false && awaitingResponse.source === 'stopActiveWorkers') {
      toggleStopWorkersModal();
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [awaitingResponse]);

  React.useEffect(() => {
    isMountedRef.current = true;
    if (cycleCount.cc) {
      retrieveInventoryInfosForCCItemsViaItemMaster();
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [cycleCount]);

  React.useEffect(() => {
    validateMetadata();
  }, [cycleCount.cc, inventoryInfos, editing]);

  const insertLpnDetailIdsToCycleCountItems = (
    lpnBarcodeToId: Map<string, number>,
    cycleCountItems: CycleCountItem[]
  ) => {
    cycleCountItems.forEach((cci) => {
      if (lpnBarcodeToId.has(cci?.lpnBarcode)) {
        cci.lpnId = lpnBarcodeToId.get(cci?.lpnBarcode);
      }
    });
    return cycleCountItems;
  };

  const loadCycleCount = async () => {
    let errs;
    setLoading(true);
    const response = await props.cycleCountsService.getCycleCount(cycleCountId);
    if (response.errors && response.errors.length) {
      errs = props.cycleCountsService.processErrors(response.errors);
    }
    const ccItems = insertLpnDetailIdsToCycleCountItems(
      new Map<string, number>(Object.entries(response.data.lpnBarcodeToId)),
      response.data.cycleCount.cycleCountItems
    );
    setCycleCount({cc: {...response.data.cycleCount, cycleCountItems: ccItems}, source: 'loadCycleCount'});
    setByLocation(response.data.cycleCount ? response.data.cycleCount.countType === CycleCountType.location : false);
    setViewByLocation(
      response.data.cycleCount ? response.data.cycleCount.countType === CycleCountType.location : false
    );
    setPageErrors(errs);
    setCycleCountReasonCodes(response.data.cycleCountReasonCodes);
    setLoading(false);
    if (
      response.data.cycleCount.state === CycleCountStatus.inReview ||
      response.data.cycleCount.state === CycleCountStatus.completed
    ) {
      checkMissingAndUnexpectedLpns(response.data.cycleCount);
      setReviewing(true);
    } else {
      setReviewing(false);
    }
  };

  const loadAdditionalConfigs = async () => {
    const [usesMobile] = await Promise.all([loadLocationConfig()]);
    if (usesMobile) {
      loadMobileFeatures();
    }
    setUsesMobileLocations(usesMobile);
  };

  const loadMobileFeatures = async () => {
    const [kBatches, kMobileUsers] = await Promise.all([loadBatchInfo(), loadMobileUsers()]);
    setKnownBatches(kBatches);
    setKnownMobileUsers(kMobileUsers);
  };

  const loadLocationConfig = async () => {
    const reservationId = get(cycleCount.cc, 'reservation.id');
    if (reservationId) {
      const response = await props.warehouseService.getReservationLocationConfig(reservationId);
      return get(response, 'data.reservationLocationConfig.locationsFlag') === 'mobile_enabled';
    } else {
      return true;
    }
  };

  /**
   * Load all reservations associated with the current warehouse and,
   * if any have lpn enabled, set it to true.
   */
  const checkIfLPNEnabled = async () => {
    const warehouseId = get(cycleCount.cc, 'warehouse.id');
    const response = await props.warehouseService.getReservationsForWarehouse(warehouseId);
    setIsLpnReservationPresent(response.data.reservations.some((res) => res.lpnEnabled));
  };

  const loadBatchInfo = async () => {
    setLoading(true);
    const isInProgress = cycleCount.cc && cycleCount.cc.state === CycleCountStatus.inProgress;
    const featureFlagOn = usesMobileLocations;
    let kBatches: CycleCountBatch[];
    if (isInProgress && featureFlagOn) {
      const response = await props.cycleCountsService.getAllBatches(cycleCountId);
      kBatches = (get(response, 'data.batches') || []).filter((batch: CycleCountBatch) => batch.status !== 'completed');
    } else {
      kBatches = [];
    }

    setLoading(false);
    return kBatches;
  };

  const loadMobileUsers = async () => {
    const isPendingStart = cycleCount.cc && cycleCount.cc.state === CycleCountStatus.pendingStart;
    const featureFlagOn = usesMobileLocations;
    let kMobileUsers: DropDownOption[];
    if (isPendingStart && featureFlagOn) {
      const whId = get(cycleCount.cc, 'warehouse.id');
      const response = await props.cycleCountsService.getMobileUsers(whId);
      kMobileUsers = (get(response, 'data.mobileUsers') || [])
        .sort((user1: MobileUser, user2: MobileUser) => {
          return user1.name.localeCompare(user2.name);
        })
        .map((user: MobileUser) => {
          return {
            value: user.id,
            name: `${user.name} (${user.username})`
          };
        });
      knownMobileUsers.unshift({value: null, name: 'Unassigned'});
    } else {
      kMobileUsers = [];
    }
    return kMobileUsers;
  };

  const transitionCycleCountState = async (event) => {
    const targetState: CycleCountStatus = event.currentTarget.getAttribute('data-target-state');
    const id = cycleCountId;
    const version = get(cycleCount.cc, 'version');
    let errs;
    setAwaitingResponse({status: true, source: 'transitionCCState'});
    let response;
    switch (targetState) {
      case CycleCountStatus.pendingStart:
        response = await props.cycleCountsService.confirmCycleCount(id, version);
        break;
      case CycleCountStatus.inProgress:
        response = await props.cycleCountsService.startCycleCount(id, version, assignToUserId);
        break;
      case CycleCountStatus.inReview:
        response = await props.cycleCountsService.submitCycleCount(id, version, cycleCount.cc.userComments);
        break;
      case CycleCountStatus.completed:
        response = await props.cycleCountsService.completeCycleCount(id, version, reasonCode, editNotes, purchaseOrder);
        break;
      case CycleCountStatus.cancelled:
        response = await props.cycleCountsService.cancelCycleCount(id, version);
        break;
    }
    if (targetState === CycleCountStatus.inReview) {
      checkMissingAndUnexpectedLpns(cycleCount.cc);
    }
    if (response && response.errors.length === 0) {
      await loadCycleCount();
    } else if (response.errors.length) {
      errs = props.cycleCountsService.processErrors(response.errors);
    }
    setAwaitingResponse({status: false, source: 'transitionCCState'});
    setShowCancelModal(false);
    setShowSubmitModal(false);
    setShowCompleteModal(false);
    setShowAssignUserModal(false);
    setShowCancelModal(false);
    setPageErrors(errs);
  };

  const retrieveInventoryInfosForCCItemsViaItemMaster = async () => {
    if (cycleCount.cc) {
      const ccItems = uniq(
        cycleCount.cc.cycleCountItems.map((cci) => {
          return cci.inventory.id;
        })
      ).filter(Number);
      const inventoryInfosResponse = await props.itemMasterService.getItems(ccItems, null);
      if (inventoryInfosResponse) {
        setInventoryInfos(inventoryInfosResponse.data.items);
      } else {
        setInventoryInfos(null);
      }
    } else {
      setInventoryInfos(null);
    }
  };

  const saveLpnMetadataEdits = async (data: CycleCountItem) => {
    updateCycleCountItemMetadata(data);
  };

  const saveCycleCountItemUpdates = async () => {
    let errs;
    let isEditing = true;
    setAwaitingResponse({status: true, source: 'saveCCItemUpdates'});
    const emptyMetadata: CycleCountItemMetadata = {
      created: null,
      counted: null,
      expected: {
        id: 0,
        metadata: {
          expirationDate: null,
          lotCode: null,
          manufactureDate: null
        },
        uoms: {eachesPerInnerPack: null, eachesPerOuterCase: null}
      },
      actual: {
        id: 0,
        metadata: {
          expirationDate: null,
          lotCode: null,
          manufactureDate: null
        },
        uoms: {eachesPerInnerPack: null, eachesPerOuterCase: null}
      }
    };
    const cycleCountItems = cycleCount.cc.cycleCountItems
      // do not attempt to save placeholder items
      .filter((i) => !isPlaceholderItem(i))
      .map((i) => {
        return {
          id: i.id,
          version: i.version,
          lpnBarcode: i.lpnBarcode,
          inventoryId: get(i, 'inventory.id'),
          locationId: get(i, 'location.id'),
          metadata: i.metadata || emptyMetadata,
          countedQuantities: i.countedQuantities
        } as CycleCountItemParams;
      });
    const response = await props.cycleCountsService.updateCycleCountItems(
      cycleCountId,
      get(cycleCount.cc, 'version'),
      get(cycleCount.cc, 'warehouse.id'),
      cycleCountItems
    );
    if (response && response.errors.length === 0) {
      isEditing = false;
      loadCycleCount();
    } else if (response.errors.length) {
      errs = props.cycleCountsService.processErrors(response.errors);
    }
    setAwaitingResponse({status: false, source: 'saveCCItemUpdates'});
    setEditing(isEditing);
    setPageErrors(errs);
  };

  const stopActiveWorkers = async () => {
    let errs;
    let batches = [];
    setAwaitingResponse({status: true, source: 'stopActiveWorkers'});
    const response = await props.cycleCountsService.closeAllBatches(cycleCountId);
    if (response && response.errors.length === 0) {
      batches = await loadBatchInfo();
    } else {
      errs = props.cycleCountsService.processErrors(response.errors);
    }
    setKnownBatches(batches);
    setAwaitingResponse({status: false, source: 'stopActiveWorkers'});
    setPageErrors(errs);
    toggleStopWorkersModal();
  };

  const handleStopWorkersClick = async () => {
    await loadBatchInfo().then((response) => {
      const workers = response.filter((batch) => batch.status === 'started').length;
      if (workers === 0) {
        // there's nothing to stop
        return;
      } else {
        toggleStopWorkersModal();
      }
    });
  };

  const validateMetadata = () => {
    let hasMissingLotOrExpiry = false;
    if (cycleCount?.cc?.cycleCountItems?.length > 0) {
      const itemMissingData = cycleCount?.cc?.cycleCountItems.find((item) => {
        if (item?.inventory?.lotCodeTrackingEnabled) {
          const hasValidLotCode = item?.metadata?.actual?.metadata?.lotCode?.length > 0;
          return !hasValidLotCode;
        }
        if (item?.inventory?.expiryDateTrackingEnabled) {
          const hasValidExpiryDate = item?.metadata?.actual?.metadata?.expirationDate?.length > 0;
          return !hasValidExpiryDate;
        }
      });
      if (itemMissingData) {
        hasMissingLotOrExpiry = true;
      }
    }
    setDisableSaveButton(hasMissingLotOrExpiry);
  };

  const handleEditClick = async () => {
    await loadBatchInfo().then((response) => {
      const workers = response.filter((batch) => batch.status === 'started').length;
      if (workers > 0) {
        toggleCannotEditModal();
      } else {
        toggleEditMode();
      }
    });
  };

  const handleSubmitClick = async () => {
    await loadBatchInfo().then((response) => {
      const workers = response.filter((batch) => batch.status === 'started').length;
      if (workers > 0) {
        toggleCannotEditModal();
      } else {
        toggleSubmitModal();
      }
    });
  };

  const handleStartClick = async (event) => {
    const featureFlagOn = usesMobileLocations;
    if (featureFlagOn && knownMobileUsers.length > 0) {
      toggleAssignUserModal();
    } else {
      await transitionCycleCountState(event);
    }
  };

  const handleSelectedMobileUser = (user: DropDownOption) => {
    setAssignToUserId(user.value);
  };

  const handleSelectedReasonCode = (reason: DropDownOption) => {
    setReasonCode(reason.value);
  };

  const handlePurchaseOrderInput = (event) => {
    setPurchaseOrder(event.target.value);
  };

  const handleEditNoteChange = (event) => {
    setEditNotes(event.target.value);
  };

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };

  const toggleSubmitModal = () => {
    setShowSubmitModal(!showSubmitModal);
  };

  const toggleCompleteModal = () => {
    setShowCompleteModal(!showCompleteModal);
  };

  const toggleStopWorkersModal = () => {
    setShowStopWorkersModal(!showStopWorkersModal);
  };

  const toggleCannotEditModal = () => {
    setShowCannotEdit(!showCannotEdit);
  };

  const toggleAssignUserModal = () => {
    setShowAssignUserModal(!showAssignUserModal);
  };

  const toggleEditMode = () => {
    const isEditing = editing;
    if (isEditing) {
      loadCycleCount();
    }
    setEditing(!isEditing);
  };

  const toggleCCViewMode = (event) => {
    const viewByLoc = !viewByLocation;
    setViewByLocation(viewByLoc);
  };

  const onAddNewItem = (newItem) => {
    const cCount = cloneDeep(cycleCount);
    cCount.cc.cycleCountItems.push(newItem);
    setCycleCount(cCount);
  };

  const updateCycleCountItem = (ccItem) => {
    const cCount = cloneDeep(cycleCount);
    const index = cCount.cc.cycleCountItems.findIndex((i) => {
      const invIdMatch = String(ccItem.inventory.id) === String(i.inventory.id);
      const locIdMatch = String(ccItem.location.id) === String(i.location.id);
      const lpnBarcodeMatch = String(ccItem.lpnBarcode) === String(i.lpnBarcode);
      const itdMatch = String(ccItem.metadata?.expected?.id) === String(i.metadata?.expected?.id);
      return invIdMatch && locIdMatch && lpnBarcodeMatch && itdMatch;
    });
    cCount.cc.cycleCountItems[index] = ccItem;
    setCycleCount(cCount);
  };

  const updateCycleCountItemMetadata = (ccItem: CycleCountItem) => {
    const cCount = cloneDeep(cycleCount);
    if (ccItem.lpnBarcode) {
      const index = cCount.cc.cycleCountItems.findIndex((i) => {
        const invIdMatch = String(ccItem.inventory.id) === String(i.inventory.id);
        const locIdMatch = String(ccItem.location.id) === String(i.location.id);
        const lpnBarcodeMatch = String(ccItem.lpnBarcode) === String(i.lpnBarcode);
        return invIdMatch && locIdMatch && lpnBarcodeMatch;
      });
      cCount.cc.cycleCountItems[index] = ccItem;
    } else {
      const index = cCount.cc.cycleCountItems.findIndex((i) => {
        const invIdMatch = String(ccItem.inventory.id) === String(i.inventory.id);
        const locIdMatch = String(ccItem.location.id) === String(i.location.id);
        const itdMatch = i.metadata ? String(ccItem.metadata?.expected?.id) === String(i.metadata?.expected?.id) : true;
        return invIdMatch && locIdMatch && itdMatch;
      });
      cCount.cc.cycleCountItems[index] = ccItem;
    }
    checkForHeldLots(ccItem);
    setCycleCount(cCount);
    // the value of this doesn't matter, the fact that it changes triggers loadCycleCount
    setMetadataChanged(!metadataChanged);
  };

  const checkForHeldLots = async (updatedCycleCountItem: CycleCountItem) => {
    const inventoryId = updatedCycleCountItem.inventory.id;
    const shipperId = inventoryInfos.find((inventory) => {
      return inventory.id === inventoryId;
    }).companyId;

    // If there's no lot code to check, don't call server
    if (
      !updatedCycleCountItem.metadata?.actual?.metadata?.lotCode ||
      !updatedCycleCountItem.metadata?.expected?.metadata?.lotCode
    ) {
      return;
    }

    const newLotCode = updatedCycleCountItem.metadata?.actual?.metadata?.lotCode;
    const oldLotCode: string = updatedCycleCountItem.metadata?.expected?.metadata?.lotCode;

    const holdCheckRequest: RetreiveMaterialStatusesRequest = {
      shipperId,
      inventoryIds: [inventoryId],
      includeEnabled: true,
      includeDisabled: false
    };
    const response = await props.inventoryService.retrieveMaterialStatuses(holdCheckRequest);
    const hasLotCodeChanged = newLotCode !== oldLotCode;

    if (hasLotCodeChanged) {
      setLotCodeChangedErrorMessage(
        checkForMatchingMaterialStatusHold(response?.data.materialStatuses, oldLotCode, inventoryId),
        checkForMatchingMaterialStatusHold(response?.data.materialStatuses, newLotCode, inventoryId)
      );
    } else {
      setLotCodeChangedWarning(null);
    }
  };

  function setLotCodeChangedErrorMessage(isOldLotCodeOnHold: boolean, isNewLotCodeOnHold: boolean) {
    if (lotCodeChangedFromOnHoldToAvailable(isOldLotCodeOnHold, isNewLotCodeOnHold)) {
      setLotCodeChangedWarning(
        'An item in this count has had its lot code changed from an on-hold lot to an available lot. Please review to ensure this change was intentional.'
      );
    } else if (lotCodeChangedFromAvailableToOnHold(isOldLotCodeOnHold, isNewLotCodeOnHold)) {
      setLotCodeChangedWarning(
        'An item in this count has had its lot code changed from an available lot to an on-hold lot. Please review to ensure this change was intentional.'
      );
    } else if (lotCodeChangedFromOnHoldToOnHold(isOldLotCodeOnHold, isNewLotCodeOnHold)) {
      setLotCodeChangedWarning(
        'An item in this count has had its lot code changed from one on-hold lot to another on-hold lot. Please review to ensure this change was intentional.'
      );
    }
    return null;
  }

  function lotCodeChangedFromOnHoldToAvailable(isOldLotCodeOnHold: boolean, isNewLotCodeOnHold: boolean) {
    return isOldLotCodeOnHold && !isNewLotCodeOnHold;
  }

  function lotCodeChangedFromAvailableToOnHold(isOldLotCodeOnHold: boolean, isNewLotCodeOnHold: boolean) {
    return !isOldLotCodeOnHold && isNewLotCodeOnHold;
  }

  function lotCodeChangedFromOnHoldToOnHold(isOldLotCodeOnHold: boolean, isNewLotCodeOnHold: boolean) {
    return isOldLotCodeOnHold && isNewLotCodeOnHold;
  }

  const checkForMatchingMaterialStatusHold = (
    materialStatuses: MaterialStatus[],
    lotCode: string,
    inventoryId: number
  ) => {
    // lot code is not on hold if there isn't any
    if (lotCode === null) {
      return false;
    }

    return materialStatuses.some((materialStatus) => {
      const inventoryProperties = materialStatus.inventoryProperties;

      return (
        inventoryProperties.lotCode === lotCode &&
        (inventoryProperties.inventoryId === null || inventoryProperties.inventoryId === inventoryId)
      );
    });
  };

  const toggleLpnDetailsModalWithData = (data) => {
    setShowLpnDetailsModal({id: data, visible: !showLpnDetailsModal.visible});
  };

  const toggleLpnDetailsModalWithEvent = (event) => {
    event.persist();
    setShowLpnDetailsModal({
      id: event.currentTarget.getAttribute('data-testid'),
      visible: !showLpnDetailsModal.visible
    });
  };

  const toggleItemDetailsModalWithId = (itemId: string, locationId: string) => {
    setShowItemPropertiesDetailsModal((currentValue) => ({itemId, locationId, visible: !currentValue.visible}));
  };

  const updateCycleCountNotes = (notes: string) => {
    cycleCount.cc.userComments = notes;
  };

  const renderActionButtons = (cCount) => {
    const isInReview = cCount.state === CycleCountStatus.inReview;
    const isInProgress = cCount.state === CycleCountStatus.inProgress;
    const isPendingStart = cCount.state === CycleCountStatus.pendingStart;
    const isUnconfirmed = cCount.state === CycleCountStatus.new;
    const isInPendingCompletion = cCount.state === CycleCountStatus.pendingCompletion;

    const isFullyCounted = !(cCount.cycleCountItems || [])
      .filter((item) => !isPlaceholderItem(item))
      .some((item) => isEmpty(item.countedQuantities));

    const statusChangeButtons = !isShipper &&
      !editing && [
        (isInReview || isInPendingCompletion) && renderButton('Complete', 'primary', toggleCompleteModal),
        isInProgress && isFullyCounted && renderButton('Submit for Review', 'primary', handleSubmitClick),
        isPendingStart && renderStateTransitionButton('Start', CycleCountStatus.inProgress, handleStartClick),
        isUnconfirmed && renderStateTransitionButton('Confirm', CycleCountStatus.pendingStart)
      ];

    const editingButtons = !isShipper &&
      (isInReview || isInProgress) && [
        !editing && renderButton('Edit', 'primary', handleEditClick),
        editing && renderButton('Save', 'primary', saveCycleCountItemUpdates, disableSaveButton),
        editing && renderButton('Cancel Edit', 'primary', toggleEditMode)
      ];

    const cancelButton = cCount.canBeCancelled && !editing && renderButton('Cancel', 'primary', toggleCancelModal);

    const showGenerateReportButton =
      cCount.state === CycleCountStatus.completed || cCount.state !== CycleCountStatus.new;

    const pickListButton = !isShipper && (isInReview || isInProgress) && (
      <span className="file-download-link-wrapper">
        <FileDownloadLink
          href={`/api/v2/cycle_counts/${cCount.id}/generate_count_list_csv.csv`}
          showAsButton
          text="Generate Count List"
        />
      </span>
    );
    const adjustmentReport = showGenerateReportButton && (
      <span className="file-download-link-wrapper">
        <FileDownloadLink
          href={`/api/v2/cycle_counts/${cCount.id}/generate_report.csv`}
          showAsButton
          text="Generate Cycle Count Report"
        />
      </span>
    );
    const toggleViewModeButton = (
      <a
        role="button"
        key="viewtoggle"
        onClick={toggleCCViewMode}
        className={classNames(['btn', 'secondary', 'pull-right', 'toggle-view-mode'])}
      >
        View by {viewByLocation ? 'SKU' : 'location'}
      </a>
    );
    const additionalActionButtons = [cancelButton, pickListButton, adjustmentReport, toggleViewModeButton];
    const allButtons = [].concat(statusChangeButtons, editingButtons, additionalActionButtons);

    return <div className="col-xs-6 cycle-count-buttons">{allButtons}</div>;
  };

  const renderButton = (
    buttonText: string,
    className: string,
    onClickHandler: (event) => void,
    disableButton: boolean = false
  ) => {
    return (
      <button
        key={buttonText}
        onClick={onClickHandler}
        disabled={awaitingResponse.status || disableButton}
        className={classNames([className, 'pull-right', 'new-button-style'])}
      >
        {buttonText}
      </button>
    );
  };

  const renderStateTransitionButton = (
    buttonText: string,
    targetState: CycleCountStatus,
    onClickAction = transitionCycleCountState
  ) => {
    return (
      <button
        key={targetState}
        onClick={onClickAction}
        disabled={awaitingResponse.status}
        data-target-state={targetState}
        className={classNames(['pull-right', 'primary', 'new-button-style'])}
      >
        {buttonText}
      </button>
    );
  };

  const renderModals = (cCount) => {
    return (
      <React.Fragment>
        <CycleCountStopActiveWorkersModal
          showStopWorkersModal={showStopWorkersModal}
          toggleStopWorkersModal={toggleStopWorkersModal}
          awaitingResponse={awaitingResponse.status}
          stopActiveWorkers={stopActiveWorkers}
          renderCurrentWorkers={renderCurrentWorkers}
        />
        <CycleCountCannotEditModal
          showCannotEditModal={showCannotEdit}
          toggleCannotEditModal={toggleCannotEditModal}
          renderCurrentWorkers={renderCurrentWorkers}
        />
        <CycleCountCancelModal
          showCancelModal={showCancelModal}
          toggleCancelModal={toggleCancelModal}
          disabled={awaitingResponse.status}
          targetState={CycleCountStatus.cancelled}
          ccId={cCount.id}
          transitionCycleCountState={transitionCycleCountState}
        />
        <CycleCountSubmitModal
          showSubmitModal={showSubmitModal}
          toggleSubmitModal={toggleSubmitModal}
          disabled={awaitingResponse.status}
          transitionCycleCountState={transitionCycleCountState}
          targetState={CycleCountStatus.inReview}
          updateCycleCountNotes={updateCycleCountNotes}
          cycleCount={cCount.cc}
        />
        <CycleCountCompleteModal
          cycleCount={cycleCount.cc}
          inventoryInfos={inventoryInfos}
          showCompleteModal={showCompleteModal}
          purchaseOrderRequired={reasonsThatRequirePO.includes(reasonCode as string)}
          purchaseOrderProvided={purchaseOrder !== ''}
          editNotesProvided={editNotes !== ''}
          cycleCountReasonCodes={cycleCountReasonCodes}
          selectedOption={reasonCode}
          toggleCompleteModal={toggleCompleteModal}
          handleComplete={transitionCycleCountState}
          handleDropDownSelect={handleSelectedReasonCode}
          handlePurchaseOrderInput={handlePurchaseOrderInput}
          handleEditNoteChange={handleEditNoteChange}
        />
        <CycleCountAssignUserModal
          enableLpnCycleCounts={props.enableLpnCycleCounts}
          showAssignUserModal={showAssignUserModal}
          disabled={awaitingResponse.status}
          targetState={CycleCountStatus.inProgress}
          knownMobileUsers={knownMobileUsers}
          assignToUserId={assignToUserId}
          toggleAssignUserModal={toggleAssignUserModal}
          transitionCycleCountState={transitionCycleCountState}
          handleSelectedMobileUser={handleSelectedMobileUser}
        />
      </React.Fragment>
    );
  };

  const renderCurrentWorkers = () => {
    const activeWorkers = knownBatches
      .filter((batch) => batch.status === 'started')
      .filter((batch) => !!batch.assignedTo)
      .map((batch) => batch.assignedTo);
    return (
      <ul>
        {activeWorkers.map((actor: ActorInfo, i) => (
          <li key={i}>{actor.name}</li>
        ))}
      </ul>
    );
  };

  const renderCycleCount = (cCount) => {
    let itemsWithLpns = [];
    let itemsWithoutLpns = [];
    const splitItems = (items) => {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < items.length; i++) {
        if (items[i].lpnBarcode) {
          itemsWithLpns.push(items[i]);
        } else {
          itemsWithoutLpns.push(items[i]);
        }
      }
    };
    const sortBySku = (a, b) => {
      if (a.inventory.sku < b.inventory.sku) {
        return -1;
      }
      if (a.inventory.sku > b.inventory.sku) {
        return 1;
      }
      return 0;
    };

    const itemsSortedByViewType = cCount
      ? groupBy(cCount.cycleCountItems, (item) => (viewByLocation ? item.location.label : item.inventory.sku))
      : {};
    return Object.keys(itemsSortedByViewType)
      .sort()
      .map((itemIdentifier, i) => {
        const allItemsForKey = itemsSortedByViewType[itemIdentifier];
        itemsWithoutLpns = [];
        itemsWithLpns = [];
        splitItems(allItemsForKey);
        itemsWithoutLpns.sort(sortBySku);
        itemsWithLpns.sort(sortBySku);
        const sortedAllItemsForKey = itemsWithoutLpns.concat(itemsWithLpns);
        const activeWorkersExist =
          knownBatches
            .filter((batch) => batch.status === 'started')
            .filter((batch) => !!batch.assignedTo)
            .map((batch) => batch.assignedTo).length > 0;
        return (
          <CCItemExpando
            key={i}
            itemIndex={i}
            forShipper={props.isShipper}
            byLocation={byLocation}
            viewByLocation={viewByLocation}
            isEditing={editing}
            isReviewing={reviewing}
            usesMobileLocations={usesMobileLocations}
            ccState={cCount.state}
            ccType={cCount.countType}
            ccItems={sortedAllItemsForKey}
            disabled={awaitingResponse.status}
            cycleCount={cCount}
            renderCurrentWorkers={renderCurrentWorkers}
            toggleLpnDetailsModalWithData={toggleLpnDetailsModalWithData}
            toggleLpnDetailsModalWithEvent={toggleLpnDetailsModalWithEvent}
            toggleItemDetailsModalWithId={toggleItemDetailsModalWithId}
            saveLpnMetadataEdits={saveLpnMetadataEdits}
            showLpnDetailsModal={showLpnDetailsModal}
            showItemPropertiesDetailsModal={showItemPropertiesDetailsModal}
            onUpdateItem={updateCycleCountItem}
            onAddNewItem={onAddNewItem}
            onWarehouserError={(errs) => setPageErrors(errs)}
            warehouseId={get(cCount, 'warehouse.id')}
            locationsService={props.locationsService}
            activeWorkersExist={activeWorkersExist}
            cycleCountsService={props.cycleCountsService}
            enableLpnCycleCounts={props.enableLpnCycleCounts}
            isReservationLPNEnabled={isLpnReservationPresent}
            enableLpnPropertiesCCButton={props.enableLpnPropertiesCCButton}
            carryForwardItdIdAfterDecomp={props.carryForwardItdIdAfterDecomp}
            hideAddItemButton={props.hideAddItemButton}
            inventoryInfos={inventoryInfos}
          />
        );
      });
  };

  const renderLpnAlerts = () => {
    const showLpnAlerts = props.enableLpnCycleCounts && (missingLpns > 0 || unexpectedLpns > 0);
    const alertMissingRow = (
      <div className="alert alert-danger alert-missing" role="alert">
        <span>
          {missingLpns} LPN{missingLpns > 1 ? 's' : ''} Missing.
        </span>
      </div>
    );
    const alertUnexpectedRow = (
      <div className="alert alert-warning alert-unexpected" role="alert">
        <span>
          {unexpectedLpns} Unexpected LPN{unexpectedLpns > 1 ? 's' : ''}.
        </span>
      </div>
    );
    return (
      <>
        {showLpnAlerts ? (
          <div className="row">
            {missingLpns > 0 ? alertMissingRow : null}
            {unexpectedLpns > 0 ? alertUnexpectedRow : null}
          </div>
        ) : null}
      </>
    );
  };

  const checkMissingAndUnexpectedLpns = (ccDetails) => {
    const ccItems = ccDetails.cycleCountItems;
    let missing = 0;
    let unexpected = 0;
    for (const ccItem of ccItems) {
      if (ccItem.lpnBarcode) {
        const countedEaches = ccItem.countedQuantities.each;
        const expectedEaches = ccItem.expectedQuantities.each;
        const countedCartons = ccItem.countedQuantities.carton;
        const expectedCartons = ccItem.expectedQuantities.carton;
        if (isMissingLpn(countedEaches, expectedEaches, countedCartons, expectedCartons)) {
          ++missing;
        } else if (isUnexpectedLpn(countedEaches, expectedEaches, countedCartons, expectedCartons)) {
          ++unexpected;
        }
        setMissingLpns(missing);
        setUnexpectedLpns(unexpected);
      }
    }
  };

  return (
    <div className="cycle-counts-component sidebar-layout">
      <div className="container-fluid">
        {renderLpnAlerts()}
        <div className="breadcrumbs row">
          <a href={`/${isShipper ? 's' : 'wh'}/inventories`}>Inventories</a>
          &nbsp;
          <i className="fa fa-angle-right"></i>
          &nbsp;
          <a href={`/${isShipper ? 's' : 'wh'}/cycle_counts`}>Cycle Counts</a>
          &nbsp;
          <i className="fa fa-angle-right"></i>
          &nbsp; Cycle Count #{cycleCountId}
        </div>
        {pageErrors && (
          <div className="alert alert-danger" role="alert">
            {pageErrors.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        )}
        {showLotCodeChangedWarning && (
          <div className="alert alert-warning" role="alert">
            {showLotCodeChangedWarning}
          </div>
        )}
        <div className="row space-below">
          <div className="col-xs-6">
            <h1>Cycle Count #{cycleCountId}</h1>
          </div>
          {cycleCount.cc && renderActionButtons(cycleCount.cc)}
        </div>
        <div className="row">
          {showCycleCountTable && renderCycleCount(cycleCount.cc)}
          <Loader loading={loading} />
          {cycleCount.cc && renderModals(cycleCount.cc)}
        </div>
      </div>
      <CCDetailSidebar
        cycleCount={cycleCount.cc}
        isLoading={loading}
        knownBatches={knownBatches}
        closeBatchesButton={
          knownBatches.length > 0 && (
            <a
              role="button"
              key="stop-workers"
              onClick={awaitingResponse.status ? undefined : handleStopWorkersClick}
              className="btn secondary"
            >
              Stop active workers
            </a>
          )
        }
      />
    </div>
  );
};

export default CycleCountDetail;
