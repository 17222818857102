/* eslint-disable max-len */
import * as React from 'react';
import {FunctionComponent} from 'react';
import {useForm} from 'react-hook-form';
import {format as formatDate} from 'date-fns';
import {cloneDeep} from 'lodash';
import FlexeButton from '../FlexeButton';
import {Item} from '../../shared/services/ItemMasterService';
import {CycleCountItem} from './CycleCountInterfaces';

interface Props {
  id: string;
  forShipper: boolean;
  disabled: boolean;
  cycleCountItem: CycleCountItem;
  toggleLpnDetailsModalWithData: (data: string) => void;
  toggleLpnDetailsModalWithEvent: (event: any) => void;
  saveLpnMetadataEdits: (data) => Promise<void>;
  inventoryInfoForCCItem: Item[];
}

// list input names here for react-hook-form
interface Inputs {
  lotCode: string;
  lpnBarcode: string;
  expirationDate: Date;
}

const LpnMetadataForm: FunctionComponent<Props> = (props) => {
  const {handleSubmit, register} = useForm<Inputs>();

  const onSubmit = (data) => {
    // eslint-disable-next-line
    console.log('data ---->', data);
    const updatedItem: CycleCountItem = cloneDeep(props.cycleCountItem);
    updatedItem.metadata.actual = {
      id: null,
      metadata: {
        lotCode: null,
        expirationDate: null,
        manufactureDate: null
      },
      uoms: {
        eachesPerInnerPack: null,
        eachesPerOuterCase: null
      }
    };
    updatedItem.metadata.actual.id = data.itdId;

    if (data.lotCode) {
      updatedItem.metadata.actual.metadata.lotCode = data.lotCode;
    }
    if (data.expDate) {
      updatedItem.metadata.actual.metadata.expirationDate = data.expDate;
    }
    if (data.eachesPerInnerPack) {
      updatedItem.metadata.actual.uoms.eachesPerInnerPack = data.eachesPerInnerPack;
    }
    if (data.eachesPerOuterCase) {
      updatedItem.metadata.actual.uoms.eachesPerOuterCase = data.eachesPerOuterCase;
    }
    props.toggleLpnDetailsModalWithData(data.lpnBarcode);
    props.saveLpnMetadataEdits(updatedItem);
  };

  const dateAcrobatics = (dateFromMetadata: string) => {
    // incoming date format: 2021-08-31
    if (dateFromMetadata) {
      return formatDate(dateFromMetadata.slice(0, 10), 'YYYY-MM-DD');
    } else {
      return null;
    }
  };

  const [actualMetadataExists, setActualMetadataExists] = React.useState(
    props.cycleCountItem.metadata && props.cycleCountItem.metadata.actual.metadata ? true : false
  );
  const [actualUomsExists, setActualUomsExists] = React.useState(
    props.cycleCountItem.metadata && props.cycleCountItem.metadata.actual.uoms ? true : false
  );
  const [expiryDateVal, setExpiryDateVal] = React.useState(
    actualMetadataExists
      ? dateAcrobatics(props.cycleCountItem.metadata.actual.metadata.expirationDate || '')
      : dateAcrobatics(props.cycleCountItem.metadata.expected.metadata.expirationDate || '')
  );
  const [lotCodeVal, setLotCodeVal] = React.useState(
    actualMetadataExists
      ? props.cycleCountItem.metadata.actual.metadata.lotCode || ''
      : props.cycleCountItem.metadata.expected.metadata.lotCode || ''
  );
  const [eachesPerInnerPackVal, setEachesPerInnerPackVal] = React.useState(
    actualUomsExists
      ? props.cycleCountItem.metadata.actual.uoms.eachesPerInnerPack || ''
      : props.cycleCountItem.metadata.expected.uoms.eachesPerInnerPack || ''
  );
  const [eachesPerOuterCaseVal, setEachesPerOuterCaseVal] = React.useState(
    actualUomsExists
      ? props.cycleCountItem.metadata.actual.uoms.eachesPerOuterCase || ''
      : props.cycleCountItem.metadata.expected.uoms.eachesPerOuterCase || ''
  );

  const registerExpDateChange = (e) => {
    setExpiryDateVal(e.target.value);
  };

  const registerLotCodeChange = (e) => {
    setLotCodeVal(e.target.value);
  };

  const regesiterEachesPerInnerPackChange = (e) => {
    setEachesPerInnerPackVal(e.target.value);
  };

  const regesiterEachesPerOuterCaseChange = (e) => {
    setEachesPerOuterCaseVal(e.target.value);
  };

  let lotCodeIsEnabled = false;
  let expDateIsEnabled = false;
  if (props.inventoryInfoForCCItem && props.inventoryInfoForCCItem.length > 0) {
    lotCodeIsEnabled = props.inventoryInfoForCCItem[0].lotCodeTrackingEnabled === true;
    expDateIsEnabled = props.inventoryInfoForCCItem[0].expiryDateTrackingEnabled === true;
  }

  return (
    <form
      data-testid="lpn-metadata-form"
      className="lpn-metadata"
      id="lpn-metadata-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div data-testid="lot-cde">
        {lotCodeIsEnabled ? (
          <div className="form-field">
            <label id="lot-code-text">Lot Code:</label>
            <input
              data-testid="lot-code-field"
              className="lpn-metadata-input text-input"
              name="lotCode"
              value={lotCodeVal || ''}
              onChange={registerLotCodeChange}
              ref={register}
            />
          </div>
        ) : null}
      </div>
      <div data-testid="expiration-date">
        {expDateIsEnabled ? (
          <div className="form-field">
            <label>Expiration Date:</label>
            <input
              type="date"
              data-testid="exp-date-field"
              className="lpn-metadata-input text-input"
              name="expDate"
              value={expiryDateVal || ''}
              onChange={registerExpDateChange}
              ref={register}
            />
          </div>
        ) : null}
      </div>
      <div
        className="form-field"
        style={props.cycleCountItem.metadata.expected.uoms.eachesPerOuterCase === null ? {display: 'none'} : null}
      >
        <label>Eaches Per Inner Pack:</label>
        <input
          data-testid="eaches-per-inner-pack-field"
          className="lpn-metadata-input text-input"
          name="eachesPerInnerPack"
          value={eachesPerInnerPackVal || ''}
          onChange={regesiterEachesPerInnerPackChange}
          ref={register}
        />
      </div>
      <div
        className="form-field"
        style={props.cycleCountItem.metadata.expected.uoms.eachesPerOuterCase === null ? {display: 'none'} : null}
      >
        <label>Eaches Per Outer Case:</label>
        <input
          data-testid="eaches-per-outer-case-field"
          className="lpn-metadata-input text-input"
          name="eachesPerOuterCase"
          value={eachesPerOuterCaseVal || ''}
          onChange={regesiterEachesPerOuterCaseChange}
          ref={register}
        />
      </div>
      <div className="form-field hidden">
        <input
          data-testid="lpn-barcode-field"
          className="lpn-metadata-input text-input"
          name="lpnBarcode"
          ref={register}
          value={props.cycleCountItem.lpnBarcode}
          readOnly={true}
        />
      </div>
      <div className="form-field hidden">
        <input
          data-testid="itd-id-field"
          className="lpn-metadata-input text-input"
          name="itdId"
          ref={register}
          value={props.cycleCountItem.metadata.expected.id || 0}
          readOnly={true}
        />
      </div>
      <FlexeButton level="primary" text="Save" type="submit" isDisabled={props.disabled} />
    </form>
  );
};

export default LpnMetadataForm;
