import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {ImportCsvDropdownOption, ModalAction} from '../shared/ItemMasterInterfaces';
import ImportCsvDropdown from '../ImportCsvDropdown';
import UpsertInventoryModal from '../shared/UpsertInventoryModal';
import DownloadInventoryModal from './DownloadInventoryModal';

interface Props {
  authenticityToken: string;
  showImsAlternateId: boolean;
  useReleaseDate: boolean;
  useSerialNumberProgram: boolean;
}

const ModalLauncher: FC<Props> = (props) => {
  const [selectedOption, setSelectedOption] = useState<ImportCsvDropdownOption>({name: '', value: ''});
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);

  useEffect(() => {
    setSelectedAction(selectedOption.value);
    renderCorrectModal(selectedOption.value);
  }, [selectedOption]);

  const renderCorrectModal = (uploadOption) => {
    switch (uploadOption) {
      case 'Create':
        setShowUpsertModal(true);
        break;
      case 'Update':
        setShowUpsertModal(true);
        break;
      case 'Download':
        setShowDownloadModal(true);
        break;
    }
  };

  const toggleUpsertModal = () => {
    if (showUpsertModal) {
      setSelectedAction('');
    }
    setShowUpsertModal(!showUpsertModal);
  };

  const toggleDownloadModal = () => {
    setShowDownloadModal(!showDownloadModal);
  };

  const handleSelect = (opt) => {
    setSelectedOption(opt);
  };

  const options: ImportCsvDropdownOption[] = [
    {name: 'Create', value: 'Create'},
    {name: 'Update', value: 'Update'},
    {name: 'Download', value: 'Download'}
  ];

  return (
    <>
      <ImportCsvDropdown titleText="Import CSV" options={options} selected={selectedOption} onSelect={handleSelect} />
      <UpsertInventoryModal
        authenticityToken={props.authenticityToken}
        isOpen={showUpsertModal}
        action={ModalAction[selectedAction]}
        toggleModal={toggleUpsertModal}
        isShipper
        showImsAlternateId={props.showImsAlternateId}
        useReleaseDate={props.useReleaseDate}
        useSerialNumberProgram={props.useSerialNumberProgram}
      />
      <DownloadInventoryModal authenticityToken={''} isOpen={showDownloadModal} toggleModal={toggleDownloadModal} />
    </>
  );
};

export default ModalLauncher;
