/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {css, jsx, SerializedStyles} from '@emotion/react';
import {InfoBox, Loader} from '@flexe/ui-components';
import {FC, useContext, useEffect, useReducer, useState} from 'react';
import {cloneDeep, get} from 'lodash';
import axios from 'axios';
import tokens from '@flexe/ui-tokens';
import {DeliverySummary} from '../shared/DeliverySummary';
import {ContainerDelivery, ContainerDeliveryState, LpnContent, PackingLists} from '../shared/DropoffInterfaces';
import {
  ApiResponse,
  Company,
  CompanyType,
  Document,
  DocumentNotableType,
  Reservation
} from '../../shared/CommonInterfaces';
import InboundDetailsHeaderPane from '../shared/InboundDetailsHeaderPane';
import {LpnStatus} from '../../lpns/LpnsInterfaces';
import DropoffHelper from '../shared/DropoffHelper';
import ShipperContainerDeliveryContext from '../contexts/ShipperContainerDeliveryContext';
import FlexeContext from '../../contexts/FlexeContext';
import EdiFilesForResource from '../../shipper/edi-files/EdiFilesForResource';

const NOTABLE_TYPE = DocumentNotableType.container;
const DOCUMENTS_ROUTE = '/api/v2/documents';

export const InboundDeliveryDetails: FC = () => {
  const {
    containerDeliveryId,
    containersService,
    documentsService,
    userService,
    warehouseService,
    packingListService
  } = useContext(ShipperContainerDeliveryContext);
  const {authenticityToken} = useContext(FlexeContext);

  const [containerDelivery, setContainerDelivery] = useState<ContainerDelivery>();
  const [packingLists, setPackingLists] = useState<PackingLists>();
  const [reservation, setReservation] = useState<Reservation>();
  const [lpnContents, setLpnContents] = useState<LpnContent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdiVisible, setIsEdiVisible] = useState(true);
  const [isContainerDeliveryLoaded, setIsContainerDeliveryLoad] = useState(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [editMode, toggleEditMode] = useReducer((isEditing) => !isEditing, false);
  const [shipperCompanyId, setShipperCompanyId] = useState(-1);
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [documentsToDelete, setDocumentsToDelete] = useState<Document[]>([]);
  const [shipperCompany, setShipperCompany] = React.useState<Company>({
    id: 0,
    enableTihiDuringInbound: false
  });

  useEffect(() => {
    async function loadContainerDelivery() {
      return await containersService.getShipment(containerDeliveryId);
    }

    loadContainerDelivery().then((response) => {
      if (response && response.errors.length === 0) {
        setContainerDelivery(response.data.shipmentDetails);
        setShipperCompanyId(response.data.shipperCompany.id);
        setPackingLists(response.data.packingLists);
        setReservation(response.data.reservation);
        setLpnContents(response.data.lpnContents);
        setShipperCompany(response.data.shipperCompany);
        setIsContainerDeliveryLoad(true);
        setIsLoading(false);
      } else {
        handleApiError(
          `There was an error retrieving information for container delivery #${containerDeliveryId}: `,
          response
        );
      }
    });
  }, [containerDeliveryId]);

  useEffect(() => {
    async function loadDocuments() {
      let err;
      try {
        const [shipperDocs, warehouseDocs] = await axios.all([
          documentsService.getDocuments(CompanyType.shipper, reservation.id, NOTABLE_TYPE, containerDelivery.id),
          documentsService.getDocuments(CompanyType.warehouse, reservation.id, NOTABLE_TYPE, containerDelivery.id)
        ]);
        const updatedDocuments = shipperDocs.concat(warehouseDocs);
        const updatedContainerDelivery = cloneDeep(containerDelivery);
        updatedContainerDelivery.hasBillOfLading = DropoffHelper.hasBillOfLading(updatedDocuments, shipperCompany.id);
        setDocuments(updatedDocuments);
        setContainerDelivery(updatedContainerDelivery);
      } catch (errorResponse) {
        err = get(errorResponse, 'response.data.errors', []).map((error) => error.detail);
      } finally {
        setErrors(err || []);
      }
    }

    if (isContainerDeliveryLoaded) {
      loadDocuments();
    }
  }, [isContainerDeliveryLoaded]);

  const handleDeleteDocsFromUi = (documentId: number) => {
    const docsAfterDelete = [...documents];
    let deletedDoc;
    documents.forEach((doc, idx) => {
      if (doc.id === documentId) {
        deletedDoc = docsAfterDelete.splice(idx, 1);
      }
    });
    const newDeletedDocs = [...documentsToDelete, ...deletedDoc];
    setDocumentsToDelete(newDeletedDocs);
    setDocuments(docsAfterDelete);
  };

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const handleCancelButtonClicked = () => {
    const currentDocs = [...documents];
    const deletedDocs = [...documentsToDelete];
    if (deletedDocs.length > 0) {
      const docsAfterResume = currentDocs.concat(deletedDocs);
      setDocuments(docsAfterResume);
      setDocumentsToDelete([]);
    }
    if (editMode) {
      toggleEditMode();
    }
  };

  const handleDeleteDocument = () => {
    const deleteDocResponse = [];
    documentsToDelete.forEach(async (doc) => {
      try {
        const response = await documentsService.deleteDocument(DOCUMENTS_ROUTE, doc.id, authenticityToken);
        if (response) {
          deleteDocResponse.push(response);
        }
      } catch (error) {
        setErrors([...errors, error]);
      }
    });
  };

  const handleApiError = (errTitle: string, response: ApiResponse<any>) => {
    setErrors(response?.errors?.map((err) => errTitle + err.detail) ?? []);
    setIsLoading(false);
  };

  const onUpdateInstructions = (newInstructions: string) => {
    const updatedContainerDelivery = cloneDeep(containerDelivery);
    updatedContainerDelivery.instructions = newInstructions;
    setContainerDelivery(updatedContainerDelivery);
  };

  const recalculatePackingListsBasedOnLpns = (lpns: LpnContent[]) => {
    const newPackingLists = cloneDeep(packingLists);
    const updatedPackingLists = containersService.calculatePackingListsBasedOnLpns(
      newPackingLists.expected,
      newPackingLists.actual,
      lpns
    );

    newPackingLists.expected = updatedPackingLists.expected;
    newPackingLists.shippable = updatedPackingLists.shippable;
    setPackingLists(newPackingLists);
    setLpnContents(lpns);
  };

  if (isLoading) {
    return <Loader loading={true} />;
  } else if (errors?.length > 0) {
    return (
      <div>
        {errors.map((error, i) => (
          <InfoBox infoType={'error'} info={error} key={i} />
        ))}
      </div>
    );
  } else {
    return (
      <div className="content">
        <div className="new-inbound-header-pane">
          <InboundDetailsHeaderPane
            containerDelivery={containerDelivery}
            containersService={containersService}
            onUpdateInstructions={(newInstructions: string) => onUpdateInstructions(newInstructions)}
            documents={documents}
            onDocumentSaved={null}
            errors={[]}
            reservation={reservation}
            reservationId={reservation.id}
            successMessage={'test'}
            enableInboundPackaging={false}
            inputTextMap={new Map<string, string>()}
            onInputTextMap={null}
            isEditing={false}
            handleIsBolUploaded={null}
            isLpnReceiveEnabled={false}
            isShipper
            isEditMode={editMode}
            handleRefreshPage={() => window.location.reload()}
            warehouseService={warehouseService}
            shipperEditProps={{
              onCancelEditClicked: handleCancelButtonClicked,
              onDeleteDocs: handleDeleteDocument,
              onDeleteDocsFromUi: handleDeleteDocsFromUi,
              setContainerDelivery,
              toggleEditMode,
              userService
            }}
          />
        </div>
        {isEdiVisible && (
          <div id="inbound-details-edi-component">
            <ContainerFluidWithBorder
              styles={css`
                padding-bottom: 20px;
              `}
            >
              <header>EDI Files</header>
              <EdiFilesForResource
                authenticityToken={authenticityToken}
                currentCompany={shipperCompany}
                resourceType="inboundOrder"
                resourceValue={containerDelivery.id.toString()}
                hide={false}
                onFilesLoaded={setIsEdiVisible}
              />
            </ContainerFluidWithBorder>
          </div>
        )}
        <div
          css={css`
            padding: ${tokens.spacing.v300.value}px;
          `}
        >
          <DeliverySummary
            packingLists={packingLists}
            lpnContents={lpnContents}
            lpnReceiveOnly={true}
            reservationId={reservation.id}
            containerDeliveryId={containerDelivery.id}
            containerDeliveryState={containerDelivery.txnState as ContainerDeliveryState}
            lpnStateData={new Map<string, LpnStatus>()}
            inProgressDropoffIds={[]}
            recalculatePackingListsBasedOnLpns={recalculatePackingListsBasedOnLpns}
            shipperProps={{
              isShipper: true,
              containerDelivery,
              isContainerDeliveryActive: [
                ContainerDeliveryState.new,
                ContainerDeliveryState.altered,
                ContainerDeliveryState.confirmed
              ].includes(containerDelivery.txnState as ContainerDeliveryState),
              containersService,
              warehouseService,
              shipperCompanyId,
              handleRefreshPage,
              handleApiError,
              packingListService
            }}
          />
        </div>
      </div>
    );
  }
};

const ContainerFluidWithBorder: FC<{
  styles?: SerializedStyles;
} & Partial<Pick<HTMLDivElement, 'className'>>> = ({children, className = 'container-fluid', styles}) => (
  <div
    className={className}
    css={css`
      background: ${tokens.color.base.white.value};
      border: ${tokens.border.round.hairline.width.value} solid #c9d1dc;
      border-radius: ${tokens.border.round.hairline.radius.value};
      max-width: none;
      ${styles}
    `}
  >
    {children}
  </div>
);

export default InboundDeliveryDetails;
