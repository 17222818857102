import {FC, ReactFragment, ReactNode, useContext} from 'react';
import * as React from 'react';
import {Location, Shipment} from '../ShipmentInterfaces';
import {formatDate} from '../../../shared/utilities/DataFormatting';
import {FreightShipMode, ParcelShipMode} from '../../../shared/constants';
import HeaderDetail from '../../../shared/HeaderDetail';
import HeaderEditableDetail, {HeaderEditableDetailType} from '../../../shared/HeaderEditableDetail';
import StagingLocationTypeAhead from '../../staging-location/StagingLocationTypeAhead';
import {renderLocationLink, renderTrailerManifestLink} from '../../../../libs/helpers';
import {ShippingInfo} from './ShippingInfo';
import {ShipmentDetailsContext} from './ShipmentDetailsContext';

interface Props {
  filterStagingLocations: boolean;
  isFreightTrailerLoadingEnabled: boolean;
  shipment: Shipment;
  editing: boolean;
  bypassPickupDetails: boolean;
  showCurrentLocationEnabled: boolean;
  showManifestIdEnabled: boolean;
  isFreightLoadGroupUIEnabled: boolean;

  scac: string;
  proNumber: string;
  trailerNumber: string;
  sealNumber: string;
  stagingLocation: Location;
  stagingLocationUpdatable: boolean;
  scheduledShipDate: string;

  setScac: (val: string) => void;
  setProNumber: (val: string) => void;
  setTrailerNumber: (val: string) => void;
  setSealNumber: (val: string) => void;
  setStagingLocation: (val: Location) => void;
  setScheduledShipDate: (val: string) => void;
}

const HeaderPane: FC<Props> = (props) => {
  const {locationsService, pickConsolidationService} = useContext(ShipmentDetailsContext);
  const {
    isFreightTrailerLoadingEnabled,
    shipment,
    editing,
    showCurrentLocationEnabled,
    showManifestIdEnabled,
    isFreightLoadGroupUIEnabled
  } = props;

  const shipDateFormat = 'MMMM D';
  const shipmentStatus = shipment.status;
  const shouldShowCurrentLocation = showCurrentLocationEnabled && shipmentStatus === 'packed';

  const handleTypeAheadSelect = (locationId: string, locationLabel: string) => {
    props.setStagingLocation({id: parseInt(locationId, 10), name: locationLabel});
  };

  const stagingLocationDetail = (): ReactFragment => {
    const enabled = editing && props.stagingLocationUpdatable;
    if (enabled) {
      return (
        <div className="detail-field-location">
          <span className="identifier">
            <b>Staging Location:</b>
          </span>
          <StagingLocationTypeAhead
            warehouseId={shipment.reservation.warehouse_id}
            locationsService={locationsService}
            pickConsolidationService={pickConsolidationService}
            usePickConsolidationService={props.filterStagingLocations}
            shipmentIds={[shipment.id]}
            onSelectCallback={handleTypeAheadSelect}
            currentLocationLabel={stagingLocationName()}
            disable={!enabled}
          />
          <br />
        </div>
      );
    } else {
      return <HeaderDetail label="Staging Location:" value={stagingLocationName()} hideWhenEmpty={false} />;
    }
  };

  const currentLocationInfo = (): ReactNode => {
    const location = shipment.current_location;
    const hasLocation = location.id && location.name;
    return hasLocation ? (
      <HeaderDetail label="Current Location:" value={renderLocationLink(location.id, location.name)} />
    ) : (
      <HeaderDetail label="Current Location:" value="--" />
    );
  };

  const stagingLocationName = () => {
    return props.stagingLocation ? props.stagingLocation.name : null;
  };

  /* eslint-disable max-len */
  return (
    <React.Fragment>
      <div id="details">
        <div id="reservation-info">
          <span>
            {`#${shipment.reservation.id} - ${shipment.reservation.shipper_name}`} &raquo;{' '}
            {shipment.reservation.warehouse_name}
          </span>
        </div>
        <div id="order-information">
          <div className="col-md-3">
            <HeaderDetail label="Shipment ID:" value={shipment.id} />
            <HeaderDetail label="P.O.:" value={shipment.purchase_order} />
            <HeaderDetail label="Order ID:" value={shipment.order_id} />
            <HeaderDetail label="Customer Reference #:" value={shipment.customer_reference_number} />
            {props.isFreightTrailerLoadingEnabled && !props.isFreightLoadGroupUIEnabled && (
              <HeaderDetail label="Destination ID:" value={shipment.destination_tag ?? '--'} hideWhenEmpty={false} />
            )}
            <div className="vertical-rule" />
          </div>

          {shipment.transportation.ship_mode === ParcelShipMode && (
            <div>
              <div className="col-md-2">
                <HeaderDetail label="Mode:" value={'Parcel'} />
                <HeaderDetail label="Carrier:" value={shipment.transportation.carrier.display_name} />
                <HeaderDetail label="TRK #:" value={shipment.transportation.tracking_number} />
                <div className="vertical-rule" />
              </div>
              {(showManifestIdEnabled || shouldShowCurrentLocation) && (
                <div className="col-md-2">
                  {showManifestIdEnabled &&
                    (shipment.outbound_manifest_id ? (
                      <HeaderDetail label="Load ID:" value={renderTrailerManifestLink(shipment.outbound_manifest_id)} />
                    ) : (
                      <HeaderDetail label="Load ID:" value="--" />
                    ))}
                  {shouldShowCurrentLocation && currentLocationInfo()}
                  <div className="vertical-rule" />
                </div>
              )}
              <div className="col-md-2">
                {shipment.is_warehouse_sfs_enabled && (
                  <HeaderDetail
                    label="Wave ID:"
                    value={
                      shipment.wave_id ? (
                        <a href={`/wh/waves/${shipment.wave_id}`} rel="noreferrer" target="_blank">
                          {shipment.wave_id}
                        </a>
                      ) : (
                        shipment.wave_id
                      )
                    }
                    hideWhenEmpty={false}
                  />
                )}

                {shipment.reservation.is_mobile_location_enabled && stagingLocationDetail()}
                <div className="vertical-rule" />
              </div>
              <div className="col-md-2">
                <HeaderDetail label="Ship By:" value={formatDate(shipment.ship_by, shipDateFormat)} />
              </div>
            </div>
          )}
          {shipment.transportation.ship_mode === FreightShipMode && (
            <div>
              <div className="col-md-2">
                <HeaderDetail label="Mode:" value={'Freight'} />
                {props.bypassPickupDetails && (
                  <React.Fragment>
                    <HeaderDetail label="Carrier Details:" value={'Routed Externally'} />
                    <HeaderDetail label="Carrier/SCAC:" value={props.scac} hideWhenEmpty={false} />
                  </React.Fragment>
                )}
                {!props.bypassPickupDetails && (
                  <React.Fragment>
                    {isFreightTrailerLoadingEnabled ? (
                      <React.Fragment>
                        <LoadDetailPlaceholder label="Carrier/SCAC:" />
                        <LoadDetailPlaceholder label="PRO #:" />
                        <LoadDetailPlaceholder label="Trailer #:" />
                        <LoadDetailPlaceholder label="Seal #:" />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <HeaderEditableDetail
                          label="Carrier/SCAC:"
                          value={props.scac}
                          type={HeaderEditableDetailType.text}
                          onChange={props.setScac}
                          maxLength={4}
                          classes={['scac-input']}
                          isEditing={editing}
                        />
                        <HeaderEditableDetail
                          label={'PRO #:'}
                          value={props.proNumber}
                          type={HeaderEditableDetailType.text}
                          onChange={props.setProNumber}
                          maxLength={50}
                          classes={['pro-input']}
                          isEditing={editing}
                        />
                        <HeaderEditableDetail
                          label={'Trailer #:'}
                          value={props.trailerNumber}
                          type={HeaderEditableDetailType.text}
                          onChange={props.setTrailerNumber}
                          maxLength={50}
                          classes={['trailer-input']}
                          isEditing={editing}
                        />
                        <HeaderEditableDetail
                          label={'Seal #:'}
                          value={props.sealNumber}
                          type={HeaderEditableDetailType.text}
                          onChange={props.setSealNumber}
                          maxLength={50}
                          classes={['seal-input']}
                          isEditing={editing}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                <div className="vertical-rule" />
              </div>

              <div className="col-md-2">
                {shipment.is_warehouse_sfs_enabled ? (
                  <HeaderDetail
                    label="Wave ID:"
                    value={
                      shipment.wave_id ? (
                        <a href={`/wh/waves/${shipment.wave_id}`} rel="noreferrer" target="_blank">
                          {shipment.wave_id}
                        </a>
                      ) : (
                        shipment.wave_id
                      )
                    }
                    hideWhenEmpty={false}
                  />
                ) : (
                  <HeaderDetail label="Batch ID:" value={shipment.fulfillment_batch_id} hideWhenEmpty={false} />
                )}

                {shipment.reservation.is_mobile_location_enabled && stagingLocationDetail()}

                {props.isFreightTrailerLoadingEnabled && props.isFreightLoadGroupUIEnabled && (
                  <React.Fragment>
                    <HeaderDetail label="Load Group:" value={shipment.freight_load_group} />
                    <HeaderDetail
                      label="Destination ID:"
                      value={shipment.destination_tag ?? '--'}
                      hideWhenEmpty={false}
                    />
                  </React.Fragment>
                )}
                <div className="vertical-rule" />
              </div>

              <div className="col-md-2">
                {isFreightTrailerLoadingEnabled ? (
                  <LoadDetailPlaceholder label="Sched. Ship Date:" />
                ) : (
                  <HeaderEditableDetail
                    label={'Sched. Ship Date:'}
                    value={props.scheduledShipDate}
                    type={HeaderEditableDetailType.date}
                    onChange={props.setScheduledShipDate}
                    classes={['scheduled-ship-date-input']}
                    isEditing={editing}
                  />
                )}
                <HeaderDetail label="Ship After:" value={formatDate(shipment.ship_after, shipDateFormat)} />
                <HeaderDetail label="Ship By:" value={formatDate(shipment.ship_by, shipDateFormat)} />
              </div>
            </div>
          )}
          <div className="col-md-3">
            <ShippingInfo destination={shipment.destination} shipperNotes={shipment.notes} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const LoadDetailPlaceholder: FC<{label: string}> = ({label}) => (
  <HeaderDetail classes={['pending-value']} label={label} value={'See Load Details'} />
);

export default HeaderPane;
