import * as React from 'react';
import {Reservation} from '../../../../shared/CommonInterfaces';
import {ContainerDeliveryState, LpnContent, PackingListItem} from '../../../shared/DropoffInterfaces';
import {DeliverySummary} from '../../../shared/DeliverySummary';
import {LpnStatus} from '../../../../lpns/LpnsInterfaces';

interface ConfirmExpectedLpnContentsStepProps {
  packingLists: {
    expected: PackingListItem[];
    shippable: PackingListItem[];
    damaged: PackingListItem[];
  };
  reservation: Reservation;
  lpnContents: LpnContent[];
  lpnReceiveOnly: boolean;
  containerDeliveryId: number;
  inProgressDropoffIds: number[];
  lpnStateData: Map<string, LpnStatus>;
  showEditLpnForAsn?: boolean;
  allowLpnTransferInAllInboundStates: boolean;
  containerDeliveryState?: ContainerDeliveryState;
  recalculatePackingListsBasedOnLpns(lpnContents: LpnContent[]);
}

export default function ConfirmExpectedLpnContentsStep(props: ConfirmExpectedLpnContentsStepProps) {
  const stepContent = () => (
    <DeliverySummary
      packingLists={props.packingLists}
      lpnContents={props.lpnContents}
      lpnReceiveOnly={props.lpnReceiveOnly}
      reservationId={props.reservation.id}
      containerDeliveryId={props.containerDeliveryId}
      inProgressDropoffIds={props.inProgressDropoffIds}
      lpnStateData={props.lpnStateData}
      recalculatePackingListsBasedOnLpns={props.recalculatePackingListsBasedOnLpns}
      isAsnLpns={true}
      showEditLpnForAsn={props.showEditLpnForAsn}
      containerDeliveryState={props.containerDeliveryState}
      allowLpnTransferInAllInboundStates={props.allowLpnTransferInAllInboundStates}
    />
  );

  return {
    title: 'Verify Contents',
    content: stepContent()
  };
}
