import * as React from 'react';
import {cloneDeep} from 'lodash';
import {Packaging} from '../../shared/CommonInterfaces';
import {renderLpnLink} from '../../../libs/helpers';

export interface OrderContent {
  sku: string;
  description: string;
  expectedQuantity: {
    amount: number;
    unit: Packaging;
    conversions: {
      each: number;
    };
  };
  actualQuantity?: {
    amount: number;
    unit: Packaging;
    conversions: {
      each: number;
    };
  };
  lpnBarcodes?: string[];
}

interface ContentProps {
  contents: OrderContent[];
  match?: {
    params: {}; // eslint-disable-line @typescript-eslint/ban-types
  };
  blockOverShipment: boolean;
}

// DEPRECATED
class ContentSummaryWithShortages extends React.Component<ContentProps, any> {
  private showWarning: boolean;
  constructor(props) {
    super(props);
    this.showWarning = false;
  }

  public render() {
    const rows: {
      discrepancies: JSX.Element[];
      contents: JSX.Element[];
    } = this.calculateRows(this.props.contents);
    return (
      <div className="lpn-contents">
        {rows.discrepancies.length > 0 && (
          <div>
            <h4>
              Shortages and Discrepancies <i className="fas fa-exclamation-triangle red3"></i>
            </h4>
            {this.props.blockOverShipment && this.checkOverShipment(this.props.contents) && (
              <h6>
                <i className="bg-warning">
                  Shipments cannot be completed when more inventory has been staged than requested.
                </i>
              </h6>
            )}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sku</th>
                  <th>Description</th>
                  <th>Expected Qty</th>
                  <th>Actual Qty</th>
                  <th>LPN</th>
                </tr>
              </thead>
              <tbody>{rows.discrepancies}</tbody>
            </table>
          </div>
        )}
        {rows.contents.length > 0 && (
          <div>
            <h4>Content Summary</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sku</th>
                  <th>Description</th>
                  <th>Expected Qty</th>
                  <th>Actual Qty</th>
                  <th>LPN</th>
                </tr>
              </thead>
              <tbody>{rows.contents}</tbody>
            </table>
          </div>
        )}
        {this.showWarning && (
          <p className="content-warning">
            Warning: by completing this order you are accepting the shown shortages/overages and the order will be
            updated to reflect the actual quantities.
          </p>
        )}
        <input type="hidden" name="order_contents" value={this.encodeContents(this.props.contents)} />
      </div>
    );
  }

  private encodeContents(contents: OrderContent[]): string {
    const filteredContents: OrderContent[] = contents.filter((content) => {
      return content.actualQuantity && content.actualQuantity.amount > 0;
    });
    return encodeURIComponent(JSON.stringify(filteredContents));
  }

  private checkOverShipment = (contents: OrderContent[]) => {
    let overShipped = false;
    for (const element of contents) {
      if (element.actualQuantity.amount > element.expectedQuantity.amount) {
        overShipped = true;
        break;
      }
    }
    return overShipped;
  };

  private calculateRows = (contents: OrderContent[]): {discrepancies: JSX.Element[]; contents: JSX.Element[]} => {
    const rows = {
      discrepancies: [],
      contents: []
    };
    this.showWarning = false;

    contents.map((content: OrderContent, rowNum: number) => {
      if (content.actualQuantity) {
        if (content.expectedQuantity.amount === 0) {
          this.showWarning = true;
          rows.discrepancies.push(this.generateRow(content, rowNum, 'additional-sku danger'));
        } else if (content.actualQuantity.amount === 0) {
          this.showWarning = true;
          rows.discrepancies.push(this.generateRow(content, rowNum, 'missing-sku danger'));
        } else if (content.expectedQuantity.amount > content.actualQuantity.amount) {
          this.showWarning = true;
          rows.discrepancies.push(this.generateRow(content, rowNum, 'shortage warning'));
        } else if (content.expectedQuantity.amount < content.actualQuantity.amount) {
          this.showWarning = true;
          rows.discrepancies.push(this.generateRow(content, rowNum, 'overage warning'));
        } else {
          rows.contents.push(this.generateRow(content, rowNum));
        }
      } else {
        rows.contents.push(this.generateRow(content, rowNum));
      }
    });

    return rows;
  };

  private generateRow(content: OrderContent, index: number, className?: string) {
    return (
      <tr key={index} className={className}>
        <td>{content.sku}</td>
        <td>{content.description}</td>
        <td>{`${content.expectedQuantity.amount} ${content.expectedQuantity.unit}`}</td>
        <td>{content.actualQuantity ? `${content.actualQuantity.amount} ${content.actualQuantity.unit}` : ''}</td>
        <td>
          {content.lpnBarcodes.map((barcode, i) => {
            return (
              <span key={i}>
                {barcode}
                <br />
              </span>
            );
          })}
        </td>
      </tr>
    );
  }
}

export default ContentSummaryWithShortages;
