import {ItemQuantity, Packaging, Reservation, UnitConversions} from '../../shared/CommonInterfaces';
import {PurchaseOrderContentData} from './PurchaseOrderContentRow';

export enum ReceivedType {
  shippable = 'shippable',
  damaged = 'damaged'
}

export const allowEditNotesInShipperUIFlag = 'inbound_3333_edit_instructions_shipper_ui';

export enum DeliverableType {
  containerDelivery = 'ContainerDelivery'
}

export enum ContainerDeliveryState {
  new = 'new',
  confirmed = 'confirmed',
  altered = 'altered',
  cancelled = 'cancelled',
  underReview = 'under_review',
  completed = 'completed',
  arrived = 'arrived',
  receiving = 'receiving'
}

export const ContainerDeliveryInProgressStates = [
  ContainerDeliveryState.confirmed,
  ContainerDeliveryState.arrived,
  ContainerDeliveryState.receiving
];
export const ContainerDeliveryFinishedStates = [ContainerDeliveryState.cancelled, ContainerDeliveryState.completed];

export enum ActorType {
  user = 'User',
  admin = 'AdminUser',
  mobile = 'MobileUser'
}

export interface Inventory {
  id: number;
  sku: string;
  description: string;
  smallest_packaging?: Packaging;
  unit_conversions?: UnitConversions;
  cartonsPerLayer?: number;
  layersPerPallet?: number;
  isInUse: boolean;
  properties: {
    carton: Property;
    each: Property;
  };
}

export interface Property {
  shipAlone: boolean;
  shipAsIs: boolean;
  unitsPerParent: number;
}

export interface PackingListItem {
  id?: number;
  damaged?: boolean;
  quantity: ItemQuantity;
  inventory: Inventory;
  shippableAmount?: number;
  damagedAmount?: number;
  pallets?: number;
  lpn?: LpnMetadata;
  uoms?: Uoms;
  purchaseOrderNumber?: string;
}

export interface PackingLists {
  expected: PackingListItem[];
  shippable: PackingListItem[];
  damaged: PackingListItem[];
  actual?: PackingListItem[];
}

export interface LpnMetadata {
  itdId: number;
  lpnBarcode: string;
  arrivedAt: Date;
}

export interface LpnContent {
  id?: number;
  lpnDetailId: number;
  lpnBarcode: string;
  inventory: Inventory;
  expectedQuantity: number;
  expectedPackaging: Packaging;
  receivedQuantity: number;
  arrivedAt: Date;
  packaging: Packaging;
  expirationDate?: Date;
  lotCode?: string;
  uoms?: Uoms;
  poNumber?: string;
  txnState?: string;
}

export interface Pallet {
  id: string;
  uniqueIdentifier: string;
  number: string; // eslint-disable-line id-denylist
  quantity: ItemQuantity;
  inventory: Inventory;
}

export interface InventoryProperties {
  cartonsPerLayer: number;
  layersPerPallet: number;
  properties: {
    carton: Property;
    each: Property;
  };
  updated: boolean;
}

export interface PutAwayLocation {
  value: string;
  name: string;
  category?: string;
}

export interface ToCapturePropertyKeysAndValues {
  [key: string]: string | number;
}

export interface Item {
  id: number;
  quantity: number;
  packaging: string;
  purchaseOrder: string;
  uoms?: Uoms;
}

export interface ContainerDelivery {
  id: number;
  txnState: string;
  sealNumber: string;
  trailerNumber: string;
  purchaseOrder: string;
  expectedInboundPackaging: string;
  actualInboundPackaging?: string;
  actualArrivalTime?: Date;
  actualInboundPackagingId: number;
  moveDate: string;
  inventoryMatch: boolean;
  instructions: string;
  monetaryValueOfGoods: string;
  valueOfGoodsAmount: number;
  hasBillOfLading: boolean;
  completedBy: {
    name: string;
    type: ActorType.user | ActorType.admin | ActorType.mobile;
  };
  receiving: {
    startAt: string;
    endAt: string;
  };
  isCrossdock: boolean;
  extraProperties?: {
    provided: Map<string, string>;
    toCapture: Map<string, InboundCapturableProperty>;
  };
  requireReferenceId: boolean;
}

export interface InboundCapturableProperty {
  type: string;
  instructions: string;
  required: boolean;
  value?: any;
  displayLabel?: string;
  defaultValue?: any;
}

export interface InboundStatus {
  className: string;
  text: string;
}

export const InboundStatusMap = new Map<string, InboundStatus>([
  ['', {className: 'not-started', text: 'Loading'}],
  [ContainerDeliveryState.new, {className: 'not-started', text: 'New'}],
  [ContainerDeliveryState.confirmed, {className: 'in-progress', text: 'Confirmed'}],
  [ContainerDeliveryState.completed, {className: 'completed', text: 'Completed'}],
  [ContainerDeliveryState.cancelled, {className: 'cancelled', text: 'Cancelled'}],
  [ContainerDeliveryState.underReview, {className: 'under-review', text: 'Under Review'}],
  [ContainerDeliveryState.altered, {className: 'altered', text: 'Altered'}],
  [ContainerDeliveryState.arrived, {className: 'arrived', text: 'Arrived'}],
  [ContainerDeliveryState.receiving, {className: 'receiving', text: 'Receiving'}]
]);

// we need this as an non-unique identifier for each line item because we now allow same SKU same PO
export interface SkuTableEntryId {
  inventoryId: number;
  purchaseOrderNumber: string;
  uoms: Uoms;
}

export interface DeliverySummaryData {
  skus: SkusTabData[];
  purchaseOrders: PurchaseOrderContentData[];
}

export interface SkusTabData {
  expectedPackingListId?: number;
  shippablePackingListId?: number;
  damagedPackingListId?: number;
  inventoryId: number;
  sku: string;
  description: string;
  po: string;
  expectedQuantity?: ItemQuantity;
  shippableQuantity?: ItemQuantity;
  damagedQuantity?: ItemQuantity;
  expectedInner: number;
  expectedOuter: number;
  receivedInner: number;
  receivedOuter: number;
  packagingConversionFactor: number;
  pallets?: number;
  receivedInMultipleUnits?: boolean;
}

export interface Uoms {
  eachesPerInnerPack: number;
  eachesPerOuterCase: number;
}

export interface PoTabMapData {
  poContainerIdsMap: Map<string, Set<number>>;
  containerIdResIdMap: Map<number, number>;
  reservationIdNameMap: Map<number, string>;
}

export interface ContainerDeliveriesApiReponse {
  dropoffs: Dropoffs[];
}

export interface UpdateDateApiResponse {
  status: string;
  message: string;
}

export interface Dropoffs {
  id: number;
  reservation: Reservation;
  packingLists: {
    expected: ExpectedPackingLists[];
  };
}

export interface ExpectedPackingLists {
  metadata: {
    poNumber: string;
  };
}
