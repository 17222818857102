import * as React from 'react';
import {useMemo} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {GridColumnVisibilityModel} from '@mui/x-data-grid';
import {chunk, flatMap} from 'lodash';
import {GridInitialStateCommunity} from '@mui/x-data-grid/models/gridStateCommunity';
import {flexeMuiTheme} from '../shared/mui/default-mui-theme';
import {FlexeDataGrid} from '../shared/mui/flexe-data-grid/FlexeDataGrid';
import {
  LocationContentsGridLocationContent,
  useLocationContentsDataGrid
} from '../shared/mui/flexe-data-grid/location-contents-grid/useLocationContentsGrid';
import ItemMasterService, {Item} from '../shared/services/ItemMasterService';
import InventoryService, {LocationContent, LpnResponse} from '../shared/services/InventoryService';

interface LocationContentsTableProps {
  locationId: number;
  itemMasterService: ItemMasterService;
  inventoryService: InventoryService;
}
export function LocationContentsTable({locationId, itemMasterService, inventoryService}: LocationContentsTableProps) {
  const {columns, rows, isLoading} = useLocationContentsDataGrid(fetchContentsPage, '/wh');

  async function fetchContentsPage(continuationToken: string) {
    const {contents, continuationToken: nextContinuationToken} = (
      await inventoryService.getLocationContentsByLocationIds([locationId], continuationToken, true)
    ).data;
    const itemIds = contents.map(({inventoryId}) => inventoryId);

    // Start item master request so it runs in parallel.
    const itemMasterItemsPromise = getInventoriesForItemIds(itemIds, itemMasterService);

    let pageLpns: LpnResponse[] = [];
    const contentLpnIds = contents.map(({lpnId}) => lpnId).filter(Boolean);
    const distinctContentLpnIds = Array.from(new Set(contentLpnIds));
    if (distinctContentLpnIds.length) {
      const lpns = await getAllLpns(distinctContentLpnIds, inventoryService);
      pageLpns = lpns;
      const parentLpnIds = lpns.map(({parentId}) => parentId).filter(Boolean);
      const distinctParentLpnIds = Array.from(new Set(parentLpnIds));
      if (distinctParentLpnIds.length) {
        const parentLpns = await getAllLpns(distinctParentLpnIds, inventoryService);
        pageLpns = [...pageLpns, ...parentLpns];
      }
    }

    const itemMasterItems = await itemMasterItemsPromise;
    const inventoriesById = new Map(itemMasterItems.map((item) => [item.id, item]));
    const lpnsById = new Map(pageLpns.map((lpn) => [lpn.id, lpn]));

    return {
      contents: contents.map((content) => mapContents(content, inventoriesById, lpnsById)),
      continuationToken: nextContinuationToken
    };
  }

  const columnVisibilityModel = useMemo<GridColumnVisibilityModel>(() => {
    const enableParentLpnColumn = rows.some(({parentLpnBarcode}) => parentLpnBarcode);
    const enableLpnColumn = rows.some(({lpnBarcode}) => lpnBarcode);
    const enableUomColumn = rows.some(({uomJson}) => uomJson);
    const enableLotCodeColumn = rows.some(({lotCode}) => lotCode);
    const enableExpirationDateColumn = rows.some(({expirationDate}) => expirationDate);
    const enableManufactureDateColumn = rows.some(({manufactureDate}) => manufactureDate);
    const model = {
      parentLpnBarcode: enableParentLpnColumn,
      lpnBarcode: enableLpnColumn,
      uomJson: enableUomColumn,
      lotCode: enableLotCodeColumn,
      expirationDate: enableExpirationDateColumn,
      manufactureDate: enableManufactureDateColumn
    };
    if (isLoading) {
      // Hide all columns until we know which ones to show
      for (const column of columns) {
        model[column.field] = false;
      }
    }
    return model;
  }, [columns, rows, isLoading]);

  const initialState: GridInitialStateCommunity = {
    sorting: {
      // By default, sort by parent LPN so that way nested LPNs are grouped together by parent.
      sortModel: [{field: 'parentLpnBarcode', sort: 'asc'}]
    }
  };

  // HACK: Manually do an implied secondary sort on LPN barcode. Multi-sorting is a paid feature. https://mui.com/x/react-data-grid/sorting/#multi-sorting
  // Remove this and add a real secondary sort when we switch to paid.
  const sortedRows = useMemo(() => [...rows.sort((a, b) => (a.lpnBarcode ?? '').localeCompare(b.lpnBarcode ?? ''))], [
    rows
  ]);

  return (
    <ThemeProvider theme={flexeMuiTheme}>
      <FlexeDataGrid
        sx={{height: '720px'}}
        columns={columns}
        rows={sortedRows}
        loading={isLoading}
        columnVisibilityModel={columnVisibilityModel}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        isRowSelectable={() => false}
        initialState={initialState}
      />
    </ThemeProvider>
  );
}

function mapContents(
  content: LocationContent,
  inventoriesById: Map<number, Item>,
  lpnsById: Map<number, LpnResponse>
): LocationContentsGridLocationContent {
  const inventoryItem = inventoriesById.get(content.inventoryId);
  const upcBarcode = inventoryItem.properties.find(({packaging}) => packaging === content.quantity.packaging)?.barcode;
  const lpn = lpnsById.get(content.lpnId);
  const parentLpn = lpn ? lpnsById.get(lpn.parentId) : null;
  return {
    reservationId: content.reservationId,
    parentLpnBarcode: parentLpn?.barcode,
    lpnBarcode: lpn?.barcode,
    lpnId: lpn?.id,
    id: content.id.toString(),
    sku: {id: content.inventoryId, sku: inventoryItem.sku},
    upc: upcBarcode,
    description: inventoryItem.description,
    quantity: content.quantity.amount,
    unit: {
      amount: content.quantity.amount,
      unit: content.quantity.packaging
    },
    uomJson: content.inventoryTrackingData?.uoms,
    lotCode: content.inventoryTrackingData?.lotCode,
    expirationDate: content.inventoryTrackingData?.expirationDate,
    manufactureDate: null
  };
}

async function getInventoriesForItemIds(itemIds: number[], itemMasterService: ItemMasterService) {
  // Batch requests to work around query string size limits
  const batchSize = 100;
  const itemIdBatches = chunk(itemIds, batchSize);
  const inventoryItemsPromises = itemIdBatches.map(async (batchItemIds) => {
    // Continuation token of null is ok because batch size is smaller than page size.
    // There will only ever be one page.
    const continuationToken = null;
    return (await itemMasterService.getItems(batchItemIds, continuationToken)).data.items;
  });

  const inventoryItemBatchResults = await Promise.all(inventoryItemsPromises);
  return flatMap(inventoryItemBatchResults);
}

async function getAllLpns(lpnIds: number[], inventoryService: InventoryService) {
  // Batch requests to conform to Inventory Service's input limits
  const batchSize = 100;
  const lpnIdBatches = chunk(lpnIds, batchSize);
  const lpnsPromises = lpnIdBatches.map(async (batchLpnIds) => (await inventoryService.getLpns(batchLpnIds)).data);

  const lpnBatchesResults = await Promise.all(lpnsPromises);
  return flatMap(lpnBatchesResults);
}
