import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {Button, Loader, Pagination, Row, Table, TableHeader} from '@flexe/ui-components';
import {FilterOption, Inventory} from '../CommonInterfaces';
import {TypeAheadOption} from '../TypeAhead';
import SelectFilter from '../SelectFilter';
import CycleCountsService, {BulkCreateCycleCountParams} from '../../shared/services/CycleCountsService';
import InventoryService from '../../shared/services/InventoryService';
import LocationsService, {LocationContents} from '../../locations/LocationsService';
import WarehouseService from '../services/WarehouseService';
import {LocationCategoryStrings, LocationsFlag, ReservationLocationConfig} from '../../locations/LocationsInterfaces';
import DropDown, {DropDownColor, DropDownOption, DropDownStyle} from '../DropDown';
import BulkCycleCountImportCSVModal from './bulkCycleCountImportCSV/BulkCycleCountImportCSVModal';
import CycleCountBulkCreateModal from './CycleCountBulkCreateModal';
import CycleCountTypeSwitchModal from './CycleCountTypeSwitchModal';
import CycleCountFilterRangeModal from './CycleCountFilterRangeModal';
import CycleCountCreateModal from './CycleCountCreateModal';
import {CycleCountType, CycleCountTypeString, ReservationFilterOption} from './CycleCountInterfaces';

interface Props {
  authenticityToken: string;
  isShipper: boolean;
  cycleCountsService: CycleCountsService;
  locationsService: LocationsService;
  inventoryService: InventoryService;
  warehouseService: WarehouseService;
  warehouses: FilterOption[];
  currentWarehouse: FilterOption;
  reservations?: ReservationFilterOption[];
  currentReservation?: ReservationFilterOption;
  enableLpnCycleCounts: boolean;
}

interface Params {
  id: string;
}

let filterTimeout;

const LOCATIONS_RESPONSE_PAGE_SIZE = 50;
const PHYSICAL_STORAGE_LOCATIONS_FILTER = {
  locationCategory: [
    LocationCategoryStrings.CATEGORY_FLOOR_STORAGE,
    LocationCategoryStrings.CATEGORY_RACK_STORAGE,
    LocationCategoryStrings.CATEGORY_BIN_STORAGE,
    LocationCategoryStrings.CATEGORY_QUALITY_AUDIT,
    LocationCategoryStrings.CATEGORY_LOST
  ]
};

enum RangeFilterTypes {
  start,
  end
}

const CycleCountCreator: React.FC<Props & RouteComponentProps<Params>> = (props) => {
  const isMountedRef = useRef(null);
  const warehouseService = props.warehouseService;

  const [errors, setErrors] = useState<string[]>(null);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showBulkCreateModal, setShowBulkCreateModal] = useState<boolean>(false);
  const [showFilterRangeModal, setShowFilterRangeModal] = useState<boolean>(false);
  const [showCcSwitchModal, setShowCcSwitchModal] = useState<boolean>(false);
  const [creatingCycleCount, setCreatingCycleCount] = useState<boolean>(false);
  const [continuationTokens, setContinuationTokens] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [skuFilter, setSkuFilter] = useState<string>(null);
  const [locationFilter, setLocationFilter] = useState<object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [locations, setLocations] = useState<LocationContents[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<LocationContents[]>([]);
  const [selectedInventories, setSelectedInventories] = useState<Inventory[]>([]);
  const [totalInventoryCount, setTotalInventoryCount] = useState<number>(0);
  const [totalLocationsCount, setTotalLocationsCount] = useState<number>(0);
  const [reservations, setReservations] = useState<ReservationFilterOption[]>(props.reservations || []);
  const [selectedReservation, setSelectedReservation] = useState<ReservationFilterOption>(props.currentReservation);
  const [warehouses, setWarehouses] = useState<FilterOption[]>(props.warehouses || []);
  const [selectedWarehouse, setSelectedWarehouse] = useState<FilterOption>(props.currentWarehouse);
  const [cycleCountType, setCycleCountType] = useState<CycleCountType>(
    props.isShipper ? CycleCountType.item : CycleCountType.location
  );
  const [isLpnReservationPresent, setIsLpnReservationPresent] = useState<boolean>(false);
  const [locationTypeaheadStartOptions, setLocationTypeaheadStartOptions] = useState<TypeAheadOption[]>([]);
  const [locationTypeaheadEndOptions, setLocationTypeaheadEndOptions] = useState<TypeAheadOption[]>([]);
  const [locationRangeStartFromState, setLocationRangeStartFromState] = useState<[string, string]>(['', '']);
  const [locationRangeEndFromState, setLocationRangeEndFromState] = useState<[string, string]>(['', '']);
  const [locationRangeLength, setLocationRangeLength] = useState<number>(0);
  const [maxFilteredLocations, setMaxFilteredLocations] = useState<number>(1000);
  const [enableCountAllLocations, setEnableCountAllLocations] = useState<boolean>(false);
  const [countAllLocations, setCountAllLocations] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<DropDownOption>({name: '', value: ''});
  const [bulkImportModal, setBulkImportModal] = useState<boolean>(false);
  const [enableCsvImport, setEnableCsvImport] = useState<boolean>(false);

  useEffect(() => {
    isMountedRef.current = true;
    if (!props.isShipper) {
      getWarehouseFeatureFlags();
      getReservations();
    }
    if (cycleCountType === CycleCountType.item || props.isShipper) {
      updateStateToReflectLpnStatus(selectedReservation ? [selectedReservation] : []);
      getInventories();
    } else {
      getLocations();
    }
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (cycleCountType === CycleCountType.location) {
      getLocations();
    }
  }, [locationFilter]);

  useEffect(() => {
    if (cycleCountType === CycleCountType.item) {
      getInventories();
    }
  }, [skuFilter]);

  useEffect(() => {
    handleWarehouseSelectionCallback();
    toggleEnableCountAllLocations();
  }, [selectedWarehouse]);

  useEffect(() => {
    if (cycleCountType === CycleCountType.item) {
      getInventories();
    }
  }, [selectedReservation]);

  useEffect(() => {
    if (cycleCountType === CycleCountType.item) {
      getInventories();
    }
    if (cycleCountType === CycleCountType.location) {
      getLocations();
    }
  }, [cycleCountType]);

  useEffect(() => {
    if (locationRangeStartFromState[1] === 'input') {
      updateLocationRangeTypeahead(RangeFilterTypes.start, locationRangeStartFromState[0]);
    }
  }, [locationRangeStartFromState]);

  useEffect(() => {
    if (locationRangeEndFromState[1] === 'input') {
      updateLocationRangeTypeahead(RangeFilterTypes.end, locationRangeEndFromState[0]);
    }
  }, [locationRangeEndFromState]);

  const getReservations = async () => {
    const res: ReservationFilterOption[] = [];
    let errs = [];
    const warehouseId: number = selectedWarehouse ? parseInt(selectedWarehouse.value as string, 10) : -1;
    const response = await warehouseService.getReservationsForWarehouse(warehouseId);
    if (response) {
      if (response.errors.length) {
        errs = props.cycleCountsService.processErrors(response.errors);
      } else if (response.data.reservations.length === 0) {
        errs = ['The selected warehouse has no reservations to cycle count'];
      } else {
        // We need to remove overflow reservations from this list before setting state,
        // to make sure that overflow reservations don't try to start cycle counts
        for (const reservation of response.data.reservations) {
          const resLocConf = await warehouseService.getReservationLocationConfig(reservation.id);
          const config: ReservationLocationConfig = resLocConf.data.reservationLocationConfig;
          if (config.locationsFlag !== LocationsFlag.locations_disabled) {
            res.push({
              displayName: reservation.name,
              value: reservation.id,
              lpnEnabled: reservation.lpnEnabled,
              locationsFlag: config.locationsFlag
            } as ReservationFilterOption);
          }
        }
        setReservations(res);
        setSelectedReservation(res[0]);
        updateStateToReflectLpnStatus(reservations);
      }
    }
    setErrors(errs);
  };

  const getWarehouseFeatureFlags = async () => {
    if (!props.isShipper) {
      const response = await warehouseService.getFeatureFlag(
        'enable-cycle-count-csv-creation',
        parseInt(selectedWarehouse.value as string, 10)
      );
      setEnableCsvImport(!!response.data.value);
    }
  };

  const toggleEnableCountAllLocations = () => {
    if (!props.isShipper) {
      setEnableCountAllLocations(true);
    } else {
      setEnableCountAllLocations(false);
    }
  };

  const getInventories = async (continuationToken: string = null, newPage: number = 1) => {
    let errs = [];
    if (!selectedReservation || reservations.length === 0) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await props.inventoryService.getInventories(
        selectedReservation.value,
        skuFilter,
        continuationToken,
        props.isShipper,
        true
      );
      const invs = response.inventory_summary.map((inv) => {
        return {
          id: inv.id,
          sku: inv.item_code,
          description: inv.description
        };
      });
      setContinuationTokens((previousTokens) =>
        newPage > 1 ? [...previousTokens, response.continuation_token] : [response.continuation_token]
      );
      setTotalInventoryCount(response.result_set_size);
      setCurrentPage(newPage);
      setInventories(invs);
    } catch (err) {
      if (err.length === undefined) {
        errs = props.cycleCountsService.processErrors([err]);
      } else {
        errs = props.cycleCountsService.processErrors(err);
      }
    } finally {
      setErrors(errs);
      setLoading(false);
    }
  };

  const getLocations = async (continuationToken: string = null, newPage: number = 1) => {
    let errs = [];
    setLoading(true);
    try {
      const warehouseId: number = selectedWarehouse ? parseInt(selectedWarehouse.value as string, 10) : -1;

      let locationCategoryFilter = PHYSICAL_STORAGE_LOCATIONS_FILTER.locationCategory;

      if (Object.keys(locationFilter).length === 0) {
        locationCategoryFilter = locationCategoryFilter.filter(
          (category) => category !== LocationCategoryStrings.CATEGORY_LOST
        );
      }

      const response = await props.locationsService.getLocations(
        warehouseId,
        LOCATIONS_RESPONSE_PAGE_SIZE,
        continuationToken,
        {...locationFilter, ...{locationCategory: locationCategoryFilter}}
      );
      const retrievedLocations = response.locations;

      setContinuationTokens((previousTokens) =>
        newPage > 1 ? [...previousTokens, response.continuationToken] : [response.continuationToken]
      );
      setTotalLocationsCount(response.totalLocationsCount);
      setCurrentPage(newPage);
      setLocations(retrievedLocations);
    } catch (err) {
      if (err.length === undefined) {
        errs = props.cycleCountsService.processErrors([err]);
      } else {
        errs = props.cycleCountsService.processErrors(err);
      }
    } finally {
      setErrors(errs);
      setLoading(false);
    }
  };

  const handlePagination = async (page: number) => {
    const ccType: string = cycleCountType;
    const cToken: string = page > 1 ? continuationTokens[page - 2] : null;
    if (ccType === CycleCountType.item) {
      await getInventories(cToken, page);
    } else {
      await getLocations(cToken, page);
    }
  };

  const handleWarehouseSelection = (warehouseIdString: string) => {
    const whId = parseInt(warehouseIdString, 10);
    const wHouse = warehouses.filter((wh) => wh.value === whId)[0];
    setSelectedWarehouse(wHouse);
    setSelectedInventories([]);
    setSelectedLocations([]);
  };

  const handleWarehouseSelectionCallback = () => {
    if (cycleCountType === CycleCountType.location) {
      getLocations();
    } else {
      getReservations();
    }
  };

  const handleReservationSelection = (reservationIdString) => {
    const resId = parseInt(reservationIdString, 10);
    const selectedRes: ReservationFilterOption = reservations.filter((res) => res.value === resId)[0];
    updateStateToReflectLpnStatus([selectedRes]);
    setSelectedReservation(selectedRes);
    setSelectedInventories([]);
    setSelectedLocations([]);
  };

  const handleCycleCountTypeSelection = () => {
    // toggle between the two cc types
    if (cycleCountType === CycleCountType.location) {
      setCycleCountType(CycleCountType.item);
      setSelectedInventories([]);
      setSelectedLocations([]);
      setShowCcSwitchModal(false);
    } else {
      setCycleCountType(CycleCountType.location);
      setSelectedInventories([]);
      setSelectedLocations([]);
      setShowCcSwitchModal(false);
    }
  };

  const updateFilter = async (event) => {
    const newFilter: string = event.target.value;
    if (filterTimeout) {
      clearTimeout(filterTimeout);
    }
    if (cycleCountType === CycleCountType.item) {
      const newSkuFilter: string = newFilter;
      filterTimeout = setTimeout(() => {
        setSkuFilter(newSkuFilter);
      }, 500);
    } else {
      const newLocationFilter: object = newFilter.length > 0 ? {locationLabel: [newFilter]} : {};
      filterTimeout = setTimeout(() => {
        setLocationFilter(newLocationFilter);
      }, 500);
    }
  };

  const getTableData = () => {
    const ccType: string = cycleCountType;
    const idHeaderType: string = ccType === CycleCountType.item ? 'SKU' : 'Location';
    const skuOrDescriptionHeader: string = ccType === CycleCountType.item ? 'Description' : 'SKU';
    const totalResultsCount: number = ccType === CycleCountType.item ? totalInventoryCount : totalLocationsCount;
    const headers = [
      {element: null},
      {className: 'id-header', element: idHeaderType},
      {element: skuOrDescriptionHeader},
      {element: !props.isShipper ? 'Eaches' : ''},
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={currentPage}
            pageSize={50}
            paginationHandler={(page) => handlePagination(page)}
            totalCount={totalResultsCount}
          />
        )
      }
    ] as TableHeader[];
    const rows = cycleCountType === CycleCountType.item ? buildRowsForSkuBasedCC() : buildRowsForLocationBasedCC();
    return {
      headers,
      rows
    };
  };

  const buildRowsForSkuBasedCC = () => {
    if (inventories && inventories.length > 0) {
      const rows = inventories.map((inv: Inventory) => {
        return [
          <a className="select-inventory" onClick={toggleItemsSelected} data-id={inv.id} data-testid={`plus-${inv.id}`}>
            {selectedInventories.find((s) => s.id === inv.id) ? (
              <i className="fa fa-check"></i>
            ) : (
              <i className="fa fa-plus"></i>
            )}
          </a>,
          inv.sku,
          inv.description,
          null
        ];
      });
      return rows;
    }
    return [];
  };

  const buildRowsForLocationBasedCC = () => {
    if (locations && locations.length > 0) {
      const rows = locations.map((loc: LocationContents) => {
        let locationSku;
        switch (true) {
          case loc.contents.length === 0:
            locationSku = '-';
            break;
          case loc.contents.length === 1:
            locationSku = loc.contents[0].inventory.sku;
            break;
          case loc.contents.length > 1:
            // eslint-disable-next-line no-case-declarations
            const firstSku = loc.contents[0].inventory.sku;
            locationSku = firstSku;
            for (let i = 1; i < loc.contents.length; i++) {
              if (loc.contents[i].inventory.sku !== firstSku) {
                locationSku = 'Multi-SKU';
                break;
              }
            }
            break;
          default:
            locationSku = '-';
        }
        const locationQuantity: number = loc.contents.reduce(
          (sum, locContents) => sum + locContents.quantity.amount,
          0
        );
        return [
          <a className="select-inventory" onClick={toggleItemsSelected} data-id={loc.id} data-testid={`plus-${loc.id}`}>
            {selectedLocations.find((s) => s.id === loc.id) ? (
              <i className="fa fa-check"></i>
            ) : (
              <i className="fa fa-plus"></i>
            )}
          </a>,
          loc.label,
          locationSku,
          locationQuantity,
          null
        ];
      });
      return rows;
    }
    return [];
  };

  const toggleItemsSelected = async (event) => {
    const itemId: number = parseInt(event.currentTarget.getAttribute('data-id'), 10);
    if (cycleCountType === CycleCountType.item) {
      const selInventories: Inventory[] = cloneDeep(selectedInventories);
      if (selInventories.find((s) => s.id === itemId)) {
        const index: number = selInventories.findIndex((s) => s.id === itemId);
        selInventories.splice(index, 1);
      } else {
        selInventories.push(inventories.find((i) => i.id === itemId));
        selInventories.sort((a, b) => {
          const aSku: string = a.sku.toLowerCase();
          const bSku: string = b.sku.toLowerCase();
          if (aSku < bSku) {
            return -1;
          } else if (aSku > bSku) {
            return 1;
          }
          return 0;
        });
      }
      setSelectedInventories(selInventories);
    } else {
      const selLocations: LocationContents[] = cloneDeep(selectedLocations);
      if (selLocations.find((s) => s.id === itemId)) {
        const index: number = selLocations.findIndex((s) => s.id === itemId);
        selLocations.splice(index, 1);
      } else {
        selLocations.push(locations.find((i) => i.id === itemId));
        selLocations.sort((a, b) => {
          const aLoc: string = a.label.toLowerCase();
          const bLoc: string = b.label.toLowerCase();
          if (aLoc < bLoc) {
            return -1;
          } else if (aLoc > bLoc) {
            return 1;
          }
          return 0;
        });
      }
      setSelectedLocations(selLocations);
    }
  };

  const clearSelections = async (event) => {
    setSelectedInventories([]);
    setSelectedLocations([]);
  };

  const toggleCreateModal = async (event) => {
    if (cycleCountType === CycleCountType.location && enableCountAllLocations) {
      setCountAllLocations(false);
      setShowBulkCreateModal(!showBulkCreateModal);
    } else {
      setShowCreateModal(!showCreateModal);
    }
  };

  const toggleBulkCreateModal = async (event, isCountingAllLocations = false) => {
    setCountAllLocations(isCountingAllLocations);
    setShowBulkCreateModal(!showBulkCreateModal);
  };

  const handleCCTypeChange = async (event) => {
    if (selectedLocations.length > 0 || selectedInventories.length > 0) {
      setShowCcSwitchModal(true);
    } else {
      handleCycleCountTypeSelection();
    }
  };

  const toggleCCTypeModal = async (event) => {
    setShowCcSwitchModal(!showCcSwitchModal);
  };

  const handleCreate = async (event) => {
    let errs = [];
    setCreatingCycleCount(true);
    const response = await props.cycleCountsService.createCycleCount(
      selectedWarehouse ? selectedWarehouse.value : null,
      selectedReservation ? selectedReservation.value : null,
      cycleCountType === CycleCountType.item ? selectedInventories.map((s) => s.sku) : null,
      cycleCountType === CycleCountType.location ? selectedLocations.map((i) => i.id) : null
    );

    if (response && response.errors.length === 0) {
      window.location.assign(`/${props.isShipper ? 's' : 'wh'}/cycle_counts/${response.data.cycleCount.id}`);
    } else if (response.errors.length) {
      errs = props.cycleCountsService.processErrors(response.errors);
    }

    setCreatingCycleCount(false);
    setErrors(errs);
    setShowCreateModal(false);
  };

  const handleBulkCreateLocCounts = async (countAllLocation: boolean, numberOfCounts: number, referenceId: string) => {
    let errs = [];
    setCreatingCycleCount(true);
    let locationCategories = [];
    let locationIds = [];
    if (countAllLocation) {
      locationCategories = PHYSICAL_STORAGE_LOCATIONS_FILTER.locationCategory.filter(
        (category) => category !== LocationCategoryStrings.CATEGORY_LOST
      );
    } else {
      locationIds = selectedLocations.map((i) => i.id);
    }
    const bulkCreateCycleCountParams: BulkCreateCycleCountParams = {
      warehouseId: selectedWarehouse ? selectedWarehouse.value : null,
      locationIds,
      locationCategories,
      numberOfCounts,
      referenceId,
      numberOfLocPerCount: Math.ceil(locationIds.length / numberOfCounts)
    };
    const response = await props.cycleCountsService.bulkCreateLocationCounts(bulkCreateCycleCountParams);

    if (response && response.errors.length === 0) {
      window.location.assign(`/${props.isShipper ? 's' : 'wh'}/cycle_counts`);
    } else if (response.errors.length) {
      errs = props.cycleCountsService.processErrors(response.errors);
    }

    setCreatingCycleCount(false);
    setErrors(errs);
    setShowBulkCreateModal(false);
  };

  const updateStateToReflectLpnStatus = (resArr: ReservationFilterOption[]) => {
    const lpnsPresent: boolean = resArr.filter((res) => res.lpnEnabled).length > 0;
    setIsLpnReservationPresent(lpnsPresent);
  };

  const toggleFilterRangeModal = async () => {
    setShowFilterRangeModal(!showFilterRangeModal);
    setLocationRangeEndFromState(['', 'modal']);
    setLocationRangeStartFromState(['', 'modal']);
    setLocationRangeLength(0);
    setLocationTypeaheadEndOptions([]);
    setLocationTypeaheadStartOptions([]);
  };

  const updateLocationRangeStart = async (locRangeStart) => {
    setLocationRangeStartFromState([locRangeStart, 'modal']);
    setLocationTypeaheadStartOptions([]);
    if (locationRangeEndFromState) {
      updateLocationRangeLength();
    }
  };

  const updateLocationRangeEnd = async (locRangeEnd) => {
    setLocationRangeEndFromState([locRangeEnd, 'modal']);
    setLocationTypeaheadEndOptions([]);
    if (locationRangeStartFromState) {
      updateLocationRangeLength();
    }
  };

  const updateLocationRangeLength = async () => {
    let errs = [];
    try {
      let rangeStart: string = locationRangeStartFromState[0];
      let rangeEnd: string = locationRangeEndFromState[0];
      if (rangeStart && rangeEnd) {
        if (rangeEnd < rangeStart) {
          [rangeStart, rangeEnd] = [rangeEnd, rangeStart];
        }
        const rangeToSelect: object = {
          locationRangeStart: rangeStart.toUpperCase(),
          locationRangeEnd: rangeEnd.toUpperCase()
        };
        const warehouseId: number = parseInt(selectedWarehouse.value as string, 10);
        const response = await props.locationsService.getLocations(warehouseId, maxFilteredLocations + 1, null, {
          ...rangeToSelect,
          ...PHYSICAL_STORAGE_LOCATIONS_FILTER
        });
        setLocationRangeLength(response.locations.length);
      }
    } catch (err) {
      errs = props.cycleCountsService.processErrors(err);
    } finally {
      setErrors(errs);
    }
  };

  const handleLocationRangeSelection = async (event) => {
    if (locationRangeStartFromState && locationRangeEndFromState) {
      let errs = [];
      try {
        let rangeStart: string = locationRangeStartFromState[0];
        let rangeEnd: string = locationRangeEndFromState[0];
        if (rangeEnd < rangeStart) {
          [rangeStart, rangeEnd] = [rangeEnd, rangeStart];
        }
        const rangeToSelect: object = {
          locationRangeStart: rangeStart.toUpperCase(),
          locationRangeEnd: rangeEnd.toUpperCase()
        };
        const warehouseId: number = parseInt(selectedWarehouse.value as string, 10);
        const response = await props.locationsService.getLocations(warehouseId, maxFilteredLocations, null, {
          ...rangeToSelect,
          ...PHYSICAL_STORAGE_LOCATIONS_FILTER
        });
        const responseLocs = response.locations;
        let selLocations: LocationContents[] = cloneDeep(selectedLocations);
        for (const loc of responseLocs) {
          if (!selLocations.includes(loc)) {
            selLocations.push(loc);
          }
        }
        selLocations = selLocations.sort((a, b) => {
          const aLoc: string = a.label.toLowerCase();
          const bLoc: string = b.label.toLowerCase();
          if (aLoc < bLoc) {
            return -1;
          } else if (aLoc > bLoc) {
            return 1;
          }
          return 0;
        });
        setSelectedLocations(selLocations);
      } catch (err) {
        errs = props.cycleCountsService.processErrors(err);
      } finally {
        setErrors(errs);
        setShowFilterRangeModal(false);
      }
    }
  };

  const updateLocationRangeStartTypeahead = async (query) => {
    setLocationRangeStartFromState([query, 'input']);
    updateLocationRangeTypeahead(RangeFilterTypes.start, query);
  };

  const updateLocationRangeEndTypeahead = async (query) => {
    setLocationRangeEndFromState([query, 'input']);
    updateLocationRangeTypeahead(RangeFilterTypes.end, query);
  };

  const updateLocationRangeTypeahead = async (rangeFilterType: number, query: string) => {
    if (query.length > 2) {
      let errs = [];
      const locFilter: object = {locationLabel: [query]};
      try {
        const warehouseId: number = parseInt(selectedWarehouse.value as string, 10);
        const response = await props.locationsService.getLocations(warehouseId, LOCATIONS_RESPONSE_PAGE_SIZE, null, {
          ...locFilter,
          ...locationFilter,
          ...PHYSICAL_STORAGE_LOCATIONS_FILTER
        });
        const retrievedLocations = response.locations;
        const options = retrievedLocations.map((loc) => {
          return {
            value: loc.label,
            displayName: `${loc.label}`
          };
        });
        if (rangeFilterType === RangeFilterTypes.start) {
          setLocationTypeaheadStartOptions(options);
        } else {
          setLocationTypeaheadEndOptions(options);
        }
      } catch (err) {
        errs = props.cycleCountsService.processErrors(err);
      }
    } else {
      if (rangeFilterType === RangeFilterTypes.start) {
        setLocationTypeaheadStartOptions([]);
      } else {
        setLocationTypeaheadEndOptions([]);
      }
    }
  };

  const getCycleCountTypes = () => {
    let types = [];
    if (!props.isShipper) {
      types = types.concat({
        displayName: CycleCountTypeString.LocationCycleCount,
        value: CycleCountType.location
      });
    }
    types = types.concat({
      displayName: CycleCountTypeString.InventoryCycleCount,
      value: CycleCountType.item
    });
    return types;
  };

  const selectedLocationsLength: number = selectedLocations.length;
  const selectedInventoriesLength: number = selectedInventories.length;

  const optionsCsv: DropDownOption[] = [
    {name: 'Upload CSV', value: 'Upload CSV'},
    {name: 'Download Template', value: 'Download'}
  ];

  const handleSelect = (opt) => {
    setSelectedOption(opt);
    if (opt.value === 'Upload CSV') {
      setBulkImportModal(true);
    }
  };

  const toggleBulkImportModal = (hasCycleCounts: boolean) => {
    if (hasCycleCounts) {
      window.location.assign(`/${props.isShipper ? 's' : 'wh'}/cycle_counts`);
    }
    setBulkImportModal(!bulkImportModal);
  };

  return (
    <div className="container-fluid">
      {isLpnReservationPresent && props.enableLpnCycleCounts === false && (
        <div className="alert alert-warning alert-lpn" role="alert">
          <span>Cycle count creation is disabled for LPN enabled reservations</span>
        </div>
      )}
      {!!(errors && errors.length) && (
        <div className="alert alert-danger" role="alert">
          {errors}
        </div>
      )}
      <div className="row col-sm-6">
        <h1>New Cycle Count</h1>
      </div>
      <div className="row">
        <Row alignmentHorizontal={'end'}>
          <Link to={`/${props.isShipper ? 's' : 'wh'}/cycle_counts`}>
            <Button size={'large'} visualType={'secondary'}>
              Cancel
            </Button>
          </Link>
          {cycleCountType === CycleCountType.item ? (
            <Button
              id="create-cta"
              onPress={toggleCreateModal}
              size={'large'}
              visualType={'secondary'}
              isDisabled={
                (isLpnReservationPresent && !props.enableLpnCycleCounts) ||
                !selectedInventories ||
                selectedInventoriesLength < 1
              }
            >
              Count Selected
            </Button>
          ) : (
            <div>
              {enableCountAllLocations && (
                <Button
                  id="count-all-loc-button"
                  size={'large'}
                  visualType={'secondary'}
                  isDisabled={isLpnReservationPresent && !props.enableLpnCycleCounts}
                  onPress={(event) => {
                    toggleBulkCreateModal(event, true);
                  }}
                >
                  Full Physical Count
                </Button>
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {enableCsvImport && (
                <DropDown
                  options={optionsCsv}
                  selected={selectedOption}
                  style={DropDownStyle.modal}
                  onSelect={handleSelect}
                  prefixText={'Create Counts via CSV'}
                  downloadLink={'/static/files/CycleCountBulkCreateImport.csv'}
                  hideSelectedName={true}
                  color={DropDownColor.white}
                />
              )}
              {bulkImportModal && (
                <BulkCycleCountImportCSVModal
                  cycleCountsService={props.cycleCountsService}
                  warehouseId={parseInt(selectedWarehouse.value as string, 10)}
                  onModalToggle={toggleBulkImportModal}
                  reservationId={parseInt(selectedReservation.value as string, 10)}
                />
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                id="create-cta"
                size={'large'}
                onPress={toggleCreateModal}
                visualType={'secondary'}
                isDisabled={
                  (isLpnReservationPresent && !props.enableLpnCycleCounts) ||
                  !selectedLocations ||
                  selectedLocationsLength < 1
                }
              >
                Count Selected
              </Button>
            </div>
          )}
        </Row>
      </div>
      <div className="row space-below">
        {!props.isShipper && selectedWarehouse && warehouses ? (
          <div className="col-sm-3">
            <SelectFilter
              label="Warehouse:"
              options={warehouses}
              name="warehouse-select"
              onSelect={handleWarehouseSelection}
              selected={selectedWarehouse.value ? selectedWarehouse.value.toString() : ''}
            />
          </div>
        ) : null}
        {!props.isShipper && (
          <div className="col-sm-2">
            <SelectFilter
              label="Count By:"
              name="count-type-select"
              options={getCycleCountTypes()}
              onSelect={handleCCTypeChange}
              selected={cycleCountType === CycleCountType.item ? CycleCountType.item : CycleCountType.location}
            />
          </div>
        )}
        {cycleCountType === CycleCountType.item ? (
          <div>
            <div className="col-sm-3">
              <SelectFilter
                label="Reservation:"
                options={reservations}
                name="reservation-select"
                onSelect={handleReservationSelection}
                selected={selectedReservation.value ? selectedReservation.value.toString() : ''}
              />
            </div>
            <div className="col-sm-3">
              <h5>Items:</h5>
              <input
                type="text"
                className="sku-filter"
                onChange={updateFilter}
                placeholder="Filter by SKU or description..."
              />
            </div>
          </div>
        ) : (
          <div className="col-sm-3">
            <h5>Locations:</h5>
            <input type="text" className="loc-filter" onChange={updateFilter} placeholder="Filter by Location..." />
          </div>
        )}
        {(selectedInventoriesLength > 0 || selectedLocationsLength > 0) && (
          <div className="col-sm-3">
            <b className="pull-right">
              {cycleCountType === CycleCountType.item ? selectedInventoriesLength : selectedLocationsLength}
              {cycleCountType === CycleCountType.item ? ' SKUs' : ' Locations'} selected
            </b>
            <br />
            <a className="pull-right" onClick={clearSelections}>
              <b>Clear All</b>
            </a>
          </div>
        )}
      </div>
      {cycleCountType === CycleCountType.item ? (
        <br />
      ) : (
        <div className="row">
          <div className="col-sm-6">
            <a onClick={toggleFilterRangeModal}>
              <span className="val">
                <h5> + Select a range of locations </h5>
              </span>
            </a>
          </div>
        </div>
      )}
      <div className="row">
        {!loading && (inventories.length > 0 || locations.length > 0) && (
          <Table tableClass="inventories-table" tableData={getTableData()} />
        )}
        {!loading && inventories.length < 1 && locations.length < 1 && (
          <p className="col-sm-12">No inventories matching the current selection.</p>
        )}
        <Loader loading={loading} />
        <CycleCountCreateModal
          showCreateModal={showCreateModal}
          cycleCountType={cycleCountType}
          creatingCycleCount={creatingCycleCount}
          selectedReservation={selectedReservation}
          selectedInventories={selectedInventories}
          selectedLocations={selectedLocations}
          countAllLocations={countAllLocations}
          selectedWarehouse={selectedWarehouse}
          toggleCreateModal={toggleCreateModal}
          handleCreate={handleCreate}
        />
        {!props.isShipper && (
          <CycleCountBulkCreateModal
            showBulkCreateModal={showBulkCreateModal}
            creatingCycleCount={creatingCycleCount}
            countAllLocations={countAllLocations}
            totalLocationsCount={totalLocationsCount}
            selectedWarehouse={selectedWarehouse}
            toggleCreateModal={toggleBulkCreateModal}
            selectedLocations={selectedLocations}
            handleCreate={handleBulkCreateLocCounts}
          />
        )}
        <CycleCountFilterRangeModal
          showFilterRangeModal={showFilterRangeModal}
          locationRangeStart={locationRangeStartFromState[0]}
          locationRangeEnd={locationRangeEndFromState[0]}
          locationTypeaheadStartOptions={locationTypeaheadStartOptions}
          locationTypeaheadEndOptions={locationTypeaheadEndOptions}
          locationRangeLength={locationRangeLength}
          maxFilteredLocations={maxFilteredLocations}
          handleLocationRangeSelection={handleLocationRangeSelection}
          updateLocationRangeStartTypeahead={updateLocationRangeStartTypeahead}
          updateLocationRangeEndTypeahead={updateLocationRangeEndTypeahead}
          updateLocationRangeStart={updateLocationRangeStart}
          updateLocationRangeEnd={updateLocationRangeEnd}
          toggleFilterRangeModal={toggleFilterRangeModal}
          handleCreate={handleCreate}
        />
        <CycleCountTypeSwitchModal
          showCcSwitchModal={showCcSwitchModal}
          toggleCCTypeModal={toggleCCTypeModal}
          handleCycleCountTypeSelection={handleCycleCountTypeSelection}
        />
      </div>
    </div>
  );
};

export default CycleCountCreator;
