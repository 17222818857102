import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {GridColDef, GridRowParams} from '@mui/x-data-grid';
import {useMemo} from 'react';
import {flexeMuiTheme} from '../shared/mui/default-mui-theme';
import {FlexeDataGrid} from '../shared/mui/flexe-data-grid/FlexeDataGrid';
import {RoutableLocation} from '../shared/services/RoutePlanningService';
import {ItemLinkCell} from '../shared/mui/flexe-data-grid/ItemLinkCell';

interface Props {
  data: RoutableLocation[];
  isLoading: boolean;
  handleSelectionModelChange: (selectedRows: number[]) => void;
  selectedLocationIds: number[];
}

export function SelectRoutableLocationsTable(props: Props) {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'locationId',
        headerName: 'Locations',
        renderCell: (params) => {
          return (
            <ItemLinkCell
              baseUrl={`./locations`}
              item={{
                id: params.row.locationId,
                value: params.row.locationName
              }}
            />
          );
        },
        minWidth: 200,
        flex: 1
      },
      {
        field: 'lpnCount',
        headerName: 'Total Units',
        type: 'number',
        minWidth: 200,
        flex: 1
      }
    ],
    []
  );

  return (
    <div id="select-routable-locations-table">
      <p>
        This will assign all the received units at selected Locations with routing information. Routing information will
        appear on labels and on the mobile devices as a Sort Code.
      </p>
      <ThemeProvider theme={flexeMuiTheme}>
        <FlexeDataGrid
          rowSelectionModel={props.selectedLocationIds}
          onRowSelectionModelChange={props.handleSelectionModelChange}
          isRowSelectable={(params: GridRowParams) => params.row.lpnCount > 0}
          autoHeight
          getRowId={(row) => row.locationId}
          columns={columns}
          rows={props.data}
          loading={props.isLoading}
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          checkboxSelection
          keepNonExistentRowsSelected
        />
      </ThemeProvider>
    </div>
  );
}

export default SelectRoutableLocationsTable;
