/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {Dispatch, SetStateAction, useCallback, useEffect, useReducer, useState} from 'react';
import axios from 'axios';
import {Button, InfoBox, Text} from '@flexe/ui-components';
import {pick} from 'lodash';
import {addDays} from 'date-fns';
import {css, jsx} from '@emotion/react';
import HeaderDetail from '../../shared/HeaderDetail';
import HeaderEditableDetail, {HeaderEditableDetailType} from '../../shared/HeaderEditableDetail';
import ErrorDisplay from '../../shared/ErrorDisplay';
import WarehouseInformation from '../shipper/WarehouseInformation';
import WarehouseService from '../../shared/services/WarehouseService';
import UserService from '../../shared/services/UserService';
import {CancelInboundModal} from '../shipper/CancelInboundModal';
import {formatDate} from '../../shared/utilities/DataFormatting';
import {Document, Reservation} from '../../shared/CommonInterfaces';
import ContainersService from '../../shared/services/ContainersService';
import {
  ActorType,
  allowEditNotesInShipperUIFlag,
  ContainerDelivery,
  ContainerDeliveryState,
  InboundCapturableProperty,
  InboundStatusMap
} from './DropoffInterfaces';
import InboundDocuments from './InboundDocuments';
import InboundNotes from './InboundNotes';
import {useInfoBox} from './UseInfoBox';

export interface InboundDetailsHeaderPaneProps {
  containerDelivery: ContainerDelivery;
  containersService: ContainersService;
  onUpdateInstructions: (instructions: string) => void;
  documents: Document[];
  onDocumentSaved: (Document) => void;
  errors: string[];
  reservation: Reservation;
  reservationId: number;
  successMessage: string;
  enableInboundPackaging: boolean;
  inputTextMap: Map<string, string>;
  onInputTextMap: (
    eventTargetValue?: string,
    propertyKey?: string,
    inputTextMap?: Map<string, string>,
    setInputTextMap?: (value) => void
  ) => void;
  isEditing: boolean;
  handleIsBolUploaded: (isUploaded: boolean) => void;
  isLpnReceiveEnabled: boolean;
  isShipper: boolean;
  isEditMode: boolean;
  handleRefreshPage: () => void;
  warehouseService: WarehouseService;
  shipperEditProps?: {
    onCancelEditClicked: () => void;
    onDeleteDocs: () => void;
    onDeleteDocsFromUi: (documentId: number) => void;
    setContainerDelivery: Dispatch<SetStateAction<ContainerDelivery>>;
    toggleEditMode: () => void;
    userService: UserService;
  };
}

const VALUE = 'value';
const inboundHeaderStyle = css({
  maxWidth: 3000
});

export const InboundDetailsHeaderPane: React.FC<InboundDetailsHeaderPaneProps> = (props) => {
  const {
    isShipper,
    containersService,
    isEditMode,
    containerDelivery,
    isLpnReceiveEnabled,
    reservation,
    enableInboundPackaging,
    isEditing,
    errors,
    inputTextMap,
    onInputTextMap,
    documents,
    onDocumentSaved,
    handleIsBolUploaded,
    onUpdateInstructions,
    handleRefreshPage,
    warehouseService,
    shipperEditProps: {
      onCancelEditClicked,
      onDeleteDocs,
      onDeleteDocsFromUi,
      setContainerDelivery,
      toggleEditMode,
      userService
    } = {}
  } = props;
  const [documentErrors, setDocumentErrors] = useState<string[]>([]);
  const [isCancelModalOpen, toggleIsCancelModalOpen] = useReducer((isOpen) => !isOpen, false);

  const originalFormValues = pick(containerDelivery, 'moveDate', 'sealNumber', 'trailerNumber');
  const [formValues, setFormValues] = useState(originalFormValues);
  const infoBoxHook = useInfoBox();
  const [allowEditNotesInShipperUI, setAllowEditNotesInShipperUI] = useState<boolean>(false);

  const isContainerDeliveryActive = [
    ContainerDeliveryState.new,
    ContainerDeliveryState.confirmed,
    ContainerDeliveryState.altered,
    ContainerDeliveryState.arrived
  ].includes(containerDelivery.txnState as ContainerDeliveryState);

  useEffect(() => {
    loadFeatureFlags();
  }, [props.reservation]);

  const originalJson = JSON.stringify(originalFormValues);
  useEffect(() => {
    setFormValues(JSON.parse(originalJson));
  }, [originalJson]);

  const loadFeatureFlags = async () => {
    let featureFlagLoadErrors;
    try {
      const [editNotesInShipperUI] = await axios.all([
        warehouseService.getFeatureFlag(allowEditNotesInShipperUIFlag, null, props.reservation.id, null, null)
      ]);
      setAllowEditNotesInShipperUI(editNotesInShipperUI.data.value);
    } catch (errorResponse) {
      featureFlagLoadErrors = errorResponse.data.errors.map((error) => error.detail);
      // eslint-disable-next-line no-console
      featureFlagLoadErrors.forEach((e) => console.log(e));
    }
  };

  const handleUpdate = useCallback(async () => {
    if (!isEditMode) {
      toggleEditMode();
      return;
    }

    const response = await containersService.updateContainerDelivery({
      data: formValues,
      id: containerDelivery.id
    });
    onDeleteDocs();
    if (response?.errors?.length > 0) {
      setFormValues(originalFormValues);
      infoBoxHook.setError(response.errors.map((_) => _.detail).join(', '));
    } else {
      setContainerDelivery((_) => ({..._, ...formValues}));
      infoBoxHook.setInfo('Successfully updated container delivery.');
    }

    toggleEditMode();
  }, [
    isEditMode,
    toggleEditMode,
    containersService,
    containerDelivery,
    formValues,
    onDeleteDocs,
    originalFormValues,
    infoBoxHook,
    setFormValues
  ]);

  const completedBy = (container: ContainerDelivery) => {
    let result;
    switch (container.completedBy.type) {
      case ActorType.user:
        result = (
          <span>
            {' '}
            <i className="fa fa-user"></i> {container.completedBy.name}
          </span>
        );
        break;
      case ActorType.admin:
        result = ` FLEXE Staff (${container.completedBy.name})`;
        break;
      case ActorType.mobile:
        result = (
          <span>
            {' '}
            <i className="fa fa-mobile"></i> {container.completedBy.name}
          </span>
        );
        break;
      default:
        result = '';
    }
    return result;
  };

  const hasExtraProperties = (container: ContainerDelivery): boolean => {
    return (
      container.extraProperties &&
      (Object.keys(container.extraProperties.provided).length > 0 ||
        Object.keys(container.extraProperties.toCapture).length > 0)
    );
  };

  const checkContainerDeliveryState = () => {
    let isContainerDeliveryConfirmed = false;
    if (isLpnReceiveEnabled && containerDelivery.actualArrivalTime !== null) {
      isContainerDeliveryConfirmed = true;
    }
    if (
      !isLpnReceiveEnabled &&
      containerDelivery.receiving.startAt !== null &&
      containerDelivery.receiving.endAt !== null
    ) {
      isContainerDeliveryConfirmed = true;
    }
    return isContainerDeliveryConfirmed;
  };

  const inboundStatus = InboundStatusMap.get(containerDelivery.txnState);

  return (
    <div id="inbound-details-page">
      <div className="inbound-details-background">
        <div id="inbound-details-container" className="container-fluid" css={inboundHeaderStyle}>
          <div id="inbound-details">
            <Navigation
              inboundDeliveryLink={isShipper ? '/s/dropoffs' : '/wh/dropoffs'}
              containerDeliveryId={containerDelivery.id}
            />
            <Errors errors={errors} />
            {documentErrors?.map((err, i) => (
              <InfoBox infoType={'error'} info={err} key={i} />
            ))}
            {infoBoxHook.component}
            <header className="inbound-header">
              <div>
                <h1 className="inbound-title">Inbound Delivery #{containerDelivery.id}</h1>
                <span id="inbound-status" className={inboundStatus.className}>
                  {inboundStatus.text}
                </span>
              </div>
              {isShipper && isContainerDeliveryActive && (
                <div className="shipper-button-container">
                  <div className="shipper-cancel-edit-button">
                    {isEditMode && <Button onPress={onCancelEditClicked}>Cancel</Button>}
                  </div>
                  <div className="shipper-edit-update-button">
                    <Button onPress={handleUpdate} visualType={isEditMode ? 'primary' : 'secondary'}>
                      {isEditMode ? 'Update' : 'Edit'}
                    </Button>
                  </div>
                  <div className="shipper-cancel-button">
                    <Button onPress={toggleIsCancelModalOpen}>Cancel Delivery</Button>
                    <CancelInboundModal
                      containerDeliveryId={containerDelivery.id}
                      isOpen={isCancelModalOpen}
                      toggleModal={toggleIsCancelModalOpen}
                      containersService={containersService}
                      handleRefreshPage={handleRefreshPage}
                    />
                  </div>
                </div>
              )}
            </header>
            <div id="details">
              <div id="reservation-info">
                <span>{`Reservation >> ${reservation.id}`}</span>
              </div>
            </div>
            <div id="inbound-information">
              <div className="col-md-4">
                <h4 className="text-uppercase">Delivery Information</h4>
                {!isShipper && containerDelivery.completedBy?.type && (
                  <div className="detail-field">
                    <span className="identifier">Completed By: </span>
                    {completedBy(containerDelivery)}
                  </div>
                )}
                {isShipper && !enableInboundPackaging && <HeaderDetail label={'Type:'} value={'Container Unload'} />}
                {isShipper ? (
                  <HeaderEditableDetail
                    isEditing={
                      isEditMode &&
                      [
                        ContainerDeliveryState.new,
                        ContainerDeliveryState.confirmed,
                        ContainerDeliveryState.altered
                      ].includes(containerDelivery.txnState as ContainerDeliveryState)
                    }
                    label={'Date:'}
                    onChange={(moveDate) => setFormValues((_) => ({..._, moveDate}))}
                    required
                    type={{
                      displayDateFormat: 'MM/DD/YYYY',
                      type: HeaderEditableDetailType.date
                    }}
                    validations={{
                      min: formatDate(addDays(new Date(), 1), 'MM/DD/YYYY')
                    }}
                    value={formValues.moveDate}
                  />
                ) : (
                  <HeaderDetail
                    label={'Requested:'}
                    value={formatDate(new Date(containerDelivery.moveDate), 'MM/DD/YYYY', 'UTC')}
                  />
                )}
                {enableInboundPackaging &&
                  containerDelivery.expectedInboundPackaging &&
                  containerDelivery.expectedInboundPackaging !== '' && (
                    <HeaderDetail label={'Expected Inbound Type:'} value={containerDelivery.expectedInboundPackaging} />
                  )}
                {enableInboundPackaging && (
                  <HeaderDetail label={'Actual Inbound Type:'} value={containerDelivery.actualInboundPackaging} />
                )}
                <HeaderEditableDetail
                  isEditing={isEditMode && isContainerDeliveryActive}
                  label={'Trailer #:'}
                  onChange={(trailerNumber) => setFormValues((_) => ({..._, trailerNumber}))}
                  required
                  type={HeaderEditableDetailType.text}
                  value={formValues.trailerNumber}
                />
                <HeaderEditableDetail
                  isEditing={isEditMode && isContainerDeliveryActive}
                  label={'Seal #:'}
                  onChange={(sealNumber) => setFormValues((_) => ({..._, sealNumber}))}
                  required
                  type={HeaderEditableDetailType.text}
                  value={formValues.sealNumber}
                />
                {containerDelivery.receiving.startAt && !isShipper && (
                  <HeaderDetail
                    label={'Delivery Window:'}
                    value={containerDelivery.receiving.startAt + ' - ' + containerDelivery.receiving.endAt}
                  />
                )}
                {containerDelivery.requireReferenceId && (
                  <HeaderDetail label={'Has LPNs with Ref. ID:'} value={'Yes'} />
                )}
                <div className="vertical-rule" />
              </div>
              {hasExtraProperties(containerDelivery) && (
                <div data-testid="inbound-extra-properties" className="col-md-4">
                  <h4 className="text-uppercase">Additional Information</h4>
                  {containerDelivery.extraProperties &&
                    Object.entries(containerDelivery.extraProperties.provided).map((value, i) => (
                      <HeaderDetail key={i} label={`${value[0]}: `} value={value[1]} />
                    ))}
                  {containerDelivery.extraProperties &&
                    Object.entries(containerDelivery.extraProperties.toCapture).map(
                      ([name, property]: [string, InboundCapturableProperty], i) => (
                        <div>
                          <HeaderEditableDetail
                            editingInstructions={property.instructions}
                            isEditing={isEditing}
                            key={i}
                            propertyKey={name}
                            label={`${property.displayLabel}: `}
                            maxLength={50}
                            onChange={onInputTextMap}
                            required={property.required}
                            toolTipId={`inbound-details-input-tooltip-${i}`}
                            type={HeaderEditableDetailType[property.type]}
                            value={
                              inputTextMap.get(name) || containerDelivery.extraProperties.toCapture?.[name]?.[VALUE]
                            }
                          />
                        </div>
                      )
                    )}
                  <div className="vertical-rule" />
                </div>
              )}
              <div className="col-md-4">
                <h4 className="text-uppercase">Notes</h4>
                <div className="wh-shipment-instructions">
                  <span className="identifier">
                    <strong>Warehouse Shipment Instructions: </strong>
                  </span>
                  <InboundNotes
                    allowEditNotesInShipperUI={allowEditNotesInShipperUI}
                    isShipper={isShipper}
                    containersService={containersService}
                    containerDelivery={containerDelivery}
                    onUpdateInstructions={onUpdateInstructions}
                    onDocumentUploadError={setDocumentErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="inbound-additional-details" className="warehouse-info-and-docs">
        {isShipper && (
          <WarehouseInformation
            container={containerDelivery}
            reservation={reservation}
            isShipper={isShipper}
            warehouseService={warehouseService}
            userService={userService}
          />
        )}
        {documents && (
          <InboundDocuments
            isShipper={isShipper}
            documents={documents}
            notableId={containerDelivery.id}
            onDocumentSaved={onDocumentSaved}
            handleIsBolUploaded={handleIsBolUploaded}
            isContainerDeliveryConfirmed={checkContainerDeliveryState()}
            isEditMode={isEditMode}
            onDeleteDocsFromUi={onDeleteDocsFromUi}
            handleRefreshPage={handleRefreshPage}
          />
        )}
      </div>
    </div>
  );
};

const Navigation: React.FC<{
  inboundDeliveryLink: string;
  containerDeliveryId: ContainerDelivery['id'];
}> = ({containerDeliveryId, inboundDeliveryLink}) => (
  <div className="breadcrumbs delivery">
    <a href={inboundDeliveryLink}>Inbound Deliveries</a>
    <span className="navigation-separator"> / </span>
    <span>Inbound Delivery {containerDeliveryId}</span>
  </div>
);

const Errors: React.FC<{errors: string[]}> = ({errors = []}) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <div className="col-md-12 space-below">
      <ErrorDisplay errorText={errors.join(' ')} />
    </div>
  );
};

export default InboundDetailsHeaderPane;
