import {FC, useEffect, useState} from 'react';
import * as React from 'react';
import {Modal} from '@flexe/ui-components';
import {
  getLocationsWithLooseContents,
  getMheLocationIds,
  getNamedLocations
} from '../warehouse/outbound-shipments/shipment-details/DanglingLooseGoodsError';
import FlexeButton from '../shared/FlexeButton';
import {Location} from '../warehouse/outbound-shipments/ShipmentInterfaces';
import {ShipmentDetailsContext} from '../warehouse/outbound-shipments/shipment-details/ShipmentDetailsContext';

enum ErrorType {
  MheContainsLooseGoods,
  Unknown,
  None
}

interface Props {
  validate: boolean;
  onCancel: () => void;
  confirmPrint: () => void;
}

export const BulkCartonSsccPrinting: FC<Props> = (props) => {
  const {validate} = props;
  const {authenticityToken, wave} = React.useContext(ShipmentDetailsContext);

  const [activeMhes, setActiveMhes] = useState<Location[]>([]);
  const [error, setError] = useState<ErrorType>(ErrorType.None);

  const [pickCartsValidated, setPickCartsValidated] = useState<boolean>(false);

  // validations are triggered by a prop attached to the parent's print button
  useEffect(() => {
    if (validate) {
      validateMheContents();
    } else {
      setPickCartsValidated(false); // reset validation status for the next print attempt
    }
  }, [validate]);

  // abort modal creation and just print if the validations are successful
  useEffect(() => {
    if (pickCartsValidated) {
      props.confirmPrint();
    }
  }, [pickCartsValidated]);

  const validateMheContents = async () => {
    if (!wave || !authenticityToken) {
      return;
    }

    try {
      const pickCartLocationIds = await getMheLocationIds(authenticityToken, wave);
      const locationsIdsWithLooseGoods = await getLocationsWithLooseContents(authenticityToken, pickCartLocationIds);

      if (locationsIdsWithLooseGoods.length > 0) {
        const locations = await getNamedLocations(authenticityToken, locationsIdsWithLooseGoods, wave.reservation.id);
        setActiveMhes(locations);
        setError(ErrorType.MheContainsLooseGoods);
        setPickCartsValidated(false);
      } else {
        setPickCartsValidated(true);
      }
    } catch (err) {
      setError(ErrorType.Unknown);
      setPickCartsValidated(false);
    }
  };

  const getModalTitle = () => {
    switch (error) {
      case ErrorType.MheContainsLooseGoods:
        return 'MHEs Still Active';
      case ErrorType.Unknown:
        return 'Internal Server Error';
      default:
        return '';
    }
  };

  const getModalDetail = () => {
    switch (error) {
      case ErrorType.MheContainsLooseGoods:
        return getMheStillActiveError();
      case ErrorType.Unknown:
        return <div className={'error-detail'}>An unexpected error occurred. Please contact Flexe support.</div>;
      default:
        return null;
    }
  };

  const getMheStillActiveError = () => {
    return (
      <div>
        The following MHEs still have units tied to this shipment.
        <br />
        {activeMhes.map((mhe) => {
          return (
            <div className={'mhe-list-entry'}>
              <b>» {mhe.name}</b>
            </div>
          );
        })}
        <br />
      </div>
    );
  };

  const exitModal = () => {
    setError(ErrorType.None);
    props.onCancel();
  };

  const printAnyway = () => {
    setError(ErrorType.None);
    props.confirmPrint();
  };

  return (
    <Modal title={getModalTitle()} isOpen={error !== ErrorType.None} toggleModal={exitModal}>
      <div id={'bulk-sscc-carton-printing'} data-testid={'bulk-sscc-carton-printing'}>
        <div className={'error-detail'}>{getModalDetail()}</div>
        <div className={'buttons'}>
          <FlexeButton
            text={'Print Anyway'}
            isDisabled={false}
            handleClick={printAnyway}
            level="primary"
            data-testid={'bulk-sscc-carton-print-button'}
          />
          <FlexeButton
            text={"Don't Print Yet"}
            isDisabled={false}
            handleClick={exitModal}
            level="secondary"
            data-testid={'bulk-sscc-carton-cancel-button'}
          />
        </div>
      </div>
    </Modal>
  );
};
