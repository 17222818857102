import axios from 'axios';
import uuidv4 from 'uuid/v4';
import {get, unionBy, uniq} from 'lodash';
import {ParseError} from '../../libs/CsvValidator';
import {ApiResponse, Measurement, Packaging, ResponseError, SimpleLocationsResponse} from '../shared/CommonInterfaces';
import InternalAPIService from '../shared/services/InternalAPIService';
import {GetMovementLogRequest, GetMovementLogResponse} from './MovementLogInterfaces';
import {BulkToggleLocationResponse, LocationEntityType, LocationStatus} from './LocationsInterfaces';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export interface LocationsResponse {
  continuationToken: string;
  didHitLocationContentRetrievalLimit: boolean;
  locations: LocationContents[];
  totalLocationsCount: number;
}

export interface LocationsV2Response {
  continuationToken: string;
  locations: [
    {
      id: number;
      label: string;
    }
  ];
}

export interface LocationContents {
  barcodeText: string;
  category: string;
  contents: LocationContent[];
  createdAt: string;
  dimensions: {
    length: Measurement;
    width: Measurement;
    height: Measurement;
  };
  id: number;
  isDefaultLocation: boolean;
  label: string;
  state: string;
  type: string;
  updatedAt: string;
  arrivalTime: string;
  warehouse: {
    type: string;
    id: number;
  };
  allocatable: boolean;
  putAwayZone: string;
  palletCapacity: number;
  sequenceId: number;
  associatedEntity: {
    id: number;
    type: LocationEntityType;
  };
  primaryPickLocation: boolean;
  lockInfo: string;
  primarySku: string;
  primarySkuPackagingType: string;
  primarySkuMaxQuantity: number;
}

export interface LocationContent {
  entity: {
    type?: string;
    id?: string; // lpn_barcode
    lpnId?: number; // lpn_detail_id
  };
  inventory: {
    type: string;
    id: number;
    sku: string;
  };
  quantity: {
    unit: Packaging;
    amount: number;
    conversions: {
      each: {unit: string; amount: string};
      carton: {unit: string; amount: string};
      pallet: {unit: string; amount: string};
    };
  };
  reservation: {
    type: string;
    id: number;
  };
  type: string;
  inventoryTrackingData: {
    lotCode: string;
    expirationDate: string;
  };
}

// We need to use require() to load Papa because otherwise, TypeScript doesn't allow Papa.LocalChunkSize to be changed.
declare function require(name: string);
/* eslint-disable-next-line */
const Papa = require('papaparse');

// TODO - refactor this entire service to use InternalAPIService methods
class LocationsService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/wh/locations_v2/');
  }

  public async getLocations(
    warehouseId: number,
    pageSize: number,
    continuationToken?: string,
    filterBy?: object
  ): Promise<LocationsResponse> {
    if (filterBy) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      filterBy['lpnCaseInsensitive'] = true;
    }

    const response = await axios.get('/wh/locations_v2/contents', {
      params: {warehouseId, pageSize, continuationToken, includeLocationTotals: true, ...filterBy}
    });
    if (response.status === 200) {
      return get(response, 'data.data');
    } else {
      throw new Error('Could not retrieve locations');
    }
  }

  public async getLocationsV2(
    locationIds: number[],
    reservationId: number,
    continuationToken?: string
  ): Promise<ApiResponse<LocationsV2Response>> {
    return this.makeGetRequest('/api/v2/locations', {locationIds, reservationId, continuationToken});
  }

  public async getCountablePhysicalLocations(
    warehouseId: number,
    locationLabel: string,
    pageSize: number,
    continuationToken?: string
  ): Promise<ApiResponse<SimpleLocationsResponse>> {
    const params = {warehouseId, locationLabel, pageSize, continuationToken};
    return await this.makeGetRequest(`${this.baseUrl}countable_physical_locations`, params);
  }

  public async getLocation(locationId: number, warehouseId: number) {
    const response = await axios.get('/wh/locations_v2/contents', {params: {locationId, warehouseId}});
    if (response.status === 200) {
      return get(response, 'data.data.locations[0]');
    } else {
      throw new Error(`Could not retrieve location with ID: ${locationId}`);
    }
  }

  public async updateLocation(warehouseId: number, locationData) {
    const response = await axios.patch('/wh/locations_v2/update', {
      authenticity_token: this.authenticityToken,
      meta: {correlationId: uuidv4()},
      data: {warehouseId, locationData}
    });
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      throw new Error('Could not update the location');
    }
  }

  public async bulkCreateLocations(warehouseId: number, locationData) {
    const response = await axios.post('/wh/locations_v2/bulk_create', {
      authenticity_token: this.authenticityToken,
      meta: {correlationId: uuidv4()},
      data: {
        uploadUuid: uuidv4(),
        warehouseId,
        locationData
      }
    });
    if (response.status === 201) {
      return get(response, 'data.data');
    } else {
      throw new Error('Locations were not created.');
    }
  }

  public async bulkToggleLocations(
    state: LocationStatus,
    warehouseId: number,
    locationIds: string[]
  ): Promise<ApiResponse<BulkToggleLocationResponse>> {
    const data = {txnState: state, warehouseId, locationIds};
    const response = await this.makePostRequest('/wh/locations_v2/bulk_toggle', data);
    return response;
  }

  public prepareCsvChunkForBulkCreate(csvChunk, csvChunkErrors) {
    // Extract all the invalid row indexes and sort them in descending order
    // subtract 2 from each index since the error rows we produce in CsvValidator
    // assume the Csv header is row 1
    const invalidRowIndexes = uniq(csvChunkErrors.map((error) => error.row - 2)).sort((a: number, b: number) => b - a);

    /* Now we can remove invalid rows before sending to the server. Because sortedInvalidRowIndexes is sorted
     * in descending order, splicing from chunk.data does not change the indexes of each successive row to remove.
     */
    invalidRowIndexes.forEach((invalidRowIdx) => csvChunk.data.splice(invalidRowIdx, 1));

    // Format the location data for sending to the server
    return csvChunk.data.map((row) => {
      const loc = {
        label: row.Name,
        category: row.Category,
        barcodeText: row.Barcode || null,
        length: null,
        width: null,
        height: null,
        palletCapacity: row['Pallet Capacity'],
        sequenceId: row['Sequence ID'],
        primaryPickLocation: row['Primary Pick Location'],
        primarySku: row['Primary Sku'],
        primarySkuPackagingType: row['Primary Sku Packaging Type'],
        primarySkuMaxQuantity: row['Primary Sku Max Quantity'],
        putAwayZone: row['Put Away Zone']
      };
      // Even the CSV now prompts for Length, we need to keep Depth to have minimal impact on Ops
      // as a WH likely has a saved template
      if (row.Depth) {
        loc.length = {
          amount: row.Depth,
          unit: row['Depth Unit']
        };
      }
      if (row.Length) {
        loc.length = {
          amount: row.Length,
          unit: row['Length Unit']
        };
      }
      if (row.Width) {
        loc.width = {
          amount: row.Width,
          unit: row['Width Unit']
        };
      }
      if (row.Height) {
        loc.height = {
          amount: row.Height,
          unit: row['Height Unit']
        };
      }
      return loc;
    });
  }

  public createInvalidRowsCsv(totalCsvErrors: ParseError[]) {
    if (totalCsvErrors.length) {
      // Create a csv with just the invalid rows
      const uniqueInvalidRows = unionBy(totalCsvErrors, (csvError) => csvError.row).map((csvError) => {
        return {
          ...csvError.rowData,
          // Concatenate all the errors for a row so it shows up as a new column in the csv file
          Errors: totalCsvErrors
            .filter((parseError) => parseError.row === csvError.row)
            .reduce((prevStr, currentParseError) => {
              return prevStr + ' ' + currentParseError.message;
            }, '')
        };
      });
      return Papa.unparse(uniqueInvalidRows);
    }
  }

  public async getLocationMovementLog(
    warehouseId: number | string,
    request: GetMovementLogRequest
  ): Promise<ApiResponse<GetMovementLogResponse>> {
    return await this.makeGetRequest('/api/v2/movement_logs', {warehouseId, ...request});
  }

  public processErrors = (errors: ResponseError[]) => {
    return errors.map((error) => error.detail || error.title);
  };
}

export default LocationsService;
