import {OrderLine, OrderType, OutboundOrder} from '../OutboundOrdersInterfaces';
import {lineHasLotInfoSpecified} from './OrderLines';

function concatOptionalArrays(array1?: string[], array2?: string[]): string[] {
  if (array1 && array2) {
    return array1.concat(array2);
  }
  if (array1) {
    return array1;
  }
  if (array2) {
    return array2;
  }

  return [];
}

function idListsOverlap(array1: string[], array2: string[]): boolean {
  return array2.some((e) => array1.includes(e));
}

export function shouldShowLotInfo(
  order: OutboundOrder,
  lines: OrderLine[],
  distributionByLotReservationIds: string[]
): boolean {
  // If lot info was specified, show it no matter what
  if (lines.some((line) => lineHasLotInfoSpecified(line))) {
    return true;
  }

  return lines.some((line) => {
    // Since a requested reservation should be in the planned reservation,
    //  just combine the lists to ensure we get all relevant reservations
    const specifiedReservationIds = concatOptionalArrays(line.plannedReservationIds, line.requestedReservationIds);

    // If reservations are specified, only consider those reservations
    if (specifiedReservationIds.length > 0) {
      return idListsOverlap(specifiedReservationIds, distributionByLotReservationIds);
    }

    // Otherwise, who knows what will be selected so show it if dist by lot is on anywhere
    return distributionByLotReservationIds.length > 0;
  });
}
