import * as React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import Select from 'react-select';
import {Reservation} from '../../../shared/CommonInterfaces';

export interface ReservationOption {
  value: number;
  label: string;
}

interface ReservationSelectProps {
  reservations: Reservation[];
  savedReservationId?: number;
  onReservationSelect: (reservation: ReservationOption | null) => void;
}

const ReservationSelect: React.FC<ReservationSelectProps> = ({
  reservations,
  savedReservationId,
  onReservationSelect
}) => {
  const {errors, control, setValue} = useFormContext();

  const formatReservationsForSelect = () => {
    return reservations.map((reservation) => {
      return {value: reservation.id, label: reservation.name};
    });
  };

  const handleReservationChange = (selectedOption: ReservationOption | null) => {
    setValue('reservation', selectedOption);
    onReservationSelect(selectedOption);
  };

  const defaultReservation = React.useMemo(() => {
    if (savedReservationId && reservations) {
      const reservation = reservations.find((res) => res.id === savedReservationId);
      return reservation ? {value: reservation.id, label: reservation.name} : null;
    }
    return null;
  }, [savedReservationId, reservations]);

  React.useEffect(() => {
    if (defaultReservation) {
      onReservationSelect(defaultReservation);
    }
  }, [defaultReservation]);

  return (
    <>
      <div className="form-field">
        <div data-testid="reservation-field">
          <Controller
            name="reservation"
            control={control}
            rules={{required: 'Reservation is required'}}
            defaultValue={defaultReservation}
            render={({value}) => (
              <>
                <label>
                  Reservation
                  <br />
                  <span className="input-hint">Set the reservation for this template</span>
                </label>
                <Select
                  id="reservation"
                  name="reservation"
                  className="wave-template-select"
                  placeholder="Select a reservation"
                  options={formatReservationsForSelect()}
                  onChange={handleReservationChange}
                  value={value}
                />
              </>
            )}
          />
        </div>
      </div>
      {errors.reservation && <span className="required-input validation-msg">{errors.reservation.message}</span>}
    </>
  );
};

export default ReservationSelect;
