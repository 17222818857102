import * as React from 'react';
import {useFormContext} from 'react-hook-form';

export interface CustomAttribute {
  name: string;
  value: string;
}

interface Props {
  savedValue: CustomAttribute;
}

const CustomAttributesInput: React.FC<Props> = ({savedValue}) => {
  const {errors, getValues, register} = useFormContext();

  return (
    <React.Fragment>
      <div className="form-field custom-attributes">
        <label>
          Custom Client Attribute
          <br />
          <span className="input-hint">(optional) Custom attributes are passed through by the client</span>
        </label>

        <div className="custom-attributes-text">
          <input
            data-testid="custom-attributes-name"
            className="wave-template-input text-input"
            placeholder="Name"
            defaultValue={savedValue?.name}
            name="customAttributeKey"
            ref={register({
              validate: {
                bothCustomAttrsAreSet: (custKey) => {
                  const custValue = getValues('customAttributeValue');
                  if ((custKey && !custValue) || (!custKey && custValue)) {
                    return 'Both custom attribute inputs must be provided';
                  }
                }
              }
            })}
          />
          <input
            data-testid="custom-attributes-value"
            className="wave-template-input text-input"
            placeholder="Value"
            defaultValue={savedValue?.value}
            name="customAttributeValue"
            ref={register}
          />
        </div>
      </div>
      {errors.customAttributeKey && (
        <span className="required-input validation-msg">{errors.customAttributeKey.message}</span>
      )}
    </React.Fragment>
  );
};

export default CustomAttributesInput;
