import * as React from 'react';
import {FC, useState, useEffect, useMemo, useRef} from 'react';
import {Box, Tab, Tabs} from '@mui/material';
import OmniReportingService from '../shared/services/OmniReportingService';
import {OmniReportRecord} from './OmniReportRecord';

interface Props {
  authenticityToken: string;
  title: string;
  omniReports: OmniReportRecord[];
}

const OmniReportPage: FC<Props> = (props) => {
  const {authenticityToken, title, omniReports} = props;

  const omniReportingService = useMemo(() => new OmniReportingService(authenticityToken), [authenticityToken]);
  const [iFrameSrc, setIFrameSrc] = useState<string>(null);
  const [errors, setErrors] = useState<string[]>(null);
  const [tabValue, setTabValue] = React.useState(0);
  const errorsRef = useRef(null);

  interface TabPanelProps {
    children?: React.ReactNode;
  }

  useEffect(() => {
    getReport();
  }, [authenticityToken, tabValue]);

  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/no-shadow
  function CustomTabPanel(props: TabPanelProps) {
    const {children, ...other} = props;

    return (
      <div role="tabpanel" style={{display: 'block'}} id={`simple-tabpanel`} aria-labelledby={`simple-tab`} {...other}>
        {<Box>{children}</Box>}
      </div>
    );
  }

  async function getReportUrl(omniReportRecord: OmniReportRecord) {
    setIFrameSrc(null);
    try {
      const response = await omniReportingService.getDashboard(omniReportRecord.dashboardId);

      if (response.data?.url) {
        setErrors(null);
        setIFrameSrc(response.data.url);
      }

      if (response.errors && response.errors.length > 0) {
        response.errors.map((e) => {
          setErrors([e.detail.toString()].concat(errors || []));
        });
      }
    } catch (e) {
      handleErrorCatch(e);
    }
  }

  const handleErrorCatch = (e) => {
    if (typeof e === 'string') {
      setErrors([e].concat(errors || []));
    } else if (e instanceof Error) {
      setErrors([e.message].concat(errors || []));
    }
  };

  const getReport = async () => {
    try {
      await getReportUrl(omniReports[tabValue]);
    } catch (e) {
      handleErrorCatch(e);
    }
  };

  const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      'id': `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const tabs = omniReports.map((value, index) => {
    return <Tab sx={{fontSize: 14}} label={value.displayName} {...a11yProps(index)} />;
  });

  return (
    <div id="omni-reports-page">
      <h1 style={{padding: '10px', marginBottom: '0px'}}>{title}</h1>
      <Box sx={{width: '100%'}}>
        <Box>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            {tabs}
          </Tabs>
        </Box>
        <CustomTabPanel>
          {errors && errors.length > 0 && (
            <div className="alert alert-danger popup-error" role="alert" ref={errorsRef}>
              <ul>
                {errors.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            </div>
          )}
          {!errors && iFrameSrc && (
            <iframe
              src={iFrameSrc}
              width={'100%'}
              style={{height: '81vh', position: 'sticky', top: 0, overflowY: 'scroll'}}
            />
          )}
        </CustomTabPanel>
      </Box>
    </div>
  );
};
export default OmniReportPage;
