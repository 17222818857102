import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ContainersService from '../shared/services/ContainersService';
import InventoryService from '../shared/services/InventoryService';
import FlexeContext, {FlexeContextValues} from '../contexts/FlexeContext';
import DropoffContext, {DropoffContextValues} from '../contexts/DropoffContext';
import DocumentsService from '../shared/services/DocumentsService';
import LpnService from '../shared/services/LpnService';
import {CompanyType} from '../shared/CommonInterfaces';
import WarehouseService from '../shared/services/WarehouseService';
import FulfillmentInbound from './fulfillment/FulfillmentInbound';
import LpnDropoff from './lpns/LpnDropoff';

interface Props {
  authenticityToken: string;
  iconContainerImgPath: string;
  receivingListPath: string;
  shipmentInboundImgPath: string;
  enableInboundPackaging: boolean;
  enableLpn: boolean;
  lpnReceiveOnly: boolean;
  containerDeliveryId: number;
  containerDeliveryStartTime: number;
  containerDeliveryEndTime: number;
  warehouseId: number;
  showEditLpnForAsn: boolean;
  allowLpnTransferInAllInboundStates: boolean;
}

function Dropoffs(props: Props) {
  const containersService = new ContainersService(props.authenticityToken);
  const lpnService = new LpnService(props.authenticityToken);
  const inventoryService = new InventoryService(props.authenticityToken);
  const documentsService = new DocumentsService(CompanyType.warehouse);
  const warehouseService = new WarehouseService(props.authenticityToken);

  const flexeContextValue: FlexeContextValues = {
    authenticityToken: props.authenticityToken
  };

  const dropoffContextValues: DropoffContextValues = {
    containerDeliveryId: props.containerDeliveryId,
    enableInboundPackaging: props.enableInboundPackaging,
    enableLpn: props.enableLpn,
    lpnReceiveOnly: props.lpnReceiveOnly,
    containersService,
    lpnService,
    inventoryService,
    documentsService,
    warehouseService,
    receivingListPath: props.receivingListPath,
    containerDeliveryStartTime: props.containerDeliveryStartTime,
    containerDeliveryEndTime: props.containerDeliveryEndTime,
    warehouseId: props.warehouseId,
    showEditLpnForAsn: props.showEditLpnForAsn,
    allowLpnTransferInAllInboundStates: props.allowLpnTransferInAllInboundStates
  };

  return (
    <FlexeContext.Provider value={flexeContextValue}>
      <DropoffContext.Provider value={dropoffContextValues}>
        <Router>
          <Switch>
            <Route
              path="/wh/dropoffs/container/:id"
              render={() => {
                if (props.lpnReceiveOnly || props.enableLpn) {
                  return <LpnDropoff />;
                } else {
                  return <FulfillmentInbound />;
                }
              }}
            />
          </Switch>
        </Router>
      </DropoffContext.Provider>
    </FlexeContext.Provider>
  );
}

export default Dropoffs;
