import {ResponseErrorV2} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

export interface RoutePlanningApiV2Response<T> {
  data: T | null;
  errors: ResponseErrorV2[] | null;
}

export interface StateDetail {
  id: number;
  routingRequestId: string;
  reservationId: number;
  state: RouteWaveState;
  createdAt: string;
  updatedAt: string;
  lpnIds: number[];
  locationIds: number[];
}

export interface StateSummary {
  id: number;
  routingRequestId: string;
  reservationId: number;
  state: RouteWaveState;
  createdAt: string;
  updatedAt: string;
  numberOfLpns: number | null;
}

export interface FetchRouteWaveSummariesResponse {
  results: StateSummary[];
  continuationToken: string;
}

export enum RouteWaveState {
  CREATED = 'created',
  REQUESTED = 'requested',
  COMPLETE = 'complete'
}

export interface GetRouteWaveSummariesRequest {
  routePlanningRequestIds?: string[];
  reservationId?: number;
  states?: RouteWaveState[];
  createdBefore?: string;
  createdAfter?: string;
  continuationToken?: string;
  pageSize: number;
}

export interface RoutableLocationsGetResponse {
  locations: RoutableLocation[];
}

export interface RoutableLocation {
  locationName: string;
  locationId: number;
  lpnCount: number;
}

export interface RouteDetails {
  routeId: string;
  lpnCount: number;
  sortedCount: number;
  manifestedCount: number;
}

export interface CreateRoutePlanningRequest {
  routingRequestId: string;
  reservationId: number;
  locationIds: number[];
}

export interface FetchRouteProgressRequest {
  routingRequestId: string;
}

export interface FetchRouteProgressResponse {
  progressSummaries: RouteDetails[];
}

class RoutePlanningService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/v2/route-planning');
  }

  public async createRouteWave(request: CreateRoutePlanningRequest): Promise<RoutePlanningApiV2Response<StateDetail>> {
    return await this.makePostRequest(`${this.baseUrl}/state/create`, request);
  }

  public async fetchRoutableLocations(
    warehouseId: number
  ): Promise<RoutePlanningApiV2Response<RoutableLocationsGetResponse>> {
    return await this.makeGetRequest(`${this.baseUrl}/ui/locations?warehouse-id=${warehouseId}`);
  }

  public async fetchRouteWavesSummary(
    request: GetRouteWaveSummariesRequest
  ): Promise<RoutePlanningApiV2Response<FetchRouteWaveSummariesResponse>> {
    return this.makePostRequest(`${this.baseUrl}/state/summaries`, request);
  }

  public async fetchRouteRequestSortProgress(
    request: FetchRouteProgressRequest
  ): Promise<RoutePlanningApiV2Response<FetchRouteProgressResponse>> {
    return this.makePostRequest(`${this.baseUrl}/progress/fetch`, request);
  }
}

export default RoutePlanningService;
