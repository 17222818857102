import {CsvColumns, CsvFieldType} from '../../../libs/CsvValidator';
import {LocationCategory} from '../../locations/LocationsInterfaces';
import {LengthUnit, Packaging} from '../CommonInterfaces';

export enum CsvErrorType {
  InvalidDateTime = 'Date or Time not parsable',
  DateInPast = 'Move Date cannot be in the past',
  Required = 'Value is required'
}

export interface ValidationErrors {
  [Key: string]: CsvErrorType;
}

/* eslint-disable */
export const locations_bulk_upload_columns_spec: CsvColumns = {
  'Name': {
    required: true,
    type: CsvFieldType.string
  },
  'Category': {
    required: true,
    type: CsvFieldType.enum,
    enum: LocationCategory
  },
  'Barcode': {
    required: false,
    type: CsvFieldType.string
  },
  // Even the CSV now prompts for Length, we need to keep Depth & Depth Unit to have minimal impact on Ops
  // as a WH likely has a saved template
  'Depth': {
    required: false,
    type: CsvFieldType.number,
    requiredIfPresent: 'Depth Unit'
  },
  'Length': {
    required: false,
    type: CsvFieldType.number,
    requiredIfPresent: 'Length Unit'
  },
  'Depth Unit': {
    required: false,
    type: CsvFieldType.enum,
    enum: LengthUnit,
    requiredIfPresent: 'Depth'
  },
  'Length Unit': {
    required: false,
    type: CsvFieldType.enum,
    enum: LengthUnit,
    requiredIfPresent: 'Length'
  },
  'Width': {
    required: false,
    type: CsvFieldType.number,
    requiredIfPresent: 'Width Unit'
  },
  'Width Unit': {
    required: false,
    type: CsvFieldType.enum,
    enum: LengthUnit,
    requiredIfPresent: 'Width'
  },
  'Height': {
    required: false,
    type: CsvFieldType.number,
    requiredIfPresent: 'Height Unit'
  },
  'Height Unit': {
    required: false,
    type: CsvFieldType.enum,
    enum: LengthUnit,
    requiredIfPresent: 'Height'
  },
  'Sequence ID': {
    required: false,
    type: CsvFieldType.integer
  },
  'Pallet Capacity': {
    required: false,
    type: CsvFieldType.integer
  },
  'Primary Pick Location': {
    required: false,
    type: CsvFieldType.boolean
  },
  'Primary Sku': {
    required: false,
    type: CsvFieldType.string
  },
  'Primary Sku Packaging Type': {
    required: false,
    type: CsvFieldType.string
  },
  'Primary Sku Max Quantity': {
    required: false,
    type: CsvFieldType.integer
  }
};
/* eslint-enable */
