import * as React from 'react';
import {FunctionComponent} from 'react';
import {Icon} from '@flexe/ui-components';
import DoWhenClickOutside from './DoWhenClickOutside';

export interface SelectOption {
  name: string;
  value: string;
  count?: number;
}

interface Props {
  options: SelectOption[];
  selected: string[];
  title?: string;
  prefixText?: string;
  keepOptionsOpen?: boolean;
  onSelect(option: string);
  isLoading?: boolean;
}

const SelectorWithCheckbox: FunctionComponent<Props> = (props) => {
  const [showOptions, setShowOptions] = React.useState<boolean>(false);

  const handleOptionSelect = (value) => {
    if (!props.keepOptionsOpen) {
      setShowOptions(false);
    }
    props.onSelect(value);
  };

  const getDropDownDisplay = () => {
    let displayString = props.title;

    if (props.selected && props.options.length > 0) {
      const selectedOptions = props.options.filter((option) => props.selected.includes(option.value));
      let selectedOptionsNames = selectedOptions.map((option) => `${option.name}`).join(', ');

      if (props.selected.length > 1 && selectedOptionsNames.length > 12) {
        selectedOptionsNames = '...' + selectedOptionsNames.substring(selectedOptionsNames.length - 12);
      }

      if (selectedOptionsNames) {
        displayString = `${props.prefixText ? props.prefixText : ''}${selectedOptionsNames}`;
      }
    }
    return displayString;
  };

  return (
    <div className="selector-with-checkbox">
      <DoWhenClickOutside callback={(_) => setShowOptions(false)}>
        <button
          className={`btn btn-default dropdown-toggle${showOptions ? ' focus' : ''}`}
          type="button"
          id="dropdown"
          data-toggle="dropdown"
          onClick={() => setShowOptions(!showOptions)}
          aria-haspopup="true"
          aria-expanded="false"
          disabled={props.isLoading}
        >
          {props.isLoading ? (
            <Icon icon="spinner" size="lg" animation="spin" label="Fetching data" />
          ) : (
            getDropDownDisplay()
          )}
          <span className="caret"></span>
        </button>
        {showOptions && (
          <ul data-testid="menu" className="dropdown-menu show">
            {props.options.map((option) => {
              return (
                <li key={`dropdown-value-${option.value}`}>
                  <a data-testid={`${option.value}-anchor`} onClick={() => handleOptionSelect(option.value)}>
                    <input
                      type="checkbox"
                      key={`dropdown-checkbox-${option.value}`}
                      className="dropdown-checkbox"
                      checked={props.selected.includes(option.value)}
                      readOnly
                    />
                    {option.name}
                    {option.count !== null && option.count !== undefined && (
                      <span className="count">{option.count}</span>
                    )}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </DoWhenClickOutside>
    </div>
  );
};

export default SelectorWithCheckbox;
