import * as React from 'react';
import {RouteDetails} from '../shared/services/RoutePlanningService';
import RouteCard from './RouteCard';

interface RouteListProps {
  routeDetailsList: RouteDetails[];
}

const RouteList: React.FC<RouteListProps> = ({routeDetailsList}) => {
  return (
    <div id="route-card-list" data-testid="route-card-list">
      {routeDetailsList
        .filter((route) => {
          return route != null;
        })
        .sort((routeOne, routeTwo) => {
          // Sort the list so all FULLY loaded routes are at the bottom
          // For each segment (in progress or loaded), sort the list by the route id.
          if (routeOne.manifestedCount === routeOne.lpnCount) {
            if (routeTwo.manifestedCount === routeTwo.lpnCount) {
              return routeOne.routeId.localeCompare(routeTwo.routeId);
            } else {
              return 1;
            }
          } else if (routeTwo.manifestedCount === routeTwo.lpnCount) {
            return -1;
          } else {
            return routeOne.routeId.localeCompare(routeTwo.routeId);
          }
        })
        .map((routeDetails, index) => (
          <RouteCard key={index} routeDetails={routeDetails} />
        ))}
    </div>
  );
};

export default RouteList;
