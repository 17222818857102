import * as React from 'react';
import {Reservation, Warehouse} from '../../shared/CommonInterfaces';
import BatchWavingService from '../../shared/services/BatchWavingService';
import PickConsolidationService from '../../shared/services/PickConsolidationService';
import MheService, {Mhe} from '../../shared/services/MheService';
import {FilterParams} from './WaveBatchInterfaces';

export interface TWaveContext {
  authenticityToken: string;
  batchWavingService: BatchWavingService;
  pickConsolidationService: PickConsolidationService;
  mheService: MheService;
  warehouse: Warehouse;
  reservationIds: number[];
  filterParams: FilterParams;
  carriers: object;
  reservations: Reservation[];
  refreshing: boolean;
  setRefreshing: React.Dispatch<React.SetStateAction<boolean>>;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
}

export const WaveContext = React.createContext<TWaveContext>({
  authenticityToken: '',
  warehouse: null,
  batchWavingService: null,
  pickConsolidationService: null,
  mheService: null,
  reservationIds: null,
  filterParams: null,
  carriers: {},
  reservations: [],
  refreshing: false,
  setRefreshing: null,
  setErrors: null
});
