import InternalAPIService from '../shared/services/InternalAPIService';
import {ApiResponse} from '../shared/CommonInterfaces';
import {LocationMovementHaltResponse, LocationMovementsResponse} from './MovementInterfaces';

class MovementsService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/');
  }

  public async getMovements(
    warehouseId: number,
    statuses: string[],
    pageSize?: number,
    continuationToken?: string
  ): Promise<ApiResponse<LocationMovementsResponse>> {
    try {
      const response = await this.makeGetRequest('/api/v2/location_movements', {
        warehouseId,
        status: statuses,
        pageSize,
        continuationToken
      });
      return response as ApiResponse<LocationMovementsResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async haltMovements(warehouseId: number, locationMovementIds: string[], taskIds: number[]) {
    try {
      const response = await this.makePatchRequest('/api/v2/location_movements/halt_movements', {
        warehouseId,
        locationMovementIds,
        taskIds
      });
      return response as ApiResponse<LocationMovementHaltResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }
}

export default MovementsService;
