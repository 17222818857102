import * as React from 'react';
import {renderLocationLink} from '../../libs/helpers';
import {RoutableLocation} from '../shared/services/RoutePlanningService';

interface CreateRouteSummaryProps {
  routableLocations: RoutableLocation[];
}

const CreateRouteSummary: React.FC<CreateRouteSummaryProps> = ({routableLocations}) => {
  const lpnCount = routableLocations.reduce((sum, location) => sum + location.lpnCount, 0);

  return (
    <div id="create-route-summary">
      <p>
        This will assign all the received units at selected Locations with routing information. Routing information will
        appear on labels and on the mobile devices as a Sort Code.
      </p>
      <p>
        You've selected {lpnCount.toLocaleString()} {lpnCount === 1 ? 'unit' : 'units'} from {routableLocations.length}{' '}
        {routableLocations.length === 1 ? 'location' : 'locations'}. This may take a few minutes while data is sent to
        calculate the routes and those routes are applied.{' '}
        <strong className="danger">Once routing is started it can't be undone</strong>. Units with issues will have to
        be rerouted.
      </p>
      <div>
        <div className="location-column">
          <div className="selected-location-label">Selected Location(s):</div>
        </div>
        <div className="location-column">
          {routableLocations.map((location) => (
            <div className="location-row" key={location.locationId}>
              <div className="column">{renderLocationLink(location.locationId, location.locationName)}</div>
              <hr className="separator" />
              <div className="column">
                {location.lpnCount.toLocaleString()} {location.lpnCount === 1 ? 'unit' : 'units'}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreateRouteSummary;
