import * as React from 'react';
import {FC, useState, useMemo} from 'react';
import {ErrorBoundaryContext} from './ErrorBoundaryContext';
interface ErrorBoundaryProps {
  parentSetError?: (error: string) => void;
  fallbackComponent?: React.ReactElement;
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = (props) => {
  const {fallbackComponent, parentSetError} = props;

  const [hasError, setHasError] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      setErrors: (error: Error) => {
        setHasError(true);
        if (parentSetError) {
          parentSetError(error.message);
        }
      }
    }),
    [parentSetError]
  );

  return (
    <>
      {hasError && fallbackComponent}
      {hasError && !fallbackComponent && <></>}
      {!hasError && (
        <ErrorBoundaryContext.Provider value={contextValue}>{props.children}</ErrorBoundaryContext.Provider>
      )}
    </>
  );
};

export default ErrorBoundary;
