import {get} from 'lodash';
import {Inventory, Packaging} from '../CommonInterfaces';
import {Item} from '../services/ItemMasterService';
import {CycleCountItem} from './CycleCountInterfaces';

export function isPlaceholderItem(item: CycleCountItem): boolean {
  return item.id === null && (item.location.id === undefined || item.inventory.id === undefined);
}

export interface CycleCountReasonCode {
  name: string;
  value: string;
}

export function isMissingLpn(countedEaches, expectedEaches, countedCartons, expectedCartons): boolean {
  return !countedEaches && !countedCartons && (expectedEaches > 0 || expectedCartons > 0);
}

export function isUnexpectedLpn(countedEaches, expectedEaches, countedCartons, expectedCartons): boolean {
  return (countedEaches > 0 || countedCartons > 0) && !expectedEaches && !expectedCartons;
}

export function conversion(item: CycleCountItem): number {
  return get(item, 'inventory.unitConversions.carton.each', 0);
}

export function calculateDeltaForCountedItem(item: CycleCountItem) {
  const counted = item.countedQuantities || {};
  const expected = item.expectedQuantities || {};
  const countedCartons = counted[Packaging.carton] || 0;
  const countedEaches = counted[Packaging.each] || 0;
  const expectedCartons = expected[Packaging.carton] || 0;
  const expectedEaches = expected[Packaging.each] || 0;

  const totalCountedEaches = countedEaches + countedCartons * conversion(item);
  const totalExpectedEaches = expectedEaches + expectedCartons * conversion(item);
  const showDelta = counted[Packaging.carton] === undefined && counted[Packaging.each] === undefined ? 0 : 1;
  const delta = totalCountedEaches - totalExpectedEaches;

  return [showDelta, totalCountedEaches, totalExpectedEaches, delta];
}
