import {Controller, FormProvider, useForm} from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import * as React from 'react';
import {LegacyModal} from '@flexe/ui-components';
import WaveTemplateService from '../../../shared/services/WaveTemplateService';
import {Reservation, Warehouse} from '../../../shared/CommonInterfaces';
import BatchWavingService from '../../../shared/services/BatchWavingService';
import WarehouseService from '../../../shared/services/WarehouseService';
import FlexeButton from '../../../shared/FlexeButton';
import {OrderType, SkuVariety} from '../../ecommerce-batches/BatchInterfaces';
import FlexeLinkButton from '../../../shared/FlexeLinkButton';
import CustomAttributesInput from './CustomAttributesInput';

/* eslint-disable dot-notation,@typescript-eslint/dot-notation */

interface Props {
  waveTemplateService: WaveTemplateService;
  batchWavingService: BatchWavingService;
  warehouseService: WarehouseService;
  selectedWarehouse: Warehouse;
  reservationToSortationEnabled: object;
  carrierLabels: object;
}

// list input names here for react-hook-form
interface Inputs {
  templateName: string;
  reservation: {value: number};
  carrier: string;
  serviceLevel: string[];
  shipmentType: string;
  skuVariety: string;
  purchaseOrder: string;
  packaging: string;
  skuSelect: SelectOption[];
  postalCodeRangeStart: string;
  postalCodeRangeEnd: string;
  customAttributeKey: string;
  customAttributeValue: string;
}

interface SelectOption {
  label: string;
  value: string;
}

interface PageErrorObj {
  createError: string;
}

const NewWaveTemplateForm: React.FC<Props> = (props) => {
  // used to prevent state updates on unmounted components
  const isMountedRef = React.useRef(null);

  // handle form functionality, state, and validation
  const {
    clearErrors,
    control,
    errors,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    trigger,
    unregister,
    watch
  } = useForm<Inputs>();

  const [reservations, setReservations] = React.useState<Reservation[]>([]);
  const [serviceLevelMap, setServiceLevelMap] = React.useState<object>({});
  const [advice, setAdvice] = React.useState<string>('Note: this reservation is set to sortation only');
  const [showAdvice, setShowAdvice] = React.useState(false);
  const [resSelected, setResSelected] = React.useState(false);
  const [nameProvided, setNameProvided] = React.useState(false);
  const [skuVarietyChosen, setSkuVarietyChosen] = React.useState(false);
  const [carrierSelected, setCarrierSelected] = React.useState(false);
  const [pageErrors, setPageErrors] = React.useState<PageErrorObj>(null);
  const [loading, setLoading] = React.useState(false);
  const [succeeded, setSucceeded] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);

  const toggleBooleanState = (conditional, hookToUse, thingToWatch) => {
    React.useEffect(() => {
      isMountedRef.current = true;
      if (conditional) {
        if (isMountedRef.current) {
          hookToUse(true);
        }
      } else {
        if (isMountedRef.current) {
          hookToUse(false);
        }
      }
      return () => {
        isMountedRef.current = false;
      };
    }, thingToWatch);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    loadForWarehouse();
    return () => {
      isMountedRef.current = false;
    };
  }, [props.selectedWarehouse.id]);

  // show fieldset two if name is provided AND reservation is selected
  toggleBooleanState(watch('templateName'), setNameProvided, [watch('templateName')]);
  toggleBooleanState(watch('reservation'), setResSelected, [watch('reservation')]);
  // enable service level select if carrier is selected
  toggleBooleanState(!(watch('carrier') === undefined), setCarrierSelected, [watch('carrier')]);

  const loadForWarehouse = async () => {
    const allErrors = await Promise.all([loadReservations(), loadShippingInfo()]);
    const loadErrors = [].concat(...allErrors);
    handleLoadErrors(loadErrors);
  };

  const handleLoadErrors = (responseErrors) => {
    setPageErrors(responseErrors.map((e) => e.detail));
  };

  const loadReservations = async () => {
    const response = await props.warehouseService.getReservationsForWarehouse(props.selectedWarehouse.id);
    if (!responseHasErrors(response) && isMountedRef.current) {
      setReservations(response.data.reservations);
    }
    return response.errors;
  };

  const loadShippingInfo = async () => {
    const response = await props.batchWavingService.getAllCarriersAndServiceTypes();
    if (!responseHasErrors(response) && isMountedRef.current) {
      setServiceLevelMap(response.data.mappedShippingInfo);
    }
    return response.errors;
  };
  const responseHasErrors = (response) => {
    return response && response.errors && response.errors.length;
  };

  const formatReservationsForSelect = (availableReservations) => {
    const formattedReservations: SelectOption[] = [];
    // eslint-disable-next-line
    for (const i in availableReservations) {
      formattedReservations.push({value: availableReservations[i].id, label: availableReservations[i].name});
    }
    return formattedReservations;
  };

  const formatCarriersForSelect = (carrierLabels) => {
    const formattedCarrierLabels: SelectOption[] = [];
    for (const i in carrierLabels) {
      // eslint-disable-next-line no-prototype-builtins
      if (carrierLabels.hasOwnProperty(i)) {
        formattedCarrierLabels.push({value: i, label: carrierLabels[i]});
      }
    }
    return formattedCarrierLabels;
  };

  const formatServiceLevelsForSelect = (carrier, serviceLevels) => {
    const formattedServiceLevels: SelectOption[] = [];
    if (carrier) {
      // eslint-disable-next-line
      for (const l in serviceLevels[carrier.value]) {
        formattedServiceLevels.push({value: serviceLevels[carrier.value][l], label: serviceLevels[carrier.value][l]});
      }
    }
    return formattedServiceLevels;
  };

  const skuPromiseOptions = async (query) => {
    if (query.length > 1) {
      try {
        const response = await props.batchWavingService.skuTypeAhead(watch('reservation').value, query);
        const formattedResult = [];
        if (response && response.data && response.data.skus) {
          response.data.skus.forEach((sku) => {
            formattedResult.push({value: sku.replace('&amp;', '&'), label: sku.replace('&amp;', '&')});
          });
        }
        return formattedResult;
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
        //TODO handle error properly
      }
    }
  };

  // these explicit calls to set state are required for testing
  // without them, Jest state will not change when a reservation or order profile is chosen
  const triggerReservationState = (e) => {
    setResSelected(true);
    const selectedReservationId = e.value;
    // if reservation is sortation enabled, display a note to that effect
    const sortationEnabled = props.reservationToSortationEnabled[selectedReservationId] === true;
    if (sortationEnabled) {
      setAdvice('Note: this reservation is set to sortation only');
    }
    setShowAdvice(sortationEnabled);
    // return value required for onChange of controlled component
    return e;
  };
  const triggerCarrierState = (e) => {
    setCarrierSelected(true);
    setValue('serviceLevel', []);
    // return value required for onChange of controlled component
    return e;
  };
  const triggerShipmentTypeState = (e) => {
    watch('shipmentType', e.target.value);
  };
  const triggerSkuVarietyState = (e) => {
    setSkuVarietyChosen(true);
    watch('skuVariety', e.target.value);
  };

  const shipmentTypeSkuVarietyHint = () => {
    if (watch('shipmentType') === 'single' && watch('skuVariety') === 'multi') {
      return (
        <div>
          <p className="selection-hint" data-testid="hint-text">
            *Using this selection with 'Single-unit Only' won’t produce any results for this template.
          </p>
        </div>
      );
    }
  };

  const renderValidation = (inputName, fieldErrors, state) => {
    return (
      <React.Fragment>
        {!state && (
          <p className="required-input">
            {fieldErrors && <span className="validation-msg">{inputName} is </span>}required
          </p>
        )}
        {state && (
          <p className="required-input valid">
            <i className="fas fa-check"></i> required
          </p>
        )}
      </React.Fragment>
    );
  };

  const onSubmit = (data) => {
    setLoading(true);
    setPageErrors(null);
    const newTemplate = {};
    const wave = {};
    const skuArr: string[] = [];
    newTemplate['templateName'] = data.templateName;
    wave['reservationId'] = data.reservation.value;
    if (data.skuSelect) {
      // eslint-disable-next-line
      for (let i = 0; i < data.skuSelect.length; i++) {
        skuArr.push(data.skuSelect[i].value);
      }
    }
    wave['skus'] = skuArr;
    if (data.purchaseOrder) {
      wave['purchaseOrder'] = data.purchaseOrder;
    } else {
      wave['purchaseOrder'] = '';
    }
    wave['carriers'] = [data.carrier.value];
    if (data.serviceLevel && data.serviceLevel.length) {
      wave['serviceTypes'] = [];
      for (let i = 0; i < data.serviceLevel.length; i++) {
        wave['serviceTypes'][i] = data.serviceLevel[i].label;
      }
    } else {
      wave['serviceTypes'] = [];
    }

    if (data.shipmentType === 'single') {
      wave['shipmentType'] = OrderType.SingleItem;
    } else if (data.shipmentType === 'multi') {
      wave['shipmentType'] = OrderType.MultiItem;
    } else {
      wave['shipmentType'] = OrderType.SingleOrMulti;
    }
    if (data.skuVariety === 'single') {
      wave['skuVariety'] = SkuVariety.SingleSku;
    } else if (data.skuVariety === 'multi') {
      wave['skuVariety'] = SkuVariety.MultiSku;
    } else {
      wave['skuVariety'] = SkuVariety.SingleOrMultiSku;
    }
    if (data.packaging === 'ship-alone') {
      wave['shipmentType'] = OrderType.ShipAlone;
    }
    if (data.packaging === 'overbox') {
      wave['shipAsIs'] = false;
    }
    if (data.packaging === 'ship-as-is') {
      wave['shipAsIs'] = true;
    }
    if (data.packaging === 'hazmat') {
      wave['includesHazmat'] = true;
    }
    if (data.packaging === 'site-to-store') {
      wave['siteToStoreOnly'] = true;
    }

    let customAttrFormValid = true;
    if (data.customAttributeKey || data.customAttributeValue) {
      const nameSet = data.customAttributeKey?.length > 0;
      const valueSet = data.customAttributeValue?.length > 0;
      if ((nameSet && !valueSet) || (!nameSet && valueSet)) {
        customAttrFormValid = false;
        handleError('Both custom attribute inputs must be provided');
      } else {
        const attributeMap = {};
        attributeMap[data.customAttributeKey] = data.customAttributeValue;
        wave['customAttributes'] = attributeMap;
      }
    }

    let postalCodeRangeFormValid = true;
    if (
      (data.postalCodeRangeStart && !data.postalCodeRangeEnd) ||
      (!data.postalCodeRangeStart && data.postalCodeRangeEnd)
    ) {
      postalCodeRangeFormValid = false;
      handleError('Both start and end range must be provided');
    } else {
      if (data.postalCodeRangeStart && data.postalCodeRangeEnd) {
        wave['postalCodeRange'] = {start: data.postalCodeRangeStart, end: data.postalCodeRangeEnd};
      }
    }

    if (customAttrFormValid && postalCodeRangeFormValid) {
      newTemplate['wave'] = wave;
      templateCreate(newTemplate);
    }
  };

  const templateCreate = async (values) => {
    const response = await props.waveTemplateService.createWaveTemplate(values.templateName, values.wave);
    if (response && response.errors && response.errors.length === 0) {
      handleCreationSuccess();
    } else {
      handleCreateError(response);
    }
  };

  const handleCreationSuccess = () => {
    setSucceeded(true);
    setLoading(false);
    if (!loading && !pageErrors.createError) {
      window.location.href = '/wh/fulfillment/ecommerce/waving';
    }
  };

  // TODO: make this more robust
  const handleCreateError = (response) => {
    const message = 'There was an issue creating your template';
    handleError(message);
  };

  const handleError = (message) => {
    setLoading(false);
    setPageErrors({createError: message});
  };

  const cancelModalTitle = (
    <div>
      <h5>Are you sure you want to cancel?</h5>
    </div>
  );

  const cancelModal = () => {
    const cancelFooter = (
      <div className="modal-footer">
        <FlexeButton text="Keep Editing" handleClick={() => setShowCancelModal(false)} level={'secondary'} />
        <FlexeLinkButton href={'/wh/fulfillment/ecommerce/waving'} level={'primary'}>
          {' '}
          Yes, Cancel
        </FlexeLinkButton>
      </div>
    );

    return (
      <div data-testid="cancel-modal">
        <LegacyModal
          id="cancel-modal"
          show={showCancelModal}
          size="small"
          title={cancelModalTitle}
          transitionSpeed="fast"
          hideClose={true}
          toggleModal={() => setShowCancelModal(!showCancelModal)}
        >
          {cancelFooter}
        </LegacyModal>
      </div>
    );
  };

  return (
    <React.Fragment>
      <FormProvider
        clearErrors={clearErrors}
        control={control}
        register={register}
        errors={errors}
        formState={formState}
        getValues={getValues}
        handleSubmit={handleSubmit}
        reset={reset}
        setError={setError}
        setValue={setValue}
        trigger={trigger}
        unregister={unregister}
        watch={watch}
      >
        <form
          data-testid="new-wave-template-form"
          className="new-wave-template"
          id="new-wave-template-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {showCancelModal && cancelModal()}
          <div className="form-header">
            <h1>New Wave Template</h1>
            <div className="form-buttons">
              <FlexeButton
                testid="cancel-top"
                level="secondary"
                text="Cancel"
                handleClick={() => setShowCancelModal(true)}
              />
              <FlexeButton
                testid="save-top"
                level="primary"
                text="Save"
                type="submit"
                isDisabled={loading || succeeded}
              />
            </div>
          </div>
          <div className="form-body">
            <fieldset form="new-wave-template-form" id="fieldset-one">
              <div className="form-field">
                <label>
                  Template Name
                  <br />
                  <span className="input-hint">Give your template a name you can remember</span>
                </label>
                <input
                  data-testid="template-name-field"
                  className="wave-template-input text-input"
                  name="templateName"
                  ref={register({required: true})}
                />
                {renderValidation('Template name', errors.templateName, nameProvided)}
              </div>

              {reservations && (
                <div className="form-field">
                  <label>
                    Reservation
                    <br />
                    <span className="input-hint">Set the reservation for this template</span>
                  </label>
                  <div data-testid="reservation-field">
                    <Controller
                      name="reservation"
                      control={control}
                      rules={{required: true}}
                      render={({onChange}) => (
                        <Select
                          id="reservation"
                          name="reservation"
                          className="wave-template-select"
                          placeholder="Select a reservation"
                          options={formatReservationsForSelect(reservations)}
                          onChange={(val) => onChange(triggerReservationState(val))}
                        />
                      )}
                    />
                  </div>
                  {showAdvice && <p className="selection-advice">{advice}</p>}
                  {renderValidation('Reservation', errors.reservation, resSelected)}
                </div>
              )}
            </fieldset>
            {nameProvided && resSelected && (
              <fieldset form="new-wave-template-form" id="fieldset-two" data-testid="fieldset-two">
                <div className="form-field">
                  <label>
                    Carrier and Service Level
                    <br />
                    <span className="input-hint">Specify carrier and service level(s) for this template</span>
                  </label>
                  <div data-testid="carrier-field">
                    <Controller
                      name="carrier"
                      control={control}
                      rules={{required: true}}
                      render={({onChange}) => (
                        <Select
                          id="carrier"
                          name="carrier"
                          className="wave-template-select"
                          placeholder="Select a carrier"
                          options={formatCarriersForSelect(props.carrierLabels)}
                          onChange={(val) => onChange(triggerCarrierState(val))}
                        />
                      )}
                    />
                  </div>
                  {renderValidation('Carrier', errors.carrier, carrierSelected)}
                </div>

                <div className="form-field" data-testid="service-level-field">
                  <Controller
                    name="serviceLevel"
                    control={control}
                    render={({onChange, value}) => (
                      <Select
                        id="serviceLevel"
                        name="serviceLevel"
                        className="wave-template-select"
                        placeholder="Type to select one or more service levels"
                        isDisabled={!carrierSelected}
                        isMulti
                        options={formatServiceLevelsForSelect([watch('carrier')][0], serviceLevelMap)}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>

                <div className="form-field" data-testid="shipment-type-field">
                  <label>
                    Specify Order Profile
                    <br />
                    <span className="input-hint">Wave only single unit or multi-unit shipments.</span>
                  </label>
                  <div className="rbtn-group">
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="shipmentType"
                      id="shipment-type-either"
                      data-testid="shipment-type-either"
                      value={'either'}
                      ref={register({required: true})}
                      onChange={triggerShipmentTypeState}
                      defaultChecked={true}
                    />
                    <label htmlFor="shipment-type-either">Both Single and Multi-Unit</label>
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="shipmentType"
                      id="shipment-type-single"
                      data-testid="shipment-type-single"
                      value={'single'}
                      ref={register({required: true})}
                      onChange={triggerShipmentTypeState}
                    />
                    <label htmlFor="shipment-type-single">Single-unit Only</label>
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="shipmentType"
                      id="shipment-type-multi"
                      data-testid="shipment-type-multi"
                      value={'multi'}
                      ref={register({required: true})}
                      onChange={triggerShipmentTypeState}
                    />
                    <label htmlFor="shipment-type-multi">Multi-unit Only</label>
                  </div>
                  {renderValidation('Order profile', errors.shipmentType, true)}
                </div>

                <div className="form-field" data-testid="sku-variety-mode">
                  <label>
                    Specify SKU Mix
                    <br />
                    <span className="input-hint">Wave only single-SKU or multi-SKU shipments.</span>
                  </label>
                  <div className="rbtn-group">
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="skuVariety"
                      id="sku-variety-single-and-multi-sku"
                      data-testid="sku-variety-single-and-multi-sku"
                      value={'either'}
                      ref={register({required: true})}
                      onChange={triggerSkuVarietyState}
                      defaultChecked={true}
                    />
                    <label htmlFor="sku-variety-single-and-multi-sku">Both Single and Multi-SKU</label>
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="skuVariety"
                      id="sku-variety-single-sku"
                      data-testid="sku-variety-single-sku"
                      value={'single'}
                      ref={register({required: true})}
                      onChange={triggerSkuVarietyState}
                    />
                    <label htmlFor="sku-variety-single-sku">Single-SKU Only</label>
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="skuVariety"
                      id="sku-variety-multi-sku"
                      data-testid="sku-variety-multi-sku"
                      value={'multi'}
                      ref={register({required: true})}
                      onChange={triggerSkuVarietyState}
                    />
                    <label htmlFor="sku-variety-multi-sku">Multi-SKU Only</label>
                    {shipmentTypeSkuVarietyHint()}
                  </div>
                </div>

                <div className="form-field">
                  <label>
                    Packaging Requirements (optional)
                    <br />
                    <span className="input-hint"> Add packing and/or labeling requirements</span>
                  </label>
                  <div className="rbtn-group">
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="packaging"
                      id="none-selected"
                      data-testid="none-selected"
                      value={'none-selected'}
                      defaultChecked={true}
                      ref={register}
                    />
                    <label htmlFor="none-selected">None</label>
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="packaging"
                      id="ship-alone"
                      data-testid="ship-alone"
                      value={'ship-alone'}
                      ref={register}
                    />
                    <label htmlFor="ship-alone">Ship Alone</label>
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="packaging"
                      id="overbox"
                      value={'overbox'}
                      ref={register}
                    />
                    <label htmlFor="overbox">Overbox Required</label>
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="packaging"
                      id="ship-as-is"
                      value={'ship-as-is'}
                      ref={register}
                    />
                    <label htmlFor="ship-as-is">Ship As-Is</label>
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="packaging"
                      id="hazmat"
                      value={'hazmat'}
                      ref={register}
                    />
                    <label htmlFor="hazmat">Hazmat</label>
                    <input
                      className="wave-template-input"
                      type="radio"
                      name="packaging"
                      id="site-to-store"
                      value={'site-to-store'}
                      ref={register}
                    />
                    <label htmlFor="site-to-store">Site to Store</label>
                  </div>
                </div>

                <div className="form-field">
                  <label>
                    SKU (optional)
                    <br />
                    <span className="input-hint"> Restrict this template to specific SKUs</span>
                  </label>
                  <div data-testid="sku-select">
                    <Controller
                      name="skuSelect"
                      control={control}
                      render={({onChange}) => (
                        <AsyncSelect
                          id="skuSelect"
                          className="async-select"
                          name="skuSelect"
                          placeholder="Type to select a SKU"
                          isMulti
                          cacheOptions
                          defaultOptions={[]}
                          loadOptions={skuPromiseOptions}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="form-field">
                  <label>
                    P.O. Contains (optional)
                    <br />
                    <span className="input-hint">
                      {' '}
                      Include a reoccurring set of numbers and/or characters to target groups of Purchase Orders
                    </span>
                  </label>
                  <input
                    data-testid="purchase-order-field"
                    className="wave-template-input text-input"
                    name="purchaseOrder"
                    ref={register}
                  />
                </div>
                <div className="form-field">
                  <label>
                    Postal Code Range (optional)
                    <br />
                    <span className="input-hint"> Include a start and stop range</span>
                  </label>
                  <div className="postalrange-text">
                    <input
                      data-testid="postal-code-range-start-field"
                      className="wave-template-input text-input"
                      name="postalCodeRangeStart"
                      placeholder="Start of range"
                      ref={register}
                    />
                    <input
                      data-testid="postal-code-range-end-field"
                      className="wave-template-input text-input"
                      name="postalCodeRangeEnd"
                      placeholder="End of range"
                      ref={register}
                    />
                  </div>
                </div>

                <div
                  onClick={() => {
                    setPageErrors({createError: null});
                  }}
                >
                  <Controller
                    name="customAttribute"
                    control={control}
                    render={() => <CustomAttributesInput savedValue={null} />}
                  />
                </div>
              </fieldset>
            )}
          </div>
          {pageErrors && pageErrors.createError && <p className="creation-error">{pageErrors.createError}</p>}
          <div className="form-footer">
            <div className="form-buttons">
              <FlexeButton
                testid="cancel-bottom"
                level="secondary"
                text="Cancel"
                handleClick={() => setShowCancelModal(true)}
              />
              <FlexeButton
                testid="save-bottom"
                level="primary"
                text="Save"
                type="submit"
                isDisabled={loading || succeeded}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default NewWaveTemplateForm;
