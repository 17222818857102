import {ActorInfo, AddressData, Reservation, ResponseErrorV2} from '../../shared/CommonInterfaces';
import {HistogramEntries} from '../dashboard/DashboardInterfaces';
import OutboundOrdersService from './OutboundOrdersService';

export interface OutboundOrdersProps {
  authenticityToken: string;
  reservations?: Reservation[];
  outboundOrdersService?: OutboundOrdersService;
}

export enum OrdersViewType {
  orders = 'Orders',
  lines = 'Lines'
}

export enum OrderStatusDisplay {
  open = 'Open',
  closed = 'Closed'
}

export enum OrderStatus {
  open = 'open',
  closed = 'closed'
}

export enum OrderType {
  distribution = 'Distribution',
  ecommerce = 'Ecommerce'
}

// This must stay in sync with OrderLineStatus
export enum OrderLineStatusDisplay {
  unallocated = 'Unallocated',
  allocated = 'Allocated',
  partially_allocated = 'Partially Allocated',
  partially_shipped = 'Partially Shipped',
  no_units_shipped = 'No Units Shipped',
  shipped = 'Shipped',
  overshipped = 'Overshipped',
  cancelled = 'Cancelled',
  partially_cancelled = 'Partially Cancelled'
}

// This must stay in sync with OrderLineStatusDisplay
export enum OrderLineStatus {
  unallocated = 'unallocated',
  allocated = 'allocated',
  partiallyAllocated = 'partially_allocated',
  partiallyShipped = 'partially_shipped',
  noUnitsShipped = 'no_units_shipped',
  shipped = 'shipped',
  overshipped = 'overshipped',
  cancelled = 'cancelled',
  partiallyCancelled = 'partially_cancelled'
}

export enum AllocationErrorCode {
  insufficient_inventory = 'insufficient_inventory',
  unknown_sku = 'unknown_sku',
  invalid_inventory_properties = 'invalid_inventory_properties',
  no_enabled_carrier_accounts = 'no_enabled_carrier_accounts',
  no_weight_property = 'no_weight_property',
  invalid_phone_number = 'invalid_phone_number',
  invalid_reservation_configuration = 'invalid_reservation_configuration',
  unsupported_service_type = 'unsupported_service_type',
  invalid_address = 'invalid_address',
  invalid_email = 'invalid_email'
}

export interface OutboundRecipient {
  name: string;
  phone: string;
  email: string;
  address: AddressData;
}

export interface OutboundShippingDetails {
  instructions: string;
  serviceType?: string;
  carrierBillingAccountId?: string;
  signatureConfirmation: string;
  labelReference1: string;
  labelReference2: string;
  shipMode?: string;
}
export interface OutboundOrder {
  id: string;
  externalId: string;
  reservationId?: string;
  labels?: {
    [index: string]: string;
  };
  shipmentIds?: string[];
  hasErrors: boolean;
  state: OrderStatus;
  recipient: OutboundRecipient;
  shipping: OutboundShippingDetails;
  shipAfter?: string;
  shipBefore?: string;
  createdAt: string;
  createdBy?: ActorInfo;
  completedAt: string;
  completedBy?: ActorInfo;
  requestedReservationIds?: string[];
  plannedReservationIds?: string[];
  orderType?: string;
}

export interface UpdateOrderParams {
  labels?: {
    [index: string]: string;
  };
  recipient?: OutboundRecipient;
  shipping?: OutboundShippingDetails;
  reservationId?: string;
}

export interface UpdateLineParams {
  sku: string;
  quantity: number;
  unitOfMeasure: string;
  labels?: {
    [index: string]: string;
  };
  reservationId?: string;
  inventoryLotControl?: OrderInventoryLotControl;
  inventoryReferenceControl?: OrderInventoryReferenceControl;
  itemPrice?: number;
  itemCurrency?: string;
}

export interface OrderInventoryLotControl {
  lotCode: string;
  expirationDate?: string;
}

export interface OrderInventoryReferenceControl {
  inventoryReference: string;
}

export interface OrderLine {
  id: string;
  externalId: string;
  labels?: {
    [index: string]: string;
  };
  orderId: string;
  shipmentIds: string[];
  hasErrors: boolean;
  state?: OrderLineStatus; // now assigned by front-end
  percentAllocated?: number; //deprecated
  percentShipped?: number; //deprecated
  recipient: OutboundRecipient;
  shipping: OutboundShippingDetails;
  shipAfter?: string;
  shipBefore?: string;
  sku: string;
  quantity: number;
  createdAt: string;
  createdBy?: ActorInfo;
  completelyShippedAt?: string;
  cancelledAt?: string;
  cancelledBy?: ActorInfo;
  unitOfMeasure?: string;
  allocatedQuantity: number;
  shippedQuantity: number;
  reservationId?: string;
  requestedReservationIds?: string[];
  plannedReservationIds?: string[];
  inventoryLotControl?: OrderInventoryLotControl;
  inventoryReferenceControl?: OrderInventoryReferenceControl;
  itemPrice?: number;
  itemCurrency?: string;
}

export interface OrderLineReceipt {
  id: string;
  orderId: string;
  orderLineId: string;
  shipmentId?: string;
  shipmentType?: string;
  reservationId: string;
  orderExternalId?: string;
  lineExternalId?: string;
  quantity: number;
  carrier?: string;
  labels?: {
    [index: string]: string;
  };
  trackingNumber?: string;
  serviceLevel?: string;
  proNumber?: string;
  asn?: string;
  createdAt?: string;
  shippedAt?: string;
  wavedAt?: string;
  cancelledAt?: string;
  inventoryLotControl?: OrderInventoryLotControl;
  inventoryReferenceControl?: OrderInventoryReferenceControl;
  calculatedShipByDate?: string;
  calculatedShipByDateTime?: string;
  itemPrice?: number;
  itemCurrency?: string;
}

export interface AllocationError {
  createdAt: string;
  orderId: string;
  orderLineId?: string;
  orderExternalId?: string;
  lineExternalId?: string;
  code: AllocationErrorCode;
  details: string;
  labels?: {
    [index: string]: string;
  };
}

export interface OutboundOrdersResponse {
  continuationToken: string;
  total: number;
  outboundOrders: OutboundOrder[];
  errors?: ResponseErrorV2[];
}

export interface AllocationErrorsResponse {
  continuationToken: string;
  total: number;
  allocationErrors: AllocationError[];
  errors?: ResponseErrorV2[];
}

export interface OrderLinesResponse {
  continuationToken: string;
  total: number;
  lines: OrderLine[];
  errors?: ResponseErrorV2[];
}

export interface OrderLineReceiptsResponse {
  continuationToken: string;
  total: number;
  receipts: OrderLineReceipt[];
  errors?: ResponseErrorV2[];
}

export interface OrderAttachmentsResponse {
  attachments: OrderAttachment[];
  continuationToken: string;
}

export interface OrderAttachment {
  id: string;
  orderId: string;
  type: string;
  fileGroup: string;
  fileKey: string;
  documentType: string;
  externalId: string;
  displayName: string;
  barcodeText: string;
  trackingNumber: string;
  createdAt: string;
}

export interface EditOrderResponse {
  outboundOrder: OutboundOrder;
  errors?: ResponseErrorV2[];
}

export interface UpdateLineResponse {
  orderLine: OrderLine;
  errors?: ResponseErrorV2[];
}

export interface CancelOrderLineResponse extends OrderLine {
  errors?: ResponseErrorV2[];
}

export interface OrderAttachmentErrorsResponse {
  errors?: ResponseErrorV2[];
}

export interface ReleaseForPickingResponse {
  errors?: ResponseErrorV2[];
}

export interface CreateOrderRequest {
  externalId?: string;
  labels: Labels;
  recipient: OutboundRecipient;
  reservationId: string;
  shipping: OutboundShippingDetails;
  lines?: CreateOrderLineRequest[];
  orderNumber?: string;
}

// TODO If we use Typescript 3.6+, we can consider using as const to maintain a simple list for type cheecking
//  Or potentially an object so we can map display names better.
//  An enum is good, but adds to bundle size OR forces recompilation on change (in the case of a const enum)
//  so not doing that for initial implementation
export type AttachmentType = 'insert' | 'shipping_label'; // Other types not yet supported. Needs research.
export type AttachmentFileGroup = 'Inserts'; // Other fileGroups not yet supported. Needs research.
export type AttachmentDocumentType = 'file' | 'template';

export interface CreateOrderAttachmentRequest {
  type: string;
  fileGroup?: string;
  fileKey: string;
  documentType: string;
  externalId?: string;
  displayName?: string;
  trackingNumber?: string;
}

export interface CreateOrderLineRequest {
  quantity: number;
  sku: string;
  externalID?: string;
  unitOfMeasure?: string;
  labels?: LineLabels;
}

export interface Labels {
  flexe_order_source?: string;
  flexe_bol_number?: string;
  flexe_scac?: string;
  flexe_ltl_v1?: string;
  flexe_retailer?: string;
  flexe_ship_within_start?: string;
  flexe_ship_within_end?: string;
  flexe_route_by?: string;
  ponumber?: string;
  sequencenumber?: string;
  ship_from_name?: string;
  ship_from_id?: string;
  ship_from_line1?: string;
  ship_from_line2?: string;
  ship_from_city?: string;
  ship_from_state?: string;
  ship_from_postcode?: string;
  ship_from_country?: string;
  ship_to_id?: string;
  method_of_payment?: string;
  plant_name?: string;
  transportation_method?: string;
  flexe_override_min_qty?: string;
  flexe_freight_workflow?: string;
  flexe_purchase_order_override?: string;
}

export interface LineLabels {
  flexe_order_source?: string;
  customer_itemnumber?: string;
  skudescription?: string;
  requested_quantity?: string;
  customer_uom?: string;
}

export interface OrdersHistogramResponse {
  data: HistogramEntries;
  errors?: ResponseErrorV2[];
}
