import * as React from 'react';
import {flatten} from 'lodash';
import {ErrorCard} from '../shared/ErrorCard';
import {Location} from '../ShipmentInterfaces';
import {Wave} from '../../batch-waving/WaveInterfaces';
import InventoryService from '../../../shared/services/InventoryService';
import LocationsService from '../../../locations/LocationsService';
import {renderLocationLink} from '../../../../libs/helpers';
import {WarningCard} from '../shared/WarningCard';
import WaveService from '../../../shared/services/WaveService';

export interface DanglingLooseGoodsErrorProps {
  danglingLocations: Location[];
  warnings: string[];
}

export const DanglingLooseGoodsError: React.FC<DanglingLooseGoodsErrorProps> = (props) => {
  const hasWarnings = props.warnings && props.warnings.length > 0;
  const hasLocations = props.danglingLocations && props.danglingLocations.length > 0;
  if (!hasWarnings && !hasLocations) {
    return null;
  }

  if (hasWarnings) {
    return (
      <WarningCard>
        <b>Unable to find if there are picked goods that haven't been loaded.</b>
        Units may have been picked for this Shipment and still on Pick Carts.
        <br />
        <br />
        Details: {props.warnings.join('\n')}
      </WarningCard>
    );
  }

  return (
    <ErrorCard>
      <b>There are picked units that haven't been loaded.</b> Units have been picked for this Shipment that are still on
      {props.danglingLocations.map((location, index) => (
        <React.Fragment key={index}>
          {' '}
          {renderLocationLink(location.id, location.name)}
          {index !== props.danglingLocations.length - 1 && ','}
        </React.Fragment>
      ))}
      . Add them to an LPN or remove them from the MHE using the Move App to continue.
    </ErrorCard>
  );
};

export const findDanglingLooseGoods = async (
  authenticityToken: string,
  wave: Wave
): Promise<DanglingLooseGoodsErrorProps> => {
  const defaultResponse: DanglingLooseGoodsErrorProps = {
    danglingLocations: [],
    warnings: []
  };
  // Non SFS reservations won't have a wave associated to a shipment. So it can't have dangling loose goods
  if (!wave) {
    return defaultResponse;
  }

  try {
    const locationIds = await getMheLocationIds(authenticityToken, wave);

    if (!locationIds || locationIds.length < 1) {
      // MHEs must have cubbies.
      return defaultResponse;
    }

    const uniqueDanglingLocationIds = await getLocationsWithLooseContents(authenticityToken, locationIds);
    if (!uniqueDanglingLocationIds || uniqueDanglingLocationIds.length < 1) {
      // None of the locations have loose goods so SOP was followed
      return defaultResponse;
    }

    const descriptiveLocations = await getNamedLocations(
      authenticityToken,
      uniqueDanglingLocationIds,
      wave.reservation.id
    );
    return {
      ...defaultResponse,
      danglingLocations: descriptiveLocations
    };
  } catch (error) {
    return {
      danglingLocations: [],
      warnings: [error.message]
    };
  }
};

export const getMheLocationIds = async (authenticityToken: string, wave: Wave): Promise<number[]> => {
  const waveService = new WaveService(authenticityToken);

  let locationIds: number[] = [];
  let continuationToken: string = null;

  do {
    const mhesResponse = await waveService.pickMhesForWave(wave.id, wave.reservation.warehouse_id, continuationToken);

    if (mhesResponse?.data?.mhes) {
      const pageIds = flatten(mhesResponse.data.mhes.map((mhe) => mhe.cubbies.map((cubby) => cubby.locationId)));
      locationIds = locationIds.concat(pageIds);
      continuationToken = mhesResponse.data.continuationToken;
    } else if (mhesResponse?.errors) {
      throw new Error(mhesResponse.errors.join('\n'));
    } else {
      throw new Error('Failed to fetch locations from the Wave service.');
    }
  } while (continuationToken != null);

  return locationIds;
};

export const getLocationsWithLooseContents = async (
  authenticityToken: string,
  locationIds: number[]
): Promise<number[]> => {
  const inventoryService = new InventoryService(authenticityToken);

  let danglingLocations: number[] = [];
  let continuationToken: string = null;

  do {
    const response = await inventoryService.getLocationContentsByLocationIds(locationIds, continuationToken, true);
    if (response?.data?.contents) {
      const looseContents = response.data.contents.filter((content) => !content.lpnId);
      const locationsPage = looseContents.map((content) => content.locationId);
      danglingLocations = danglingLocations.concat(locationsPage);
      continuationToken = response.data.continuationToken;
    } else if (response?.errors) {
      throw new Error(response.errors.join('\n'));
    } else {
      throw new Error('Failed to get location contents.');
    }
  } while (continuationToken != null);

  const uniqueDanglingLocations = new Set(danglingLocations);
  return Array.from(uniqueDanglingLocations);
};

export const getNamedLocations = async (
  authenticityToken: string,
  locationIds: number[],
  reservationId: number
): Promise<Location[]> => {
  const locationsService = new LocationsService(authenticityToken);

  let detailedLocations: Location[] = [];
  let continuationToken: string = null;

  do {
    const response = await locationsService.getLocationsV2(Array.from(locationIds), reservationId, continuationToken);

    if (response?.data?.locations) {
      const locationsPage = response.data.locations.map((location) => ({id: location.id, name: location.label}));
      detailedLocations = detailedLocations.concat(locationsPage);
      continuationToken = response.data.continuationToken;
    } else if (response?.errors) {
      throw new Error(response.errors.join('\n'));
    } else {
      throw new Error('Failed to get location names.');
    }
  } while (continuationToken != null);

  return detailedLocations;
};
