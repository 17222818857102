import {get} from 'lodash';
import * as jsonPointer from 'json-pointer';
import {AxiosResponse} from 'axios';
import * as React from 'react';
import {Packaging, Response} from '../components/shared/CommonInterfaces';

const US_STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];
const CA_PROVINCES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK'];

interface CountryInfo {
  name: string;
  cityLabel: string;
  postalCodeLabel: string;
  regionLabel: string;
  regions: string[];
}

/* In the future, we should look at I18N functions like this:
  if(typeof Intl.DisplayNames !== undefined)
    return Intl.DisplayNames(['en'], { type: 'region' }).of()
 */
// At least during initial dev, TS did not work as expected
//  if we tried to use COUNTRY_ABBR_* const's as the keys
const COUNTRY_ABBR_TO_INFO: Record<string, CountryInfo> = {
  CA: {
    name: 'Canada',
    cityLabel: 'Municipality',
    postalCodeLabel: 'Postal Code',
    regionLabel: 'Province',
    regions: CA_PROVINCES
  },
  US: {
    name: 'United States',
    cityLabel: 'City',
    postalCodeLabel: 'Zip Code',
    regionLabel: 'State',
    regions: US_STATES
  }
};

// To add a country, see COUNTRY_ABBR_TO_INFO as well.
export const COUNTRY_ABBR_CANADA = 'CA';
export const COUNTRY_ABBR_UNITED_STATES = 'US';

/**
 * Gets the country name, given an ISO abbreviation.
 *  See COUNTRY_ABBR_TO_INFO for supported values
 * @param countryAbbr The abbreviation to convert
 * @returns The country name. abbr if it's not recognized
 */
export function countryAbbrToName(countryAbbr: string): string {
  const countryInfo = COUNTRY_ABBR_TO_INFO[countryAbbr];
  if (countryInfo) {
    return countryInfo.name;
  } else {
    return countryAbbr;
  }
}

/**
 * Gets the states/regions for a country, given a country ISO abbreviation.
 *  Only supports Canada and US today.
 * @param countryAbbr The country abbreviation to lookup
 * @returns The list of regions. Empty if the country isn't known.
 */
export function getRegions(countryAbbr: string): string[] {
  const countryInfo = COUNTRY_ABBR_TO_INFO[countryAbbr];
  if (countryInfo) {
    return countryInfo.regions;
  } else {
    return [];
  }
}

/**
 * Gets what to call a "region", given a country ISO abbreviation.
 *  Only supports Canada and US today.
 * @param countryAbbr The country abbreviation to lookup
 * @param defaultToUS Whether to default to the US label.
 *  If false, an unknown/empty country returns empty string.
 * @returns What to call "region" (e.g. State or Province)
 */
export function getRegionLabel(countryAbbr: string, defaultToUS: boolean = false): string {
  const countryInfo = COUNTRY_ABBR_TO_INFO[countryAbbr];
  if (countryInfo) {
    return countryInfo.regionLabel;
  } else if (defaultToUS) {
    return COUNTRY_ABBR_TO_INFO[COUNTRY_ABBR_UNITED_STATES].regionLabel;
  } else {
    return '';
  }
}

/**
 * Gets what to call a "city", given a country ISO abbreviation.
 *  Only supports Canada and US today.
 * @param countryAbbr The country abbreviation to lookup
 * @param defaultToUS Whether to default to the US label.
 *  If false, an unknown/empty country returns empty string.
 * @returns What to call "city" (e.g. City or Municipality)
 */
export function getCityLabel(countryAbbr: string, defaultToUS: boolean = false): string {
  const countryInfo = COUNTRY_ABBR_TO_INFO[countryAbbr];
  if (countryInfo) {
    return countryInfo.cityLabel;
  } else if (defaultToUS) {
    return COUNTRY_ABBR_TO_INFO[COUNTRY_ABBR_UNITED_STATES].cityLabel;
  } else {
    return '';
  }
}

/**
 * Gets what to call a "postal code", given a country ISO abbreviation.
 *  Only supports Canada and US today.
 * @param countryAbbr The country abbreviation to lookup
 * @param defaultToUS Whether to default to the US label.
 *  If false, an unknown/empty country returns empty string.
 * @returns What to call "postal code" (e.g. Zip Code or Postal Code)
 */
export function getPostalCodeLabel(countryAbbr: string, defaultToUS: boolean = false): string {
  const countryInfo = COUNTRY_ABBR_TO_INFO[countryAbbr];
  if (countryInfo) {
    return countryInfo.postalCodeLabel;
  } else if (defaultToUS) {
    return COUNTRY_ABBR_TO_INFO[COUNTRY_ABBR_UNITED_STATES].postalCodeLabel;
  } else {
    return '';
  }
}

export function displayPackaging(packaging: Packaging | string, count: number) {
  if (count === 0) {
    return null;
  }
  switch (packaging) {
    case Packaging.each:
      if (count > 1) {
        return 'Eaches';
      } else {
        return 'Each';
      }
    case Packaging.carton:
      if (count > 1) {
        return 'Cartons';
      } else {
        return 'Carton';
      }
    case Packaging.pallet:
      if (count > 1) {
        return 'Pallets';
      } else {
        return 'Pallet';
      }
    default:
      return 'Unknown';
  }
}

export function renderItemLink(itemId: string | number, sku: string, isShipper: boolean) {
  const baseUrl = isShipper ? '/s' : '/wh';
  if (itemId) {
    return (
      <a href={`${baseUrl}/inventories/${itemId}`} target="_blank" rel="noreferrer">
        {sku}
      </a>
    );
  } else {
    return;
  }
}

export function renderInventoryStorageLookupLink(sku: string) {
  return (
    <a href={`/wh/locations?sku=${sku}`} target="_blank" rel="noreferrer">
      {sku}
    </a>
  );
}

export function renderLpnLink(lpnBarcode: string, isShipper: boolean, lpnId?: number) {
  const baseUrl = isShipper ? '/s' : '/wh';
  const urlPath = lpnId ? `lpns?lpnId=${lpnId}` : `lpns/${lpnBarcode}`;
  return (
    <a href={`${baseUrl}/${urlPath}`} target="_blank" rel="noreferrer">
      {lpnBarcode}
    </a>
  );
}

export function renderLocationLink(id: string | number, label: string) {
  return (
    <a href={`/wh/locations/${id}`} target="_blank" rel="noreferrer">
      {label}
    </a>
  );
}

export function renderInboundLink(id: number, isShipper: boolean, isPallet: boolean) {
  if (isShipper) {
    if (isPallet) {
      return (
        <a href={`/s/dropoffs/palletized/${id}`} target="_blank" rel="noreferrer">
          Pallet Dropoff {id}
        </a>
      );
    } else {
      return (
        <a href={`/s/dropoffs/container/${id}`} target="_blank" rel="noreferrer">
          Container Delivery {id}
        </a>
      );
    }
  } else {
    if (isPallet) {
      return (
        <a href={`/wh/dropoffs/palletized/${id}`} target="_blank" rel="noreferrer">
          Pallet Dropoff {id}
        </a>
      );
    } else {
      return (
        <a href={`/wh/dropoffs/container/${id}`} target="_blank" rel="noreferrer">
          Container Delivery {id}
        </a>
      );
    }
  }
}

export function renderReturnsLink() {
  return (
    <a href={'/wh/returns'} target="_blank" rel="noreferrer">
      Returns
    </a>
  );
}

export function renderCycleCountsLink(id: number, isShipper: boolean) {
  const baseUrl = isShipper ? '/s' : '/wh';
  return (
    <a href={`${baseUrl}/cycle_counts/${id}`} target="_blank" rel="noreferrer">
      Cycle Count {id}
    </a>
  );
}

export function renderFulfillmentBatch(id: number) {
  return (
    <a href={`/wh/fulfillment/batches/${id}`} target="_blank" rel="noreferrer">
      Fulfillment Batch {id}
    </a>
  );
}

const PickWaveLink = ({id}: {id: number}) => (
  <a href={`/wh/waves/${id}`} target="_blank" rel="noreferrer">
    Wave {id}
  </a>
);

export const PickBatchLink = ({id}: {id: number}) => (
  <a href={`/wh/batches/${id}`} target="_blank" rel="noreferrer">
    Fulfillment Batch {id}
  </a>
);

export function renderOrderLink(id: number) {
  return (
    <a href={`/s/fulfillment/orders/${id}`} target="_blank" rel="noreferrer">
      {id}
    </a>
  );
}

export function renderShipment(
  id: number | string,
  isShipper: boolean,
  isRetail: boolean,
  isOmni?: boolean,
  displayIdOnly?: boolean
) {
  const userType = isShipper ? '/s' : '/wh';
  let linkTitle = '';
  let url = '';

  if (!isShipper && isOmni) {
    url = `${userType}/shipments/${id}`;
    linkTitle = displayIdOnly ? `${id}` : `Shipment ${id}`;
  } else if (isRetail) {
    url = `${userType}/fulfillment/retail/${id}`;
    linkTitle = displayIdOnly ? `${id}` : `Retail Order ${id}`;
  } else {
    const shipperSpecificUrlPiece = isShipper ? 'ecommerce' : 'shipments';
    url = `${userType}/fulfillment/${shipperSpecificUrlPiece}/${id}`;
    linkTitle = displayIdOnly ? `${id}` : `Shipment ${id}`;
  }

  return (
    <a href={url} target="_blank" rel="noreferrer">
      {linkTitle}
    </a>
  );
}

export function renderTrailerManifestLink(id: number) {
  return (
    <a href={`/wh/loads/${id}`} target="_blank" rel="noreferrer">
      Load {id}
    </a>
  );
}

export const determineLink = (id: number, type: string, humanReadableType: string, isShipper: boolean) => {
  switch (type) {
    case 'Fulfillment::Models::FulfillmentBatch':
      return renderFulfillmentBatch(id);
    case 'Delivery':
      return renderInboundLink(id, isShipper, true);
    case 'ContainerDelivery':
      return renderInboundLink(id, isShipper, false);
    case 'Returns::Models::ReturnedInventory':
      return renderReturnsLink();
    case 'CycleCount':
      return renderCycleCountsLink(id, isShipper);
    case 'Fulfillment::Models::FulfillmentOrder':
      return renderShipment(id, isShipper, false, true);
    case 'Fulfillment::Models::RetailFulfillmentOrder':
      return renderShipment(id, isShipper, true);
    case 'TrailerLoading::Models::OutboundManifest':
      return renderTrailerManifestLink(id);
    case 'WavingBatching::Models::PickBatch':
      return <PickBatchLink id={id} />;
    case 'WavingBatching::Models::PickWave':
      return <PickWaveLink id={id} />;
    default:
      return `${humanReadableType} ${id || ''}`;
  }
};

export function buildTextInput(
  placeholder: string,
  value: string,
  onChange: (v: string) => void,
  title?: string,
  maxLength?: number,
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
) {
  return (
    <input
      placeholder={placeholder}
      type="text"
      title={title || placeholder}
      maxLength={maxLength || Number.MAX_SAFE_INTEGER}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      onBlur={onBlur ? (event) => onBlur(event) : undefined}
    />
  );
}

// Useful for multi-status requests, as can happen in partial success cases
export function processErrorsInSuccessResponse(response: AxiosResponse) {
  const errors = get(response, 'data.errors');
  return processErrors(errors);
}

export function processErrorResponse(errorResponse: Response) {
  const errors = get(errorResponse, 'response.data.errors');
  return processErrors(errors);
}

function processErrors(errors) {
  const formErrors = {data: {}};
  errors.forEach((error) => {
    const pointer = get(error, 'source.pointer');
    const errorMessage = error.detail || error.title || true;
    jsonPointer.set(formErrors, pointer, errorMessage);
  });
  return formErrors.data;
}
