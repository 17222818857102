import * as React from 'react';
import {ErrorCard} from '../warehouse/outbound-shipments/shared/ErrorCard';

/**
 * total - The number of widgets that are being tracked by the component. Must be greater than 0.
 * inProgress - The number of widgets that have some OR ALL of their work done. Must be greater than or equal to Completed and less than or equal to total.
 * completed  - The number of widgets that have all of their work done. Must be greater than or equal to 0 and less than or equal to inProgress.
 *
 * If any prop is 0, its related bar won't be displayed and an error will be displayed instead.
 *
 * Ex: If a route has 10 LPNs. 4 are sorted and another 3 are on a manifest this component should get
 * Total = 10; InProgress = 7; Completed = 3
 */
interface MultiStatusProgressBarProps {
  total: number;
  inProgress: number;
  completed: number;
}

const MultiStatusProgressBar: React.FC<MultiStatusProgressBarProps> = ({total, inProgress, completed}) => {
  // Input validation
  if (total < 0 || inProgress < 0 || completed < 0) {
    return <ErrorCard>Progress bar created with negative inputs.</ErrorCard>;
  }
  if (inProgress < completed) {
    return <ErrorCard>Progress bar created with fewer in progress than completed.</ErrorCard>;
  }
  if (inProgress > total) {
    return <ErrorCard>Progress bar created with more in progress than total.</ErrorCard>;
  }

  const notStarted = total - inProgress;
  const inProgressNotCompleted = inProgress - completed;

  const completedPercentage = total > 0 ? (completed / total) * 100 : 0;
  const inProgressPercentage = total > 0 ? (inProgressNotCompleted / total) * 100 : 0;
  const notStartedPercentage = total > 0 ? (notStarted / total) * 100 : 0;

  const notStartedName = inProgress > 0 ? 'remaining' : 'not-started';

  return (
    <div className="multi-status-progress-bar-container">
      {inProgressNotCompleted > 0 && (
        <div
          role={'progressbar'}
          aria-label={'in-progress'}
          className="in-progress-bar bar-segment"
          style={{
            width: `${inProgressPercentage}%`
          }}
        />
      )}
      {completed > 0 && (
        <div
          role={'progressbar'}
          aria-label={'completed'}
          className="completed-bar bar-segment"
          style={{
            width: `${completedPercentage}%`
          }}
        />
      )}
      {notStarted > 0 && (
        <div
          role={'progressbar'}
          aria-label={notStartedName}
          className={`${notStartedName}-bar bar-segment`}
          style={{
            width: `${notStartedPercentage}%`
          }}
        />
      )}
    </div>
  );
};

export default MultiStatusProgressBar;
