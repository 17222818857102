import * as React from 'react';
import {Button, Icon, Row} from '@flexe/ui-components';
import WarehouseSelector from '../shared/WarehouseSelector';
import {Warehouse} from '../shared/CommonInterfaces';
import ReservationDropdown from '../shared/ReservationDropdown';
import RelativeDatePicker, {RelativeDateOption} from '../shared/relative-date-picker/RelativeDatePicker';

interface RouteWavesIndexHeaderProps {
  selectedWarehouse: Warehouse;
  activeWarehouses: Warehouse[];
  routePlanningEnabled: boolean;
  createRouteWaveButtonAction: () => void;
  selectedReservationIdChanged: (reservationId: number) => void;
  onCreatedAfterChanged: (createdAfter: Date) => void;
}

const RouteWavesIndexHeader: React.FC<RouteWavesIndexHeaderProps> = ({
  selectedWarehouse,
  activeWarehouses,
  routePlanningEnabled,
  createRouteWaveButtonAction,
  selectedReservationIdChanged,
  onCreatedAfterChanged
}) => {
  const setSelectedWarehouse = (warehouse: Warehouse) => {
    if (warehouse.id !== selectedWarehouse.id) {
      window.location.href = `/wh/route_planning?warehouse_id=${warehouse.id}`;
    }
  };

  return (
    <div id="route-waves-index-header">
      <div className="title-header">
        <div className="row">
          <div className="title-header-controls">
            <div className={'top-left-controls'}>
              {activeWarehouses.length > 1 && (
                <div>
                  <WarehouseSelector
                    selectedWarehouse={selectedWarehouse}
                    activeWarehouses={activeWarehouses}
                    onSelect={setSelectedWarehouse}
                  />

                  {!routePlanningEnabled && (
                    <p className="route-planning-disabled-message">
                      This warehouse is not configured for route-planning, please select a different warehouse or talk
                      to <a href={'mailto:support@flexe.com'}>support@flexe.com</a>
                    </p>
                  )}
                </div>
              )}
            </div>
            {routePlanningEnabled && (
              <div className={'top-right-controls'}>
                <Button onPress={createRouteWaveButtonAction} visualType="primary">
                  <Row margin={['0', '100']}>
                    <Icon icon="plus" />
                    <span>New Route Wave</span>
                  </Row>
                </Button>
              </div>
            )}
          </div>
        </div>
        {routePlanningEnabled && (
          <>
            <div className="row">
              <h1>Route & Sort</h1>
            </div>
            <div className="header-filter-top-row">
              <ul className="list-inline">
                <li>
                  <ReservationDropdown
                    warehouseId={selectedWarehouse.id}
                    selectedReservationIdChanged={selectedReservationIdChanged}
                  />
                </li>
              </ul>
            </div>
            <hr />
            <RelativeDatePicker
              onDateSelected={onCreatedAfterChanged}
              prefixText={''}
              allowedRelativeDates={[
                RelativeDateOption.today,
                RelativeDateOption.yesterday,
                RelativeDateOption.lastThreeDays,
                RelativeDateOption.lastSevenDays
              ]}
              initialRelativeDate={RelativeDateOption.today}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RouteWavesIndexHeader;
