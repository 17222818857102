/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable dot-notation, @typescript-eslint/dot-notation */
import * as React from 'react';
import {Text} from '@flexe/ui-components';
import {Reservation} from '../../../../shared/CommonInterfaces';
import {ContainerDeliveryState, LpnContent, PackingListItem, PackingLists} from '../../../shared/DropoffInterfaces';
import LpnContents from '../../LpnContents';
import {DeliverySummary} from '../../../shared/DeliverySummary';
import {LpnStatus} from '../../../../lpns/LpnsInterfaces';
import {renderItemLink} from '../../../../../libs/helpers';

interface ConfirmLpnContentsStepProps {
  lpnStateData: Map<string, LpnStatus>;
  lpnReceiveOnly: boolean;
  lpnContents: LpnContent[];
  packingLists: {
    expected: PackingListItem[];
    shippable: PackingListItem[];
    damaged: PackingListItem[];
  };
  reservation: Reservation;
  containerDeliveryId: number;
  inProgressDropoffIds: number[];
  containerDeliveryState?: ContainerDeliveryState;
  recalculatePackingListsBasedOnLpns(lpnContents: LpnContent[]);
  allowLpnTransferInAllInboundStates: boolean;
}

// TODO: revisit and simplify this process. Expected Q1 2022. REF [IQ-767]
export default function ConfirmLpnContentsStep(props: ConfirmLpnContentsStepProps) {
  const stepContent = () => {
    const expectedUniqueKeys: string[] = [];
    const expectedInvIds: number[] = [];
    const additionalInvIds: number[] = [];
    props.packingLists.expected.forEach((expectedList) => {
      const listKey = `${expectedList.inventory.id}-${expectedList.quantity.unit}`;
      if (!expectedInvIds.includes(expectedList.inventory.id)) {
        expectedInvIds.push(expectedList.inventory.id);
      }
      if (!expectedUniqueKeys.includes(listKey)) {
        expectedUniqueKeys.push(listKey);
      }
    });
    props.packingLists.shippable.forEach((list) => {
      if (!expectedInvIds.includes(list.inventory.id)) {
        additionalInvIds.push(list.inventory.id);
      }
    });
    return (
      <DeliverySummary
        packingLists={props.packingLists}
        lpnContents={props.lpnContents}
        lpnReceiveOnly={props.lpnReceiveOnly}
        reservationId={props.reservation.id}
        containerDeliveryId={props.containerDeliveryId}
        inProgressDropoffIds={props.inProgressDropoffIds}
        lpnStateData={props.lpnStateData}
        recalculatePackingListsBasedOnLpns={props.recalculatePackingListsBasedOnLpns}
        containerDeliveryState={props.containerDeliveryState}
        allowLpnTransferInAllInboundStates={props.allowLpnTransferInAllInboundStates}
      />
    );
  };

  return {
    title: 'Confirm LPN Contents',
    content: stepContent()
  };
}
