import * as React from 'react';
import {FC} from 'react';
import {OmniReportRecord} from './OmniReportRecord';
import OmniReportPage from './OmniReportPage';

interface Props {
  authenticityToken: string;
  omniReports: OmniReportRecord[];
  pageTitle: string;
}

const OmniReporting: FC<Props> = (props) => {
  const {authenticityToken, omniReports, pageTitle} = props;

  return <OmniReportPage authenticityToken={authenticityToken} omniReports={omniReports} title={pageTitle} />;
};
export default OmniReporting;
