import axios from 'axios';
import {ApiResponse, Packaging, PaginatedResponse} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// these paginated inventories interfaces are just to support an outdated API and need
// to be retired. Please don't use them anywhere else if you can help it.
interface PaginatedInventory {
  id: number;
  item_code: string;
  description: string;
  available_eaches: number;
  available_cartons: number;
  available_pallets: number;
}
export interface PaginatedInventoriesResponse {
  inventory_summary: PaginatedInventory[];
  continuation_token: string;
  result_set_size: number;
}

export interface InventoryUpdateResponse {
  succeeded: {
    [sku: string]: number;
  };
}

export interface InventoryUpdateRequestObject {
  sku: string;
  txnState?: string;
  defaultBuildInstructions?: BuildInstructions;
  properties?: Property[];
}

export interface RetreiveMaterialStatusesRequest {
  shipperId: number;
  inventoryIds?: number[];
  includeEnabled?: boolean;
  includeDisabled?: boolean;
  continuationToken?: string;
}

export interface RetreiveMaterialStatusesResponse {
  materialStatuses: MaterialStatus[];
}

export interface MaterialStatus {
  id: number;
  shipperId: number;
  name: string;
  enabled: boolean;
  inventoryProperties: MaterialStatusInventoryProperties;
}

export interface MaterialStatusInventoryProperties {
  inventoryId?: number;
  reservationId?: number;
  lpnId?: number;
  lotCode?: string;
}

export interface RetreiveAvailableLocationContentsRequest {
  reservationId: number;
  locationIds?: number[];
  lpnIds?: number[];
  onlyLooseGoods?: boolean;
  items?: RetreiveAvailableItemsFilter[];
  materialStatus?: string;
  continuationToken?: string;
}

export interface RetreiveAvailableItemsFilter {
  inventoryId: number;
  lotCode?: string;
  expirationDate?: string;
}

export interface RetreiveAvailableLocationContentsResponse {
  contents: RetreiveAvailableLocationContentsResponseContents[];
}

// This is not nearly all the fields available in the response, but the use case for adding
// did not necessitate the need for all the fields
export interface RetreiveAvailableLocationContentsResponseContents {
  id: number;
  materialStatus: string;
}

interface BuildInstructions {
  cartonsPerPallet: number;
  cartonsPerLayer: number;
  layersPerPallet: number;
}

interface Property {
  packaging: string;
  parentPackaging: string;
  unitsPerParent?: number;
  shipAlone?: boolean;
  shipAsIs?: boolean;
}

export interface LpnResponse {
  id: number;
  barcode: string;
  reservationId: number;
  shipmentId: number;
  warehouseId: number;
  parentId?: number;
  createdAt: string;
  createdById: number;
  createdByType: string;
  updatedAt?: string;
  state: string;
  lpnType: string;
  referenceId?: string;
}

export interface LpnInventoryTrackingDataResponse extends PaginatedResponse {
  contents: LpnInventoryTrackingDataContent[];
}

export interface LpnInventoryTrackingDataContent {
  id: number;
  arrivalShipmentId: number;
  arrivalShipmentType: string;
  arrivedAt: string;
  expirationDate: string;
  manufactureDate: string;
  shipByDate: string;
  warehouseExitByDate: string;
  asnNumber: string;
  poNumber: string;
  lotCode: string;
  countryOfOrigin: string;
  originSite: string;
  customReference1: string;
  customReference2: string;
  isDamaged: boolean;
  isCrossdock: boolean;
  crossdockAssociationField: string;
  crossdockAssociationValue: string;
  uoms: string;
  inventoryId: number;
  lpnId: number;
}

export interface SerialNumbersBulkResponse extends PaginatedResponse {
  serialNumbers: SerialNumber[];
}

export interface SerialNumber {
  id: number;
  serialNumber: string;
  inventoryTrackingDataId: number;
}

export interface LpnChildrenResponse extends PaginatedResponse {
  lpnDetails: LpnResponse[];
}

export interface Quantity {
  amount: number;
  packaging: Packaging;
}

export interface LocationContent {
  id: number;
  reservationId: number;
  inventoryId: number;
  locationId: number;
  lpnId: number;
  txnState: string;
  quantity: Quantity;
  inventoryTrackingData: LpnInventoryTrackingDataContent;
}

export interface BulkLocationContentsResponse extends PaginatedResponse {
  contents: LocationContent[];
}

/**
 * Client functions for calling the Inventory Service microservice.
 * For legacy reasons, this class contains functions for accessing
 * item master data. But no further such functions should be added.
 */
class InventoryService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2');
  }

  /**
   * @deprecated - These paginated inventories interfaces are just to support an outdated API and need
   * to be retired. Please don't use them anywhere else if you can help it.
   */
  public async getInventories(
    reservationId: number | string,
    skuFilter: string = null,
    continuationToken: string,
    isShipper: boolean,
    everInUse: boolean = false
  ): Promise<PaginatedInventoriesResponse> {
    // TODO - replace this once a v2 endpoint exists
    try {
      const params = {
        sku_description_filter: skuFilter,
        reservation_filter: reservationId,
        continuation_token: continuationToken,
        ever_in_use: everInUse
      };
      const response = await axios.get(`/${isShipper ? 's' : 'wh'}/inventories/paginated_inventory_list_all.json`, {
        params
      });

      return response.data;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  /**
   * @deprecated - These paginated inventories interfaces are just to support an outdated API and need
   * to be retired. Please don't use them anywhere else if you can help it.
   */
  public async update(
    reservationId: number,
    inventory: InventoryUpdateRequestObject[]
  ): Promise<ApiResponse<InventoryUpdateResponse>> {
    return await this.makePostRequest(`${this.baseUrl}/inventories`, {
      reservation: {
        id: reservationId
      },
      inventory
    });
  }

  /**
   * @param request - Either "id" must be specified, or both "barcode" and "reservationId".
   */
  public async getLpn(
    request: {id: number} | {barcode: string; reservationId: number}
  ): Promise<ApiResponse<LpnResponse>> {
    return await this.makeGetRequest(`${this.baseUrl}/inventory-service/lpns`, request);
  }

  public async getLpnInventoryTrackingData(
    lpnId: number,
    continuationToken?: string,
    maxResults?: number
  ): Promise<ApiResponse<LpnInventoryTrackingDataResponse>> {
    return await this.makeGetRequest(`${this.baseUrl}/inventory-service/lpn-inventory-tracking-data`, {
      lpnId,
      continuationToken,
      maxResults
    });
  }

  /**
   * @param ids lpnIds of LPNs to return. Must have length <= 100
   */
  public getLpns(ids: number[]): Promise<ApiResponse<LpnResponse[]>> {
    if (ids.length > 100) {
      throw new Error('Cannot fetch more than 100 LPNs at a time.');
    }
    return this.makeGetRequest(`${this.baseUrl}/inventory-service/lpns-bulk`, {ids});
  }

  /**
   * @param request - At least one field must be populated.
   * @param continuationToken
   * @param maxResults
   */
  public async getSerialNumbersBulk(
    request: Partial<{ids: number[]; serialNumbers: string[]; inventoryTrackingDataIds: string[]}>,
    continuationToken?: string,
    maxResults?: number
  ): Promise<ApiResponse<SerialNumbersBulkResponse>> {
    return await this.makeGetRequest(`${this.baseUrl}/inventory-service/serial-numbers-bulk`, {
      ...request,
      continuationToken,
      maxResults
    });
  }

  public getLocationContentsByLocationIds(
    locationIds: number[],
    continuationToken: string,
    storedOnly: boolean = false
  ): Promise<ApiResponse<BulkLocationContentsResponse>> {
    return this.makeGetRequest(`${this.baseUrl}/inventory-service/location-contents-by-location-ids`, {
      locationIds,
      storedOnly,
      continuationToken
    });
  }

  public getLpnChildren(lpnId: number, continuationToken: string): Promise<ApiResponse<LpnChildrenResponse>> {
    return this.makeGetRequest(`${this.baseUrl}/inventory-service/lpn-children`, {lpnId, continuationToken});
  }

  public getLocationContentsByLpnIds(
    lpnIds: number[],
    continuationToken: string
  ): Promise<ApiResponse<BulkLocationContentsResponse>> {
    return this.makeGetRequest(`${this.baseUrl}/inventory-service/lpn-location-contents-bulk`, {
      lpnIds,
      continuationToken
    });
  }

  public retrieveMaterialStatuses(
    request: RetreiveMaterialStatusesRequest
  ): Promise<ApiResponse<RetreiveMaterialStatusesResponse>> {
    return this.makeGetRequest(`${this.baseUrl}/inventory-service/material-status/definitions/retrieve`, request);
  }

  public retrieveAvailableContent(
    request: RetreiveAvailableLocationContentsRequest
  ): Promise<ApiResponse<RetreiveAvailableLocationContentsResponse>> {
    return this.makeBasicPostRequest(`${this.baseUrl}/inventory-service/location-contents/retrieve-available`, request);
  }
}

export default InventoryService;
