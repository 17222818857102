export interface FulfillmentOrder {
  id: number;
  addrLine1: string;
  addrLocality: string;
  addrRegion: string;
  addrPostcode: string;
  proNumber: string;
  purchaseOrder: string;
  description: string;
  status: string;
  createdAt: string;
  sku: string;
}

export enum ManifestStatus {
  open = 'open',
  completed = 'completed',
  cancelled = 'cancelled'
}

export interface OutboundTrailerManifest {
  id: number;
  warehouseId: number;
  trailerId: string;
  loadingLocation: string;
  doorLocation: string;
  dockLocationId: number;
  scacCode: string;
  createdAt: string;
  updatedAt: string;
  status: ManifestStatus;
  freightLoadId?: number;
  reservationId: number;
  shipMode: string;
  customerLoadId?: string;
}

export interface OutboundTrailerManifestDetail extends OutboundTrailerManifest {
  totalPieces: number;
  totalWeight: number;
  shipMode: string;
  csvEnabled: boolean;
  freightLoadId: number;
  sortCode?: string;
  customerLoadId?: string;
}

export interface OutboundManifestListResponse {
  continuationToken: string;
  totalMatchingResults: number;
  outboundManifests: OutboundTrailerManifest[];
}

export interface VacantDockLocationsResponse {
  vacant_locations: VacantDockLocation[];
}

export enum ManifestLpnStatus {
  loaded = 'loaded',
  shipped = 'shipped'
}

export enum LpnType {
  pallet = 'PALLET',
  carton = 'CARTON'
}

export interface ManifestLpn {
  lpn_barcode: string;
  shipment_id: number;
  outbound_manifest_id?: number;
  lpn_detail_id?: number;
  parent_lpn_detail_id?: number;
  lpn_type?: LpnType;
  txn_state: ManifestLpnStatus;
  loaded_at?: Date;
  shipped_at?: Date;
  manifest_contents: ManifestContents[];
}

export interface ManifestContents {
  inventory_id: number;
  quantity: number;
  unit_of_measure: string;
  sku: string;
  quantity_in_all_units: QuantityInAllUnits;
}

export interface QuantityInAllUnits {
  each: number;
  carton: number;
  pallet: number;
}

export interface ManifestContentsSearchByShipmentRequest {
  continuationToken?: string;
  pageSize?: number;
  filters: ManifestContentsSearchByShipmentFilter;
}

export interface ManifestContentsSearchByManifestRequest {
  continuationToken?: string;
  pageSize?: number;
  filters: ManifestContentsSearchByManifestFilter;
}

export interface ManifestContentsSearchByShipmentFilter {
  reservationId: number;
  shipmentId: number;
}

export interface ManifestContentsSearchByManifestFilter {
  reservationId: number;
  manifestId: number;
  topLevelLpnsOnly: boolean;
}

export interface ManifestContentsSearchResponse {
  continuation_token?: string;
  count: number;
  manifest_lpns: ManifestLpn[];
}

export interface VacantDockLocation {
  location_id: number;
  location_label: string;
  location_barcode_text: string;
}
