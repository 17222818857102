/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import axios from 'axios';
import {format as formatDate, parse as parseDate} from 'date-fns';
import {cloneDeep, get, set} from 'lodash';
import Select from 'react-select';
import {css, jsx} from '@emotion/core';
import LpnService, {LpnCorrectionRequest, LpnMoveRequest} from '../../shared/services/LpnService';
import {ApiResponse, Packaging} from '../../shared/CommonInterfaces';
import {renderItemLink, renderLpnLink} from '../../../libs/helpers';
import EditLpnMenu from '../../shared/EditLpnMenu';
import UpdateControls from '../shared/UpdateControls';
import TypeAhead, {TypeAheadOption} from '../../shared/TypeAhead';
import {Inventory, LpnContent} from '../shared/DropoffInterfaces';
import CustomParams from './CustomParams';
import SerialNumbers from './SerialNumbers';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const checkmarkImg = require('../../../../assets/images/common/checkmark-green.png');
const asnLpnStyle = css({
  width: '5%'
});
const lpnColStyle = css({
  wordBreak: 'break-all'
});

interface LpnContentRowProps {
  lpnReceiveOnly: boolean;
  lpnIsDamaged: boolean;
  lpnIsArchived: boolean;
  index: number;
  lpnService: LpnService;
  reservationId: number;
  content: LpnContent;
  receivedLpns: Set<string>;
  containerDeliveryId: number;
  inProgressDropoffIds: number[];
  shouldDisplayLpn: boolean;
  shouldDisplayUoms: boolean;
  enableEdits: boolean;
  isAsnLpns: boolean;
  handleLpnUpdate(content: LpnContent, index: number);
  alertLpnDeleteSuccess(content: LpnContent);
  alertLpnMoveSuccess(content: LpnContent, index: number, moveToContainerDropoffId: number);
}

interface LpnContentRowState {
  deleted: boolean;
  edited: boolean;
  editing: boolean;
  errors: string[];
  loading: boolean;
  skuQuery: string;
  content: LpnContent;
  receivedLpns: Set<string>;
  typeAheadOptions: TypeAheadOption[];
  showDropdown: boolean;
  showMoveToList: boolean;
  showDeleteConfirmation: boolean;
  saveButtonEnabled: boolean;
}

class LpnContentRow extends React.Component<LpnContentRowProps, LpnContentRowState> {
  private static readonly contentPropertyToRequestMap = [
    {
      contentProperty: 'inventory.sku',
      requestParam: 'targetSku'
    },
    {
      contentProperty: 'lotCode',
      requestParam: 'targetLotCode'
    },
    {
      contentProperty: 'expirationDate',
      requestParam: 'targetExpirationDate'
    },
    {
      contentProperty: 'receivedQuantity',
      requestParam: 'targetQuantity'
    },
    {
      contentProperty: 'packaging',
      requestParam: 'targetUom'
    }
  ];
  private inventory: Inventory[];
  private isShipper: boolean;

  constructor(props) {
    super(props);
    this.state = {
      deleted: false,
      edited: false,
      editing: false,
      errors: [],
      loading: false,
      skuQuery: this.props.content.inventory.sku,
      content: this.props.content,
      receivedLpns: this.props.receivedLpns,
      typeAheadOptions: [],
      showDropdown: false,
      showMoveToList: false,
      showDeleteConfirmation: false,
      saveButtonEnabled: true
    };
  }

  public async componentDidMount() {
    this.isShipper = window.location.pathname[1] === 's' ? true : false;
    // Close the dropdown when the user clicks off of it
    document.body.addEventListener('mouseup', this.closeDropdownIfClickedElsewhere);
  }

  public render() {
    const {shouldDisplayLpn, enableEdits} = this.props;
    const editControlsVisible: boolean = !this.state.editing;
    const lpnStillExists: boolean = !this.props.lpnIsArchived;
    return (
      !this.state.deleted && (
        <div
          data-testid="lpn-content-row"
          className={`lpn-row ${this.state.showDropdown && 'high-lighted'} ${this.props.lpnIsArchived &&
            'archived-lpn'}`}
          key={this.props.content.id}
        >
          {this.state.showDeleteConfirmation && (
            <div className="delete-modal">
              <div className="modal-backdrop"></div>
              <div className="modal" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" aria-label="Close" onClick={this.cancelDelete}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h5 className="modal-title">{`Delete LPN ${this.props.content.lpnBarcode}`}</h5>
                    </div>
                    <div className="modal-body">
                      {'Are you sure you want to delete this LPN? This action cannot be undone.'}
                    </div>
                    <div className="modal-footer">
                      <button type="button" onClick={this.deleteLPN} className="btn">
                        {'Yes, Permanently delete this LPN'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.errors && this.state.errors.length > 0 && (
            <div className="alert alert-danger">{this.state.errors.join(' ')}</div>
          )}
          <div className="row">
            {this.props.isAsnLpns && this.props.receivedLpns.size > 0 && (
              <div className="col-md-1" css={asnLpnStyle}>
                <AsnLpnView receivedLpns={this.state.receivedLpns} lpnBarcode={this.state.content.lpnBarcode} />
              </div>
            )}
            <div className="col-md-1" css={lpnColStyle}>
              {shouldDisplayLpn &&
                renderLpnLink(this.props.content.lpnBarcode, this.isShipper, this.props?.content?.lpnDetailId)}
              {this.props.lpnIsArchived && <div style={{fontStyle: 'italic'}}>(archived)</div>}
            </div>
            <div className="col-md-2">{this.skuColumn()}</div>
            <div className="col-md-2">{this.props.content.poNumber || '-'}</div>
            {this.props.shouldDisplayUoms && this.props.content.uoms && (
              <div className="col-md-1">
                {this.props.content.uoms?.eachesPerInnerPack || '-'} each/inner pack
                <br />
                {this.props.content.uoms?.eachesPerOuterCase || '-'} each/outer case
              </div>
            )}
            {this.props.isAsnLpns && (
              <div className="col-md-1" data-testid="lpn-expected-quantity">
                {this.state.content.expectedQuantity} {this.state.content.expectedPackaging}
              </div>
            )}
            <div className="col-md-1">{this.quantityColumn()}</div>
            <div className="col-md-1">{formatDate(this.props.content.arrivedAt, 'MM/DD/YYYY')}</div>
            <div className="col-md-2">
              <CustomParams
                handleLotChange={this.handleContentUpdate}
                handleDateChange={this.handleExpirationDateUpdate}
                lotCode={this.state.content.lotCode}
                expirationDate={this.state.content.expirationDate ? parseDate(this.state.content.expirationDate) : null}
                editing={this.state.editing}
                serialNumbers={
                  <SerialNumbers
                    {...this.props.content}
                    lpnDetailId={this.props.content.lpnDetailId}
                    inboundId={this.props.containerDeliveryId}
                    onErrors={(errors) => this.handleApiError(errors, null)}
                    reservationId={this.props.reservationId}
                  />
                }
              />
            </div>
            {this.props.lpnReceiveOnly && <div className="col-md-1">{this.props.lpnIsDamaged ? 'Yes' : 'No'}</div>}
            {enableEdits && editControlsVisible && lpnStillExists && (
              <EditLpnMenu
                showEditToggle={true}
                showDropdown={this.state.showDropdown}
                showMoveToList={this.state.showMoveToList}
                containerDeliveryId={this.props.containerDeliveryId}
                inProgressDropoffIds={this.props.inProgressDropoffIds}
                toggleShowDropdown={this.toggleShowDropdown}
                toggleShowMoveToList={this.toggleShowMoveToList}
                editLpn={this.editLpn}
                moveLPNto={this.moveLPNto}
                confirmLPNDelete={this.confirmLPNDelete}
              />
            )}
            {this.state.editing && (
              <UpdateControls
                saveButtonEnabled={this.state.saveButtonEnabled}
                cancelHandler={this.stopEditing}
                saveHandler={this.updateLpn}
              />
            )}
          </div>
        </div>
      )
    );
  }

  private skuColumn() {
    const currentItemValue = this.state.edited ? this.state.content.inventory : this.props.content.inventory;
    return this.state.editing ? (
      <TypeAhead
        name="sku-select"
        placeholder="Type to select SKU..."
        value={this.state.skuQuery}
        options={this.state.typeAheadOptions}
        onRequestOptions={this.handleSkuQuery}
        onSelect={this.handleSkuSelect}
      />
    ) : (
      <div>{renderItemLink(currentItemValue.id, currentItemValue.sku, this.isShipper)}</div>
    );
  }

  private handleSkuQuery = async (query) => {
    this.setState({skuQuery: query});
    if (query.length > 2) {
      try {
        const response = await axios.get('/widgets/inventory_picker', {
          params: {
            q: query,
            reservation_id: this.props.reservationId
          }
        });
        this.inventory = get(response, 'data.matches', []);
        const typeAheadOptions = this.inventory.map((inv) => {
          return {
            value: inv.sku,
            displayName: `${inv.sku} - ${inv.description}`
          };
        });
        this.setState({typeAheadOptions});
      } catch (error) {
        this.handleApiError(['There was an error getting SKU information'], null);
      }
    }
  };

  private handleSkuSelect = (sku) => {
    const selectedInventory = this.inventory.find((inv) => inv.sku === sku);
    const content = cloneDeep(this.state.content);
    content.inventory = selectedInventory;
    this.setState({
      skuQuery: sku,
      content,
      typeAheadOptions: []
    });
  };

  private renderEditInputs(uomOpts) {
    return (
      <React.Fragment>
        <div className="form-inline quantity-edit">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              name="receivedQuantity"
              value={this.state.content.receivedQuantity}
              min="1"
              step="1"
              onChange={this.handleContentUpdate}
            />
          </div>
        </div>
        <div className="form-inline uom-edit">
          <div className="form-group">
            <Select
              options={uomOpts}
              name="receivedUnitOfMeasure"
              defaultValue={{value: this.state.content.packaging, label: this.state.content.packaging}}
              onChange={this.handleUomUpdate}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
  private quantityColumn() {
    const currentQuantityValue = this.state.edited
      ? `${this.state.content.receivedQuantity} ${this.state.content.packaging}`
      : `${this.props.content.receivedQuantity} ${this.props.content.packaging}`;

    const uomOptions = [
      {value: 'each', label: 'each'},
      {value: 'carton', label: 'carton'}
    ];

    return this.state.editing ? this.renderEditInputs(uomOptions) : currentQuantityValue;
  }

  private handleExpirationDateUpdate = (date: Date) => {
    const content = cloneDeep(this.state.content);
    content.expirationDate = date;
    this.setState({content});
  };

  private handleContentUpdate = (e) => {
    const content = cloneDeep(this.state.content);
    if (e.target.name === 'receivedQuantity') {
      content.receivedQuantity = Number(e.target.value);
    } else {
      content[e.target.name] = e.target.value;
    }

    this.setState({content});
  };

  private handleUomUpdate = (opt) => {
    const content = cloneDeep(this.state.content);
    content.packaging = opt.value === 'each' ? Packaging.each : Packaging.carton;

    this.setState({content});
  };

  private editLpn = () => {
    this.setState({editing: true});
  };

  private toggleShowDropdown = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
      showMoveToList: false
    });
  };

  private toggleShowMoveToList = () => {
    this.setState({
      showMoveToList: this.state.showDropdown && !this.state.showMoveToList
    });
  };

  private closeDropdownIfClickedElsewhere = (event) => {
    let element = event.target;
    while (element.parentNode) {
      if (element.classList && element.classList.contains('dropdown-menu')) {
        // Do nothing if clicked within the dropdown
        return;
      } else {
        element = element.parentNode;
      }
    }
    this.setState({showDropdown: false});
  };

  private stopEditing = () => {
    this.setState({
      editing: false,
      skuQuery: ''
    });
  };

  private refreshPage = () => {
    window.location.reload();
  };

  private updateLpn = async () => {
    let request: LpnCorrectionRequest = {
      reservationId: this.props.reservationId,
      lpn: this.state.content.lpnBarcode,
      currentSku: this.props.content.inventory.sku
    };

    LpnContentRow.contentPropertyToRequestMap.map((paramMap) => {
      request = this.checkChangedProperties(request, paramMap.contentProperty, paramMap.requestParam);
    });

    if (request.targetQuantity) {
      request.targetQuantity = Number(request.targetQuantity);
    }
    this.setState({saveButtonEnabled: false});

    const response = await this.props.lpnService.correctLpnInbound(request);
    if (response && response.errors.length === 0) {
      this.stopEditing();
      this.setState({edited: true});
      this.props.handleLpnUpdate(this.state.content, this.props.index);
      this.refreshPage();
    } else {
      const errors = ['There was an error updating the LPN data'];
      this.handleApiError(errors, response);
      this.stopEditing();
      this.setState({saveButtonEnabled: true});
    }
  };

  private confirmLPNDelete = () => {
    this.setState({
      showDeleteConfirmation: true,
      showDropdown: false
    });
  };

  private deleteLPN = async () => {
    this.setState({showDeleteConfirmation: false});
    const request: LpnCorrectionRequest = {
      reservationId: this.props.reservationId,
      lpn: this.props.content.lpnBarcode,
      currentSku: this.props.content.inventory.sku,
      targetQuantity: 0
    };
    const response = await this.props.lpnService.correctLpnInbound(request);
    if (response && response.errors.length === 0) {
      const snapshot = cloneDeep(this.state.content);
      snapshot.receivedQuantity = 0;
      this.setState({deleted: true, content: snapshot});
      this.props.handleLpnUpdate(this.state.content, this.props.index);
      this.props.alertLpnDeleteSuccess(this.props.content);
    } else {
      const errors = ['There was an error Deleting the LPN'];
      this.handleApiError(errors, response);
    }
  };

  private moveLPNto = async (moveToContainerDropoffId: number) => {
    this.setState({
      showDropdown: false,
      showMoveToList: false
    });
    const request: LpnMoveRequest = {
      reservationId: this.props.reservationId,
      lpn: this.props.content.lpnBarcode,
      sourceContainerDeliveryId: this.props.containerDeliveryId,
      targetContainerDeliveryId: moveToContainerDropoffId
    };
    const response = await this.props.lpnService.moveLpnToDropoff(request);
    if (response !== undefined && response.errors === undefined) {
      const snapshot = cloneDeep(this.state.content);
      snapshot.receivedQuantity = 0;
      this.setState({deleted: true, content: snapshot});
      this.props.handleLpnUpdate(this.state.content, this.props.index);
      this.props.alertLpnMoveSuccess(this.props.content, this.props.index, moveToContainerDropoffId);
    } else {
      const errors = ['There was an error Moving the LPN'];
      this.handleApiError(errors, response);
    }
  };

  private cancelDelete = () => {
    this.setState({showDeleteConfirmation: false});
  };

  private checkChangedProperties(
    request: LpnCorrectionRequest,
    snapshotProperty: string,
    requestParam: string
  ): LpnCorrectionRequest {
    if (get(this.props.content, snapshotProperty) !== get(this.state.content, snapshotProperty)) {
      set(request, requestParam, get(this.state.content, snapshotProperty));
    }
    return request;
  }

  private handleApiError(errors: string[], response: ApiResponse<any>) {
    if (response) {
      errors[0] += ':';
      response.errors.map((error) => errors.push(error.detail));
    }
    this.setState({errors});
  }
}

const AsnLpnView: React.FC<{
  receivedLpns: Set<string>;
  lpnBarcode: string;
}> = ({receivedLpns, lpnBarcode}) => {
  const isReceived: boolean = receivedLpns.has(lpnBarcode);
  if (isReceived) {
    return <img data-testid="lpn-received-checkmark" src={checkmarkImg} alt="Checkmark" />;
  }

  return null;
};

export default LpnContentRow;
