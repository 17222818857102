import {ResponseErrorV2} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';

export interface MheSearchResponse {
  mhes: Mhe[];
  continuationToken: string;
}

export interface MheServiceApiResponse<T> {
  data: T | null;
  errors: ResponseErrorV2[];
}

export enum MheSearchErrorResponseCodes {
  NotFound = '404'
}

export interface Mhe {
  id: number;
  label: string;
  barcode: string;
  type: string;
  warehouseId: number;
  cubbies: Cubby[];
}

export interface Cubby {
  locationId: number;
}

class MheService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/batch-service/mhe');
  }

  public async searchForMhes(
    mheIds: number[],
    warehouseId: number,
    continuationToken: string
  ): Promise<MheServiceApiResponse<MheSearchResponse>> {
    const data = {
      mheIds,
      warehouseId,
      continuationToken
    };
    return await this.makePostRequest(`${this.baseUrl}/search`, data);
  }
}

export default MheService;
