import {SortOrder, TransitionState, WarehouseLocation} from '../../shared/CommonInterfaces';
import {BatchDocument} from '../ecommerce-batches/BatchInterfaces';
import {WarehouseTaskStepStatus} from '../../tasks/WarehouseTasksInterfaces';
import {ShipMode as ShipModeEnum} from '../../shared/constants';

export interface WaveBatch {
  id: number;
  waveId: number;
  reservationId: number;
  status: string;
  createdAt: string;
  startedAt: string | null;
  currentPicker: string | null;
  currentMhe: WarehouseLocation[];
  barcode: string;
  taskId: number;
  documents: BatchDocument[];
  pickSteps: PickStep[];
  lastLocation: LastLocation | null;
}

export interface LastLocation extends WarehouseLocation {
  updatedAt: string;
  status: WarehouseTaskStepStatus;
}

export enum PickBatchStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE'
}

export enum PickWaveStatus {
  new = 'NEW',
  not_started = 'NEW', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  in_progress = 'IN_PROGRESS',
  completed = 'COMPLETED',
  cancelled = 'CANCELLED'
}

export interface PickStep {
  sourceLocation: number;
  status: string;
  requiredQuantity: number;
  pickedQuantity: number;
  pickedUom?: string;
  requiredUom: string;
  itemId: number;
  itemSku: string;
  lpnBarcode: string;
  locationName?: string;
  itemDescription?: string;
  updatedAt?: string;
  sourceLpn: string | null;
  lpnDetailId?: number | null;
  destinationLpn: string | null;
  inventoryRequirement: InventoryRequirement | null;
}

export interface InventoryRequirement {
  lotCode: string | null;
  expirationDate: string | null;
  referenceId: string | null;
}

export interface GetBatchesResponse {
  continuationToken: string | null;
  totalCount: number;
  batches: WaveBatch[];
}

export interface GetBatchCountsResponse {
  continuationToken: string | null;
  totalCount: number;
  batch_counts: object;
}

export interface GetBatchResponse {
  batch: WaveBatch;
  displayFromLpnColumn: boolean;
}

export enum FilterKeys {
  WaveId = 'id',
  ShipmentId = 'shipment_id',
  BatchId = 'batch_id',
  Carrier = 'carrier',
  ServiceType = 'service_type',
  StagingLocation = 'staging_location',
  Mhe = 'mhe',
  HidePrinted = 'hide_printed',
  OrderReferenceId = 'order_reference_id',
  Status = 'status',
  ShipBy = 'ship_by',
  SortOrder = 'sort_order', // eslint-disable-line @typescript-eslint/no-shadow
  Parent = 'parent',
  LoadGroup = 'load_group',
  DestinationTag = 'destination_tag',
  ShipMode = 'ship_mode',
  PurchaseOrder = 'purchase_order'
}

export enum FilterSearchTypes {
  Is = 'Is',
  Contains = 'Contains / Is'
}

export interface FilterParams {
  [FilterKeys.WaveId]?: string;
  [FilterKeys.ShipmentId]?: number;
  [FilterKeys.BatchId]?: string;
  [FilterKeys.Carrier]?: string;
  [FilterKeys.ServiceType]?: string;
  [FilterKeys.StagingLocation]?: string;
  [FilterKeys.Mhe]?: string;
  [FilterKeys.HidePrinted]?: boolean;
  [FilterKeys.OrderReferenceId]?: string;
  [FilterKeys.Status]?: TransitionState;
  [FilterKeys.ShipBy]?: Date;
  [FilterKeys.SortOrder]?: SortOrder;
  [FilterKeys.LoadGroup]?: string;
  [FilterKeys.DestinationTag]?: string;
  [FilterKeys.ShipMode]?: ShipModeEnum;
  [FilterKeys.PurchaseOrder]?: string;
}

export interface WaveRequestFilters {
  reservation_id: number;
  max_orders: number;
  batch_count?: number;
  cutoff_date?: string;
  ship_by_time?: string;
  skus?: string[];
  sku_variety?: string;
  shipment_type?: string;
  shipment_ids?: string[];
  carriers?: string[];
  service_types?: string[];
  postal_code_range?: PostalCodeRange;
  ship_as_is?: boolean;
  includes_hazmat?: boolean;
  purchase_order?: string;
}

interface PostalCodeRange {
  start: string;
  end: string;
}
