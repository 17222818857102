import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import {Item} from '../../shared/services/ItemMasterService';
import FlexeButton from '../FlexeButton';
import {CycleCountItem} from './CycleCountInterfaces';
import {ItemPropertiesDetailsModalState} from './CycleCountDetail';
import ItemPropertiesMetadataForm from './ItemPropertiesMetadataForm';

interface Props {
  id: string;
  forShipper: boolean;
  showItemPropertiesDetailsModal: ItemPropertiesDetailsModalState;
  disabled: boolean;
  cycleCountItem: CycleCountItem;
  activeWorkersExist: boolean;
  renderCurrentWorkers: () => JSX.Element;
  toggleItemDetailsModalWithId: (itemId: string, locationId: string) => void;
  saveLpnMetadataEdits: (data) => Promise<void>;
  inventoryInfoForCCItem: Item[];
}

const ItemPropertiesDetailsModal: FunctionComponent<Props> = (props) => {
  const expectedItd = props.cycleCountItem.metadata?.expected?.id.toString();
  const itdsMatch = expectedItd === props.showItemPropertiesDetailsModal.itemId;
  const itemId = props.cycleCountItem?.inventory?.id?.toString();
  const modalIdMatch =
    props.showItemPropertiesDetailsModal.itemId === itemId &&
    props.showItemPropertiesDetailsModal.locationId === props.cycleCountItem?.location?.id?.toString();
  // if expected ITD id is 0, that means this is unexpected item
  let modalId: string;
  let show: boolean;
  if (parseInt(expectedItd, 10) > 0) {
    modalId = expectedItd;
    show = itdsMatch && props.showItemPropertiesDetailsModal.visible;
  } else {
    if (modalIdMatch) {
      modalId = itemId;
      show = props.showItemPropertiesDetailsModal.visible;
    } else {
      modalId = expectedItd;
      show = itdsMatch && props.showItemPropertiesDetailsModal.visible;
    }
  }
  const renderModal = () => {
    return (
      <LegacyModal
        id={modalId}
        title={`Edit ${props.cycleCountItem.inventory.sku} Properties`}
        show={show}
        size="small"
        toggleModal={() => {
          props.toggleItemDetailsModalWithId(modalId, props.showItemPropertiesDetailsModal.locationId);
        }}
        footer={
          <FlexeButton
            text="Cancel"
            testid={modalId}
            level={'collapsed-link'}
            handleClick={() => {
              props.toggleItemDetailsModalWithId(modalId, props.showItemPropertiesDetailsModal.locationId);
            }}
          />
        }
        disableClose={false}
      >
        {props.activeWorkersExist ? (
          <div className="row space-below">
            <p>
              Editing or submitting are disabled while mobile users are in progress. To edit here, mobile users must
              submit their work from the device.
            </p>
            <p>The following workers are still active:</p>
            {props.renderCurrentWorkers()}
          </div>
        ) : (
          <ItemPropertiesMetadataForm
            id={modalId}
            forShipper={props.forShipper}
            disabled={props.disabled}
            cycleCountItem={props.cycleCountItem}
            toggleItemDetailsModalWithId={props.toggleItemDetailsModalWithId}
            saveLpnMetadataEdits={props.saveLpnMetadataEdits}
            inventoryInfoForCCItem={props.inventoryInfoForCCItem}
          />
        )}
      </LegacyModal>
    );
  };
  return renderModal();
};

export default ItemPropertiesDetailsModal;
