import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  renderInboundLink,
  renderLocationLink,
  renderLpnLink,
  renderShipment,
  renderTrailerManifestLink
} from '../../libs/helpers';
import InventoryService, {LpnResponse} from '../shared/services/InventoryService';
import LocationsService from '../locations/LocationsService';
import {formatDate} from './date-formatters';
import {LpnDetails, LPN_STATUS_TO_UI_STRINGS, LPN_TYPE_TO_UI_STRINGS} from './LpnsInterfaces';

interface LpnMetadataProps {
  lpn: LpnDetails;
  isShipper: boolean;
  isLpnParentEnabled: boolean;
  isLpnArchived: boolean;
  inventoryService: InventoryService;
  locationsService: LocationsService;
}
export function LpnMetadata({
  lpn,
  isShipper,
  isLpnParentEnabled,
  isLpnArchived,
  inventoryService,
  locationsService
}: LpnMetadataProps) {
  const parentLpn = useLpn(inventoryService, lpn.parentLpnId);
  const location = useLpnLocation(lpn, inventoryService, locationsService);

  const receivedBy = lpn.receivedBy ? lpn.receivedBy.name : null;

  let lpnTypeUiString = '--';
  if (lpn.lpnType) {
    lpnTypeUiString = LPN_TYPE_TO_UI_STRINGS[lpn.lpnType] || lpn.lpnType;
  }
  let lpnStatusUiString = '--';
  if (lpn.state) {
    lpnStatusUiString = LPN_STATUS_TO_UI_STRINGS[lpn.state] || lpn.state;
  }
  const locationFieldLabel = lpn.state === 'archived' ? 'Last Known Location' : 'Location';
  const leftColumnData = [
    {title: 'LPN Type', value: lpnTypeUiString},
    isLpnParentEnabled
      ? {title: 'LPN Parent', value: parentLpn ? renderLpnLink(parentLpn.barcode, isShipper, parentLpn.id) : '--'}
      : null,
    {title: 'LPN Status', value: lpnStatusUiString},
    {
      title: locationFieldLabel,
      value: location.id ? renderLocationLink(location.id, location.label) : '--'
    }
  ].filter(Boolean);
  if (isLpnArchived) {
    leftColumnData.push({
      title: 'Archived Date',
      value: formatDate(lpn.updatedAt)
    });
  }
  if (!isLpnArchived) {
    leftColumnData.push({
      title: 'Time in Location',
      value: calculateDaysInLocation(lpn.timeInLocation) || '--'
    });
  }
  const warehouseRightColumn = [
    {title: 'Reservation ID', value: lpn.reservation || '--'},
    {
      title: 'Dropoff ID',
      value: lpn.dropoffId ? renderInboundLink(lpn.dropoffId, isShipper, false) : '--'
    },
    {
      title: 'Received Date',
      value: formatDate(lpn.receivedDate) || '--'
    },
    {title: 'Received By', value: receivedBy || '--'},
    {
      title: 'Shipment',
      value: lpn.loadId ? renderShipment(lpn.shipmentId, isShipper, false, true) : '--'
    },
    {
      title: 'Load',
      value: lpn.loadId ? renderTrailerManifestLink(lpn.loadId) : '--'
    }
  ];
  if (lpn.referenceId) {
    warehouseRightColumn.push({
      title: 'Ref ID',
      value: lpn.referenceId
    });
  }

  const shipperRightColumn = [{title: 'Reservation ID', value: lpn.reservation || '--'}];
  if (lpn.referenceId) {
    shipperRightColumn.push({
      title: 'Ref ID',
      value: lpn.referenceId
    });
  }

  return (
    <section className="row container-fluid">
      <div className="col-sm-4">
        <dl className="dl-horizontal">
          {leftColumnData.map((entity, i) => {
            return (
              <div key={entity.title + i}>
                <dt>{entity.title}:</dt>
                <dd>{entity.value}</dd>
              </div>
            );
          })}
        </dl>
      </div>
      <div className="col-sm-4">
        <dl className="dl-horizontal">
          {isShipper
            ? shipperRightColumn.map((entity, i) => {
                return (
                  <div key={entity.title + i}>
                    <dt>{entity.title}:</dt>
                    <dd>{entity.value}</dd>
                  </div>
                );
              })
            : warehouseRightColumn.map((entity, i) => {
                return (
                  <div key={entity.title + i}>
                    <dt>{entity.title}:</dt>
                    <dd>{entity.value}</dd>
                  </div>
                );
              })}
        </dl>
      </div>
    </section>
  );
}

function useLpn(inventoryService: InventoryService, lpnId: number) {
  const [lpn, setLpn] = useState<LpnResponse>(null);
  useEffect(() => {
    if (!lpnId) {
      return;
    }

    let isCancelled = false;
    inventoryService.getLpn({id: lpnId}).then(({data}) => {
      if (!isCancelled) {
        setLpn(data);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, [inventoryService, lpnId]);

  return lpn;
}

/**
 * Abstracts away the following awkwardness:
 *  - `lpn` will have a location in the case of a non-nested LPN
 *  - `lpn` will not have a location for nested LPNs and additional data fetching is required
 */
function useLpnLocation(lpn: LpnDetails, inventoryService: InventoryService, locationsService: LocationsService) {
  const [childLocation, setChildLocation] = useState<{id: number; label: string}>(null);

  const isParentLpn = !lpn.location.id; // Parents don't have location contents of their own so they won't have a location.
  const lpnId = lpn.lpnId;
  useEffect(() => {
    if (!lpnId || !isParentLpn) {
      return;
    }

    // Derive the parent's location from one of its children's contents.
    let isCancelled = false;
    (async () => {
      // Currently, parent LPNs have no location contents of their own. In that case, get one of the children
      // and use its location as the parent location.
      const children = (await inventoryService.getLpnChildren(lpnId, null)).data?.lpnDetails;
      if (isCancelled) {
        return;
      }
      if (!children?.length) {
        // This case shouldn't happen in production, but can happen with test data in other environments.
        // So just give up instead of throw.
        return;
      }

      const locationContents = (await inventoryService.getLocationContentsByLpnIds([children[0].id], null)).data
        ?.contents;
      if (isCancelled) {
        return;
      }
      if (!locationContents?.length) {
        throw new Error(`Could not get contents for LPN ${children[0].id}`);
      }

      const childLocations = (await locationsService.getLocationsV2([locationContents[0].locationId], lpn.reservation))
        .data?.locations;
      if (isCancelled) {
        return;
      }
      if (!childLocations?.length) {
        throw new Error(`Could not fetch location ${locationContents[0].locationId}`);
      }

      setChildLocation(childLocations[0]);
    })();

    return () => {
      isCancelled = true;
    };
  }, [inventoryService, isParentLpn, locationsService, lpn.reservation, lpnId]);

  return childLocation || lpn.location;
}

function calculateDaysInLocation(timeInLocation: string) {
  if (!timeInLocation) {
    return null;
  }
  const dateAsDate = new Date(timeInLocation);
  const now = new Date(Date.now());
  const diff = Math.abs(now.getTime() - dateAsDate.getTime());
  const daysInLocation = Math.ceil(diff / (1000 * 3600 * 24));
  let plural = true;
  if (daysInLocation === 1) {
    plural = false;
  }
  return `${daysInLocation} day${plural ? 's' : ''}`;
}
