import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Button,
  DetailedInfoBox,
  Expander,
  FileSelector,
  Heading,
  InfoBox,
  Link,
  Loader,
  Modal,
  Text
} from '@flexe/ui-components';
import CycleCountsService, {BulkCreateCycleCountParams} from '../../../shared/services/CycleCountsService';
import CsvValidator, {ParseError} from '../../../../libs/CsvValidator';
import ParsingErrors from './ParsingErrors';
import SampleDataTable from './SampleDataTable';
import {cycleCountBulkUploadColumnsSpec} from './BulkCycleCountCsvUploadInterfaces';

// We need to use require() to load Papa because otherwise, TypeScript doesn't allow Papa.LocalChunkSize to be changed.
/* eslint-disable-next-line */
const Papa = require('papaparse');
Papa.LocalChunkSize = 1024 * 1024; // 1 MB chunk size

interface Props {
  cycleCountsService: CycleCountsService;
  onModalToggle: (cycleCountsHaveChanged: boolean) => void;
  warehouseId: number;
  reservationId: number;
}

const MAX_PARSING_ERRORS_ALLOWED = 100;

const BulkCycleCountImportCSVModal = ({cycleCountsService, onModalToggle, warehouseId, reservationId}: Props) => {
  const [chosenFile, setChosenFile] = useState<File>();
  const [createdCycleCount, setCreatedCycleCount] = useState<number>(0);
  const [cycleCountsHaveChanged, setCycleCountsHaveChanged] = useState<boolean>(false);
  const [invalidRowsCsvBlob, setInvalidRowsCsvBlob] = useState<Blob>();
  const [isProcessingCsvFile, setIsProcessingCsvFile] = useState<boolean>(false);
  const [parseErrors, setParseErrors] = useState<ParseError[]>([]);
  const [showSampleData, setShowSampleData] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<any>();
  const [numberOfLocationsCount, setNumberOfLocationsCount] = useState<number>(null);
  const [referenceId, setReferenceId] = useState<string>('');
  const [modalErrors, setModalErrors] = useState<string[]>([]);
  const [approximation, setApproximation] = useState<number>(null);
  const [approximationText, setApproximationText] = useState<string>(null);
  const [numLocationsParsed, setNumLocationsParsed] = useState<number>(0);
  const resetErrorsAndUpdateCreatedCounts = () => {
    setParseErrors([]);
    setUploadError(null);
    setCreatedCycleCount(0);
  };

  useEffect(() => {
    if (numberOfLocationsCount && numberOfLocationsCount !== 0 && numLocationsParsed) {
      const approximationCalc = Math.ceil(numLocationsParsed / numberOfLocationsCount);
      if (approximationCalc > 40) {
        setApproximationText(null);
        setModalErrors([
          'You are attempting to create more unique cycle counts than our system allows (40). You can increase the number of location per count, or upload multiple CSVs.'
        ]);
        setApproximationText(`Approximately ${approximationCalc} cycle count(s) will be created.`);
      } else if (approximationCalc > 10) {
        setApproximation(approximationCalc);
        setApproximationText(`Approximately ${approximationCalc} cycle count(s) will be created.`);
        setModalErrors([]);
      } else {
        setApproximation(null);
        setApproximationText(null);
        setModalErrors([]);
      }
    }
    if (numberOfLocationsCount === 0 || numberOfLocationsCount == null) {
      setApproximationText(null);
      setApproximation(null);
      setModalErrors([]);
    }
  }, [numberOfLocationsCount, numLocationsParsed]);

  const parseCSVForApproximation = (file) => {
    const allChunkErrors = [];
    Papa.parse(file, {
      delimiter: ',',
      header: true,
      skipEmptyLines: 'greedy',
      chunk: (chunk, parser) => approximateChunk(allChunkErrors, chunk, parser),
      error: () => {
        setParseErrors([
          {
            // eslint-disable-next-line max-len
            message:
              'An error occurred while parsing the CSV. Please verify the file is correctly formatted, re-select it, and try again.'
          }
        ]);
        setIsProcessingCsvFile(false);
      }
    });
  };

  const approximateChunk = (allChunkErrors, chunk, parser) => {
    const chunkErrors = checkChunkForErrors(chunk);
    allChunkErrors.push(...chunkErrors);
    if (allChunkErrors.length > MAX_PARSING_ERRORS_ALLOWED) {
      parser.abort();
    } else {
      const chunkResult = processApproximateChunk(chunk, chunkErrors);
    }
  };

  const processApproximateChunk = (chunk, chunkErrors) => {
    const cycleCountData = cycleCountsService.prepareCsvChunkForBulkCreateCycleCount(chunk, chunkErrors);

    if (cycleCountData.length === 0) {
      return null;
    }
    const locationLabels = [];
    const skus = [];
    cycleCountData.map((cycleCount) => {
      locationLabels.push(cycleCount.locationLabel);
      skus.push(cycleCount.sku);
    });

    setNumLocationsParsed(locationLabels.length);
  };

  const handleDropCsv = (acceptedFiles) => {
    if (acceptedFiles) {
      setChosenFile(acceptedFiles[0]);
      resetErrorsAndUpdateCreatedCounts();
      parseCSVForApproximation(acceptedFiles[0]);
    }
  };

  const handleFileInputChange = (event: Event) => {
    const file = (event.target as HTMLInputElement).files[0];

    if (file.name.match(/\.csv$/)) {
      setChosenFile(file);
      resetErrorsAndUpdateCreatedCounts();
      parseCSVForApproximation(file);
    }
  };

  const handleModalToggle = () => {
    if (!isProcessingCsvFile) {
      onModalToggle(cycleCountsHaveChanged);
    }
  };

  const handleSampleDataExpanderToggle = () => {
    setShowSampleData((isShown) => !isShown);
  };

  const handleSubmitButtonPress = () => {
    // reset errors / counts here in case user re-submits the same file
    resetErrorsAndUpdateCreatedCounts();
    setIsProcessingCsvFile(true);

    const chunkPromises = [];
    const allChunkErrors = [];
    Papa.parse(chosenFile, {
      delimiter: ',',
      header: true,
      skipEmptyLines: 'greedy',
      chunk: (chunk, parser) => processChunk(chunkPromises, allChunkErrors, chunk, parser),
      error: () => {
        setParseErrors([
          {
            // eslint-disable-next-line max-len
            message:
              'An error occurred while parsing the CSV. Please verify the file is correctly formatted, re-select it, and try again.'
          }
        ]);
        setIsProcessingCsvFile(false);
      },
      complete: () => onParseCompleteAsync(chunkPromises, allChunkErrors)
    });
  };

  const processChunk = (chunkPromises, allChunkErrors, chunk, parser) => {
    const chunkErrors = checkChunkForErrors(chunk);
    allChunkErrors.push(...chunkErrors);
    if (allChunkErrors.length > MAX_PARSING_ERRORS_ALLOWED) {
      parser.abort();
    } else {
      const chunkResult = uploadChunk(chunk, chunkErrors);

      if (chunkResult !== null) {
        chunkPromises.push(chunkResult);
      }
    }
  };

  const checkChunkForErrors = (chunk) => {
    // Check for invalid data
    const csvValidator = new CsvValidator();
    const [formatErrors] = csvValidator.validate(chunk, cycleCountBulkUploadColumnsSpec);

    // Map the Papa Parse errors to our ParseError format so we can create a downloadable csv file later
    const papaParseErrors = chunk.errors.map((ppError) => {
      return {
        rowData: chunk.data.find((chunkRow, idx) => idx === ppError.row),
        // Papa Parse error rows are 0-based and don't include the header, so we need to add 2 to them
        // so they match the error rows CsvValidator outputs above
        row: ppError.row + 2,
        message: ppError.message
      };
    });
    return papaParseErrors.concat(formatErrors);
  };

  const uploadChunk = (chunk, chunkErrors) => {
    const cycleCountData = cycleCountsService.prepareCsvChunkForBulkCreateCycleCount(chunk, chunkErrors);

    if (cycleCountData.length === 0) {
      return null;
    }
    const locationLabels = [];
    const skus = [];
    cycleCountData.map((cycleCount) => {
      locationLabels.push(cycleCount.locationLabel);
      skus.push(cycleCount.sku);
    });
    const bulkCreateCycleCountParams: BulkCreateCycleCountParams = {
      warehouseId,
      locationIds: [],
      locationCategories: cycleCountData.locationCategories,
      numberOfCounts: -1,
      referenceId,
      reservationId,
      skus,
      numberOfLocPerCount: numberOfLocationsCount,
      locationLabels
    };
    return cycleCountsService.bulkCreateLocationCounts(bulkCreateCycleCountParams);
  };

  const onParseCompleteAsync = async (chunkPromises, allChunkErrors) => {
    setParseErrors(allChunkErrors);
    const invalidRowsCsvStr = cycleCountsService.createInvalidRowsCsv(allChunkErrors);
    if (invalidRowsCsvStr) {
      const newInvalidRowsCsvBlob = new Blob([invalidRowsCsvStr], {type: 'text/csv'});
      setInvalidRowsCsvBlob(newInvalidRowsCsvBlob);
    }
    try {
      let createdCCLabelsTally = 0;
      const handledChunkPromises = chunkPromises.map((promise) => {
        return promise.then((responseData) => {
          const {data, errors} = responseData;
          createdCCLabelsTally += data.length;
          if (errors.length > 0) {
            throw errors;
          }
        });
      });
      await Promise.all(handledChunkPromises);
      setCreatedCycleCount(createdCCLabelsTally);
      setCycleCountsHaveChanged(createdCCLabelsTally > 0);
    } catch (error) {
      setUploadError(error);
    } finally {
      setIsProcessingCsvFile(false);
    }
  };
  const handleNumberOfCountsChange = (event) => {
    const inputNumber = Number(event.target.value);
    if (inputNumber >= 0) {
      setNumberOfLocationsCount(inputNumber);
    }
  };
  const handleReferenceIdChange = (event) => {
    const inputString = event.target.value;
    if (inputString.length <= 50) {
      setReferenceId(inputString);
    }
  };

  const csvTemplateHref = '/static/files/CycleCountBulkCreateImport.csv';

  return (
    <Modal
      isOpen
      size="large"
      subtitle={
        <div className="bulk-cycle-count-csv-modal__subtitle">
          <Text tag="p" level="Body1" bold={false}>
            Upload a CSV file to create Cycle Counts. Make sure you are using the current{' '}
            <Link href={csvTemplateHref}>CSV template</Link>. To learn more about this process visit our{' '}
            <Link
              href="https://flexesupport.zendesk.com/hc/en-us/sections/9929711857172-Cycle-Counting-Process"
              target="_blank"
            >
              Help Page
            </Link>
            .
          </Text>
        </div>
      }
      title="Create Cycle Count via CSV"
      toggleModal={handleModalToggle}
    >
      {modalErrors.map((error, idx) => (
        <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
          {error}
        </div>
      ))}
      {isProcessingCsvFile ? (
        <>
          <Heading level="h3">Please wait, your cycle counts are being processed.</Heading>
          <Loader loading={true} />
        </>
      ) : (
        <>
          <div className="bulk-cycle-count-csv-modal__file-selector">
            <FileSelector
              dropzoneProps={{
                onAcceptedFiles: () => {
                  return;
                },
                onDropzoneError: () => {
                  return;
                },
                onFileDrop: handleDropCsv,
                onRejectedFiles: () => {
                  return;
                },
                acceptedFileTypes: {'text/csv': []}
              }}
              fileInputAccept=".csv"
              selectedFile={chosenFile && chosenFile.name}
              fileInputOnChange={handleFileInputChange}
              buttonVisualType="primary"
            />
          </div>
          <div style={{display: 'flex'}}>
            <div className="col-sm-6 bulk-cycle-count-csv-modal__button-margin">
              <label className="form-label" htmlFor="numberOfLocationsCountInput">
                {'Number Of Locations Per Count to Create'}
              </label>
              <input
                aria-label="numberOfLocationsCount"
                name="numberOfLocationsCount"
                type="text"
                onChange={handleNumberOfCountsChange}
                value={numberOfLocationsCount || ''}
                placeholder=""
                style={{marginBottom: '8px'}}
              />
              {approximation && approximationText && (
                <span className="diff red2 italics" style={{marginTop: 8}}>
                  {approximationText}
                </span>
              )}
            </div>
            <div className="col-sm-6 bulk-cycle-count-csv-modal__button-margin">
              <label className="form-label" htmlFor="referenceIdInput">
                {'Reference ID'}
              </label>
              <input
                aria-label="referenceId"
                name="referenceId"
                className={'form-control short'}
                type="text"
                onChange={handleReferenceIdChange}
                value={referenceId}
                placeholder=""
              />
            </div>
          </div>
          <div className="bulk-cycle-count-csv-modal__submit-button">
            <Button
              isDisabled={
                !chosenFile || !numberOfLocationsCount || referenceId.trim().length < 1 || modalErrors.length > 0
              }
              onPress={handleSubmitButtonPress}
              visualType="primary"
            >
              Submit
            </Button>
          </div>
          {!uploadError && (
            <ParsingErrors
              invalidRowsCsvBlob={invalidRowsCsvBlob}
              maxParsingErrorsAllowed={MAX_PARSING_ERRORS_ALLOWED}
              parseErrors={parseErrors}
            />
          )}
          {uploadError && (
            <div className="bulk-cycle-count-csv-modal__results">
              <DetailedInfoBox info={uploadError.message ? uploadError.message : uploadError[0].title} infoType="error">
                <ul className="bulk-cycle-count-csv-modal__error-list">
                  {uploadError.response
                    ? uploadError.response.data.errors.map((error) => <li key={error.id}>{error.detail}</li>)
                    : uploadError.map((error) => <li key={error.id}>{error.detail}</li>)}
                </ul>
              </DetailedInfoBox>
            </div>
          )}
          {createdCycleCount > 0 && (
            <div className="bulk-cycle-count-csv-modal__results">
              <InfoBox
                info={`${createdCycleCount} New Cycle Count${createdCycleCount > 1 ? 's' : ''} Created Successfully`}
                infoType="success"
              />
            </div>
          )}
          <Expander
            isOpen={showSampleData}
            onToggle={handleSampleDataExpanderToggle}
            title="Creating Cycle Counts with CSV"
            maxHeight={1500}
          >
            <div className="bulk-cycle-count-csv-modal__sample-data-table">
              <SampleDataTable />
            </div>
          </Expander>
        </>
      )}
    </Modal>
  );
};

export default BulkCycleCountImportCSVModal;
