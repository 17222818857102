import * as React from 'react';
import {format as formatDate} from 'date-fns';
import {WarehouseTask, WarehouseTaskStepStatus} from './WarehouseTasksInterfaces';
import WarehouseTaskStepsTable from './WarehouseTaskStepsTable';

interface WarehouseTaskRowProps {
  warehouseTask: WarehouseTask;
  selectCallback: (taskId) => void;
  selected: boolean;
}

interface WarehouseTaskRowState {
  showSteps: boolean;
}

/**
 * Component for a entry in the warehouse tasks table
 */
class WarehouseTaskRow extends React.Component<WarehouseTaskRowProps, WarehouseTaskRowState> {
  constructor(props) {
    super(props);

    this.state = {
      showSteps: false
    };

    this.toggleShowMoves = this.toggleShowMoves.bind(this);
  }

  public render() {
    const chevron = this.state.showSteps ? (
      <span className="fa fa-chevron-up text-info"></span>
    ) : (
      <span className="fa fa-chevron-down"></span>
    );
    const whTask = this.props.warehouseTask;
    const selected = this.props.selected;

    const toggleSelection = (_) => {
      this.props.selectCallback(whTask.id);
    };

    const visibleSteps = whTask.steps.filter((step) => step.status !== WarehouseTaskStepStatus.VOIDED);
    const stepsTable = this.state.showSteps ? (
      <tr>
        <td colSpan={9}>
          {whTask.steps.length > 0 ? <WarehouseTaskStepsTable taskStepsToDisplay={visibleSteps} /> : this.emptyTable()}
        </td>
      </tr>
    ) : null;

    const batchDetailsUrl = whTask.isWarehouseSfsEnabled
      ? `/wh/batches/${whTask.batchId}`
      : `/wh/fulfillment/batches/${whTask.batchId}`;

    return (
      <>
        <tr key={`task-${whTask.id}`} onClick={this.toggleShowMoves}>
          <td>
            <input
              type="checkbox"
              checked={selected}
              onChange={toggleSelection}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </td>
          <td>{whTask.id}</td>
          {
            <td>
              {whTask.batchId ? (
                <a href={batchDetailsUrl} target="_blank" rel="noreferrer">
                  Batch {whTask.batchId}
                </a>
              ) : (
                '--'
              )}
            </td>
          }
          <td>{whTask.taskType}</td>
          <td>{whTask.assignedToUserName}</td>
          <td>{whTask.executingDeviceId}</td>
          <td>{whTask.priority}</td>
          <td>{whTask.startedAt}</td>
          <td>
            <time>{formatDate(whTask.updatedAt, 'M/D/YY hh:mm a')}</time>
          </td>
          <td>{whTask.status}</td>
          <td>{whTask.id && chevron}</td>
        </tr>
        {stepsTable}
      </>
    );
  }

  private emptyTable() {
    return <p>There are no steps for this task.</p>;
  }

  private toggleShowMoves() {
    const newShowMoves = !this.state.showSteps;
    this.setState({
      showSteps: newShowMoves
    });
  }
}

export default WarehouseTaskRow;
