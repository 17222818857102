import * as React from 'react';
import {useState} from 'react';
import {Loader} from '@flexe/ui-components';
import {Shipment} from '../outbound-shipments/ShipmentInterfaces';
import {WarningCard} from '../outbound-shipments/shared/WarningCard';
import DropDown, {DropDownOption, DropDownStyle, DropDownType} from '../../shared/DropDown';
import {ErrorBoundaryContext} from '../../shared/utilities/ErrorBoundaryContext';
import OutboundShipmentService from '../../shared/services/OutboundShipmentService';

export interface ShipOnTimeReasonCodeErrorProps {
  shipments: Shipment[];
  authenticityToken: string;
  setMissedSlaReasonCode: (value: boolean) => void;
}

export const ShipOnTimeReasonCodeError: React.FC<ShipOnTimeReasonCodeErrorProps> = ({
  shipments,
  authenticityToken,
  setMissedSlaReasonCode,
  children
}) => {
  const [selectedReasonCode, setSelectedReasonCode] = useState<DropDownOption>(null);
  const [saveReasonCodeLoading, setSaveReasonCodeLoading] = useState<boolean>(false);

  const outboundShipmentsService = new OutboundShipmentService(authenticityToken);

  const setReasonCode = async (code: DropDownOption) => {
    setSaveReasonCodeLoading(true);
    const response = await outboundShipmentsService.setMissedSlaReasonCode(
      shipments.map((s) => s.id),
      code.value as string
    );
    if (response?.errors && response?.errors.length > 0) {
      setSaveReasonCodeLoading(false);
      setSelectedReasonCode(null);
      throw new Error(response.errors[0].detail);
    }
    setSaveReasonCodeLoading(false);
    setSelectedReasonCode(code);
  };

  const {setErrors} = React.useContext(ErrorBoundaryContext);

  const reasonCodes: DropDownOption[] = [
    {
      name: 'Carrier Delay',
      value: 'carrier_delay'
    },
    {
      name: 'Shipper Interruption',
      value: 'shipper_interruption'
    },
    {
      name: 'Warehouse Constraint',
      value: 'warehouse_constraint'
    },
    {
      name: 'Technical Delay',
      value: 'technical_delay'
    }
  ];

  const onSelectReasonCode = async (code: DropDownOption) => {
    try {
      await setReasonCode(code);
    } catch (e) {
      if (e instanceof Error) {
        setErrors(e);
      }
      if (e instanceof String) {
        setErrors(new Error(e.toString()));
      }
      setErrors(new Error('Error setting Missed SLA reason code.'));
    }
    // always set this to true so we unblock the ship button if they tried to set it
    setMissedSlaReasonCode(true);
  };

  return (
    <div id={'capture-ship-on-time'}>
      <WarningCard>
        {children}
        <br />
        {saveReasonCodeLoading && (
          <div style={{width: '20px'}}>
            <Loader loading={saveReasonCodeLoading} />
          </div>
        )}
        {!saveReasonCodeLoading && (
          <DropDown
            options={reasonCodes}
            selected={selectedReasonCode}
            style={DropDownStyle.default}
            type={DropDownType.secondary}
            prefixText={'Reason: '}
            onSelect={onSelectReasonCode}
          />
        )}
      </WarningCard>
    </div>
  );
};
