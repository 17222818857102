import * as React from 'react';
import {useContext} from 'react';
import StatusDot from '../../../shared/StatusDot';
import {Shipment} from '../../outbound-shipments/ShipmentInterfaces';
import {getShipmentDotStatusAndText} from '../WaveShared';
import {WaveDetailsContext} from './WaveDetailsContext';

// eslint-disable-next-line @typescript-eslint/ban-types, no-empty-pattern
const ContentShipmentsTable: React.FC<{}> = ({}) => {
  const {shipments} = useContext(WaveDetailsContext);

  function getShipmentTable(curShipment: Shipment) {
    let expectedEa = 0;
    const expectedSkus: string[] = [];

    for (const lineItem of curShipment.line_items) {
      expectedEa += lineItem.units_per_packaging?.each ?? lineItem.quantity;

      if (!expectedSkus.includes(lineItem.sku)) {
        expectedSkus.push(lineItem.sku);
      }
    }

    const expectedSkuCount = expectedSkus.length;
    return {expectedEa, expectedSkuCount};
  }

  function renderLink(href: string, text: any) {
    return text ? <a href={href}>{text}</a> : <span>--</span>;
  }

  function renderShipmentsRow(curShipment: Shipment) {
    const {expectedEa, expectedSkuCount} = getShipmentTable(curShipment);
    const shipmentState = getShipmentDotStatusAndText(curShipment.status);

    return (
      <tr key={`wave-details-content-shipments-row-${curShipment.id}`}>
        <td>{renderLink(`/wh/shipments/${curShipment.id}`, curShipment.id)}</td>
        <td>{curShipment.order_id}</td>
        <td>
          <div className="info-pair">
            <span className="title">
              <StatusDot status={shipmentState.dotStatus} />
              {shipmentState.text}
            </span>
          </div>
        </td>
        <td>{curShipment.purchase_order}</td>
        <td>
          <div className="info-pair">
            <span className="title">{expectedEa} ea</span>
            <span className="light-text underline">{expectedSkuCount} SKUs</span>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <table className="table contents-table">
      <thead>
        <tr>
          <th>Shipment ID</th>
          <th>Order ID</th>
          <th>Status</th>
          <th>P.O. #</th>
          <th>Expected</th>
        </tr>
      </thead>
      <tbody>{shipments.sort((a, b) => a.id - b.id).map((curShipment) => renderShipmentsRow(curShipment))}</tbody>
    </table>
  );
};

export default ContentShipmentsTable;
