import * as React from 'react';
import MultiStatusProgressBar from '../shared/MultiStatusProgressBar';
import {RouteDetails} from '../shared/services/RoutePlanningService';

interface RouteCardProps {
  routeDetails: RouteDetails;
}

const RouteCard: React.FC<RouteCardProps> = (props) => {
  return (
    <div className={'route-card'}>
      <div className={'route-id'}>{props.routeDetails.routeId}</div>
      <div className={'route-progress-bar'}>
        <MultiStatusProgressBar
          total={props.routeDetails.lpnCount}
          inProgress={props.routeDetails.sortedCount}
          completed={props.routeDetails.manifestedCount}
        />
      </div>
      <div className={'route-progress-text'}>
        {props.routeDetails.manifestedCount === props.routeDetails.lpnCount ? (
          <span>
            {props.routeDetails.manifestedCount} of {props.routeDetails.lpnCount} Loaded
          </span>
        ) : (
          <span>
            {props.routeDetails.sortedCount} of {props.routeDetails.lpnCount} Sorted
          </span>
        )}
        <span className={'route-progress-percent'}>
          ({Math.round((props.routeDetails.sortedCount / props.routeDetails.lpnCount) * 100)}%)
        </span>
        {props.routeDetails.manifestedCount > 0 &&
          props.routeDetails.manifestedCount !== props.routeDetails.lpnCount && (
            <span>{props.routeDetails.manifestedCount} Loaded</span>
          )}
      </div>
    </div>
  );
};

export default RouteCard;
