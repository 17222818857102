// Deps
import * as React from 'react';
import {useEffect, useState} from 'react';
import {chunk, cloneDeep, isEqual, uniq} from 'lodash';
import {Loader} from '@flexe/ui-components';
import {usePrevious, valueIsNotUndefined} from '../../../shared/ReactHelpers';

// Services
import BatchWavingService from '../../../shared/services/BatchWavingService';
import OutboundShipmentService from '../../../shared/services/OutboundShipmentService';
import WarehouseSelector from '../../../shared/WarehouseSelector';
import WarehouseService from '../../../shared/services/WarehouseService';
import PickConsolidationService from '../../../shared/services/PickConsolidationService';

// Interfaces
import {Filter, FilterOption, FilterType, Reservation, SortOrder, Warehouse} from '../../../shared/CommonInterfaces';
import * as BatchWaving from '../../../warehouse/ecommerce-batches/BatchInterfaces';
import {
  FilterKeys,
  FilterParams,
  GetShipmentsParams,
  Location,
  ReservationDisplayInfo,
  ReservationDisplayInfos,
  SelectedShipment,
  Shipment,
  SortByColumns
} from '../ShipmentInterfaces';

// Components
import SelectorWithCheckbox from '../../../shared/SelectorWithCheckbox';
import WavingModal, {OnWaveClickOptions} from '../waving/WavingModal';
import WavingSuccessModal from '../waving/WavingSuccessModal';
import LpnService from '../../../shared/services/LpnService';
import {LpnSearchDetails, LpnSearchRequest} from '../../../lpns/LpnsInterfaces';
import CheckboxDropdown from '../../../shared/CheckboxDropdown';
import AlertWavingIssues from '../../batch-waving/AlertWavingIssues';
import {FreightShipMode, ShipMode} from '../../../shared/constants';
import WavingControls from '../waving/WavingControls';
import LpnContentsModal from './LpnContentsModal';
import SortBy from './SortBy';
import {ColumnNames, SortState} from './ShipmentTableHeader';
import ShipmentTable from './ShipmentTable';
import ShipmentFilter from './ShipmentFilter';
import ShipBy from './ShipBy';
import PaginationButtons from './PaginationButtons';
import ModeFilter from './ModeFilter';

interface SelectedStatusFilters {
  [key: string]: boolean;
}

interface SimpleShipmentStatusMap {
  [key: string]: string;
}

interface ShipmentIndexHistoryState {
  mode: ShipMode | null;
}

interface ShipmentIndexProps {
  authenticityToken: string;
  currentWarehouse: Warehouse;
  availableWarehouses: Warehouse[];
  isFreightTrailerLoadingEnabled: boolean;
  isFreightWorkflowEnabled: boolean;
  isLpnEnabled: boolean;
  isDistributionByLotEnabled: boolean;
  trailerLoadingEnabled: boolean;
  reservationDisplayInfos: ReservationDisplayInfos;
  enableQueryParameters: boolean;
  mode: ShipMode | null;
}

const FF_FILTER_STAGING_LOCATIONS = 'filter-staging-locations-dropdown';
const FF_FREIGHT_LOAD_GROUP_UI = 'oms-freight-load-group-ui';

const getStatusFilters = (statusFilters: SelectedStatusFilters): string[] =>
  Object.entries(statusFilters)
    .filter(([filterName, filterValue]) => filterName !== 'all' && filterValue)
    .map(([filterName, _]) => filterName);

function ShipmentIndex(props: ShipmentIndexProps) {
  const {isFreightTrailerLoadingEnabled, reservationDisplayInfos, enableQueryParameters, mode: initialMode} = props;
  const batchWavingService = new BatchWavingService(props.authenticityToken);
  const outboundShipmentService = new OutboundShipmentService(props.authenticityToken);
  const warehouseService = new WarehouseService(props.authenticityToken);
  const pickConsolidationService = new PickConsolidationService(props.authenticityToken);
  const lpnService = new LpnService(props.authenticityToken);

  const ALL_RESERVATIONS_ID_VALUE = -1;
  const PAGE_SIZE = 50;

  const NO_SELECTED_STATUS_FILTERS = {
    all: false,
    new: false,
    confirmed: false,
    completed: false,
    cancelled: false,
    attention_required: false,
    voided: false,
    ...(props.trailerLoadingEnabled ? {packed: false} : {})
  };

  const DEFAULT_SELECTED_STATUS_FILTERS = {
    all: false,
    new: true,
    confirmed: true,
    completed: false,
    cancelled: false,
    attention_required: false,
    voided: false,
    ...(props.trailerLoadingEnabled ? {packed: false} : {})
  };

  const ALL_SELECTED_STATUS_FILTERS = {
    all: true,
    new: true,
    confirmed: true,
    completed: true,
    cancelled: true,
    attention_required: true,
    voided: true,
    ...(props.trailerLoadingEnabled ? {packed: true} : {})
  };

  const SIMPLE_SHIPMENT_STATUS_MAP: SimpleShipmentStatusMap = {
    new: 'Not Started',
    confirmed: 'In Progress',
    completed: 'Shipped',
    cancelled: 'Cancelled',
    attention_required: 'Attention Required',
    voided: 'Voided',
    ...(props.trailerLoadingEnabled ? {packed: 'Packed'} : {})
  };

  // TODO: THESE WILL NEED TO CHANGE WHEN NEW STATUS MAP GETS IMPLEMENTED
  // grey: not started --> new
  // purple: in progress --> confirmed
  // green: shipped --> completed
  // red: cancelled --> cancelled
  // red: attention required --> attention_required
  // red: voided --> voided
  // use context-specific states as keys, status-dot specific states as values
  const STATUS_TO_DOT_STYLE_MAP = {
    new: 'neutral',
    confirmed: 'support',
    completed: 'valid',
    cancelled: 'invalid',
    attention_required: 'invalid',
    voided: 'invalid',
    ...(props.trailerLoadingEnabled ? {packed: 'inform'} : {})
  };

  const [errors, setErrors] = useState<string[]>([]);
  const [reloadShipments, setReloadShipments] = useState(false);
  const [continuationTokens, setContinuationTokens] = useState({1: null});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterParams, setFilterParams] = useState<FilterParams>({});
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState<ShipMode | null>(initialMode || null);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [selectedShipments, setSelectedShipments] = useState<SelectedShipment[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>(props.currentWarehouse);
  const [shipByEnd, setShipByEnd] = useState<Date>(null);
  const [shipByStart, setShipByStart] = useState<Date>(null);
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [shipmentsCount, setShipmentsCount] = useState<number>(0);
  const [showWavingModal, setShowWavingModal] = useState(false);
  const [showWavingSuccessModal, setShowWavingSuccessModal] = useState(false);
  const [sortByColumn, setSortByColumn] = useState<SortByColumns>(SortByColumns.ShipBy);
  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.ASC);
  const [statusFilters, setStatusFilters] = useState<string[]>(getStatusFilters(DEFAULT_SELECTED_STATUS_FILTERS));
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<SelectedStatusFilters>(
    DEFAULT_SELECTED_STATUS_FILTERS
  );
  const [reservationId, setReservationId] = useState<number>(ALL_RESERVATIONS_ID_VALUE);
  const [lpns, setLpns] = useState<LpnSearchDetails[]>(null);
  const [showLpnContentsModal, setShowLpnContentsModal] = useState(false);
  const [shipmentForLPN, setShipmentForLPN] = useState<Shipment>(null);
  const [filterStagingLocations, setFilterStagingLocations] = useState(false);
  const [isFreightLoadGroupUIEnabled, setIsFreightLoadGroupUIEnabled] = useState(false);
  const [reloadAlerts, setReloadAlerts] = useState(false);
  const [allowedFilterKeys, setAllowedFilterKeys] = useState<FilterKeys[]>([]);

  const prevWh = usePrevious(selectedWarehouse);

  useEffect(() => {
    setReservationId(ALL_RESERVATIONS_ID_VALUE);
    getReservations();
    loadFilterStagingLocationsFlag();
    if (valueIsNotUndefined(prevWh)) {
      setReloadShipments(!reloadShipments);
    }
    loadFreightLoadGroupUIEnabledFlag();
    getFilterKeys();
  }, [selectedWarehouse]);

  useEffect(() => {
    getFilterKeys();
  }, [isFreightLoadGroupUIEnabled]);

  const loadFilterStagingLocationsFlag = async () => {
    const response = await warehouseService.getFeatureFlag(FF_FILTER_STAGING_LOCATIONS, selectedWarehouse.id);
    if (response.errors && response.errors.length > 0) {
      setFilterStagingLocations(false);
    } else {
      setFilterStagingLocations(!!response.data.value);
    }
  };

  const loadFreightLoadGroupUIEnabledFlag = async () => {
    setIsFreightLoadGroupUIEnabled(await fetchLoadGroupUiFf());
  };

  const prevReloadShipments = usePrevious(reloadShipments);
  useEffect(() => {
    if (valueIsNotUndefined(prevReloadShipments)) {
      getShipments();
    }
  }, [reloadShipments]);

  const prevPage = usePrevious(currentPage);
  useEffect(() => {
    if (valueIsNotUndefined(prevPage)) {
      setReloadShipments(!reloadShipments);
    }
  }, [currentPage]);

  // resets page to 1, clears selections upon altering filters.
  useEffect(() => {
    resetShipmentIndexView();
  }, [reservationId, filterParams, sortByColumn, sortDirection, reservationId, shipByEnd, shipByStart, statusFilters]);

  useEffect(() => {
    if (showLpnContentsModal) {
      getLpns([shipmentForLPN]);
    } else {
      setLpns(null);
    }
  }, [showLpnContentsModal]);

  const resetShipmentIndexView = () => {
    setCurrentPage(1);
    setSelectedShipments([]);
    setContinuationTokens({1: null});
    setReloadShipments(!reloadShipments);
  };

  /* For use with the new query parameter pattern (SHIPMENT-5724).
     Reset the view as a result of fetching Shipments instead of the
     other way around as it currently does. */
  const resetShipmentIndexViewWithoutReloading = () => {
    setCurrentPage(1);
    setSelectedShipments([]);
    setContinuationTokens({1: null});
  };

  const getShipments = async () => {
    setIsLoading(true);

    const token = continuationTokens[currentPage];
    const params: GetShipmentsParams = {
      ...filterParams,
      continuation_token: token,
      mode,
      ship_by_end: shipByEnd,
      ship_by_start: shipByStart,
      sort_by: sortByColumn,
      sort_direction: sortDirection,
      status: statusFilters,
      warehouse_id: selectedWarehouse.id
    };

    if (reservationId !== ALL_RESERVATIONS_ID_VALUE) {
      params.reservation_id = reservationId;
    }

    try {
      const response = await outboundShipmentService.getShipmentsDeprecated(params);

      setErrors(response.errors.map((err) => err.detail));

      if (response.data === undefined || response.data.shipments === undefined) {
        setShipments([]);
        setShipmentsCount(0);
      } else {
        setShipments(response.data.shipments);
        const newContinuationTokens = {...continuationTokens};
        newContinuationTokens[currentPage + 1] = response.data.continuation_token;
        setContinuationTokens(newContinuationTokens);
        setShipmentsCount(response.data.counts);
      }
    } catch (error) {
      setErrors([error.toString()]);
      setShipments([]);
      setShipmentsCount(0);
    } finally {
      setIsLoading(false);
    }
  };

  /* For use with the new query parameter pattern (SHIPMENT-5724).
     Allows callers to specify arguments that haven't been set in state yet and
     eliminates reliance on useEffect to drive Shipment fetching */
  const getShipmentsAsync = async (overrides: Partial<GetShipmentsParams> = {}) => {
    setIsLoading(true);

    const params: GetShipmentsParams = {
      ...filterParams,
      continuation_token: continuationTokens[currentPage],
      mode,
      ship_by_end: shipByEnd,
      ship_by_start: shipByStart,
      sort_by: sortByColumn,
      sort_direction: sortDirection,
      status: statusFilters,
      warehouse_id: selectedWarehouse.id,
      ...(reservationId !== ALL_RESERVATIONS_ID_VALUE ? {reservation_id: reservationId} : {}),
      ...overrides
    };

    const response = await outboundShipmentService.getShipmentsDeprecated(params);

    if (response.errors.length) {
      setShipments([]);
      setShipmentsCount(0);
      setErrors(response.errors.map((e) => `${e.title}: ${e.detail}`));
    } else {
      const newContinuationTokens = {...continuationTokens};
      newContinuationTokens[currentPage + 1] = response.data.continuation_token;
      setContinuationTokens(newContinuationTokens);

      setShipments(response.data.shipments);
      setShipmentsCount(response.data.counts);
      setErrors([]);
    }

    resetShipmentIndexViewWithoutReloading();
    setIsLoading(false);
  };

  const getLpns = async (currentShipments: Shipment[]) => {
    const shipmentIds: number[] = [];
    if (currentShipments.length === 0) {
      return;
    }

    currentShipments.forEach((shipment) => {
      shipmentIds.push(shipment.id);
    });
    const lpnSearchRequest: LpnSearchRequest = {
      ReservationIds: [],
      ShipmentIds: shipmentIds,
      LpnBarcodes: [],
      Skus: []
    };
    const lpnSearchResponse = await lpnService.searchLpns(lpnSearchRequest);
    setLpns(lpnSearchResponse.data.lpns);
  };

  const handleWavingSuccessModalClose = () => {
    setShowWavingSuccessModal(false);
  };

  const getFilterKeys = async () => {
    const keys = [
      FilterKeys.ShipmentId,
      FilterKeys.PurchaseOrder,
      FilterKeys.Scac,
      FilterKeys.Bol,
      FilterKeys.ProNumber,
      FilterKeys.Lpn,
      FilterKeys.Sku,
      ...(props.isDistributionByLotEnabled ? [FilterKeys.LotCode, FilterKeys.ExpirationDate] : []),
      FilterKeys.OrderReferenceId,
      FilterKeys.DestinationTag,
      FilterKeys.CustomerReferenceNumber
    ];

    if (props.isFreightTrailerLoadingEnabled || props.trailerLoadingEnabled) {
      keys.push(FilterKeys.LoadId);
    }

    if (props.isFreightTrailerLoadingEnabled && isFreightLoadGroupUIEnabled) {
      keys.push(FilterKeys.FreightLoadGroup);
    }
    setAllowedFilterKeys(keys);
  };

  async function fetchLoadGroupUiFf() {
    const response = await warehouseService.getFeatureFlag(FF_FREIGHT_LOAD_GROUP_UI, selectedWarehouse.id);
    if (response.errors && response.errors.length > 0) {
      return false;
    } else {
      return !!response.data.value;
    }
  }

  async function callWaveByShipmentIds(shipmentIds: number[], batchCount: number, newStagingLocation: Location | null) {
    const stagingLocationId = newStagingLocation?.id;
    const batchParams: BatchWaving.WaveByShipmentIdsParams = {
      reservationId: selectedReservationId(),
      shipmentIds,
      ...(batchCount && {batchCount}),
      ...(stagingLocationId && {stagingLocationId})
    };

    const response = await batchWavingService.waveByShipmentIds(batchParams);
    setReloadAlerts(!reloadAlerts);

    if (response.errors.length > 0) {
      setErrors(response.errors.map((err) => err.detail));
      return false;
    } else {
      const failedShipments = response.data.result.failedShipments;

      if (failedShipments && failedShipments.length > 0) {
        const plural = failedShipments.length > 1;
        const shipmentErrors = [
          `Shipment ID${plural ? 's' : ''} ${failedShipments.join(', ')} ${plural ? 'are' : 'is'} not ready to wave. ` +
            'Please make sure they have the required labels and inventory and try again.'
        ];
        setErrors(shipmentErrors);
        return false;
      }
    }

    const wavingWarnings = response.data.result.warnings ?? [];
    if (wavingWarnings?.length > 0) {
      setErrors([...wavingWarnings]);
      return false;
    }

    return true;
  }

  const waveByShipmentIdsAsync = async ({stagingLocation, batchCount}: OnWaveClickOptions) => {
    const freightConsolidationEnabled = selectedShipments.some(
      (selectedShipment) => selectedShipment.isFreightConsolidationEnabled
    );
    const allShipmentsFreight = selectedShipments.every(
      (selectedShipment) => selectedShipment.shipMode === FreightShipMode
    );
    const shipmentIds = selectedShipmentIds();

    let waveRequestSucceeded = false;
    // non freight-consolidated freight waves are only one shipment per Wave, so they are safe to chunk
    if (!freightConsolidationEnabled && allShipmentsFreight) {
      const shipmentChunks = chunk(shipmentIds, 10);
      for (const shipmentChunk of shipmentChunks) {
        waveRequestSucceeded = await callWaveByShipmentIds(shipmentChunk, batchCount, stagingLocation);
        if (!waveRequestSucceeded) {
          break;
        }
      }
    } else {
      waveRequestSucceeded = await callWaveByShipmentIds(shipmentIds, batchCount, stagingLocation);
    }

    setShowWavingModal(false);

    if (waveRequestSucceeded) {
      setShowWavingSuccessModal(true);
      setReloadShipments(!reloadShipments);
      setSelectedShipments([]);
    }
  };

  const selectedReservationId = () => {
    return uniq(selectedShipments.map((selectedShipment) => selectedShipment.reservationId))[0];
  };

  const selectedShipmentIds = () => {
    return uniq(selectedShipments.map((selectedShipment) => selectedShipment.shipmentId));
  };

  const getReservations = async () => {
    const response = await warehouseService.getReservationsForWarehouse(selectedWarehouse.id);
    setReservations(response.data.reservations);
  };

  const reservationDropdown = () => {
    const options = reservations.map((reservation) => {
      return {
        name: reservation.name,
        value: `${reservation.id}`
      };
    });

    options.unshift({name: 'All', value: `${ALL_RESERVATIONS_ID_VALUE}`});

    const onSelect = (value: string) => {
      const selectedId = parseInt(value, 10);
      setReservationId(selectedId);
    };

    return (
      <SelectorWithCheckbox
        options={options}
        selected={[`${reservationId}`]}
        onSelect={onSelect}
        prefixText="Reservation: "
      />
    );
  };

  const updateFilterParams = (key: string, value: string) => {
    // Update filterParams if filterParams[key] is set or if there's a value to
    // set for filterParams[key]
    if (filterParams[key] || value) {
      const newFilterParams = {...filterParams};

      if (value) {
        newFilterParams[key] = value;
      } else {
        delete newFilterParams[key];
      }

      setFilterParams(newFilterParams);
    }
  };

  const errorDisplay = errors.map((error, idx) => {
    return (
      <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
        {error}
      </div>
    );
  });

  const updateSelectedStatus = (sel) => {
    const filterKeys = Object.keys(sel);

    if ('all' in sel) {
      const allSelected = sel.all;

      // This handles the "Select All" checkbox. i.e. if it's clicked and now checked,
      // everything is checked. If it's clicked and not check, nothing is checked.
      if (allSelected) {
        setSelectedStatusFilters(ALL_SELECTED_STATUS_FILTERS);
      } else {
        setSelectedStatusFilters(NO_SELECTED_STATUS_FILTERS);
      }
    } else {
      const copiedFilters = cloneDeep(selectedStatusFilters);
      filterKeys.forEach((filter) => {
        copiedFilters[filter] = sel[filter];
      });
      copiedFilters.all = false;
      setSelectedStatusFilters(copiedFilters);
    }
  };

  const applyMultiStatusFilter = () => {
    const newFilters = getStatusFilters(selectedStatusFilters);

    /* Check to make sure this new string[] doesn't have the same values as
       the current one. If it does it'll still trigger the useEffect that executes
       when statusFilter changes since React compares arrays by reference instead
       of their contents */
    if (isEqual(statusFilters, newFilters)) {
      return;
    }

    if (newFilters.length > 0) {
      setStatusFilters(newFilters);
    } else {
      setStatusFilters(null);
    }
  };

  const buildColumnsToHide = () => {
    // Default columns that will always be hidden. Generally the columns were created for
    // a different consumer of this control and should always be hidden here.
    const columnsToHide: ColumnNames[] = ['destination', 'remove'];

    const allReservationsAreConfiguredToNotShowLpns = Object.values(reservationDisplayInfos).every(
      (reservationDisplayInfo: ReservationDisplayInfo) => !reservationDisplayInfo.displayLpns
    );

    if (allReservationsAreConfiguredToNotShowLpns) {
      columnsToHide.push('lpns');
    }

    columnsToHide.push(props.isFreightTrailerLoadingEnabled ? 'orderId' : 'loadDetails', 'pro/track');

    return columnsToHide;
  };

  const handleWavingModalClose = () => {
    setShowWavingModal(false);
  };

  const handleModeChangeAsync = async (newMode: ShipMode) => {
    if (enableQueryParameters) {
      const url = new URL(window.location.href);

      if (newMode) {
        url.searchParams.set('mode', newMode);
      } else {
        url.searchParams.delete('mode');
      }

      pushHistoryState(url, {mode: newMode});
    }

    setMode(newMode);
    await getShipmentsAsync({mode: newMode});
  };

  const pushHistoryState = (url: URL, historyState: Partial<ShipmentIndexHistoryState> = {}) => {
    history.pushState({mode, ...historyState}, '', url);
  };

  useEffect(() => {
    /* This event triggers when the user clicks the browser back/forward
       buttons. If they were already on the ShipmentIndex page a fresh page
       load won't occur and instead this event will be triggered. */
    const handlePopstate = ({state}: PopStateEvent) => {
      const stateMode = state?.mode || null;
      setMode(stateMode);

      getShipmentsAsync({mode: stateMode});
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  return (
    <div className="container-fluid">
      <div id="shipment-list">
        <WavingModal
          show={showWavingModal}
          onClose={handleWavingModalClose}
          warehouseId={selectedWarehouse.id}
          selectedShipments={selectedShipments}
          onWaveClickAsync={waveByShipmentIdsAsync}
          pickConsolidationService={pickConsolidationService}
          shouldFilterStagingLocations={filterStagingLocations}
          isSfsEnabled={selectedWarehouse.isSfsEnabled}
          isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
        />
        <WavingSuccessModal
          show={showWavingSuccessModal}
          isSfsEnabled={selectedWarehouse.isSfsEnabled}
          onClose={handleWavingSuccessModalClose}
        />
        {shipmentForLPN && (
          <LpnContentsModal
            showModal={showLpnContentsModal}
            setShowModal={setShowLpnContentsModal}
            shipmentId={shipmentForLPN.id}
            lpns={lpns}
            shipmentStatus={shipmentForLPN && shipmentForLPN.status}
          />
        )}
        {isLoading && <Loader loading={isLoading} />}
        {errorDisplay}
        <div className="title-header" data-testid="shipment-filters">
          <div className="row">
            <div className="title-header-controls">
              <div className={'top-left-controls'}>
                <WarehouseSelector
                  selectedWarehouse={selectedWarehouse}
                  activeWarehouses={props.availableWarehouses}
                  onSelect={setSelectedWarehouse}
                />
              </div>
              <div className={'top-right-controls'}>
                <AlertWavingIssues
                  warehouseId={selectedWarehouse.id}
                  batchWavingService={batchWavingService}
                  reloadTrigger={reloadAlerts}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <h1>Shipments</h1>
          </div>
          <div className="header-filter-top-row">
            <ul className="list-inline">
              <li>{reservationDropdown()}</li>
              <li>
                <ShipBy onStartChange={setShipByStart} onEndChange={setShipByEnd} />
              </li>
            </ul>
          </div>
          <hr />
          <ShipmentFilter
            filterParams={filterParams}
            allowedFilterKeys={allowedFilterKeys}
            onFilterChange={updateFilterParams}
            outboundShipmentService={outboundShipmentService}
            warehouseId={selectedWarehouse.id}
          />
        </div>
        <div className="container-fluid">
          <div className="row space-below">
            {props.isFreightWorkflowEnabled && <ModeFilter mode={mode} onModeChange={handleModeChangeAsync} />}
            <CheckboxDropdown
              includeAllOption
              dotStyleMap={STATUS_TO_DOT_STYLE_MAP}
              optionToStatusMap={SIMPLE_SHIPMENT_STATUS_MAP}
              optionFilters={selectedStatusFilters}
              onStatusChange={updateSelectedStatus}
              applyFilters={applyMultiStatusFilter}
            />
          </div>

          <WavingControls
            shipments={shipments}
            selectedShipments={selectedShipments}
            setShowWavingModal={setShowWavingModal}
            setSelectedShipments={setSelectedShipments}
            isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
          />

          <div className="row vertical-align pull-right space-below">
            <SortBy onColumnChange={setSortByColumn} onDirectionChange={setSortDirection} />

            <PaginationButtons
              currentPage={currentPage}
              pageSize={PAGE_SIZE}
              setCurrentPage={setCurrentPage}
              shipmentsCount={shipmentsCount}
            />
          </div>

          <ShipmentTable
            shipments={shipments}
            selectedShipments={selectedShipments}
            setSelectedShipments={setSelectedShipments}
            disableSelectedShipments={false}
            isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
            isFreightWorkflowEnabled={props.isFreightWorkflowEnabled}
            isOmniEnabled={true}
            setShowLpnContentsModal={setShowLpnContentsModal}
            setShipmentForLPN={setShipmentForLPN}
            columnsToHide={buildColumnsToHide()}
            showCarrierUnderPro={false}
            enableSortableHeaders={false}
            reservationDisplayInfos={reservationDisplayInfos}
            onSortChanged={(state: SortState) => {
              return;
            }}
            onRemoveShipment={(shipment: Shipment) => {
              return;
            }}
            isFreightLoadGroupUIEnabled={isFreightLoadGroupUIEnabled}
          />
        </div>
      </div>
    </div>
  );
}
export default ShipmentIndex;
