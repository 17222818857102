import * as React from 'react';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {Loader} from '@flexe/ui-components';
import ShipmentStatusMap from '../ShipmentConstants';
import {Location, RetailVarianceConfig, Shipment, shipmentToSelectedShipment} from '../ShipmentInterfaces';
import PickConsolidationService from '../../../shared/services/PickConsolidationService';
import OutboundShipmentService from '../../../shared/services/OutboundShipmentService';
import DocumentsService from '../../../shared/services/DocumentsService';
import {usePrevious, valueIsNotUndefined} from '../../../shared/ReactHelpers';
import {ShipmentDocuments} from '../../../documents/ShipmentDocuments';
import LpnService from '../../../shared/services/LpnService';
import {CompanyType} from '../../../shared/CommonInterfaces';
import OutboundFreightLoadService from '../../../shared/services/OutboundFreightLoadService';
import FreightPackingListService from '../../../shared/services/FreightPackingListService';
import BatchWavingService from '../../../shared/services/BatchWavingService';
import {FreightShipMode} from '../../../shared/constants';
import {getBooleanFeatureFlag} from '../ShipmentHelper';
import {ShipmentRetailVariance, VarianceStatus} from '../ShipmentRetailVariance';
import WarehouseService from '../../../shared/services/WarehouseService';
import LocationsService from '../../../locations/LocationsService';
import {Location as LocationsLocation, LocationContentEntityType} from '../../../locations/LocationsInterfaces';
import OutboundManifestService from '../../../shared/services/OutboundManifestService';
import ManifestContentsService from '../../../shared/services/ManifestContentsService';
import {LpnSearchDetails} from '../../../lpns/LpnsInterfaces';
import WavingModal, {OnWaveClickOptions} from '../waving/WavingModal';
import {Wave} from '../../batch-waving/WaveInterfaces';
import {CustomKebabMenu, KebabMenuOption} from '../../../shared/CustomKebabMenu';
import ErrorBoundary from '../../../shared/utilities/ErrorBoundary';
import {SsccLabelRequirementsInfo} from '../../../documents/SsccInterfaces';
import {CompleteShipmentModal, ShipButtonText} from './CompleteShipmentModal';
import {Actions} from './Actions';
import {ShipmentContents} from './ShipmentContents';
import {ShipmentHeader} from './ShipmentHeader';
import {ShipmentDetailsContext} from './ShipmentDetailsContext';
import HeaderPane from './HeaderPane';
import {UnpackWorkflow, UnpackWorkflowState} from './unpack/UnpackWorkflow';
import {ShipmentProgress, ShipmentProgressFallback} from './shipment-progress/ShipmentProgress';

interface Props {
  authenticityToken: string;
  bypassPickupDetails: boolean;
  displayPrintLpnLabelsButton: boolean;
  enableGeneratePackingListAfterComplete: boolean;
  enableFreightPackingListRefresh: boolean;
  enableShipBlockIfNoLabelsModal: boolean;
  enableStickersForReservation?: boolean;
  isFreightTrailerLoadingEnabled: boolean;
  isOvershippingBlocked: boolean;
  recommendedPalletQuantity: number;
  shipmentId: number;
  showCurrentLocationEnabled: boolean;
  showLpnPanel: boolean;
  showManifestIdEnabled: boolean;
  viewOnlyPalletsLoaded?: boolean;
  warnShortShipForPackingList: boolean;
  isFreightLoadGroupUIEnabled: boolean;
  enablePrintCartonSsccByLpn: boolean;
}

// Shipment Documents are FKA required labels so that is why this FF is named the way.
const FF_FILTER_STAGING_LOCATIONS = 'filter-staging-locations-dropdown';

const ShipmentDetails: FC<Props> = ({
  authenticityToken,
  bypassPickupDetails,
  displayPrintLpnLabelsButton,
  enableGeneratePackingListAfterComplete,
  enableFreightPackingListRefresh,
  enableShipBlockIfNoLabelsModal,
  enableStickersForReservation,
  isFreightTrailerLoadingEnabled,
  isOvershippingBlocked,
  shipmentId,
  showCurrentLocationEnabled,
  showLpnPanel,
  showManifestIdEnabled,
  viewOnlyPalletsLoaded,
  warnShortShipForPackingList,
  isFreightLoadGroupUIEnabled,
  enablePrintCartonSsccByLpn
}) => {
  const outboundFreightLoadService = useMemo(() => new OutboundFreightLoadService(authenticityToken), [
    authenticityToken
  ]);
  const freightPackingListService = useMemo(() => new FreightPackingListService(authenticityToken), [
    authenticityToken
  ]);
  const outboundShipmentsService = useMemo(() => new OutboundShipmentService(authenticityToken), [authenticityToken]);
  const pickConsolidationService = useMemo(() => new PickConsolidationService(authenticityToken), [authenticityToken]);
  const documentsService = useMemo(() => new DocumentsService(CompanyType.warehouse), [authenticityToken]);
  const lpnService = useMemo(() => new LpnService(authenticityToken), [authenticityToken]);
  const batchWavingService = useMemo(() => new BatchWavingService(authenticityToken), [authenticityToken]);
  const warehouseService = useMemo(() => new WarehouseService(authenticityToken), [authenticityToken]);
  const locationService = useMemo(() => new LocationsService(authenticityToken), [authenticityToken]);

  const [shipmentLoaded, setShipmentLoaded] = useState<boolean>(false);
  const [shipment, setShipment] = useState<Shipment>(null);
  const [lpns, setLpns] = useState<LpnSearchDetails[]>([]);
  const [lpnsLoading, setLpnsLoading] = useState(true);
  const [overages, setOverages] = useState([]);
  const [lpnsInStagingLocation, setLpnsInStagingLocation] = useState<string[]>([]);
  const [looseGoodsInStagingLocation, setLooseGoodsInStagingLocation] = useState<any[]>([]);
  const [retailVarianceConfig, setRetailVarianceConfig] = useState<RetailVarianceConfig>(null);
  const [shipmentVariance, setShipmentVariance] = useState<ShipmentRetailVariance>();
  const [isVarianceAcknowledgmentRequired, setIsVarianceAcknowledgmentRequired] = useState<boolean>();
  const [shipmentStatusClass, setShipmentStatusClass] = useState<string>('');
  const [shipmentStatusText, setShipmentStatusText] = useState<string>('');
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [wave, setWave] = useState<Wave>();

  const [showCompletedModal, setShowCompletedModal] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [shipButtonText, setShipButtonText] = useState(ShipButtonText.Ship);
  const [isWavingModalOpen, setIsWavingModalOpen] = useState(false);

  //all editable fields
  const [scac, setScac] = useState<string>(null);
  const [proNumber, setProNumber] = useState<string>(null);
  const [scheduledShipDate, setScheduledShipDate] = useState<string>(null);
  const [palletCount, setPalletCount] = useState<number>(null);
  const [recommendedPalletQuantity, setRecommendedPalletQuantity] = useState<number>(null);
  const [trailerNumber, setTrailerNumber] = useState<string>(null);
  const [sealNumber, setSealNumber] = useState<string>(null);
  const [stagingLocation, setStagingLocation] = useState<Location>(null);
  const [originalStagingLocation, setOriginalStagingLocation] = useState<Location>(null);

  const [freightLoadHasChanged, setFreightLoadHasChanged] = useState<boolean>(false);
  const [freightLoadStopHasChanged, setFreightLoadStopHasChanged] = useState<boolean>(false);
  const [shipmentHasChanged, setShipmentHasChanged] = useState<boolean>(false);

  const [billOfLadingIsGenerating, setBillOfLadingIsGenerating] = useState<boolean>(false);
  const [freightPackingListIsGenerating, setFreightPackingListIsGenerating] = useState<boolean>(false);
  const [filterStagingLocations, setFilterStagingLocations] = useState(false);

  const [ssccLabelsHaveGenerated, setSsccLabelsHaveGenerated] = useState<boolean>(false);
  const [ssccLabelsAreRequiredForCompletion, setSsccLabelsAreRequiredForCompletion] = useState<boolean>(false);
  const [showKebabMenu, setShowKebabMenu] = useState<boolean>(false);
  const [activateUnpackButton, setActivateUnpackButton] = useState<boolean>(false);
  const [isShipmentTrailerLoaded, setIsShipmentTrailerLoaded] = useState<boolean>(false);
  const [unpackWorkflowState, setUnpackWorkflowState] = useState<UnpackWorkflowState>(UnpackWorkflowState.closed);

  // eslint-disable-next-line max-len
  const STANDARD_ERROR =
    'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.';

  const resultRef = useRef(null);

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    setupMenu();
  }, [shipmentLoaded, isShipmentTrailerLoaded]);

  useEffect(() => {
    setFreightLoadHasChanged(true);
  }, [scac, scheduledShipDate, trailerNumber]);

  useEffect(() => {
    setFreightLoadStopHasChanged(true);
  }, [proNumber, sealNumber]);

  const prevPalletCount = usePrevious(palletCount);
  useEffect(() => {
    if (valueIsNotUndefined(prevPalletCount)) {
      setShipmentHasChanged(true);
    }
  }, [palletCount]);

  useEffect(() => {
    getStagingLocationContent();
  }, [stagingLocation]);

  const resetStagingLocationFromNetwork = () => {
    setStagingLocation(originalStagingLocation);
  };

  const setStagingLocationFromUserInput = (loc) => {
    setStagingLocation(loc);
    setShipmentHasChanged(true);
  };

  useEffect(() => {
    const flags = [[FF_FILTER_STAGING_LOCATIONS, setFilterStagingLocations]] as const;

    for (const [flag, setterFn] of flags) {
      getBooleanFeatureFlag(flag, setterFn, shipment, authenticityToken, errors, setErrors);
    }
  }, [shipment]);

  useEffect(() => {
    switch (unpackWorkflowState) {
      case UnpackWorkflowState.completedError:
      case UnpackWorkflowState.completedSuccess:
        // Reload the shipment because the server has taken actions
        getDetails();
        break;
      default:
        // no action needed for other statuses
        break;
    }
  }, [unpackWorkflowState]);

  const detailsOption: KebabMenuOption = {
    optionText: 'Unpack Shipment',
    optionAction: () => setUnpackWorkflowState(UnpackWorkflowState.waitingForUser),
    isActionDisabled: !activateUnpackButton,
    isActionDangerous: true
  };

  const createOptions = (): KebabMenuOption[] => {
    const options: KebabMenuOption[] = [];
    options.push(detailsOption);
    return options;
  };

  const getStagingLocationContent = async () => {
    // Don't try to search when we don't have a staging location.
    // The staging location object will exist even when the id doesn't so check both.
    if (!shipment || !stagingLocation || !stagingLocation.id) {
      return;
    }

    try {
      const response: LocationsLocation = await locationService.getLocation(
        stagingLocation.id,
        shipment.reservation.warehouse_id
      );
      // Reduce to an array of lpn barcodes
      const lpnBarcodes: string[] = response.contents
        .filter((content) => {
          return content.entity && content.entity.type === LocationContentEntityType.LPN;
        })
        .map((content) => {
          return content.entity.id;
        });

      // Filter the loose goods so only skus on the shipment are included
      const shipmentSkus = new Set();
      shipment.line_items.forEach((lineItem) => {
        shipmentSkus.add(lineItem.sku);
      });
      const looseGoods = response.contents.filter((content) => {
        if (content && shipmentSkus.has(content.inventory.sku)) {
          return !content.entity || content.entity.type !== LocationContentEntityType.LPN;
        }
        return false;
      });

      setLpnsInStagingLocation(lpnBarcodes);
      setLooseGoodsInStagingLocation(looseGoods);
    } catch (e) {
      setErrors(['An error occurred! Unable to fetch Staging Location content.', e.detail]);
    }
  };

  const setupMenu = () => {
    if (!(shipment?.transportation?.ship_mode === FreightShipMode)) {
      setShowKebabMenu(true);
      if (shipment?.status === 'packed' && !isShipmentTrailerLoaded) {
        setActivateUnpackButton(true);
      } else {
        setActivateUnpackButton(false);
      }
    } else {
      setShowKebabMenu(false);
    }
  };

  const getDetails = async () => {
    const getDetailsErrors = [];
    setLoading(true);
    setShipmentLoaded(false);
    try {
      const response = await outboundShipmentsService.shipmentDetailsDeprecated(shipmentId);
      if (response.errors.length > 0) {
        getDetailsErrors.push(response.errors.map((e) => e.detail));
      } else {
        setRetailVarianceConfig(response.data.retail_variance_config);
        setShipment(response.data.shipment);
        setScac(response.data.shipment.transportation.carrier.scac);
        setProNumber(response.data.shipment.transportation.pro_number);
        setTrailerNumber(response.data.shipment.transportation.trailer_number);
        setSealNumber(response.data.shipment.transportation.seal_number);
        setScheduledShipDate(response.data.shipment.transportation.scheduled_ship_date);
        setPalletCount(response.data.shipment.num_pallets_built);
        setRecommendedPalletQuantity(response.data.recommended_pallet_quantity);
        setShipmentStatusClass(ShipmentStatusMap.get(response.data.shipment.status).className);
        setShipmentStatusText(ShipmentStatusMap.get(response.data.shipment.status).text);
        await getStagingLocationAsync();
        setShipmentLoaded(true);
        setShipmentHasChanged(false);
        setFreightLoadHasChanged(false);
        setFreightLoadStopHasChanged(false);
      }
    } catch (error) {
      getDetailsErrors.push(error.toString());
    } finally {
      setLoading(false);
      if (getDetailsErrors.length) {
        setErrors((existingErrors) => [...existingErrors, ...getDetailsErrors]);
      }
    }
  };

  const getStagingLocationAsync = async () => {
    try {
      const response = await pickConsolidationService.stagingLocation(shipmentId);
      if (response.errors?.length > 0) {
        setErrors([STANDARD_ERROR].concat(errors || []));
      } else {
        setStagingLocation(response.data.staging_location);
        setOriginalStagingLocation(response.data.staging_location);
      }
    } catch (error) {
      setErrors([error.toString()].concat(errors || []));
    }
  };

  const fetchLpns = async () => {
    if (shipment === null) {
      return;
    }

    try {
      const response = await lpnService.searchShipmentDetailsLpns({
        ReservationIds: [],
        ShipmentIds: [shipment.id],
        LpnBarcodes: [],
        Skus: []
      });

      if (response.errors.length === 0) {
        setLpns(sortLpnsByParent(response.data.lpns));
      } else {
        setErrors(response.errors.map((e) => e.detail));
      }
    } catch {
      setLpns([]);
    } finally {
      setLpnsLoading(false);
    }
  };

  function sortLpnsByParent(lpnResponse: LpnSearchDetails[]): LpnSearchDetails[] {
    const parentIds = new Set(lpnResponse.map((lpn) => lpn.parentLpnId));

    const [parentLpns, childLpns]: LpnSearchDetails[][] = lpnResponse.reduce(
      (accumulator, e) => {
        const isParent = parentIds.has(e.lpnId) || e.parentLpnId == null;
        accumulator[isParent ? 0 : 1].push(e);
        return accumulator;
      },
      [[], []]
    );

    const sortedParentLpns = parentLpns.sort((a, b) => a.lpnId - b.lpnId);
    const sortedChildLpns = childLpns.sort((a, b) => a.parentLpnId - b.parentLpnId);

    return sortedParentLpns.concat(sortedChildLpns);
  }

  useEffect(() => {
    fetchLpns();
    calculateOverages();

    if (shipment?.wave_id) {
      getWaveAsync();
    }
  }, [shipment]);

  const calculateOverages = async () => {
    if (shipment === null) {
      return;
    }

    try {
      const response = await outboundShipmentsService.calculateOverages(shipmentId);
      if (response.errors.length === 0) {
        setOverages(response.data.overages);
      }
    } catch {
      setOverages([]);
    }
  };

  useEffect(() => {
    setShipmentVariance(new ShipmentRetailVariance(shipment, lpns, retailVarianceConfig));
  }, [shipment, lpns]);

  const onLpnsUpdated = async (updatedPalletCount: number) => {
    setBillOfLadingIsGenerating(true);
    setPalletCount(updatedPalletCount);
    await fetchLpns();
  };

  useEffect(() => {
    if (
      shipmentVariance !== undefined &&
      (shipmentVariance.getOverallShipmentVarianceStatus() === VarianceStatus.OVER_PICKED_BREACHES_THRESHOLD ||
        shipmentVariance.getOverallShipmentVarianceStatus() === VarianceStatus.SHORT_PICKED_BREACHES_THRESHOLD)
    ) {
      setIsVarianceAcknowledgmentRequired(true);
    }
  }, [shipmentVariance]);

  const onCancelEdits = () => {
    //undo all the edits made in the header pane
    setScac(shipment.transportation.carrier.scac);
    setProNumber(shipment.transportation.pro_number);
    setScheduledShipDate(shipment.transportation.scheduled_ship_date);
    resetStagingLocationFromNetwork();
    setEditing(false);
  };

  //returns 'true' promise if the save(s) succeed
  const onSave = async (): Promise<boolean> => {
    const changesWereMade = freightLoadHasChanged || freightLoadStopHasChanged || shipmentHasChanged;

    if (!changesWereMade) {
      setEditing(false);
      setShowCompletedModal(false);
      return true;
    }

    const errs = [];
    setLoading(true);
    try {
      if (freightLoadHasChanged) {
        await saveFreightLoad(errs);
      }
      if (freightLoadStopHasChanged) {
        await saveFreightLoadStop(errs);
      }
      if (shipmentHasChanged) {
        await saveShipment(errs);
      }

      if (errs.length > 0) {
        onCancelEdits();
        return false;
      } else {
        //save edits to the shipment
        const transportationCpy = {...shipment.transportation};
        transportationCpy.carrier.scac = scac;
        transportationCpy.pro_number = proNumber;
        transportationCpy.scheduled_ship_date = scheduledShipDate;
        setShipment((prevState) => ({
          ...prevState,
          transportation: transportationCpy,
          num_pallets_built: palletCount
        }));
      }
    } catch (error) {
      errs.push(error.toString());
      onCancelEdits();
      return false;
    } finally {
      setErrors(errs);
      setEditing(false);
      setLoading(false);
      setShowCompletedModal(false);
    }

    return true;
  };

  const saveFreightLoad = async (errs = []) => {
    setBillOfLadingIsGenerating(true);
    setFreightPackingListIsGenerating(true);
    const response = await outboundFreightLoadService.updateFreightLoad({
      freightLoadId: shipment.transportation.load_id,
      generateBol: true,
      scac,
      scheduledShipDate,
      trailerNumber
    });
    if (response.errors) {
      errs.push(response.errors.map((err) => err.detail));
    }

    if (enableFreightPackingListRefresh) {
      const freightPackingListResponse = await freightPackingListService.enqueueFreightPackingListRegeneration(
        shipment.id
      );
      if (freightPackingListResponse.errors.length > 0) {
        errs.push(response.errors.map((e) => e.detail));
      }
    }
    setFreightLoadHasChanged(false);
  };

  const saveFreightLoadStop = async (errs = []) => {
    setBillOfLadingIsGenerating(true);
    setFreightPackingListIsGenerating(true);
    const response = await outboundFreightLoadService.updateFreightLoadStop({
      freightLoadStopId: shipment.transportation.stop_id,
      generateBol: true,
      proNumber,
      sealNumber
    });
    if (response.errors) {
      errs.push(response.errors.map((err) => err.detail));
    }

    if (enableFreightPackingListRefresh) {
      const freightPackingListResponse = await freightPackingListService.enqueueFreightPackingListRegeneration(
        shipment.id
      );
      if (freightPackingListResponse.errors.length > 0) {
        errs.push(response.errors.map((e) => e.detail));
      }
    }

    setFreightLoadStopHasChanged(false);
  };

  const saveShipment = async (errs = []) => {
    setBillOfLadingIsGenerating(true);

    if (!viewOnlyPalletsLoaded && palletCount != null) {
      const shipmentUpdateResponse = await outboundShipmentsService.updateShipment({
        shipmentId,
        numPalletsBuilt: palletCount
      });
      if (shipmentUpdateResponse.errors) {
        errs.push(shipmentUpdateResponse.errors.map((err) => err.detail));
      }
    }

    // check that the staging location was actually edited and edits are allowed
    // this needs to be done because pallet count and staging location edits trigger the same save callback
    if (stagingLocation.id !== originalStagingLocation.id && originalStagingLocation.is_updatable) {
      const stagingUpdateResponse = await pickConsolidationService.updateStagingLocation({
        shipmentIds: [shipmentId],
        locationId: stagingLocation.id,
        warehouseId: shipment.reservation.warehouse_id
      });

      if (stagingUpdateResponse.errors) {
        errs.push(stagingUpdateResponse.errors.map((err) => err.detail));
      } else {
        setOriginalStagingLocation({
          id: stagingLocation.id,
          name: stagingLocation.name,
          is_updatable: originalStagingLocation.is_updatable
        });
      }
    }

    setShipmentHasChanged(false);
  };

  const saveScheduledShipDate = async () => {
    await saveFreightLoad();
  };

  const saveScac = async () => {
    await saveFreightLoad();
  };

  const saveTrailerNumber = async () => {
    await saveFreightLoad();
  };

  const saveProNumber = async () => {
    await saveFreightLoadStop();
  };

  const saveSealNumber = async () => {
    await saveFreightLoadStop();
  };

  const handleTrailerLoadedState = (isTrailerLoaded: boolean) => {
    setIsShipmentTrailerLoaded(isTrailerLoaded);
  };

  const waveShipmentAsync = async (newStagingLocation: Location | null, batchCount: number | null) => {
    let wavingErrors = [];

    const stagingLocationId = newStagingLocation?.id;
    const waveByShipmentIdsResponse = await batchWavingService.waveByShipmentIds({
      reservationId: shipment.reservation.id,
      shipmentIds: [shipmentId],
      ...(batchCount && {batchCount}),
      ...(stagingLocationId && {stagingLocationId})
    });

    if (waveByShipmentIdsResponse.errors.length > 0) {
      wavingErrors = waveByShipmentIdsResponse.errors.map((err) => err.detail);
    } else {
      const failedShipments = waveByShipmentIdsResponse.data.result.failedShipments;

      if (failedShipments?.length > 0) {
        wavingErrors = [
          'Shipment failed to wave. Please try again shortly and contact support if the problem persists.'
        ];
      }
    }

    wavingErrors.push(...(waveByShipmentIdsResponse.data?.result.warnings ?? []));

    if (wavingErrors.length) {
      setErrors(wavingErrors);
    }

    setIsWavingModalOpen(false);
    await getDetails();
  };

  const completeShipment = async () => {
    //save changes before shipping, but don't ship if the save fails
    if (await onSave()) {
      const errs = [];
      try {
        const response = await outboundShipmentsService.ship(shipmentId);
        if (response.errors) {
          errs.push(response.errors.map((e) => e.detail));
        } else {
          window.location.reload();
        }
      } catch (e) {
        errs.push(e.toString());
      } finally {
        setErrors(errs);
      }
    }
  };

  const enqueueBillOfLadingGeneration = async () => {
    const errs = [];
    try {
      setBillOfLadingIsGenerating(true);
      const response = await outboundFreightLoadService.enqueueBillOfLadingGeneration(shipment.transportation.load_id);
      if (response.errors.length > 0) {
        errs.push(response.errors.map((e) => e.detail));
      }
    } catch (e) {
      errs.push(e.toString());
    } finally {
      setErrors(errs);
    }
  };

  const enqueueFreightPackingListRegeneration = async () => {
    const errs = [];
    try {
      setFreightPackingListIsGenerating(true);
      const response = await freightPackingListService.enqueueFreightPackingListRegeneration(shipment.id);
      if (response.errors.length > 0) {
        errs.push(response.errors.map((e) => e.detail));
      }
    } catch (e) {
      errs.push(e.toString());
    } finally {
      setErrors(errs);
    }
  };

  const getWaveAsync = async () => {
    const result = await batchWavingService.getWaveById(shipment.wave_id);

    if (result.errors.length > 0) {
      setErrors((currentErrors) => [
        ...currentErrors,
        // eslint-disable-next-line max-len
        'An error occurred while loading the Wave documents. Please try again shortly and contact support if the problem persists.'
      ]);
    } else {
      setWave(result.data.wave);
    }
  };

  const errorDisplay = errors.map((error, idx) => {
    return (
      <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
        {error}
      </div>
    );
  });

  const navigation = (
    <div className="breadcrumbs delivery" ref={resultRef}>
      <a href="/wh/shipments">Shipments</a>
      <span className="navigation-separator">/</span>
      {shipmentId}
    </div>
  );

  const manifestContentsService = useMemo(() => new ManifestContentsService(authenticityToken), [authenticityToken]);
  const outboundManifestService = useMemo(() => new OutboundManifestService(authenticityToken), [authenticityToken]);
  const contextValue = useMemo(
    () => ({
      authenticityToken,
      shipmentId,
      wave,
      locationsService: locationService,
      lpnService,
      manifestContentsService,
      outboundManifestService,
      setErrors,
      shipmentService: outboundShipmentsService,
      warehouseService,
      pickConsolidationService
    }),
    [
      shipmentId,
      wave,
      locationService,
      lpnService,
      manifestContentsService,
      outboundManifestService,
      setErrors,
      outboundShipmentsService
    ]
  );

  const handleWaveButtonClick = () => {
    if (shipment.is_warehouse_sfs_enabled) {
      setIsWavingModalOpen(true);
    } else {
      waveShipmentAsync(null, null);
    }
  };

  const handleWavingModalClose = () => {
    setIsWavingModalOpen(false);
  };

  const handleWavingModalWaveButtonClickAsync = async ({
    stagingLocation: newStagingLocation,
    batchCount
  }: OnWaveClickOptions) => {
    await waveShipmentAsync(newStagingLocation, batchCount);
  };

  /**
   * When the call to getSsccLabelRequirements in ShipmentDocuments returns, ShipmentDocuments will use this
   * callback to determine if the shipment uses SSCC labels; if it does,
   * SsccLabelsAreRequiredForCompletion will be set to TRUE and that value will be passed to
   * the CompleteShipmentModal so it can be included in the criteria that enable/disable the Complete button.
   *
   * We deliberately DO NOT load Sscc Labels in this parent component because we want to keep
   * the SSCC Service call in ShipmentDocuments, which is used in multiple component trees.
   * @param reqInfo Required SsccLabelRequirementsInfo passed up from the child component that calls this function
   */
  const handleCheckForRequiredLabels = (reqInfo: SsccLabelRequirementsInfo) => {
    setSsccLabelsAreRequiredForCompletion(
      reqInfo.cartonLabelsRequiredForAnyShipment || reqInfo.palletLabelsRequiredForAnyShipment
    );
    setSsccLabelsHaveGenerated(
      reqInfo.cartonLabelRequirementsHaveBeenMetForAllShipments &&
        reqInfo.palletLabelRequirementsHaveBeenMetForAllShipments
    );
  };

  return (
    <ShipmentDetailsContext.Provider value={contextValue}>
      <div id="omnichannel-shipment-details-page">
        <div className="shipment-details-background">
          <div id="shipment-details-container" className="container-fluid">
            <div id="shipment-details">
              <UnpackWorkflow
                authenticityToken={authenticityToken}
                workflowState={unpackWorkflowState}
                setWorkflowState={setUnpackWorkflowState}
                shipmentId={shipmentId}
                shipmentLineItems={shipment?.line_items}
                unpackLocation={shipment?.current_location}
                loadId={shipment?.outbound_manifest_id}
              />
              {navigation}
              {errorDisplay}
              {shipmentLoaded && (
                <ShipmentHeader
                  shipmentId={shipmentId}
                  shipmentStatusClass={shipmentStatusClass}
                  shipmentStatusText={shipmentStatusText}
                  shipmentVariance={shipmentVariance}
                />
              )}
              {loading && <Loader loading={loading} />}
              {shipmentLoaded && (
                <HeaderPane
                  filterStagingLocations={filterStagingLocations}
                  isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
                  showCurrentLocationEnabled={showCurrentLocationEnabled}
                  showManifestIdEnabled={showManifestIdEnabled}
                  shipment={shipment}
                  editing={editing}
                  bypassPickupDetails={bypassPickupDetails}
                  scac={scac}
                  proNumber={proNumber}
                  trailerNumber={trailerNumber}
                  sealNumber={sealNumber}
                  stagingLocation={stagingLocation}
                  stagingLocationUpdatable={
                    originalStagingLocation == null ? false : originalStagingLocation.is_updatable
                  }
                  scheduledShipDate={scheduledShipDate}
                  setScac={(value) => {
                    setScac((value || '').toUpperCase());
                  }}
                  setProNumber={(value) => setProNumber(value)}
                  setTrailerNumber={(value) => setTrailerNumber(value)}
                  setSealNumber={(value) => setSealNumber(value)}
                  setStagingLocation={(value) => setStagingLocationFromUserInput(value)}
                  setScheduledShipDate={(value) => setScheduledShipDate(value)}
                  isFreightLoadGroupUIEnabled={isFreightLoadGroupUIEnabled}
                />
              )}
              {showKebabMenu && shipmentLoaded && (
                <div id="shipment-details-kebab-menu-container" data-testid="kebab-menu-container">
                  <CustomKebabMenu options={createOptions()} />
                </div>
              )}
            </div>
          </div>
        </div>
        {shipmentLoaded && (
          <div className="container-fluid">
            <ShipmentDocuments
              authenticityToken={authenticityToken}
              batchId={shipment.fulfillment_batch_id}
              billOfLadingIsGenerating={billOfLadingIsGenerating}
              bolNumber={shipment.transportation.bol_number}
              checkLabelRequirementsForCompletion={handleCheckForRequiredLabels}
              displayPrintLpnLabelsButton={
                displayPrintLpnLabelsButton && shipment.transportation.ship_mode === FreightShipMode
              }
              documentsService={documentsService}
              enableGeneratePackingListAfterComplete={enableGeneratePackingListAfterComplete}
              enableFreightPackingListRefresh={enableFreightPackingListRefresh}
              enableStickersForReservation={enableStickersForReservation}
              enqueueBillOfLadingGeneration={enqueueBillOfLadingGeneration}
              enqueueFreightPackingListRegeneration={enqueueFreightPackingListRegeneration}
              freightPackingListIsGenerating={freightPackingListIsGenerating}
              reservationId={shipment.reservation.id}
              shipmentId={shipmentId}
              shipmentIds={[shipmentId]}
              shipmentStatus={shipment.status}
              showUploadButton={true}
              stopId={isFreightTrailerLoadingEnabled ? null : shipment.transportation.stop_id}
              warnShortShipForPackingList={warnShortShipForPackingList}
              wave={wave}
              enablePrintCartonSsccByLpn={enablePrintCartonSsccByLpn}
            />

            <ErrorBoundary
              fallbackComponent={<ShipmentProgressFallback authenticityToken={authenticityToken} shipment={shipment} />}
            >
              <ShipmentProgress
                authenticityToken={authenticityToken}
                isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
                shipment={shipment}
                lpns={lpns}
                lpnsLoading={lpnsLoading}
                looseGoodsInStagingLocation={looseGoodsInStagingLocation}
              />
            </ErrorBoundary>

            <ShipmentContents
              isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
              shipment={shipment}
              lpns={lpns}
              lpnsLoading={lpnsLoading}
              palletCount={palletCount}
              setPalletCount={(count) => setPalletCount(count)}
              savePalletCount={() => saveShipment()}
              showLpnPanel={showLpnPanel}
              viewOnlyPalletsLoaded={viewOnlyPalletsLoaded}
              recommendedPalletQuantity={recommendedPalletQuantity}
              shipmentVariance={shipmentVariance}
              onLpnsUpdated={onLpnsUpdated}
              looseGoodsInStagingLocation={looseGoodsInStagingLocation}
              stagingLocation={stagingLocation}
              setTrailerLoadedState={handleTrailerLoadedState}
            />

            <Actions
              isFreight={shipment.transportation.ship_mode === FreightShipMode}
              isBatched={!!shipment.fulfillment_batch_id || !!shipment.wave_id}
              status={shipment.status}
              shipButtonText={shipButtonText}
              onStartEdits={() => setEditing(true)}
              onCancelEdits={onCancelEdits}
              onSave={onSave}
              onShip={() => setShowCompletedModal(true)}
              onWave={handleWaveButtonClick}
            />

            <CompleteShipmentModal
              showModal={showCompletedModal}
              toggleModal={onSave}
              onComplete={completeShipment}
              bypassPickupDetails={bypassPickupDetails}
              shipment={shipment}
              scac={scac}
              proNumber={proNumber}
              scheduledShipDate={scheduledShipDate}
              palletCount={palletCount}
              sealNumber={sealNumber}
              trailerNumber={trailerNumber}
              recommendedPalletQuantity={recommendedPalletQuantity}
              shipmentVariance={shipmentVariance}
              setScac={(value) => {
                setScac((value || '').toUpperCase());
              }}
              saveScac={() => saveScac()}
              setProNumber={(value) => setProNumber(value)}
              saveProNumber={() => saveProNumber()}
              setScheduledShipDate={(value) => setScheduledShipDate(value)}
              saveScheduledShipDate={() => saveScheduledShipDate()}
              setPalletCount={(value) => setPalletCount(value)}
              savePalletCount={() => saveShipment()}
              setSealNumber={(value) => setSealNumber(value)}
              saveSealNumber={() => saveSealNumber()}
              setTrailerNumber={(value) => setTrailerNumber(value)}
              saveTrailerNumber={() => saveTrailerNumber()}
              setShipButtonText={setShipButtonText}
              viewOnlyPalletsLoaded={viewOnlyPalletsLoaded}
              isVarianceAcknowledgmentRequired={isVarianceAcknowledgmentRequired}
              lpnsOnShipment={lpns}
              lpnsInStagingLocation={lpnsInStagingLocation}
              overages={overages}
              isOvershippingBlocked={isOvershippingBlocked}
              isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
              stagingLocation={stagingLocation}
              ssccLabelsHaveGenerated={ssccLabelsHaveGenerated}
              ssccLabelsAreRequiredForCompletion={enableShipBlockIfNoLabelsModal && ssccLabelsAreRequiredForCompletion}
              wave={wave}
              authenticityToken={authenticityToken}
            />

            <WavingModal
              onClose={handleWavingModalClose}
              onWaveClickAsync={handleWavingModalWaveButtonClickAsync}
              pickConsolidationService={pickConsolidationService}
              selectedShipments={[shipmentToSelectedShipment(shipment)]}
              shouldFilterStagingLocations={filterStagingLocations}
              show={isWavingModalOpen}
              warehouseId={shipment.reservation.warehouse_id}
              isSfsEnabled={shipment.is_warehouse_sfs_enabled}
              isFreightTrailerLoadingEnabled={isFreightTrailerLoadingEnabled}
            />
          </div>
        )}
      </div>
    </ShipmentDetailsContext.Provider>
  );
};

export default ShipmentDetails;
