import * as React from 'react';
import {FunctionComponent} from 'react';
import {CustomKebabMenu, KebabMenuOption} from './CustomKebabMenu';
import WarningModal from './WarningModal';

interface Props {
  title: string;
  id: number | string;
  options?: KebabMenuOption[];
  deleteEnabled?: boolean; // if delete is enabled, you MUST pass in deleteFunction
  deleteFunction?: any;
}

// Provides hooks for a Kebab menu with an option for "Delete" with a modal
const SimpleKebabMenu: FunctionComponent<Props> = (props) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const deleteItem = async () => {
    await props.deleteFunction(props.id);
    setShowDeleteModal(false);
  };

  const deleteOption: KebabMenuOption = {
    optionText: 'Delete',
    isActionDangerous: true,
    optionAction: toggleDeleteModal
  };

  const createOptions = (): KebabMenuOption[] => {
    const kebabOptions: KebabMenuOption[] = props.options || [];

    if (props.deleteEnabled) {
      kebabOptions.push(deleteOption);
    }

    return kebabOptions;
  };

  const deleteModal = (): React.ReactFragment => {
    const modalTitle = (
      <React.Fragment>
        Delete <em>{props.title}</em>
      </React.Fragment>
    );

    const modalBody = (
      <React.Fragment>
        Are you sure you want to delete <em>{props.title}</em>? This action cannot be undone.
      </React.Fragment>
    );

    const confirmButtonText = (
      <React.Fragment>
        Yes, permanently delete <em>{props.title}</em>
      </React.Fragment>
    );

    return (
      <WarningModal
        elementId="delete-modal"
        toggleModalHandler={toggleDeleteModal}
        onConfirmClick={deleteItem}
        confirmButtonText={confirmButtonText}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    );
  };

  return (
    <React.Fragment>
      <CustomKebabMenu options={createOptions()} />

      {showDeleteModal && deleteModal()}
    </React.Fragment>
  );
};

export default SimpleKebabMenu;
