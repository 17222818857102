import {ItemQuantity, Packaging} from '../shared/CommonInterfaces';
import {Company, Inventory} from '../locations/LocationsInterfaces';

export enum BatchType {
  retail_fulfillment = 'retail_fulfillment',
  ecommerce = 'ecommerce',
  mixed = 'mixed'
}

export enum BatchTypeName {
  retail_fulfillment = 'Retail Fulfillment',
  ecommerce = 'Ecomm',
  parcel = 'Parcel',
  freight = 'Freight',
  mixed = 'Mixed'
}

export enum TripStatus {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  issues = 'issues',
  completed = 'completed'
}

export enum TripType {
  pick = 'pick',
  move = 'move',
  bulk_pick = 'bulk_pick',
  cluster_pick = 'cluster_pick',
  rebin = 'rebin',
  directed_putaway = 'directed_putaway',
  trailer_loading = 'trailer_loading'
}

export enum TripTypeName {
  move = 'Move',
  bulk_pick = 'Bulk Pick',
  cluster_pick = 'Cluster Pick',
  rebin = 'Rebin',
  directed_putaway = 'Directed Putaway',
  trailer_loading = 'Trailer Loading',
  pick = 'Pick'
}

export interface LocationMovementHaltResponse {
  succeeded: boolean;
}

export interface LocationMovementsResponse {
  trips: Trip[];
  continuationToken?: string;
}

export interface MoveItem {
  item: Inventory;
  packaging: Packaging;
  picks: PickOrPut[];
  puts: PickOrPut[];
  company: Company;
  lpnBarcode?: string;
  lpnDetailId?: number;
}

export interface Movement {
  moveItems: MoveItem[];
  batchId?: number;
  pickMethod?: TripType;
  batchType?: BatchType;
  requiredPickItems?: PickItem[];
}

export interface PickItem {
  sku: string;
  quantity: ItemQuantity;
}

export interface PickOrPut {
  location: {
    id: number;
    label: string;
  };
  quantity: {
    unit: Packaging;
    amount: number;
  };
}

export interface Trip {
  id: number;
  type: TripType;
  userDisplayName: string;
  tripStatus: TripStatus;
  startedAt: string;
  lastTouchedAt: string;
  sinceLastTouchedText?: string;
  typeInfo: Movement;
  skuCount?: number;
  quantity?: number;
  packaging?: 'Eaches' | 'Cartons' | 'Mixed';
  tripToken?: number;
}
