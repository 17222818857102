import {DropDownOption} from '../DropDown';
import {ApiResponse, Company, Packaging, Reservation} from '../CommonInterfaces';
import {
  ContainerDeliveriesApiReponse,
  ContainerDelivery,
  Item,
  LpnContent,
  PackingListItem,
  Pallet,
  ToCapturePropertyKeysAndValues,
  UpdateDateApiResponse
} from '../../dropoffs/shared/DropoffInterfaces';
import InternalAPIService from './InternalAPIService';

export interface ShipmentInfo {
  availableStagingLocations: DropDownOption[];
  lpnContents: LpnContent[];
  packingLists: {
    expected: PackingListItem[];
    shippable: PackingListItem[];
    damaged: PackingListItem[];
    actual: PackingListItem[];
  };
  pallets: Pallet[];
  reservation: Reservation;
  shipmentDetails: ContainerDelivery;
  shipperCompany: Company;
  inProgressForReservation: InProgressDropoff[];
  // to be removed once lpn snapshot is removed from shipment API.
  lpnSnapshots?: LpnContent[];
}

interface InProgressDropoff {
  id: number;
  createdAt: string;
}

export interface ConfirmationResponse {
  id: number;
}

interface ConfirmationRequest {
  id: number;
  startTime: number;
  endTime: number;
  instructions: string;
}

export interface UpdateToCapturePropertyValuesResponse {
  toCapturePropertyValues: ToCapturePropertyKeysAndValues;
}

export interface AddItemsResponse {
  addedItems: Item[];
}

export interface RemoveItemResponse {
  status: string;
  message: string;
}

export interface AddItemsRequest {
  companyId: number;
  containerDeliveryId: number;
  items: Item[];
}

interface UpdateDateRequest {
  obfuscatedId: number;
  moveDateDisplay: string;
}

interface UpdateContainerDeliveryRequest {
  id: number;
  data: {
    moveDate?: string;
    sealNumber?: string;
    trailerNumber?: string;
  };
}

interface InstructionUpdateResponse {
  id: number;
  instructions: string;
}

interface CompleteDropoffRequest {
  id: number;
  locationId: number;
  shippableInventories: DropoffInventory[];
  damagedInventories: DropoffInventory[];
  reservationId: number;
  actualArrivalTime: Date;
  actualInboundPackagingId: number;
}

interface DropoffInventory {
  inventoryId: number;
  quantity: {
    amount: number;
    unit: Packaging;
  };
}

class ContainersService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2');
  }

  public async getShipment(id: number | string): Promise<ApiResponse<ShipmentInfo>> {
    return await this.makeGetRequest(`${this.baseUrl}/containers/${id}`);
  }

  public async getShipmentsByPos(poList: string[]): Promise<ApiResponse<ContainerDeliveriesApiReponse>> {
    return await this.makeGetRequest(`${this.baseUrl}/containers`, {
      purchaseOrders: poList
    });
  }

  public async updateDateAsync(request: UpdateDateRequest): Promise<UpdateDateApiResponse> {
    return await this.makePatchRequest(`/s/dropoffs/container/${request.obfuscatedId}/update_date`, {
      move_date_display: request.moveDateDisplay
    });
  }

  public async updateContainerDelivery(
    request: UpdateContainerDeliveryRequest
  ): Promise<Record<string, never> | ApiResponse<Record<string, never>>> {
    return this.makePatchRequest(`${this.baseUrl}/containers/${request.id}`, request.data);
  }

  public async confirmDelivery(request: ConfirmationRequest): Promise<ApiResponse<ConfirmationResponse>> {
    return await this.makePatchRequest(`${this.baseUrl}/containers/${request.id}/confirm`, {
      startTime: request.startTime,
      endTime: request.endTime,
      instructions: request.instructions
    });
  }

  public async cancelDelivery(id: number): Promise<ApiResponse<Record<string, never>>> {
    return await this.makePatchRequest(`/s/dropoffs/container/${id}/cancel`, {id});
  }

  public async updateReceiveMethod(id: number): Promise<ApiResponse<any>> {
    const res = await this.makePatchRequest(`${this.baseUrl}/containers/${id}/update_receive_method`, {
      id
    });
    return res;
  }

  public async updateInstructions(id: number, instructions: string): Promise<ApiResponse<InstructionUpdateResponse>> {
    return await this.makePatchRequest(`${this.baseUrl}/containers/${id}/update_instructions`, {instructions});
  }

  public async updateToCapturePropertyValues(
    id: number,
    propertyKeysAndValues: ToCapturePropertyKeysAndValues
  ): Promise<ApiResponse<UpdateToCapturePropertyValuesResponse>> {
    return await this.makePatchRequest(`${this.baseUrl}/containers/${id}/update_capturable_property_values`, {
      propertyKeysAndValues
    });
  }

  public async addItems(request: AddItemsRequest): Promise<ApiResponse<AddItemsResponse>> {
    return this.makePatchRequest(`${this.baseUrl}/containers/${request.containerDeliveryId}/add_items`, {
      companyId: request.companyId,
      items: request.items
    });
  }

  public async deletePackingList(containerDeliveryId: number, packingListId: number): Promise<RemoveItemResponse> {
    return this.makeDeleteRequest(`/s/dropoffs/container/${containerDeliveryId}/packing_lists`, {
      packing_list_id: packingListId
    });
  }

  public async completeAndFinalizeShipment(request: CompleteDropoffRequest) {
    return await this.makePatchRequest(`${this.baseUrl}/containers/${request.id}/complete`, {
      locationId: request.locationId,
      shippableInventories: request.shippableInventories,
      damagedInventories: request.damagedInventories,
      reservationId: request.reservationId,
      actualArrivalTime: request.actualArrivalTime,
      actualInboundPackagingId: request.actualInboundPackagingId
    });
  }

  public async receiveShipment(
    id: number,
    actualArrivalTime: Date,
    actualInboundPackaging: number
  ): Promise<ApiResponse<any>> {
    return await this.makePatchRequest(`${this.baseUrl}/containers/${id}/receive_shipment`, {
      actualArrivalTime,
      actualInboundPackaging
    });
  }

  public calculatePackingListsBasedOnLpns(
    expectedLists: PackingListItem[],
    actualLists: PackingListItem[],
    lpns: LpnContent[] = []
  ) {
    const processedLpns: number[] = [];
    const updatedLists = expectedLists.map((list) => {
      const actuals = actualLists.filter(
        (item) => item.inventory.id === list.inventory.id && item.quantity.unit === list.quantity.unit
      );
      if (actuals.length > 0) {
        actuals.map((item) => {
          processedLpns.push(item.id);
          const itemLpns = lpns.filter(
            (lpn) =>
              lpn.inventory.id === list.inventory.id &&
              lpn.packaging === list.quantity.unit &&
              lpn.lpnBarcode === item.lpn.lpnBarcode &&
              lpn.inventory.sku === item.inventory.sku &&
              lpn.packaging === item.quantity.unit
          );

          if (itemLpns.length > 0) {
            item.quantity.amount = 0;
            itemLpns.forEach((lpn) => (item.quantity.amount += lpn.receivedQuantity));
          }

          list.shippableAmount = isNaN(list.shippableAmount)
            ? item.quantity.amount
            : list.shippableAmount + item.quantity.amount;
        });
      }
      return list;
    });

    return {
      expected: updatedLists,
      shippable: this.calculateAdditionalPackingLists(processedLpns, actualLists)
    };
  }

  private calculateAdditionalPackingLists(processedLpns: number[], actualLists: PackingListItem[]): PackingListItem[] {
    let additionalLists: PackingListItem[] = [];

    if (processedLpns.length < actualLists.length) {
      actualLists.map((item) => {
        if (!processedLpns.includes(item.id)) {
          const existingList = additionalLists.find(
            (list) => item.inventory.id === list.inventory.id && item.quantity.unit === list.quantity.unit
          );
          if (existingList) {
            existingList.shippableAmount += item.quantity.amount;
          } else {
            additionalLists.push({
              quantity: {
                unit: item.quantity.unit,
                amount: 0 // Not expected sku
              },
              inventory: item.inventory,
              shippableAmount: item.quantity.amount
            });
          }
        }
      });
    }

    additionalLists = additionalLists.filter((list) => list.shippableAmount > 0);
    return additionalLists;
  }
}

export default ContainersService;
