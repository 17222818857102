import * as React from 'react';
import {Loader} from '@flexe/ui-components';
import {sortBy} from 'lodash';
import WaveTemplateService from '../../shared/services/WaveTemplateService';
import {ApiResponse} from '../../shared/CommonInterfaces';
import WaveTemplateServiceV2 from '../../shared/services/WaveTemplateServiceV2';
import WaveTemplateList from './WaveTemplateList';
import {WaveTemplate, WaveTemplateV2} from './wave-templates/WaveTemplateInterfaces';

interface ManagedWaveTemplateListProps {
  warehouseId: number;
  waveTemplateService: WaveTemplateService;
  reservationToSortationEnabled: object;
  reservationIdToName: object;
  carriers: object;
  loadForWarehouse(): void;
  setShowWaveModal(show: boolean): void;
  setTemplateToWave(template: WaveTemplate): void;
  errorCatapult(errors: string[]): void;
  waveTemplateEnhancementsEnabled: boolean;
  shipOnDate: string;
  waveTemplateServiceV2: WaveTemplateServiceV2;
}

const ManagedWaveTemplateList: React.FC<ManagedWaveTemplateListProps> = (props) => {
  const {
    warehouseId,
    shipOnDate,
    waveTemplateService,
    errorCatapult,
    reservationToSortationEnabled,
    reservationIdToName,
    carriers,
    loadForWarehouse,
    setShowWaveModal,
    setTemplateToWave,
    waveTemplateEnhancementsEnabled,
    waveTemplateServiceV2
  } = props;

  const [waveTemplateRecords, setWaveTemplateRecords] = React.useState<WaveTemplateV2[]>(null);
  const [waveTemplates, setWaveTemplates] = React.useState<WaveTemplate[]>(null);

  React.useEffect(
    () => {
      const callTemplateIndex = async () => {
        // Fetch all the templates for the warehouse
        const templates: WaveTemplateV2[] = [];
        let continuationToken: string = null;
        do {
          const indexResponse = await waveTemplateServiceV2.getWaveTemplatesV2(warehouseId, continuationToken);
          if (indexResponse?.errors?.length > 0) {
            errorCatapult(indexResponse.errors.map((error) => error.detail));
            continuationToken = null;
          } else if (!indexResponse?.data) {
            errorCatapult(['Failed to fetch templates']);
            continuationToken = null;
          } else {
            templates.push(...indexResponse.data.templates);
            continuationToken = indexResponse.data.continuationToken;
          }
        } while (continuationToken !== '' && continuationToken !== null && continuationToken !== undefined);

        setWaveTemplateRecords(templates);
      };

      callTemplateIndex().catch((error) => errorCatapult([error]));
    },
    // Don't add errorCatapult to the list of dependencies otherwise the API will be called more often than required.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warehouseId]
  );

  React.useEffect(
    () => {
      const callTemplateLoad = async () => {
        // Fetch the details for each template. Split into multiple chunks so we aren't hammering the API.
        // Arbitrarily pick 5 templates at a time. Could ramp up or down in the future to manage load.
        const chunkSize = 5;
        const expandedTemplateResponses: ApiResponse<WaveTemplate>[] = [];
        for (let i = 0; i < waveTemplateRecords.length; i += chunkSize) {
          const chunk = waveTemplateRecords.slice(i, i + chunkSize);
          const responsesChunk = await Promise.all(
            chunk.map((templateRecord) => waveTemplateService.getWaveTemplate(templateRecord.id, shipOnDate))
          );
          expandedTemplateResponses.push(...responsesChunk);
        }

        const errorResponses: ApiResponse<WaveTemplate>[] = [];
        const successfulResponses: ApiResponse<WaveTemplate>[] = [];
        expandedTemplateResponses.forEach((response) => {
          if (response?.errors?.length > 0) {
            errorResponses.push(response);
          } else {
            successfulResponses.push(response);
          }
        });

        if (errorResponses.length > 0) {
          errorCatapult(errorResponses.map((response) => response.errors[0].detail));
        }
        setWaveTemplates(
          sortBy(
            successfulResponses.map((response) => response.data),
            ['count']
          ).reverse()
        );
      };

      if (waveTemplateRecords) {
        callTemplateLoad().catch((error) => errorCatapult([error]));
      } else {
        setWaveTemplates(null);
      }
    },
    // Don't add errorCatapult or waveTemplateService to the list of dependencies otherwise the API will be called more often than required.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipOnDate, waveTemplateRecords]
  );

  return (
    <div>
      {!waveTemplates ? (
        <Loader loading={true} />
      ) : (
        <WaveTemplateList
          isPastDue={false}
          waveTemplates={waveTemplates}
          waveTemplateService={waveTemplateService}
          reservationToSortationEnabled={reservationToSortationEnabled}
          reservationIdToName={reservationIdToName}
          carriers={carriers}
          loadForWarehouse={loadForWarehouse}
          setShowWaveModal={setShowWaveModal}
          setTemplateToWave={setTemplateToWave}
          errorCatapult={errorCatapult}
          waveTemplateEnhancementsEnabled={waveTemplateEnhancementsEnabled}
        />
      )}
    </div>
  );
};

export default ManagedWaveTemplateList;
