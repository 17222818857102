import axios from 'axios';
import {get, split} from 'lodash';
import {v4} from 'uuid';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
class CarrierAccountService {
  private authenticityToken: string;

  private baseUrl = '/s/carrier_accounts/';
  private sampleShippingLabelUrl = '/api/v2/shipping_labels/sample_shipping_label';

  constructor(authenticityToken: string) {
    this.authenticityToken = authenticityToken;
  }

  public async getAccounts() {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(`${this.baseUrl}company_account_infos`, {
        params: {
          authenticity_token: this.authenticityToken
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async getCarriers() {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(`${this.baseUrl}definitions`, {
        params: {
          authenticity_token: this.authenticityToken
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async createAccount(createAccountParams) {
    // eslint-disable-next-line no-useless-catch
    try {
      const params = {
        authenticity_token: this.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: createAccountParams
      };
      const response = await axios.post(this.baseUrl, params);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async updateAccount(accountId, updateAccountParams) {
    // eslint-disable-next-line no-useless-catch
    try {
      const params = {
        authenticity_token: this.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: updateAccountParams
      };
      const response = await axios.patch(`${this.baseUrl}${accountId}`, params);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async softDeleteAccount(accountId) {
    // eslint-disable-next-line no-useless-catch
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          authenticity_token: this.authenticityToken,
          meta: {correlationId: v4()},
          data: {}
        }
      };
      const response = await axios.delete(`${this.baseUrl}${accountId}`, config);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async enableReservations(accountId: number, reservationIds: number[], shipperOwnedCarrierAccount: boolean) {
    // eslint-disable-next-line no-useless-catch
    try {
      const params = {
        authenticity_token: this.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: {
          reservationIds,
          shipperOwnedCarrierAccount
        }
      };
      const response = await axios.patch(`${this.baseUrl}${accountId}/enable_reservations`, params);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async getSampleShippingLabel(accountId) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.get(`${this.sampleShippingLabelUrl}?carrier_account_id=${accountId}`, {
        params: {
          authenticity_token: this.authenticityToken
        }
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async enableCarrierAccount(accountId) {
    // eslint-disable-next-line no-useless-catch
    try {
      const params = {
        authenticity_token: this.authenticityToken,
        meta: {
          correlationId: v4()
        },
        data: {}
      };
      const response = await axios.patch(`${this.baseUrl}${accountId}/enable_carrier_account`, params);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public processErrorResponse = (errorResponse: Response) => {
    const errors = get(errorResponse, 'response.data.errors');
    return errors.map((error) => error.detail);
  };

  public processPopupErrorResponse = (errorResponse: Response, splitToken: string) => {
    let popupErrors = [];
    const processedErrors = this.processErrorResponse(errorResponse);
    for (const errorIdx in processedErrors) {
      if (typeof processedErrors[errorIdx] === 'string') {
        popupErrors = popupErrors.concat(processedErrors[errorIdx].split(splitToken));
      }
    }
    return popupErrors;
  };

  public async getCarrierConnectUrl(accountId) {
    const response = await axios.get(`${this.baseUrl}${accountId}/carrier_connect_url`, {
      params: {
        authenticity_token: this.authenticityToken
      }
    });
    return response.data;
  }
}

export default CarrierAccountService;
