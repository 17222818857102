import * as React from 'react';
import {FC} from 'react';

interface Props {
  barcodeText: string;
  barcodeSvgBase64: string;
}

const BarcodeCard: FC<Props> = (props) => {
  return (
    <div className="barcode-card">
      <img className="barcode-image" src={`data:image/svg+xml;base64,${props.barcodeSvgBase64}`} alt="Flexe" />
      <p>
        <b>{props.barcodeText}</b>
      </p>
    </div>
  );
};

export default BarcodeCard;
