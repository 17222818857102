import * as React from 'react';
import {cloneDeep, get, parseInt} from 'lodash';
import {compareAsc as compareDateAsc, differenceInMinutes, format as formatDate} from 'date-fns';
import {LegacyModal, Loader, Tabs} from '@flexe/ui-components';
import Filters from '../shared/Filters';
import ErrorDisplay from '../shared/ErrorDisplay';
import {Filter, FilterType, Packaging, Response, Warehouse} from '../shared/CommonInterfaces';
import {PickBatchLink, renderFulfillmentBatch, renderTrailerManifestLink} from '../../libs/helpers';
import {BatchTypeName, MoveItem, PickOrPut, Trip, TripStatus, TripType, TripTypeName} from './MovementInterfaces';
import MoveItemsTable from './MoveItemsTable';
import MovementsService from './MovementsService';

const TRIP_MINUTE_LIMIT = 60;

export enum SelectState {
  notSelectable,
  unSelected,
  selected
}

interface TripState {
  showMoves?: boolean;
  selected?: Trip | SelectState;
  trip?: Trip;
}

interface TripStates {
  [tripId: string]: TripState;
}

type TabLoadingStatus = {
  [tabName in TripStatus]: boolean;
};

interface MovementTasksProps {
  authenticityToken: string;
  warehouse: Warehouse;
  sfsEnabled: boolean;
  movementsPageSize?: number;
}

interface MovementFilters {
  userName?: string[];
  tripId?: string[];
  tripType?: TripType[];
  companyName?: string[];
  sku?: string[];
  lpn?: string[];
}

interface MovementCounts {
  notStartedCount: number;
  inProgressCount: number;
  issuesCount: number;
  completedCount: number;
}

interface MovementTasksState {
  filters: MovementFilters;
  movementCounts: MovementCounts;
  tripsToDisplay: Trip[];
  selectedWarehouse: Warehouse;
  selectedTab: TripStatus;
  errorResponse: Response;
  errorMessage: string;
  successMessage: string;
  sortedTrips: SortedTrips;
  filteredSortedTrips: SortedTrips;
  tripStates: TripStates;
  allTripsSelected: boolean;
  userNameOptions: Set<string>;
  tripIdOptions: Set<number>;
  companyNameOptions: Set<string>;
  skuOptions: Set<string>;
  lpnOptions: Set<string>;
  locationOptions: Set<string>;
  showBulkActionModal: boolean;
  tabLoadingStatus: TabLoadingStatus;
}

type SortedTrips = {
  [tabName in TripStatus]: Trip[];
};

class MovementTasks extends React.Component<MovementTasksProps, MovementTasksState> {
  private movementsService: MovementsService;

  private fetchIntervalId: number;

  constructor(props) {
    super(props);

    this.movementsService = new MovementsService(props.authenticityToken);

    this.state = {
      filters: null,
      selectedWarehouse: props.warehouse,
      selectedTab: TripStatus.inProgress,
      errorResponse: null,
      errorMessage: '',
      successMessage: '',
      sortedTrips: {
        notStarted: [],
        inProgress: [],
        issues: [],
        completed: []
      },
      filteredSortedTrips: {
        notStarted: [],
        inProgress: [],
        issues: [],
        completed: []
      },
      movementCounts: {
        notStartedCount: 0,
        inProgressCount: 0,
        issuesCount: 0,
        completedCount: 0
      },
      tripsToDisplay: [],
      tripStates: {},
      allTripsSelected: false,
      userNameOptions: new Set(),
      tripIdOptions: new Set(),
      companyNameOptions: new Set(),
      skuOptions: new Set(),
      lpnOptions: new Set(),
      locationOptions: new Set(),
      showBulkActionModal: false,
      tabLoadingStatus: {
        notStarted: true,
        inProgress: true,
        issues: true,
        completed: true
      }
    };
  }

  public componentDidMount() {
    this.fetchMovements(this.state.selectedWarehouse.id, [
      TripStatus.issues,
      TripStatus.notStarted,
      TripStatus.inProgress
    ]);

    // Fetch completed movements separately, since there will be more of them.
    this.fetchMovements(this.state.selectedWarehouse.id, [TripStatus.completed]);
  }

  public componentWillUnmount() {
    clearInterval(this.fetchIntervalId);
  }

  public render() {
    const selectedTripsCount = this.selectedTripsCount();

    return (
      <div>
        {this.state.successMessage && (
          <div className="alert alert-success">
            <div className="content">
              <p>{this.state.successMessage}</p>
            </div>
          </div>
        )}

        <div className={`alert alert-info selected-trips-count${selectedTripsCount > 0 ? '' : ' invisible'}`}>
          <div className="content">
            You have {selectedTripsCount} {selectedTripsCount > 1 ? 'movements' : 'movement'} selected. &nbsp;&nbsp;
          </div>
        </div>

        <ErrorDisplay errorResponse={this.state.errorResponse} errorText={this.state.errorMessage} />

        <Filters filters={this.getFilters()} filterChangeHandler={this.handleFilterChange} />

        <Tabs tabs={this.getTabs()} tabEvent={this.handleTabEvent} />

        {this.selectedTabIsLoading() ? (
          <Loader loading={true} />
        ) : (
          <div id="location-movements-table">
            {this.state.tripsToDisplay.length ? this.movementsTable() : this.emptyTable()}
          </div>
        )}

        <LegacyModal
          id="bulk_action_modal"
          toggleModal={this.toggleBulkActionModal}
          show={this.state.showBulkActionModal}
          title="Bulk Pause"
          footer={
            <div className="action-buttons">
              <a className="btn" onClick={this.toggleBulkActionModal}>
                Cancel
              </a>
              <a className="btn halt-trips" onClick={this.handleHaltTrips}>
                Pause
              </a>
            </div>
          }
        >
          <div className="modal-contents">
            <h3>You have {selectedTripsCount} movements selected</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Type</th>
                  <th>User</th>
                  <th>SKUs</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  <th>Started</th>
                  <th>Last Touched</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(this.state.tripStates).map((tripId) => {
                  const selected = this.state.tripStates[tripId].selected;
                  if (selected === SelectState.selected) {
                    const trip = this.state.tripStates[tripId].trip;
                    const isIssue = differenceInMinutes(Date.now(), trip.lastTouchedAt) >= TRIP_MINUTE_LIMIT;
                    const pickItems = get(trip, 'typeInfo.requiredPickItems');
                    return (
                      <tr key={trip.id}>
                        <td>{trip.type === TripType.pick ? get(trip, 'typeInfo.batchId') : trip.id}</td>
                        <td>{TripTypeName[trip.type]}</td>
                        <td>{trip.userDisplayName}</td>
                        <td>{pickItems?.length ? `${trip.skuCount} of ${pickItems.length}` : trip.skuCount}</td>
                        <td>
                          {pickItems?.length
                            ? `${trip.quantity} of ${pickItems.reduce((a, v) => a + v.quantity.amount, 0)}`
                            : trip.quantity}
                        </td>
                        <td>{trip.packaging}</td>
                        <td>
                          <time>{formatDate(trip.startedAt, 'M/D/YY hh:mm a')}</time>
                        </td>
                        <td className="last-touched-at">
                          {isIssue && <i className="fa fa-exclamation-triangle text-danger" />}
                          <time>{formatDate(trip.lastTouchedAt, 'M/D/YY hh:mm a')}</time>
                          <b className={`min-ago${isIssue ? ' text-danger' : ''}`}>{trip.sinceLastTouchedText}</b>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
        </LegacyModal>
      </div>
    );
  }

  private selectedTripsCount() {
    return Object.keys(this.state.tripStates).reduce((count, tripId) => {
      if (this.state.tripStates[tripId].selected === SelectState.selected) {
        return count + 1;
      }
      return count;
    }, 0);
  }

  private renderTripId(trip: Trip) {
    if (trip.type === TripType.pick) {
      if (this.props.sfsEnabled) {
        return <PickBatchLink id={trip.typeInfo.batchId} />;
      } else {
        return renderFulfillmentBatch(trip.typeInfo.batchId);
      }
    } else if (trip.type === TripType.rebin) {
      return renderFulfillmentBatch(trip.typeInfo.batchId);
    } else if (trip.type === TripType.trailer_loading) {
      return renderTrailerManifestLink(trip.typeInfo.batchId);
    } else {
      return trip.id;
    }
  }

  private movementsTable() {
    const {allTripsSelected, selectedTab} = this.state;
    const bulkActionDisableClass = this.selectedTripsCount() === 0 ? ' disabled' : '';
    return (
      <div className={`${selectedTab}`}>
        {selectedTab === TripStatus.inProgress && (
          <div className="bulk-action-buttons">
            <a className={`btn clear-selections btn-sm${bulkActionDisableClass}`} onClick={this.clearSelectedTrips}>
              Clear Selections
            </a>
            <a className={`btn bulk-halt btn-sm${bulkActionDisableClass}`} onClick={this.toggleBulkActionModal}>
              Pause
            </a>
          </div>
        )}
        <table className="table movements">
          <thead>
            <tr>
              {selectedTab === TripStatus.inProgress && (
                <th className="select-all">
                  <input
                    type="checkbox"
                    checked={allTripsSelected}
                    onChange={this.onAllTripsSelected}
                    title={`${allTripsSelected ? 'Deselect' : 'Select'} all trips in this table`}
                  />
                </th>
              )}
              <th>Id</th>
              <th>Type</th>
              <th>Trip Token</th>
              <th>User Name</th>
              <th>SKUs</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Started</th>
              <th>Last Touched</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.tripsToDisplay.map((trip, idx) => {
              const odd = idx % 2 !== 0;
              const tripState = this.state.tripStates[trip.id] || {};
              const isIssue =
                selectedTab === TripStatus.issues ||
                differenceInMinutes(Date.now(), trip.lastTouchedAt) >= TRIP_MINUTE_LIMIT;
              const isInProgress = selectedTab === TripStatus.inProgress;
              const pickItems = get(trip, 'typeInfo.requiredPickItems');
              const chevron = tripState.showMoves ? (
                <span className="fa fa-chevron-up text-info"></span>
              ) : (
                <span className="fa fa-chevron-down"></span>
              );
              return [
                <tr
                  className={`trip${odd ? ' odd' : ''}${tripState.showMoves ? ' showing-moves' : ''}`}
                  key={`trip-${trip.id}`}
                  data-trip-id={trip.id}
                  onClick={this.toggleShowMoves}
                >
                  {trip.id && isInProgress && (
                    <td className="select-trip">
                      <input
                        type="checkbox"
                        data-trip-id={trip.id}
                        checked={tripState.selected === SelectState.selected}
                        onChange={this.onTripSelected}
                        onClick={this.onTripCheckboxClicked}
                      />
                    </td>
                  )}
                  <td>{this.renderTripId(trip)}</td>
                  <td>{this.tripTypeDisplayString(trip)}</td>
                  <td>{trip.tripToken || <span>&mdash;</span>}</td>
                  <td>{trip.userDisplayName || <span>&mdash;</span>}</td>
                  <td>{pickItems?.length ? `${trip.skuCount} of ${pickItems.length}` : trip.skuCount}</td>
                  <td>
                    {pickItems?.length
                      ? `${trip.quantity} of ${pickItems.reduce((a, v) => a + v.quantity.amount, 0)}`
                      : trip.quantity}
                  </td>
                  <td>{trip.packaging}</td>
                  <td>
                    <time>{trip.startedAt ? formatDate(trip.startedAt, 'M/D/YY hh:mm a') : <span>&mdash;</span>}</time>
                  </td>
                  <td className="last-touched-at">
                    {(selectedTab === TripStatus.inProgress || selectedTab === TripStatus.issues) && isIssue && (
                      <i className="fa fa-exclamation-triangle text-danger" />
                    )}
                    <time>
                      {trip.lastTouchedAt ? formatDate(trip.lastTouchedAt, 'M/D/YY hh:mm a') : <span>&mdash;</span>}
                    </time>
                    {(selectedTab === TripStatus.inProgress || selectedTab === TripStatus.issues) && (
                      <b className={`min-ago${isIssue ? ' text-danger' : ''}`}>{trip.sinceLastTouchedText}</b>
                    )}
                  </td>
                  <td>{trip.id && chevron}</td>
                </tr>,
                tripState.showMoves ? (
                  <tr className={`moves${odd ? ' odd' : ''}`} key={`moves-${trip.id}`}>
                    <td colSpan={10}>
                      <MoveItemsTable items={trip.typeInfo.moveItems} />
                    </td>
                  </tr>
                ) : null
              ];
            })}
          </tbody>
        </table>
      </div>
    );
  }

  private emptyTable() {
    return <p>There are no movements in this state matching your current selection.</p>;
  }

  private async fetchMovements(warehouseId: number, tripStatusesToLoad: TripStatus[]) {
    try {
      const trips: Trip[] = await this.fetchAllTrips(warehouseId, this.getMovementStatusFilter(tripStatusesToLoad));

      this.addFilterOptionsFromTrips(trips);

      const processedTrips: Trip[] = trips.map(this.processTrip);

      let tripStates = this.addAnyNewTripStates(processedTrips);

      const sortedTrips: SortedTrips = this.getSortedTrips(processedTrips);

      let selectedTab = this.state.selectedTab;

      // If there are any issues...
      if (sortedTrips[TripStatus.issues] && sortedTrips[TripStatus.issues].length) {
        // ...sort them
        sortedTrips[TripStatus.issues] = sortedTrips[TripStatus.issues].sort((tripA, tripB) => {
          return compareDateAsc(tripA.lastTouchedAt, tripB.lastTouchedAt);
        });

        tripStates = this.toggleSelectedAllTrips(sortedTrips[TripStatus.issues], tripStates, SelectState.unSelected);

        selectedTab = TripStatus.issues;
      }

      const filteredSortedTrips = this.getFilteredSortedTrips(sortedTrips, this.state.filters);
      const tripsToDisplay = filteredSortedTrips[selectedTab];
      const movementCounts = this.getMovementCounts(filteredSortedTrips);
      const tabLoadingStatus = this.getUpdatedTabLoadingStatus(tripStatusesToLoad);

      this.setState({
        tripsToDisplay,
        movementCounts,
        sortedTrips,
        filteredSortedTrips,
        selectedTab,
        tripStates,
        tabLoadingStatus
      });
    } catch (errorResponse) {
      this.setState({
        errorResponse,
        tabLoadingStatus: {
          notStarted: false,
          inProgress: false,
          issues: false,
          completed: false
        }
      });
      this.showErrorMessage('There was an error fetching movements', errorResponse);
    }
  }

  private addFilterOptionsFromTrips(trips: Trip[]) {
    // Create a hash of all the filterable elements in the movements
    const tripIds = new Set<number>();
    const userNames = new Set<string>();
    const companyNames = new Set<string>();
    const skus = new Set<string>();
    const lpns = new Set<string>();
    const locations = new Set<string>();
    trips.forEach((trip) => {
      tripIds.add(trip.type === TripType.pick ? trip.typeInfo.batchId : trip.id);

      userNames.add(trip.userDisplayName);

      trip.typeInfo.moveItems.forEach((item: MoveItem) => {
        companyNames.add(item.company.name);
        skus.add(item.item.sku);
        lpns.add(item.lpnBarcode);

        item.picks.forEach((pickOrPut: PickOrPut) => {
          locations.add(pickOrPut.location.label);
        });
        item.puts.forEach((pickOrPut: PickOrPut) => {
          locations.add(pickOrPut.location.label);
        });
      });
    });

    const tripIdOptions = this.buildOptions(this.state.tripIdOptions, tripIds);
    const userNameOptions = this.buildOptions(this.state.userNameOptions, userNames);
    const companyNameOptions = this.buildOptions(this.state.companyNameOptions, companyNames);
    const skuOptions = this.buildOptions(this.state.skuOptions, skus);
    const lpnOptions = this.buildOptions(this.state.lpnOptions, lpns);
    const locationOptions = this.buildOptions(this.state.locationOptions, locations);

    this.setState({
      userNameOptions,
      tripIdOptions,
      companyNameOptions,
      skuOptions,
      lpnOptions,
      locationOptions
    });
  }

  private getSortedTrips(trips: Trip[]): SortedTrips {
    const notStartedTrips = trips.filter((trip) => {
      return trip.tripStatus === TripStatus.notStarted;
    });
    const inProgressTrips = trips.filter((trip) => {
      const minutesSinceLastTouched = differenceInMinutes(Date.now(), trip.lastTouchedAt);
      trip.sinceLastTouchedText = this.humanReadableMinutesAgo(minutesSinceLastTouched);
      return trip.tripStatus === TripStatus.inProgress;
    });
    const issuesTrips = trips.filter((trip) => {
      const minutesSinceLastTouched = differenceInMinutes(Date.now(), trip.lastTouchedAt);
      trip.sinceLastTouchedText = this.humanReadableMinutesAgo(minutesSinceLastTouched);
      return trip.tripStatus === TripStatus.issues;
    });
    const completedTrips = trips.filter((trip) => {
      return trip.tripStatus === TripStatus.completed;
    });
    return {
      notStarted: [...this.state.sortedTrips[TripStatus.notStarted], ...notStartedTrips],
      inProgress: [...this.state.sortedTrips[TripStatus.inProgress], ...inProgressTrips],
      issues: [...this.state.sortedTrips[TripStatus.issues], ...issuesTrips],
      completed: [...this.state.sortedTrips[TripStatus.completed], ...completedTrips]
    };
  }

  private async fetchAllTrips(warehouseId: number, statuses: string[]): Promise<Trip[]> {
    let continuationToken = null;
    let trips: Trip[] = [];
    do {
      const response = await this.movementsService.getMovements(
        warehouseId,
        statuses,
        this.props.movementsPageSize,
        continuationToken
      );
      if (response?.errors?.length) {
        return Promise.reject('There was an error fetching movements');
      } else {
        trips = [...trips, ...response.data.trips];
        continuationToken = response.data.continuationToken;
      }
    } while (continuationToken);

    return trips;
  }

  private addAnyNewTripStates = (newTrips: Trip[]) => {
    // Set the trips selected and showMoves states for the UI
    const tripStates = cloneDeep(this.state.tripStates);
    newTrips.forEach((trip, idx) => {
      // If there is no existing trip state object, add a new one with both properties set to false
      if (!tripStates[trip.id]) {
        tripStates[trip.id] = {
          trip,
          selected: SelectState.notSelectable,
          showMoves: false
        };
      }
    });
    return tripStates;
  };

  private onTripSelected = (event) => {
    const tripStates = cloneDeep(this.state.tripStates);
    const tripId = parseInt(event.currentTarget.getAttribute('data-trip-id'), 10);
    // Set all trips unselected by default
    let allTripsSelected = false;

    if (tripStates[tripId] && tripStates[tripId].selected === SelectState.selected) {
      // Deselect the trip. We can leave allTripsSelected as false because we know they aren't all selected
      tripStates[tripId].selected = SelectState.unSelected;
    } else {
      // Select the trip
      const selectedTrip = this.state.sortedTrips[this.state.selectedTab].find((trip) => trip.id === tripId);
      tripStates[tripId].selected = SelectState.selected;
      tripStates[tripId].trip = selectedTrip;
      // All trips might be selected now, let's check
      allTripsSelected = Object.keys(tripStates).every((tId) => {
        return tripStates[tId].selected !== SelectState.unSelected;
      });
    }
    this.setState({tripStates, allTripsSelected});
  };

  private onAllTripsSelected = (event) => {
    const checked = event.target.checked;
    const tripsToTarget: Trip[] = this.getTargetedTrips(this.state.selectedTab);
    const tripStates = checked ? this.selectTheseTrips(tripsToTarget) : this.deselectTheseTrips(tripsToTarget);
    this.setState({tripStates, allTripsSelected: checked});
  };

  private getTargetedTrips = (tripStatus: TripStatus) => {
    return this.state.sortedTrips[tripStatus];
  };

  private selectTheseTrips = (trips: Trip[]) => {
    return this.toggleSelectedAllTrips(trips, cloneDeep(this.state.tripStates), SelectState.selected);
  };

  private deselectTheseTrips = (trips: Trip[]) => {
    return this.toggleSelectedAllTrips(trips, cloneDeep(this.state.tripStates), SelectState.unSelected);
  };

  private toggleSelectedAllTrips = (trips: Trip[], tripStates: TripStates, selected: SelectState) => {
    trips.forEach((trip, idx) => {
      tripStates[trip.id].selected = selected;
    });
    return tripStates;
  };

  private onTripCheckboxClicked = (event) => {
    // This prevents the click event on the checkbox propagating up to the table row, and toggling the detail view
    event.stopPropagation();
  };

  private clearSelectedTrips = () => {
    const tripStates = this.deselectTheseTrips(this.getTargetedTrips(this.state.selectedTab));
    this.setState({tripStates, allTripsSelected: false});
  };

  private toggleBulkActionModal = () => {
    this.setState({showBulkActionModal: !this.state.showBulkActionModal});
  };

  private handleHaltTrips = async () => {
    const locationMovementIds = Object.keys(this.state.tripStates).filter((tripId) => {
      return this.state.tripStates[tripId].selected === SelectState.selected;
    });
    const haltResponse = await this.movementsService.haltMovements(
      this.state.selectedWarehouse.id,
      locationMovementIds,
      []
    );
    if (haltResponse && haltResponse.errors && haltResponse.errors.length !== 0) {
      this.showErrorMessage('Movements could not be paused.', haltResponse);
      return;
    }
    try {
      this.showSuccessMessage('Movements were successfully paused!');
      this.clearSelectedTrips();
      const sortedTrips = cloneDeep(this.state.sortedTrips);
      if (this.state.selectedTab === TripStatus.inProgress) {
        locationMovementIds.forEach((moveId) => {
          // Need to move 'inProgress' movements that were halted to 'issues'
          const tripsToHalt = sortedTrips[TripStatus.inProgress].filter((trip) => {
            return trip.id.toString() === moveId;
          });
          if (tripsToHalt.length > 1) {
            this.showErrorMessage('Unable to refresh the page. Please refresh.', null);
          } else if (tripsToHalt.length === 1) {
            const tripToHalt = tripsToHalt[0];
            const tripToHaltIdx = sortedTrips[TripStatus.inProgress].indexOf(tripToHalt);
            if (tripToHaltIdx > -1) {
              sortedTrips[TripStatus.issues].push(tripToHalt);
              sortedTrips[TripStatus.inProgress].splice(tripToHaltIdx, 1);
            }
          }
        });
        this.setState({sortedTrips});
      }
    } catch (error) {
      this.showErrorMessage('Unable to refresh the page. Please refresh.', error.message);
    } finally {
      this.setState({showBulkActionModal: false});
    }
  };

  private showSuccessMessage(successMessage: string) {
    this.setState({successMessage});
    setTimeout(() => this.setState({successMessage: null}), 5000);
  }

  private showErrorMessage(errorMessage, errorResponse) {
    this.setState({errorMessage, errorResponse});
    setTimeout(() => this.setState({errorMessage: null, errorResponse: null}), 5000);
  }

  private toggleShowMoves = (event) => {
    const tripId = parseInt(event.currentTarget.getAttribute('data-trip-id'), 10);
    if (tripId) {
      const tripStates = cloneDeep(this.state.tripStates);
      tripStates[tripId].showMoves = !tripStates[tripId].showMoves;
      this.setState({tripStates});
    }
  };

  private processTrip = (trip) => {
    const uniqueSKUs = {};
    const moveItems = trip.typeInfo.moveItems;
    const hasEaches = moveItems.some((item) => item.packaging === Packaging.each);
    const hasCartons = moveItems.some((item) => item.packaging === Packaging.carton);
    let packaging = 'Eaches';
    if (hasCartons) {
      if (hasEaches) {
        packaging = 'Mixed';
      } else {
        packaging = 'Cartons';
      }
    }

    moveItems.forEach((moveItem) => {
      uniqueSKUs[moveItem.item.sku] = true;
    });

    // Count how many items are being moved
    const quantity = moveItems.reduce((itemAcc, currentItem) => {
      return (
        itemAcc +
        currentItem.picks.reduce((pickAcc, currentPick) => {
          return pickAcc + parseInt(currentPick.quantity.amount, 10);
        }, 0)
      );
    }, 0);

    return {
      ...trip,
      skuCount: Object.keys(uniqueSKUs).length,
      packaging,
      quantity
    };
  };

  private getFilters(): Filter[] {
    return [
      {
        displayName: 'Trip Type',
        key: 'tripType',
        type: FilterType.Dropdown,
        allowMultiple: true,
        options: [
          {
            displayName: 'Choose a Trip Type',
            value: ''
          },
          {
            displayName: TripTypeName.move,
            value: TripType.move
          },
          {
            displayName: TripTypeName.bulk_pick,
            value: TripType.bulk_pick
          },
          {
            displayName: TripTypeName.cluster_pick,
            value: TripType.cluster_pick
          },
          {
            displayName: TripTypeName.rebin,
            value: TripType.rebin
          }
        ]
      },
      {
        displayName: 'Trip ID',
        key: 'tripId',
        type: FilterType.DataList,
        options: Array.from(this.state.tripIdOptions).map((k) => ({displayName: k.toString(), value: k})) || [],
        allowMultiple: true
      },
      {
        displayName: 'User Name',
        key: 'userName',
        type: FilterType.DataList,
        options: Array.from(this.state.userNameOptions).map((k) => ({displayName: k, value: k})) || [],
        allowMultiple: true
      },
      {
        displayName: 'Company Name',
        key: 'companyName',
        type: FilterType.DataList,
        options: Array.from(this.state.companyNameOptions).map((k) => ({displayName: k, value: k})) || [] || [],
        allowMultiple: true
      },
      {
        displayName: 'SKU',
        key: 'sku',
        type: FilterType.DataList,
        options: Array.from(this.state.skuOptions).map((k) => ({displayName: k, value: k})) || [] || [],
        allowMultiple: true
      },
      {
        displayName: 'LPN',
        key: 'lpn',
        type: FilterType.DataList,
        options: Array.from(this.state.lpnOptions).map((k) => ({displayName: k, value: k})) || [] || [],
        allowMultiple: true
      },
      {
        displayName: 'Location',
        key: 'location',
        type: FilterType.DataList,
        options: Array.from(this.state.locationOptions).map((k) => ({displayName: k, value: k})) || [] || [],
        allowMultiple: true
      }
    ];
  }

  private handleFilterChange = (filterData) => {
    const filters: MovementFilters = {};
    filterData.forEach((fData) => {
      const filterKey = fData.filter.key;
      const newFilterVal = fData.value;
      if (!filters[filterKey]) {
        filters[filterKey] = [newFilterVal];
      } else {
        filters[filterKey].push(newFilterVal);
      }
    });

    const filteredSortedTrips = this.getFilteredSortedTrips(this.state.sortedTrips, filters);
    const tripsToDisplay = filteredSortedTrips[this.state.selectedTab];
    const movementCounts = this.getMovementCounts(filteredSortedTrips);

    this.setState({
      filters,
      filteredSortedTrips,
      tripsToDisplay,
      movementCounts
    });
  };

  private getTabs() {
    return [
      {
        key: TripStatus.notStarted,
        title: <i className="fa fa-list" aria-hidden="true"></i>,
        subTitle: `Not Started (${this.state.movementCounts.notStartedCount})`,
        active: this.state.selectedTab === TripStatus.notStarted
      },
      {
        key: TripStatus.inProgress,
        title: <i className="fa fa-refresh" aria-hidden="true"></i>,
        subTitle: `In Progress (${this.state.movementCounts.inProgressCount})`,
        active: this.state.selectedTab === TripStatus.inProgress
      },
      {
        key: TripStatus.issues,
        title: <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>,
        subTitle: `Issues (${this.state.movementCounts.issuesCount})`,
        active: this.state.selectedTab === TripStatus.issues
      },
      {
        key: TripStatus.completed,
        title: <i className="fa fa-check-circle" aria-hidden="true"></i>,
        subTitle: `Completed (${this.state.movementCounts.completedCount})`,
        active: this.state.selectedTab === TripStatus.completed,
        pullRight: true
      }
    ];
  }

  private handleTabEvent = (tabKey) => {
    const filteredSortedTrips = this.state.filteredSortedTrips;
    const tripsToDisplay = filteredSortedTrips[tabKey];
    const movementCounts = this.getMovementCounts(filteredSortedTrips);

    this.setState({
      selectedTab: tabKey,
      movementCounts,
      tripsToDisplay
    });
  };

  private humanReadableMinutesAgo(minutesAgo: number) {
    if (minutesAgo === 0) {
      return '(< 1 min)';
    }
    const hours: number = Math.floor(minutesAgo / 60);
    const hoursText: string = hours >= 1 ? `${hours} hr` : '';

    const minutes: number = minutesAgo % 60;
    const minutesText: string = minutes > 0 ? `${minutes} min` : '';

    const separator: string = hoursText && minutesText ? ' ' : '';

    return `(${hoursText}${separator}${minutesText})`;
  }

  private getFilteredSortedTrips(sortedTrips: SortedTrips, filters: MovementFilters): SortedTrips {
    const filteredSortedTrips: SortedTrips = {
      notStarted: this.getFilteredTrips(sortedTrips[TripStatus.notStarted], filters),
      inProgress: this.getFilteredTrips(sortedTrips[TripStatus.inProgress], filters),
      issues: this.getFilteredTrips(sortedTrips[TripStatus.issues], filters),
      completed: this.getFilteredTrips(sortedTrips[TripStatus.completed], filters)
    };

    return filteredSortedTrips;
  }

  private getFilteredTrips(trips: Trip[], filters: MovementFilters): Trip[] {
    trips = trips || [];

    let filteredTrips = trips;

    if (filters) {
      filteredTrips = trips.filter((trip) => {
        let toDisplay = true;
        const filterNames = Object.keys(filters);

        filterNames.forEach((filterName) => {
          const filter = filters[filterName];

          let anyMatch = false;
          if (filterName === 'tripId') {
            // partially match tripId against batchId for picks only
            anyMatch = filters.tripId.some((id) => {
              return !!(trip.type === TripType.pick
                ? trip.typeInfo.batchId.toString().match(id)
                : trip.id.toString().match(id));
            });
          } else if (filterName === 'tripType') {
            // exactly match tripType against pickMethod for picks only, otherwise exactly match type
            anyMatch = filters.tripType.some((type) => {
              const tripType = trip.type === TripType.pick ? trip.typeInfo.pickMethod : trip.type;
              return type === tripType;
            });
          } else if (filterName === 'companyName') {
            anyMatch = trip.typeInfo.moveItems.some((item) =>
              filter.some((val) => item.company && item.company.name && item.company.name.match(val))
            );
          } else if (filterName === 'sku') {
            anyMatch = trip.typeInfo.moveItems.some((item) =>
              filter.some((val) => item.item.sku && item.item.sku.match(val))
            );
          } else if (filterName === 'lpn') {
            anyMatch = trip.typeInfo.moveItems.some((item) =>
              filter.some((val) => item.lpnBarcode && item.lpnBarcode.match(val))
            );
          } else if (filterName === 'userName') {
            anyMatch = filter.some((val) => trip.userDisplayName && trip.userDisplayName.match(val));
          } else if (filterName === 'location') {
            anyMatch = trip.typeInfo.moveItems.some((item) =>
              filter.some(
                (val) =>
                  item.picks.some((pickOrPut) => !!pickOrPut.location.label.match(val)) ||
                  item.puts.some((pickOrPut) => !!pickOrPut.location.label.match(val))
              )
            );
          }

          if (!anyMatch) {
            toDisplay = false;
          }
        });
        return toDisplay;
      });
    }

    return filteredTrips;
  }

  private getMovementCounts(sortedTrips: SortedTrips): MovementCounts {
    const notStartedCount = sortedTrips[TripStatus.notStarted].filter(
      (trip) => trip.tripStatus === TripStatus.notStarted
    ).length;
    const inProgressCount = sortedTrips[TripStatus.inProgress].filter(
      (trip) => trip.tripStatus === TripStatus.inProgress
    ).length;
    const issuesCount = sortedTrips[TripStatus.issues].filter((trip) => trip.tripStatus === TripStatus.issues).length;
    const completedCount = sortedTrips[TripStatus.completed].filter((trip) => trip.tripStatus === TripStatus.completed)
      .length;

    return {
      notStartedCount,
      inProgressCount,
      issuesCount,
      completedCount
    };
  }

  private tripTypeDisplayString(trip: Trip): string {
    if (trip.type === TripType.pick) {
      return `${BatchTypeName[trip.typeInfo.batchType] || ''} ${TripTypeName[trip.typeInfo.pickMethod] || 'Pick'}`;
    } else {
      return TripTypeName[trip.type];
    }
  }

  private getUpdatedTabLoadingStatus(loadedStatuses: TripStatus[]): TabLoadingStatus {
    const currentLoadingStatus = cloneDeep(this.state.tabLoadingStatus);
    loadedStatuses.forEach((status) => {
      currentLoadingStatus[status] = false;
    });
    return currentLoadingStatus;
  }

  private selectedTabIsLoading(): boolean {
    return this.state.tabLoadingStatus[this.state.selectedTab];
  }

  private buildOptions<T>(originalOptions: Set<T>, newOptions: Set<T>): Set<T> {
    const result = new Set<T>();
    originalOptions.forEach((k) => {
      result.add(k);
    });
    newOptions.forEach((k) => {
      result.add(k);
    });
    return result;
  }

  private getMovementStatusFilter(tripStatuses: TripStatus[]): string[] {
    const statuses = [];
    if (tripStatuses.includes(TripStatus.completed)) {
      statuses.push('completed');
    }
    if (tripStatuses.includes(TripStatus.issues)) {
      statuses.push('halted');
    }
    if (tripStatuses.includes(TripStatus.inProgress)) {
      statuses.push('picking', 'putting');
    }
    if (tripStatuses.includes(TripStatus.notStarted)) {
      statuses.push('unstarted');
    }
    return statuses;
  }
}

export default MovementTasks;
