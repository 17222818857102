import * as React from 'react';
import {Button, Icon, Row} from '@flexe/ui-components';

interface RouteWaveIntroCardProps {
  createRouteWaveButtonAction: () => void;
}

const RouteWavesIntroCard: React.FC<RouteWaveIntroCardProps> = ({createRouteWaveButtonAction}) => {
  return (
    <div id="route-waves-intro-card" data-testid="route-waves-intro-card">
      <div className="card roomy center-text">
        <h4>Create a Route Wave to Start</h4>
        <p>Adding Locations to a Wave lets you select and manage what you want to Route for each Wave.</p>
        <div className="center-buttons">
          <Button onPress={createRouteWaveButtonAction} visualType="secondary">
            <Row margin={['0', '100']}>
              <Icon icon="plus" />
              <span>New Route Wave</span>
            </Row>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RouteWavesIntroCard;
