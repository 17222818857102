import * as React from 'react';
import {FunctionComponent, useState} from 'react';
import {useForm} from 'react-hook-form';
import {format as formatDate, parse} from 'date-fns';
import {cloneDeep} from 'lodash';
import FlexeButton from '../FlexeButton';
import {Item} from '../../shared/services/ItemMasterService';
import {WarningCard} from '../../warehouse/outbound-shipments/shared/WarningCard';
import {CycleCountItem, CycleCountItemMetadata} from './CycleCountInterfaces';

interface Props {
  id: string;
  forShipper: boolean;
  disabled: boolean;
  cycleCountItem: CycleCountItem;
  toggleItemDetailsModalWithId: (itemId: string, locationId?: string) => void;
  saveLpnMetadataEdits: (data) => Promise<void>;
  inventoryInfoForCCItem: Item[];
}

// list input names here for react-hook-form
interface Inputs {
  lotCode: string;
  expirationDate: Date;
}

const ItemPropertiesMetadataForm: FunctionComponent<Props> = (props) => {
  const {handleSubmit, register} = useForm<Inputs>();
  const [missingLotCode, setMissingLotCode] = React.useState(false);
  const [missingExpiryDate, setMissingExpiryDate] = React.useState(false);

  const onSubmit = (data) => {
    let updatedItem: CycleCountItem = cloneDeep(props.cycleCountItem);
    if (updatedItem.metadata) {
      updatedItem.metadata.actual = {
        id: 0,
        metadata: {
          lotCode: null,
          expirationDate: null,
          manufactureDate: null
        },
        uoms: {
          eachesPerInnerPack: null,
          eachesPerOuterCase: null
        }
      };
      updatedItem.metadata.actual.id = parseInt(data.itdId, 10);

      if (data.lotCode) {
        updatedItem.metadata.actual.metadata.lotCode = data.lotCode;
      }
      if (data.expDate) {
        updatedItem.metadata.actual.metadata.expirationDate = data.expDate;
      }
    } else {
      const newMetadata: CycleCountItemMetadata = {
        created: null,
        expected: {
          id: 0,
          metadata: {
            expirationDate: null,
            lotCode: null,
            manufactureDate: null
          },
          uoms: {eachesPerInnerPack: null, eachesPerOuterCase: null}
        },
        actual: {
          id: 0,
          metadata: {
            lotCode: data.lotCode,
            expirationDate: data.expDate,
            manufactureDate: null
          },
          uoms: {
            eachesPerInnerPack: null,
            eachesPerOuterCase: null
          }
        },
        counted: undefined
      };
      updatedItem = {
        ...updatedItem,
        metadata: newMetadata
      };
    }

    props.toggleItemDetailsModalWithId(data.itdId);
    props.saveLpnMetadataEdits(updatedItem);
  };

  const dateAcrobatics = (dateFromMetadata: string) => {
    // incoming date format: 2021-08-31
    if (dateFromMetadata) {
      return formatDate(dateFromMetadata.slice(0, 10), 'YYYY-MM-DD');
    } else {
      return null;
    }
  };

  // the optional chaining operators here support loose goods with ITDs that will add metadata later
  const actualMetadata = props.cycleCountItem.metadata?.actual?.metadata;
  const expectedMetadata = props.cycleCountItem.metadata?.expected?.metadata;
  const actualMetadataExists = Boolean(actualMetadata);
  const [expiryDateVal, setExpiryDateVal] = React.useState(
    actualMetadataExists
      ? dateAcrobatics(actualMetadata?.expirationDate || '')
      : dateAcrobatics(expectedMetadata?.expirationDate || '')
  );
  const [lotCodeVal, setLotCodeVal] = React.useState(
    actualMetadataExists ? actualMetadata?.lotCode || '' : expectedMetadata?.lotCode || ''
  );

  React.useEffect(() => {
    if (!expiryDateVal && expDateIsEnabled) {
      setMissingExpiryDate(true);
    } else {
      setMissingExpiryDate(false);
    }
  }, [expiryDateVal]);

  React.useEffect(() => {
    if (!lotCodeVal && lotCodeIsEnabled) {
      setMissingLotCode(true);
    } else {
      setMissingLotCode(false);
    }
  }, [lotCodeVal]);

  const registerExpDateChange = (e) => {
    setExpiryDateVal(e.target.value);
  };

  const registerLotCodeChange = (e) => {
    setLotCodeVal(e.target.value);
  };

  let lotCodeIsEnabled: boolean;
  let expDateIsEnabled: boolean;
  if (props.inventoryInfoForCCItem && props.inventoryInfoForCCItem.length > 0) {
    lotCodeIsEnabled = props.inventoryInfoForCCItem[0].lotCodeTrackingEnabled;
    expDateIsEnabled = props.inventoryInfoForCCItem[0].expiryDateTrackingEnabled;
  } else {
    lotCodeIsEnabled = props.cycleCountItem?.inventory?.lotCodeTrackingEnabled || false;
    expDateIsEnabled = props.cycleCountItem?.inventory?.expiryDateTrackingEnabled || false;
  }

  return (
    <form
      data-testid="itd-metadata-form"
      className="lpn-metadata"
      id="lpn-metadata-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div data-testid="lot-code">
        {lotCodeIsEnabled ? (
          <div className="form-field">
            <label>Lot Code:</label>
            <input
              data-testid="lot-code-field"
              name="lotCode"
              value={lotCodeVal || ''}
              onChange={registerLotCodeChange}
              ref={register}
            />
          </div>
        ) : null}
      </div>
      <div data-testid="expiration-date">
        {expDateIsEnabled ? (
          <div className="form-field">
            <label>Expiration Date:</label>
            <input
              type="date"
              data-testid="exp-date-field"
              name="expDate"
              value={expiryDateVal || ''}
              onChange={registerExpDateChange}
              ref={register}
            />
          </div>
        ) : null}
      </div>
      <div className="form-field hidden">
        <input
          data-testid="itd-id-field"
          name="itdId"
          ref={register}
          value={props.cycleCountItem.metadata?.expected?.id || 0}
          readOnly={true}
        />
      </div>
      <div>
        {missingLotCode && (
          <WarningCard>
            <div data-testid="missing-lot-code-warning">
              <b>Lot Code Tracked SKU </b>
              {`You will need to add a Lot Code before this inventory can be added to this location.`}
            </div>
          </WarningCard>
        )}
        {missingExpiryDate && (
          <WarningCard>
            <div data-testid="missing-lot-code-warning">
              <b>Expiration Date Tracked SKU </b>
              {`You will need to add an Expiration Date before this inventory can be added to this location.`}
            </div>
          </WarningCard>
        )}
      </div>
      <FlexeButton
        level="primary"
        text="Save"
        type="submit"
        isDisabled={props.disabled || missingLotCode || missingExpiryDate}
      />
    </form>
  );
};

export default ItemPropertiesMetadataForm;
