import * as React from 'react';
import {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Loader} from '@flexe/ui-components';
import WaveTemplateServiceV2 from '../../../shared/services/WaveTemplateServiceV2';
import {Reservation} from '../../../shared/CommonInterfaces';
import WarehouseService from '../../../shared/services/WarehouseService';
import FlexeButton from '../../../shared/FlexeButton';
import {OrderType} from '../../ecommerce-batches/BatchInterfaces';
import {WaveTemplateV2} from './WaveTemplateInterfaces';
import OrderProfile from './OrderProfile';
import SkuMix from './SkuMix';
import PackagingOptions from './PackagingOptions';
import PostalCodeRange from './PostalCodeRange';
import ReservationSelect, {ReservationOption} from './ReservationSelect';
import CustomAttributesInput from './CustomAttributesInput';
import CarrierSelect from './CarrierSelect';
import SkuSelect from './SkuSelect';

interface WaveTemplateFormProps {
  templateId?: number;
  header: string;
  nameSuffix?: string;
  warehouseId: number;
  carrierLabels: {[key: string]: string};
  serviceLevelMap: {[key: string]: string[]};
  authenticityToken: string;
  saveFunc: (waveTemplateV2: WaveTemplateV2) => Promise<any>;
}

interface Inputs {
  templateName: string;
  reservation: ReservationOption;
  carrier: string;
  serviceLevel: string[];
  shipmentType: string;
  skuVariety: string;
  purchaseOrder: string;
  packaging: string;
  skus: string[];
  postalCodeRangeStart: string;
  postalCodeRangeEnd: string;
  customAttributeKey: string;
  customAttributeValue: string;
}

const WaveTemplateForm: React.FC<WaveTemplateFormProps> = ({
  templateId,
  header,
  nameSuffix,
  warehouseId,
  carrierLabels,
  serviceLevelMap,
  authenticityToken,
  saveFunc
}) => {
  const waveTemplateServiceV2 = new WaveTemplateServiceV2();
  const warehouseService = new WarehouseService(authenticityToken);

  const methods = useForm<Inputs>();
  const {setValue, watch} = methods;

  const [isLoading, setIsLoading] = useState(true);
  const [savedTemplate, setSavedTemplate] = useState<WaveTemplateV2>(null);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [pageLoadError, setPageLoadError] = useState<string>(null);
  const [skuError, setSkuError] = useState<string>(null);
  const [updateError, setUpdateError] = useState<string>(null);
  const [selectedReservationId, setSelectedReservationId] = useState<number | null>(null);
  const [savedSkus, setSavedSkus] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await loadSavedTemplate();
        await loadReservations();
      } catch (err) {
        setPageLoadError(err.toString());
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  async function loadSavedTemplate() {
    if (!templateId) {
      setSavedTemplate(null);
      return;
    }
    const waveTemplateResponse = await waveTemplateServiceV2.getWaveTemplate(templateId);
    const template = waveTemplateResponse?.data;
    const errors = waveTemplateResponse?.errors || [];
    if (!template || errors.length > 0) {
      if (errors[0]?.code === '404') {
        setPageLoadError(`Template with ID ${templateId} was not found.`);
      } else {
        setPageLoadError('There was an error fetching the template.');
      }
    }
    setSavedTemplate(template);
    setSelectedReservationId(template?.waveFilters?.reservationId || null);
    setSavedSkus(template?.waveFilters?.skus || []);
  }

  async function loadReservations() {
    const reservationsResponse = await warehouseService.getReservationsForWarehouse(warehouseId);
    const reservationsData = reservationsResponse?.data?.reservations;
    if (!reservationsData || (reservationsResponse?.errors && reservationsResponse?.errors.length > 0)) {
      setPageLoadError('There was an error fetching reservations for this warehouse.');
    } else if (reservationsData?.length === 0) {
      setPageLoadError('There are no active reservations for this warehouse.');
    }
    setReservations(reservationsData);
  }

  function renderError(errorMsg) {
    return (
      errorMsg && (
        <>
          <div className="alert alert-danger" role="alert">
            {errorMsg}
          </div>
          <br />
        </>
      )
    );
  }

  function renderTemplateNameInput() {
    let defaultValue = savedTemplate?.templateName;
    if (nameSuffix) {
      defaultValue = `${defaultValue} ${nameSuffix}`;
    }
    return (
      <div className="form-field">
        <label>
          Template Name
          <br />
          <span className="input-hint">Give your template a name you can remember</span>
        </label>
        <input
          data-testid="template-name-field"
          className="wave-template-input text-input"
          name="templateName"
          defaultValue={defaultValue}
          ref={methods.register({required: 'Template Name is required'})}
        />
        <span className="required-input">
          {methods.errors.templateName && <span className="validation-msg">{methods.errors.templateName.message}</span>}
        </span>
      </div>
    );
  }

  function renderSkus() {
    return (
      <SkuSelect
        savedSkus={savedSkus}
        authenticityToken={authenticityToken}
        reservationId={selectedReservationId}
        errorCallback={function() {
          setSkuError('There was an error fetching SKUs.');
        }}
        clearErrorsCallback={function() {
          setSkuError(null);
        }}
      />
    );
  }

  function renderPurchaseOrderInput() {
    return (
      <div className="form-field">
        <label>
          P.O. Contains (optional)
          <br />
          <span className="input-hint">
            {' '}
            Include a reoccurring set of numbers and/or characters to target groups of Purchase Orders
          </span>
        </label>
        <input
          data-testid="purchase-order-field"
          className="wave-template-input text-input"
          name="purchaseOrder"
          defaultValue={savedTemplate?.waveFilters?.purchaseOrder}
          ref={methods.register}
        />
      </div>
    );
  }

  function renderCustomAttributes() {
    const savedCustomAttrKeys = Object.keys(savedTemplate?.waveFilters?.customAttributes || {});
    // Only one custom attribute is currently supported
    const savedValue =
      savedCustomAttrKeys.length > 0
        ? {
            name: savedCustomAttrKeys[0],
            value: savedTemplate.waveFilters.customAttributes[savedCustomAttrKeys[0]]
          }
        : null;
    return <CustomAttributesInput savedValue={savedValue} />;
  }

  function renderForm() {
    return (
      <div className="form-body">
        <fieldset form="wave-template-form" id="fieldset-one">
          {renderTemplateNameInput()}
          <ReservationSelect
            reservations={reservations}
            savedReservationId={savedTemplate?.waveFilters?.reservationId}
            onReservationSelect={handleReservationSelect}
          />
          <CarrierSelect
            carrierLabels={carrierLabels}
            serviceLevelMap={serviceLevelMap}
            savedCarriers={savedTemplate?.waveFilters?.carriers}
            savedServiceLevels={savedTemplate?.waveFilters?.serviceTypes}
          />
          <OrderProfile savedShipmentType={savedTemplate?.waveFilters?.shipmentType} />
          <SkuMix savedSkuVariety={savedTemplate?.waveFilters?.skuVariety} />
          <PackagingOptions savedWaveFilters={savedTemplate?.waveFilters} />
          {renderSkus()}
          {renderPurchaseOrderInput()}
          <PostalCodeRange savedPostalCodeRange={savedTemplate?.waveFilters?.postalCodeRange} />
          {renderCustomAttributes()}
        </fieldset>
      </div>
    );
  }

  const saveTemplate = async (updatedTemplate: WaveTemplateV2) => {
    setUpdateError(null);
    const response = await saveFunc(updatedTemplate);
    if (response && response.errors && response.errors.length > 0) {
      setUpdateError(`There was an error saving the template: ${response.errors[0].detail}`);
    } else if (!response?.data) {
      setUpdateError(`There was an error saving the template.`);
    } else {
      window.location.assign(`/wh/fulfillment/ecommerce/waving`);
    }
  };

  const onSubmit = (data) => {
    const updatedTemplate: WaveTemplateV2 = {
      id: templateId,
      warehouseId,
      templateName: data.templateName,
      waveFilters: {
        reservationId: data.reservation.value,
        carriers: [data.carrier.value],
        serviceTypes: data.serviceLevel?.map((serviceLevel) => serviceLevel.label),
        shipmentType: data.shipmentType,
        skuVariety: data.skuVariety,
        purchaseOrder: data.purchaseOrder,
        skus: data.skus?.map((sku) => sku.label)
      }
    };
    switch (data.packaging) {
      case 'ship-alone':
        updatedTemplate.waveFilters.shipmentType = OrderType.ShipAlone;
        break;
      case 'overbox':
        updatedTemplate.waveFilters.shipAsIs = false;
        break;
      case 'ship-as-is':
        updatedTemplate.waveFilters.shipAsIs = true;
        break;
      case 'hazmat':
        updatedTemplate.waveFilters.includesHazmat = true;
        break;
      case 'site-to-store':
        updatedTemplate.waveFilters.siteToStoreOnly = true;
        break;
    }
    if (data.postalCodeRangeStart && data.postalCodeRangeEnd) {
      updatedTemplate.waveFilters.postalCodeRange = {start: data.postalCodeRangeStart, end: data.postalCodeRangeEnd};
    }
    if (data.customAttributeKey && data.customAttributeValue) {
      const attributeMap = {};
      attributeMap[data.customAttributeKey] = data.customAttributeValue;
      updatedTemplate.waveFilters.customAttributes = attributeMap;
    }

    saveTemplate(updatedTemplate);
  };

  const handleReservationSelect = (reservation: ReservationOption | null) => {
    setSelectedReservationId(reservation?.value || null);
    setSavedSkus([]);
    setValue('skus', []);
  };

  return (
    <>
      {renderError(pageLoadError)}
      {renderError(skuError)}
      {renderError(updateError)}
      <div className="container-fluid new-wave-template">
        <FormProvider {...methods}>
          <form
            data-testid="wave-template-form"
            className="new-wave-template"
            id="wave-template-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="form-header">
              <h1>{header}</h1>
              <div className="form-buttons">
                <FlexeButton
                  testid="save-top"
                  level="primary"
                  text="Save"
                  type="submit"
                  isDisabled={isLoading || !!pageLoadError}
                />
              </div>
            </div>
            {isLoading && <Loader loading={true} />}
            {!pageLoadError && <div>{!isLoading && renderForm()}</div>}
            <div className="form-footer">
              <div className="form-buttons">
                <FlexeButton
                  testid="save-bottom"
                  level="primary"
                  text="Save"
                  type="submit"
                  isDisabled={isLoading || !!pageLoadError}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default WaveTemplateForm;
