/** @jsxRuntime classic */
/** @jsx jsx */

import * as React from 'react';
import {jsx} from '@emotion/react';
import {useTable} from '@flexe/ui-components';

interface Props {
  crossdockEnabled: boolean;
  directedPutAwayEnabled: boolean;
  zonesEnabled: boolean;
  primarySkuEnabled: boolean;
}

const SampleDataTable = ({crossdockEnabled, directedPutAwayEnabled, zonesEnabled, primarySkuEnabled}: Props) => {
  const {tableStyles} = useTable({headerBorder: true});
  return (
    <React.Fragment>
      <table {...tableStyles}>
        <thead>
          <tr>
            <th>Column&nbsp;Header</th>
            <th>Sample&nbsp;Input</th>
            <th>Description</th>
            <th>Guidelines</th>
          </tr>
        </thead>
        <tbody className="locations-modal__sample-data-table-body">
          <tr>
            <td>
              <span className="locations-modal__required-asterix">*</span> Name
            </td>
            <td>FS-A001-C22</td>
            <td>How the location is identified and labeled</td>
            <td>Alphanumeric string, must be unique</td>
          </tr>
          <tr>
            <td>
              <span className="locations-modal__required-asterix">*</span> Category
            </td>
            <td>floor_storage</td>
            <td>The type of location</td>
            <td>
              Options include:
              <ul>
                <li>
                  <i>floor_storage</i>
                </li>
                <li>
                  <i>rack_storage</i>
                </li>
                <li>
                  <i>bin_storage</i>
                </li>
                <li>
                  <i>inbound_staging</i>
                </li>
                <li>
                  <i>outbound_staging</i>
                </li>
                <li>
                  <i>pack_station</i>
                </li>
                <li>
                  <i>quality_audit</i>
                </li>
                <li>
                  <i>outbound_returns_staging</i>
                </li>
                <li>
                  <i>dock</i>
                </li>
                {crossdockEnabled && (
                  <li>
                    <i>crossdock</i>
                  </li>
                )}
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <span className="locations-modal__required-asterix">*</span> Barcode
            </td>
            <td>DIWUJS39</td>
            <td>Scannable location barcode</td>
            <td>Alphanumeric string, may be identical to Name. Must be unique, 1 per active location</td>
          </tr>
          <tr>
            <td>Length</td>
            <td>72</td>
            <td>The length of the location</td>
            <td rowSpan={6}>
              <p>Dimensions help Flexe recommend inventory for that space</p>

              <p>Length, Width, and Height: numeric string </p>
              <div>Length, Width, and Height Units:</div>
              <ul>
                <li>
                  <b>in</b>: inches
                </li>
                <li>
                  <b>ft</b>: feet
                </li>
                <li>
                  <b>cm</b>: centimeters
                </li>
                <li>
                  <b>m</b>: meters
                </li>
              </ul>
              <p>Either all or none of these values should be provided</p>
            </td>
          </tr>
          <tr>
            <td>Length Unit</td>
            <td>in</td>
            <td>The unit for the length dimension</td>
          </tr>
          <tr>
            <td>Width</td>
            <td>60.5</td>
            <td>The width of the location</td>
          </tr>
          <tr>
            <td>Width Unit</td>
            <td>in</td>
            <td>The unit for the width dimension</td>
          </tr>
          <tr>
            <td>Height</td>
            <td>60</td>
            <td>The height of the location</td>
          </tr>
          <tr>
            <td>Height Unit</td>
            <td>in</td>
            <td>The unit for the height dimension</td>
          </tr>
          <tr>
            <td>Sequence ID</td>
            <td>1200378</td>
            <td>Determines picker’s priority order</td>
            <td>Numeric string, must be unique</td>
          </tr>
          {directedPutAwayEnabled && (
            <tr>
              <td>Pallet Capacity</td>
              <td>0</td>
              <td>This value represents the number of pallets that can be stored in the location</td>
              <td>
                Numeric string. It can only be set for location category types <i>rack_storage</i> and{' '}
                <i>floor_storage</i>
              </td>
            </tr>
          )}
          <tr>
            <td>Primary Pick Location</td>
            <td>True</td>
            <td>
              Setting a primary pick location to true prioritizes the location over others storing the same SKU when
              picking
            </td>
            <td>
              {'Must be either True or False. This value can only be set for location category types '}
              <i>rack_storage</i> and <i>floor_storage</i>
            </td>
          </tr>
          {zonesEnabled && (
            <tr>
              <td>Put Away Zone</td>
              <td>ZONE-1</td>
              <td>
                The purpose of a put away zone is to assign a group of locations based on certain inventory attributes
              </td>
              <td>
                String. This value should be a value declared in the <i>route_to_zone_name</i> field in the Zones table
              </td>
            </tr>
          )}
          {primarySkuEnabled && (
            <tr>
              <td>Primary Sku</td>
              <td>SAMPLESKU</td>
              <td>The primary SKU is the item the location is intending on storing</td>
              <td>String. Must be an exact match from the Item Master</td>
            </tr>
          )}
          {primarySkuEnabled && (
            <tr>
              <td>Primary Sku Packaging Type</td>
              <td>each</td>
              <td>The packaging type in which the max quantity will be set for </td>
              <td>
                String. Must be <i>each</i>, <i>carton</i>, <i>pallet</i> or <i>inner_pack</i>
              </td>
            </tr>
          )}
          {primarySkuEnabled && (
            <tr>
              <td>Primary Sku Max Quantity</td>
              <td>24</td>
              <td>The max number of units the primary can be stored in the location in the packaging type specified</td>
              <td>Numeric string</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="locations-modal__table-legend">
        <span className="locations-modal__required-asterix">*</span>{' '}
        <span className="locations-modal__required-text">Required</span>
      </div>
    </React.Fragment>
  );
};

export default SampleDataTable;
