/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react';
import {cloneDeep, uniqueId} from 'lodash';
import {DropEvent, FileRejection} from 'react-dropzone';
import {ReactNode} from 'react';
import {Button, Expander, FileSelector, InfoBox, Link, Modal, Text, useTable} from '@flexe/ui-components';
import * as Papa from 'papaparse';
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import {CsvUploadParams, MoreExplicitUploadParams} from '../shared/CommonInterfaces';

// this type means that requiredHeaders,
// oldRequiredHeaders, and templateUpdateDateString
// must ALL be provided when backwardsCompatible={true}
type CompatibilityProps =
  | {
      backwardsCompatible?: false;
      requiredHeaders: string[][];
      fileUploadType: string;
      oldRequiredHeaders?: never;
      oldFileUploadType?: never;
      templateUpdateDateString?: never;
    }
  | {
      backwardsCompatible: true;
      requiredHeaders: string[][];
      fileUploadType: string;
      oldRequiredHeaders: string[][];
      oldFileUploadType: string;
      templateUpdateDateString: string;
    };

interface CommonProps {
  showModal: boolean;
  toggleModal: () => void;
  templatePath: string;
  instructionsTableHeaders: ReactNode[];
  instructionsTableRows: ReactNode[][];
  uploading: boolean;
  handleUpload: (params: CsvUploadParams, type: string) => Promise<void>;
}

export type Props = CommonProps & CompatibilityProps;

const BulkContainerUploadModal: React.FunctionComponent<Props> = (props) => {
  const maxFileSizeMB = 2; // max .csv file size in MB
  const maxFileSize = maxFileSizeMB * 1024 * 1024;
  const [isExpanderOpen, setIsExpanderOpen] = React.useState<boolean>(false);
  const [showVersionAlert, setShowVersionAlert] = React.useState<boolean>(false);
  const [chosenFile, setchosenFile] = React.useState<any>({});
  const [uploadFileType, setUploadFileType] = React.useState<string>(props.fileUploadType);
  const [errors, setErrors] = React.useState<any>(null);
  const [uploadParams, setUploadParams] = React.useState<any>({file: {}});

  React.useEffect(() => {
    if (chosenFile.name) {
      const uploadParamsState = cloneDeep(uploadParams);
      uploadParamsState.file = chosenFile;
      const oldRows = props.oldRequiredHeaders;
      const newRows = props.requiredHeaders;
      const [oldRequiredFields, oldOptionalFields] = buildRequiredAndOptionalCsvFieldArrays(oldRows);
      const [newRequiredFields, newOptionalFields] = buildRequiredAndOptionalCsvFieldArrays(newRows);

      validateNewHeaders(
        chosenFile,
        oldRequiredFields,
        newRequiredFields,
        oldOptionalFields,
        newOptionalFields,
        uploadParamsState
      );
    }
  }, [chosenFile]);

  React.useEffect(() => {
    if (chosenFile.name) {
      setchosenFile(null);
    }
  }, []);

  const {tableStyles} = useTable({
    headerBorder: true,
    zebraStripe: true,
    showLegend: true
  });

  const toggleExpander = () => {
    setIsExpanderOpen(!isExpanderOpen);
  };

  const toggleVersionAlert = () => {
    setShowVersionAlert(!showVersionAlert);
  };

  const handleFileDrop = (droppedFiles: File[]) => {
    setErrors(null);
  };

  const handleRejectedFiles = (rejectedFiles: FileRejection[], event: DropEvent) => {
    // not used in this instance, bu required by FileSelector
  };

  const handleAcceptedFiles = (acceptedFiles: File[], event: DropEvent) => {
    const uploadParamsState = cloneDeep(uploadParams);
    uploadParamsState.file = chosenFile;
    const oldRows = props.oldRequiredHeaders;
    const newRows = props.requiredHeaders;
    const [oldRequiredFields, oldOptionalFields] = buildRequiredAndOptionalCsvFieldArrays(oldRows);
    const [newRequiredFields, newOptionalFields] = buildRequiredAndOptionalCsvFieldArrays(newRows);

    validateNewHeaders(
      acceptedFiles[0],
      oldRequiredFields,
      newRequiredFields,
      oldOptionalFields,
      newOptionalFields,
      uploadParamsState
    );
  };

  const handleDropzoneError = (err: Error) => {
    // not used in this instance, bu required by FileSelector
  };

  const fileInputHandleFileChange = (e) => {
    const files = e.target.files;
    let errs;
    const file = files[0];
    if (file && file.size <= maxFileSize) {
      if (file.name && file.name.match(/\.csv$/)) {
        setchosenFile(file);
        setUploadParams({file: {}});
        setErrors(null);
      } else {
        errs = <InfoBox info="The selected file is not in .csv format." infoType="error" />;
      }
    } else {
      errs = <InfoBox info={`Please select a .csv file less than ${maxFileSizeMB} MB.`} infoType="error" />;
    }
    if (errs) {
      setErrors(errs);
    }
  };

  const renderInstructionsTableRow = (row: ReactNode[]) => {
    return (
      <tr>
        {row.map((datum) => {
          return <td key={uniqueId('instructions-row-')}>{datum}</td>;
        })}
      </tr>
    );
  };

  const buildRequiredAndOptionalCsvFieldArrays = (rows): [string[], string[]] => {
    const requiredFields = [];
    const optionalFields = []; // This will include any deprecated fields so validation works
    if (rows && rows.length > 0) {
      rows.forEach((row) => {
        const name = row[0];
        if (row[1] === 'Yes') {
          requiredFields.push(name);
        } else {
          optionalFields.push(name);
        }
      });
    }
    return [requiredFields, optionalFields];
  };

  const validateNewHeaders = (
    file: File,
    oldReqFields: string[],
    newReqFields: string[],
    oldOptFields: string[],
    newOptFields: string[],
    uploadParamsFromState: MoreExplicitUploadParams
  ) => {
    setchosenFile(file);
    const reader = new FileReader();
    reader.readAsText(file);
    // This is a bug in the Typescript compiler. It is fixed in version 3.6, and
    // we can remove this cast to "any" when we upgrade:
    // https://github.com/microsoft/TypeScript/issues/25510
    reader.onload = (event: any) => {
      const headers = Papa.parse(event.target.result.toString(), {
        header: false,
        preview: 1
      });
      const formattedHeaders = headers.data[0]
        ? headers.data[0].map((header) => {
            const idxOptional = header.indexOf('(optional)');
            if (idxOptional >= 0) {
              header = header.slice(0, idxOptional).trim();
            }
            const idxRequired = header.indexOf('(required)');
            if (idxRequired >= 0) {
              header = header.slice(0, idxRequired).trim();
            }
            return header
              .trim()
              .toUpperCase()
              .replaceAll(' ', '_');
          })
        : headers.data[0];
      const allFields = newOptFields.concat(newReqFields);
      let validField = true;
      let erroneousHeader = '';
      // eslint-disable-next-line
      for (let i = 0; i < newReqFields.length; i++) {
        if (formattedHeaders === undefined) {
          validField = false;
          setErrors(<InfoBox info="The selected file is empty." infoType="error" />);
          break;
        }
        if (!formattedHeaders.includes(newReqFields[i])) {
          erroneousHeader = newReqFields[i];
          validField = false;
          break;
        }
      }
      let validHeader = true;
      if (validField) {
        // eslint-disable-next-line
        for (let i = 0; i < formattedHeaders.length; i++) {
          if (!allFields.includes(formattedHeaders[i])) {
            erroneousHeader = formattedHeaders[i];
            validHeader = false;
            break;
          }
        }
      }
      if (validField && validHeader) {
        headersMatchTemplate(uploadParamsFromState);
      } else {
        // TODO: make error feedback more robust
        if (!errors) {
          validateOldHeaders(file, oldReqFields, oldOptFields, uploadParamsFromState);
        }
      }
    };
  };

  const validateOldHeaders = (
    file: File,
    requiredFields: string[],
    optionalFields: string[],
    uploadParamsFromState: MoreExplicitUploadParams
  ) => {
    const reader = new FileReader();
    reader.readAsText(file);
    // This is a bug in the Typescript compiler. It is fixed in version 3.6, and
    // we can remove this cast to "any" when we upgrade:
    // https://github.com/microsoft/TypeScript/issues/25510
    reader.onload = (event: any) => {
      const headers = Papa.parse(event.target.result.toString(), {
        header: false,
        preview: 1
      });
      const allFields = optionalFields.concat(requiredFields);
      let validField = true;
      let erroneousHeader = '';
      // eslint-disable-next-line
      for (let i = 0; i < requiredFields.length; i++) {
        if (!headers.data[0].includes(requiredFields[i])) {
          erroneousHeader = requiredFields[i];
          validField = false;
          break;
        }
      }
      let validHeader = true;
      if (validField) {
        // eslint-disable-next-line
        for (let i = 0; i < headers.data[0].length; i++) {
          if (!allFields.includes(headers.data[0][i])) {
            erroneousHeader = headers.data[0][i];
            validHeader = false;
            break;
          }
        }
      }
      if (validField && validHeader) {
        headersMatchTemplate(uploadParamsFromState, true);
      } else {
        headersDoNotMatchTemplate();
      }
    };
  };

  const headersMatchTemplate = (params, isOldVersion: boolean = false) => {
    if (isOldVersion) {
      setShowVersionAlert(true);
      setUploadFileType(props.oldFileUploadType);
      setUploadParams(params);
    } else {
      submitNewTemplateCsv(params);
    }
  };

  const headersDoNotMatchTemplate = () => {
    setErrors(<InfoBox info="The selected file headers do not match the template." infoType="error" />);
  };

  const submitNewTemplateCsv = (params) => {
    setUploadParams(params);
    setUploadFileType(props.fileUploadType);
  };

  const submitOldTemplateCsv = () => {
    setUploadParams(uploadParams);
    setShowVersionAlert(false);
  };

  const handleUpload = () => {
    props.handleUpload(uploadParams, uploadFileType);
    setUploadParams({file: {}});
    setErrors(null);
  };

  const handleToggleModal = () => {
    setErrors(null);
    setchosenFile({});
    props.toggleModal();
  };

  const requiredStyles = css({
    color: tokens.color.base.red.v300.value
  });

  const footerStyles = css({
    textAlign: 'right'
  });

  const fileSelectorWrapStyles = css({
    maxWidth: '80%',
    paddingBottom: `${tokens.spacing.v400.value}px`
  });

  // TODO: PORT-361
  // delete this style const
  const overrideModalHeaderStyles = css({
    fontSize: `${tokens?.font.b400.size.value}px` || '16px',
    fontWeight: tokens?.font.b400.weight.value || '500',
    display: 'none'
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={props.showModal}
        toggleModal={handleToggleModal}
        title="Create Containers from a CSV File"
        subtitle={
          // TODO: PORT-361
          // remove the <span>, <InfoBox> and <ReactFragment>
          // to remove the version-change warning message
          <React.Fragment>
            <Text tag="p" level="Body1" bold={false}>
              Upload a CSV file to create containers. Please ensure you are using the current CSV{' '}
              <Link href={props.templatePath}>template</Link>.
            </Text>
            <span css={overrideModalHeaderStyles}>
              <InfoBox info="Update!" infoType="warning">
                The CSV template changed on 8/10/22. Please ensure you are using the latest version
              </InfoBox>
            </span>
          </React.Fragment>
        }
        size="full"
      >
        <div css={fileSelectorWrapStyles}>
          <FileSelector
            dropzoneProps={{
              onFileDrop: handleFileDrop,
              onAcceptedFiles: handleAcceptedFiles,
              onRejectedFiles: handleRejectedFiles,
              onDropzoneError: handleDropzoneError,
              acceptedFileTypes: {'text/csv': []},
              multiple: false
            }}
            fileInputAccept=".csv"
            fileInputOnChange={fileInputHandleFileChange}
            selectedFile={chosenFile.name}
            errors={errors}
          />
        </div>
        <Expander
          title="See a sample of the data required for your file"
          isOpen={isExpanderOpen}
          maxHeight={600}
          onToggle={toggleExpander}
        >
          <table {...tableStyles}>
            <thead>
              <tr>
                {props.instructionsTableHeaders.map((header: string) => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.instructionsTableRows.map((row) => {
                return <React.Fragment key={uniqueId('info-box-')}>{renderInstructionsTableRow(row)}</React.Fragment>;
              })}
              <tr>
                <td colSpan={4}>
                  <Text tag="span" color={tokens.color.base.gray.v300.value} fontStyle="italic">
                    <span css={requiredStyles}>*</span> Required
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </Expander>
        <div css={footerStyles}>
          <Button visualType="primary" onPress={handleUpload} isDisabled={!chosenFile.name || !!errors}>
            Submit
          </Button>
        </div>
      </Modal>
      <Modal title="Out of Date Template" size="small" isOpen={showVersionAlert} toggleModal={toggleVersionAlert}>
        <div>
          <p>Your CSV upload template is out-of-date. This template will stop being supported in the near future.</p>
          <p className="bold">
            Please download the <a href={props.templatePath}>new example template</a> and update your process as soon as
            possible.
          </p>
          <button onClick={submitOldTemplateCsv}>Continue</button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default BulkContainerUploadModal;
