import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {OrderType, SkuVariety} from '../../ecommerce-batches/BatchInterfaces';

interface SkuMixProps {
  savedSkuVariety?: SkuVariety;
}

const SkuMix: React.FC<SkuMixProps> = ({savedSkuVariety}) => {
  const {errors, getValues, register} = useFormContext();

  return (
    <>
      <div className="form-field" data-testid="sku-variety-mode">
        <label>
          Specify SKU Mix
          <br />
          <span className="input-hint">Wave only single-SKU or multi-SKU shipments.</span>
        </label>
        <div className="rbtn-group">
          <input
            className="wave-template-input"
            type="radio"
            name="skuVariety"
            id="sku-variety-single-and-multi-sku"
            data-testid="sku-variety-single-and-multi-sku"
            value={SkuVariety.SingleOrMultiSku}
            defaultChecked={![SkuVariety.SingleSku, SkuVariety.MultiSku].includes(savedSkuVariety)}
            ref={register}
          />
          <label htmlFor="sku-variety-single-and-multi-sku">Both Single and Multi-SKU</label>

          <input
            className="wave-template-input"
            type="radio"
            name="skuVariety"
            id="sku-variety-single-sku"
            data-testid="sku-variety-single-sku"
            value={SkuVariety.SingleSku}
            defaultChecked={savedSkuVariety === SkuVariety.SingleSku}
            ref={register}
          />
          <label htmlFor="sku-variety-single-sku">Single-SKU Only</label>

          <input
            className="wave-template-input"
            type="radio"
            name="skuVariety"
            id="sku-variety-multi-sku"
            data-testid="sku-variety-multi-sku"
            value={SkuVariety.MultiSku}
            defaultChecked={savedSkuVariety === SkuVariety.MultiSku}
            ref={register({
              validate: {
                noConflictingOptions: (skuType) => {
                  const shipmentType = getValues('shipmentType');
                  if (skuType === SkuVariety.MultiSku && shipmentType === OrderType.SingleItem) {
                    return 'Using \'Multi-SKU Only\' with \'Single-unit Only\' will never produce any results';
                  }
                }
              }
            })}
          />
          <label htmlFor="sku-variety-multi-sku">Multi-SKU Only</label>
        </div>
      </div>
      {errors.skuVariety && <span className="required-input validation-msg">{errors.skuVariety.message}</span>}
    </>
  );
};

export default SkuMix;
