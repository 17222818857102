import * as React from 'react';
import {determineLink, renderItemLink, renderShipment} from '../../../../libs/helpers';
import {BatchErrorDetailProps} from './BatchErrorDetailProps';

export const InsufficientInventoryError: React.FC<BatchErrorDetailProps> = ({alert}) => {
  const shipmentId = alert.shipmentIds?.[0];
  return (
    <React.Fragment>
      {`Incomplete `}
      <b>{determineLink(alert.waveId, 'WavingBatching::Models::PickWave', null, false)}</b>
      {`, `}
      {shipmentId && (
        <>
          <b>{renderShipment(shipmentId, false, false, true, false)}</b>
          {` contains at least one `}
          <b>{renderItemLink(alert.itemId, alert.sku, false)}</b>
          {` short inventory${alert.lotCode != null ? ' for the ordered lot: ' + alert.lotCode : ''}.`}
        </>
      )}
      {!shipmentId && (
        <>
          {` short at least one `}
          <b>{renderItemLink(alert.itemId, alert.sku, false)}</b>
          {`${alert.lotCode != null ? ' for the ordered lot: ' + alert.lotCode : ''}.`}
        </>
      )}
    </React.Fragment>
  );
};
