import * as React from 'react';
import axios from 'axios';
import {cloneDeep, get} from 'lodash';
import {useContext, useEffect, useState} from 'react';
import {
  ContainerDelivery,
  ContainerDeliveryState,
  PackingListItem,
  PackingLists,
  Pallet
} from '../shared/DropoffInterfaces';
import DropoffHelper from '../shared/DropoffHelper';
import {
  ApiResponse,
  Company,
  CompanyType,
  Document,
  DocumentNotableType,
  Reservation
} from '../../shared/CommonInterfaces';
import ConfirmationOfDelivery from '../shared/ConfirmationOfDelivery';
import ContainerContents from '../shared/ContainerContents';
import {AlteredDropoffConfirmation} from '../shared/AlteredDropoffReconfirmation';
import {DropDownOption} from '../../shared/DropDown';
import DocumentManagerSmall from '../../shared/DocumentManagerSmall';
import InlineEdit from '../../shared/InlineEdit';
import DropoffContext from '../../contexts/DropoffContext';
import {BLANK_CONTAINER, EMPTY_PACKING_LISTS} from '../shared/DropoffConstants';
import {InboundDetailsHeaderPane} from '../shared/InboundDetailsHeaderPane';
import FulfillmentInboundConfirmed from './FulfillmentInboundConfirmed';

const FulfillmentInbound: React.FC = () => {
  const context = useContext(DropoffContext);
  const notableType = DocumentNotableType.container;
  //state
  const [errors, setErrors] = React.useState<string[]>([]);
  const [successMessage, setSuccessMessage] = React.useState<string>(null);
  const [containerDelivery, setContainerDelivery] = React.useState<ContainerDelivery>(BLANK_CONTAINER);
  const [containerDeliveryStartTime, setContainerDeliveryStartTime] = React.useState<number>(
    context.containerDeliveryStartTime
  );
  const [containerDeliveryEndTime, setContainerDeliveryEndTime] = React.useState<number>(
    context.containerDeliveryEndTime
  );
  const [shipperCompany, setShipperCompany] = React.useState<Company>({
    id: 0,
    enableTihiDuringInbound: false
  });
  const [reservation, setReservation] = React.useState<Reservation>({
    id: 0,
    usesMobile: false
  });
  const [availableStagingLocations, setAvailableStagingLocations] = React.useState<DropDownOption[]>([]);
  const [pallets, setPallets] = React.useState<Pallet[]>([]);
  const [packingLists, setPackingLists] = React.useState<PackingLists>(EMPTY_PACKING_LISTS);
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [editingInstructions, setEditingInstructions] = React.useState<boolean>(false);
  const [instructions, setInstructions] = React.useState<string>(null);
  const [showUploadDocumentModal, setShowUploadDocumentModal] = React.useState<boolean>(false);
  const [containerLoaded, setContainerLoaded] = React.useState<boolean>(false);
  const [inputTextMap, setInputTextMap] = useState(new Map<string, string>());
  const [isEditing, setIsEditing] = useState(false);
  const {documentsService, containersService, warehouseService, containerDeliveryId} = context;
  const [isBolUploaded, setIsBolUploaded] = useState<boolean>(false);

  //replaces componentDidMount
  useEffect(() => {
    loadContainer();
  }, []);

  useEffect(() => {
    if (containerLoaded) {
      loadDocuments();
      const isNotConfirmed: boolean =
        containerDelivery.txnState === ContainerDeliveryState.new ||
        containerDelivery.txnState === ContainerDeliveryState.altered;
      if (isNotConfirmed) {
        setIsEditing(true);
      }
    }
  }, [containerLoaded]);

  const loadContainer = async () => {
    const response = await containersService.getShipment(containerDeliveryId);
    if (response && response.errors.length === 0) {
      const updatedContainerDelivery: ContainerDelivery = response.data.shipmentDetails;
      const startTime = updatedContainerDelivery.receiving.startAt
        ? DropoffHelper.hoursAndMinutesToMs(updatedContainerDelivery.receiving.startAt)
        : containerDeliveryStartTime;
      const endTime = updatedContainerDelivery.receiving.endAt
        ? DropoffHelper.hoursAndMinutesToMs(updatedContainerDelivery.receiving.endAt)
        : containerDeliveryEndTime;

      setContainerDelivery(updatedContainerDelivery);
      setContainerDeliveryStartTime(startTime);
      setContainerDeliveryEndTime(endTime);
      setShipperCompany(response.data.shipperCompany);
      setReservation(response.data.reservation);
      setAvailableStagingLocations(response.data.availableStagingLocations);
      setPallets(response.data.pallets);
      setPackingLists(response.data.packingLists);
      setContainerLoaded(true);
    } else {
      handleApiError([`There was an error retrieving information for dropoff #${containerDelivery.id}`], response);
      setContainerLoaded(false);
    }
  };

  const handleInputTextMap = (eventTargetValue: string, propertyKey: string) => {
    const updatedInputTextMap: Map<string, string> = new Map<string, string>(inputTextMap);
    updatedInputTextMap.set(propertyKey, eventTargetValue);
    setInputTextMap(updatedInputTextMap);
  };

  const resetSuccessMessage = () => {
    window.setTimeout(() => {
      setSuccessMessage(null);
    }, 5000);
  };

  const handleSavedDoc = (savedDoc: Document) => {
    const updatedDocuments = documents.concat(savedDoc);
    const updatedContainerDelivery = cloneDeep(containerDelivery);
    updatedContainerDelivery.hasBillOfLading = DropoffHelper.hasBillOfLading(updatedDocuments, shipperCompany.id);
    setDocuments(documents.concat(savedDoc));
    setContainerDelivery(updatedContainerDelivery);
  };

  const handleIsBolUploaded = (isUploaded: boolean) => {
    setIsBolUploaded(isUploaded);
  };

  const handleApiError = (errs: string[], response: ApiResponse<any>) => {
    if (response) {
      errs[0] += ':';
      response.errors.map((error) => errs.push(error.detail));
    }
    setErrors(errs);
  };

  const loadDocuments = async () => {
    let loadDocErrors;
    try {
      const [shipperDocs, warehouseDocs] = await axios.all([
        documentsService.getDocuments(CompanyType.shipper, reservation.id, notableType, containerDelivery.id),
        documentsService.getDocuments(CompanyType.warehouse, reservation.id, notableType, containerDelivery.id)
      ]);
      const updatedDocuments = shipperDocs.concat(warehouseDocs);
      setDocuments(updatedDocuments);
      const updatedContainerDelivery = cloneDeep(containerDelivery);
      updatedContainerDelivery.hasBillOfLading = DropoffHelper.hasBillOfLading(updatedDocuments, shipperCompany.id);
      setContainerDelivery(updatedContainerDelivery);
    } catch (errorResponse) {
      loadDocErrors = get(errorResponse, 'response.data.errors', []).map((error) => error.detail);
    } finally {
      setErrors(loadDocErrors);
    }
  };

  const handleContainerDeliveryUpdate = (cd: ContainerDelivery) => {
    setContainerDelivery(cd);
  };

  const handleErrors = (errs: string[], response) => {
    handleApiError(errs, response);
  };

  const handleToggleInstructionsEdit = () => {
    setInstructions(instructions || containerDelivery.instructions);
    setEditingInstructions(!editingInstructions);
  };

  const handleInstructionsUpdate = async (newInstructions: string) => {
    const response = await containersService.updateInstructions(containerDeliveryId, newInstructions);
    if (response && response.errors.length === 0) {
      const updatedContainerDelivery = cloneDeep(containerDelivery);
      updatedContainerDelivery.instructions = newInstructions;
      setContainerDelivery(updatedContainerDelivery);
      setInstructions(null);
      setEditingInstructions(false);
      setSuccessMessage('Instructions successfully updated!');
      resetSuccessMessage();
    } else {
      handleApiError([`There was an error updating instructions for #${containerDeliveryId}`], response);
    }
  };

  const toggleUploadDocumentModal = () => {
    setShowUploadDocumentModal(!showUploadDocumentModal);
  };

  const handleCapturablePropertiesUpdated = (updatedState: boolean) => {
    setIsEditing(updatedState);
  };

  const handlePackingListChange = (packingList: PackingListItem[], additional: boolean) => {
    const key = additional ? 'shippable' : 'expected';

    setPackingLists({
      ...packingLists,
      [key]: packingList
    });
  };

  const docManager = (
    <DocumentManagerSmall
      documents={documents}
      documentsRoute="/api/v2/documents"
      notableType={notableType}
      notableId={containerDelivery.id}
      showUploadModal={showUploadDocumentModal}
      toggleUploadModal={toggleUploadDocumentModal}
      onDocumentSaved={handleSavedDoc}
    />
  );

  const showReadOnlyPackingLists =
    containerDelivery.txnState === ContainerDeliveryState.new ||
    containerDelivery.txnState === ContainerDeliveryState.altered ||
    containerDelivery.txnState === ContainerDeliveryState.completed ||
    containerDelivery.txnState === ContainerDeliveryState.underReview;

  const notes =
    containerDelivery.txnState !== ContainerDeliveryState.new ? (
      <InlineEdit
        textArea={true}
        placeholderText="No instructions provided."
        editing={editingInstructions}
        value={instructions && instructions.length ? instructions : containerDelivery.instructions}
        onToggleEdit={handleToggleInstructionsEdit}
        onUpdate={handleInstructionsUpdate}
      />
    ) : (
      <span>{containerDelivery.instructions}</span>
    );

  const renderContainerContents = () => {
    if (showReadOnlyPackingLists) {
      return (
        <ContainerContents
          expectedPackingLists={packingLists.expected}
          shippablePackingLists={packingLists.shippable}
          damagedPackingLists={packingLists.damaged}
          hasLpns={false}
          containerDeliveryId={containerDelivery.id}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className="content">
      {
        <InboundDetailsHeaderPane
          containerDelivery={containerDelivery}
          containersService={containersService}
          warehouseService={warehouseService}
          onUpdateInstructions={handleInstructionsUpdate}
          errors={errors}
          documents={documents}
          onDocumentSaved={handleSavedDoc}
          reservation={reservation}
          reservationId={reservation.id}
          successMessage={successMessage}
          enableInboundPackaging={true}
          onInputTextMap={handleInputTextMap}
          inputTextMap={inputTextMap}
          isEditing={isEditing}
          handleIsBolUploaded={handleIsBolUploaded}
          isLpnReceiveEnabled={false}
          isShipper={false}
          isEditMode={false}
          handleRefreshPage={() => window.location.reload()}
        />
      }

      <div className="container-fluid" id="dropoff-detail">
        {containerDelivery.txnState === ContainerDeliveryState.new && (
          <ConfirmationOfDelivery
            {...context}
            containerDelivery={containerDelivery}
            containerDeliveryStartTime={containerDeliveryStartTime}
            containerDeliveryEndTime={containerDeliveryEndTime}
            instructions={instructions}
            handleApiError={handleApiError}
            setSuccessMessage={setSuccessMessage}
            resetSuccessMessage={resetSuccessMessage}
            reloadContainer={loadContainer}
            inputTextMap={inputTextMap}
            handleCapturablePropertiesUpdated={handleCapturablePropertiesUpdated}
            setIsEditing={setIsEditing}
            reservation={reservation}
          />
        )}

        {containerDelivery.txnState === ContainerDeliveryState.altered && (
          <AlteredDropoffConfirmation
            {...context}
            containerDelivery={containerDelivery}
            instructions={instructions}
            containerDeliveryStartTime={containerDeliveryStartTime}
            containerDeliveryEndTime={containerDeliveryEndTime}
            handleApiError={handleApiError}
            setSuccessMessage={setSuccessMessage}
            resetSuccessMessage={resetSuccessMessage}
            reloadContainer={loadContainer}
          />
        )}

        {containerDelivery.txnState === ContainerDeliveryState.confirmed && (
          <FulfillmentInboundConfirmed
            {...context}
            availableStagingLocations={availableStagingLocations}
            containerDelivery={containerDelivery}
            containersService={containersService}
            packingLists={packingLists}
            pallets={pallets}
            reservation={reservation}
            shipperCompany={shipperCompany}
            showUploadDocumentModal={showUploadDocumentModal}
            documents={documents}
            handleSavedDoc={handleSavedDoc}
            notableId={containerDelivery.id}
            handleContainerDeliveryUpdate={handleContainerDeliveryUpdate}
            handleErrors={handleErrors}
            reloadContainer={loadContainer}
            resetSuccessMessage={resetSuccessMessage}
            setSuccessMessage={setSuccessMessage}
            toggleUploadDocumentModal={toggleUploadDocumentModal}
            handlePackingListChange={handlePackingListChange}
            isBolUploaded={isBolUploaded}
          />
        )}
        {renderContainerContents()}
      </div>
    </div>
  );
};

export default FulfillmentInbound;
