import axios from 'axios';
import {v4} from 'uuid';
import {ApiRequest, ApiResponse} from '../CommonInterfaces';
import {
  CreateWaveTemplateRequest,
  CreateWaveTemplateResponse,
  DeleteWaveTemplateRequest,
  DeleteWaveTemplateResponse,
  GetPastDueRequest,
  GetWaveTemplatesRequest,
  GetWaveTemplatesResponse,
  WaveTemplate
} from '../../warehouse/batch-waving/wave-templates/WaveTemplateInterfaces';
import * as BatchWaving from '../../warehouse/ecommerce-batches/BatchInterfaces';
import InternalAPIService from './InternalAPIService';

class WaveTemplateService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/wh/wave_template');
  }

  public async getPastDue(
    warehouseId: number,
    currentDate: string,
    currentDateTime: string
  ): Promise<ApiResponse<GetWaveTemplatesResponse>> {
    let query: GetPastDueRequest;
    if (currentDate) {
      query = {
        warehouseId,
        currentDate
      };
    } else {
      query = {
        warehouseId,
        currentDateTime
      };
    }

    return this.makeGetRequest(`${this.baseUrl}/past_due_shipments`, query);
  }

  /**
   * Fetch all wave templates for a warehouse.
   * There have been performance issues with this end point in the past. Suggested to use the getWaveTemplateId function instead.
   * @param warehouseId ID of the warehouse to fetch templates for.
   * @param shipByDate
   */
  public async getWaveTemplates(
    warehouseId: number,
    shipByDate: string
  ): Promise<ApiResponse<GetWaveTemplatesResponse>> {
    const query: GetWaveTemplatesRequest = {
      warehouseId,
      shipByDate
    };
    return this.makeGetRequest(this.baseUrl, query);
  }

  /**
   * Fetch a single wave template by id.
   * @param id ID of the template to fetch.
   * @param shipByDate If included, the number of shipments that can be waved will be included in the response.
   */
  public async getWaveTemplate(id: number, shipByDate: string): Promise<ApiResponse<WaveTemplate>> {
    return this.makeGetRequest(`${this.baseUrl}/${id}`, {shipByDate});
  }

  public async createWaveTemplate(
    templateName: string,
    wave: BatchWaving.WaveParameters
  ): Promise<ApiResponse<CreateWaveTemplateResponse>> {
    const requestParameters: ApiRequest<CreateWaveTemplateRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        templateName,
        wave
      }
    };
    try {
      const response = await axios.post(`${this.baseUrl}`, requestParameters);
      return response.data as ApiResponse<CreateWaveTemplateResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  public async deleteWaveTemplate(templateId: number): Promise<ApiResponse<DeleteWaveTemplateResponse>> {
    const requestParameters: ApiRequest<DeleteWaveTemplateRequest> = {
      authenticity_token: this.authenticityToken,
      meta: {
        correlationId: v4()
      },
      data: {
        templateId
      }
    };
    try {
      const response = await axios.delete(`${this.baseUrl}/${templateId}`, requestParameters);
      return {data: {status: response.status}} as ApiResponse<DeleteWaveTemplateResponse>;
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }
}

export default WaveTemplateService;
