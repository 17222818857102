import * as React from 'react';
import {Button, Icon, LegacyModal, Row} from '@flexe/ui-components';
import {useState} from 'react';

interface Props {
  isOpen: boolean;
  title: string;
  toggleModal: () => void;
  finalStepButtonText: string;
  completeAction: () => Promise<void>;
  children: any[];
  subHeader: (currentStepIndex: number) => any;
  footerInfo?: (currentStepIndex: number) => any;
  disableNextButton?: boolean;
}

const WizardModal: React.FC<Props> = (props: Props) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isLastStep = (): boolean => {
    return currentStep + 1 === props.children.length;
  };

  const nextButtonText = () => {
    if (isLastStep()) {
      return props.finalStepButtonText;
    }
    return 'Next';
  };

  const handleToggle = () => {
    setIsLoading(false);
    setCurrentStep(0);
    props.toggleModal();
  };

  const handleGoForward = () => {
    if (isLastStep()) {
      setIsLoading(true);
      props.completeAction().finally(() => {
        setCurrentStep(0);
        setIsLoading(false);
      });
      return;
    }

    setCurrentStep(currentStep + 1);
  };

  const handleGoBack = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const nextButtonDisabled = () => {
    if (isLoading) {
      return true;
    }
    return props.disableNextButton ?? false;
  };

  return (
    <LegacyModal
      id="wizard-modal"
      size="medium"
      show={props.isOpen}
      toggleModal={handleToggle}
      title={
        <>
          <h4>Create Route Wave</h4>
        </>
      }
      footer={
        <div>
          <div className="pull-left">
            {currentStep >= 1 && (
              <Button onPress={handleGoBack}>
                <Row margin={['0', '100']}>
                  <Icon icon="arrow-left" />
                  <span>Go Back</span>
                </Row>
              </Button>
            )}
          </div>
          <div className="pull-right align-baseline">
            <div className="footer-info">{props.footerInfo && props.footerInfo(currentStep)}</div>
            <Button onPress={handleGoForward} visualType="primary" isDisabled={nextButtonDisabled()}>
              <Row margin={['0', '100']} alignmentHorizontal="center">
                {isLoading && (
                  <div className="spinner">
                    <Icon icon="spinner" animation="spin" label="Loading" />
                  </div>
                )}
                <span style={{visibility: isLoading ? 'hidden' : 'visible'}}>{nextButtonText()}</span>
                {!isLastStep() && <Icon icon="arrow-right" />}
              </Row>
            </Button>
          </div>
        </div>
      }
    >
      <>
        {props.subHeader(currentStep)}
        {props.children[currentStep]}
      </>
    </LegacyModal>
  );
};

export default WizardModal;
