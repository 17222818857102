import * as React from 'react';
import {ReactNode} from 'react';

// TODO move to a shared library if other teams like this
export interface CsvColumnSpec {
  name: string;
  requirement?: ReactNode; // Reminder that ReactNode includes boolean
  description: ReactNode;
  inputGuidelines: ReactNode;
  sampleInput: ReactNode;
}
export type CsvSpec = CsvColumnSpec[];

// TODO delete when we no longer support this format
const getCsvCreateHeadersV1 = (includeDeprecated: boolean = false) => {
  const rows = [
    ['external_id', 'Yes'],
    ['service_type', 'No'],
    ['carrier_billing_account_id', 'No'],
    ['name', 'Yes'],
    ['email', 'No'],
    ['address_1', 'Yes'],
    ['address_2', 'No'],
    ['address_3', 'No'],
    ['city', 'Yes'],
    ['state', 'Yes'],
    ['postal_code', 'Yes'],
    ['country', 'Yes'],
    ['phone', 'No'],
    ['reference_1', 'No'],
    ['reference_2', 'No'],
    ['signature_confirmation', 'No'],
    ['reservation_id', 'No'],
    ['sku', 'Yes'],
    ['quantity', 'Yes'],
    ['order_labels', 'No'],
    ['uom', 'No'],
    ['shipment_notes', 'No']
  ];

  if (includeDeprecated) {
    rows.push(['order_number', 'Deprecated']);

    rows.push(['address_4', 'Deprecated']);
  }
  return rows;
};

// TODO separate MR to move this to the CsvSpec format
const getCsvCreateHeadersV2 = () => {
  // these comparison arrays are ['header text', 'isRequired'] values
  // headers in the file are converted to lower case and have
  //  underscore replaced with spaces, so use that format here
  return [
    ['order type', 'No'],
    ['po number', 'No'],
    ['customer order number', 'Yes'],
    ['label reference 1', 'No'],
    ['label reference 2', 'No'],
    ['customer order date', 'No'],
    ['name', 'Yes'],
    ['address 1', 'Yes'],
    ['address 2', 'No'],
    ['address 3', 'No'],
    ['city', 'Yes'],
    ['state / province', 'Yes'],
    ['postal code', 'Yes'],
    ['country', 'Yes'],
    ['phone number', 'No'],
    ['email', 'No'],
    ['item / sku', 'Yes'],
    ['customer order line number', 'No'],
    ['line qty', 'Yes'],
    ['line custom attributes', 'No'],
    ['unit of measure (uom)', 'Yes'],
    ['item price per unit', 'No'],
    ['item price currency', 'No'],
    ['latest ship date', 'Yes'],
    ['earliest ship date', 'No'],
    ['carrier', 'Yes'],
    ['service level or scac', 'No'],
    ['bol number', 'No'],
    ['shipping notes', 'No'],
    ['requested reservation id', 'No'],
    ['carrier billing account id', 'No'],
    ['custom attributes', 'No'],
    ['signature requirement', 'No']
  ];
};

const getCsvCreateOrderByLotHeaders = () => {
  const headers = getCsvCreateHeadersV2();
  headers.push(['lot code', 'No'], ['expiration date', 'No']);
  return headers;
};

export const cancelCsvFlexeOrderId = 'Flexe Order ID';
export const cancelCsvExternalOrderId = 'External Order ID';
export const cancelCsvFlexeOrderLineId = 'Flexe Order Line ID';
const csvBulkCancellationSpec: CsvSpec = [
  {
    name: cancelCsvFlexeOrderId,
    requirement: (
      <span className="italics">
        Either this or <span className="bold">{cancelCsvExternalOrderId}</span> is required
      </span>
    ),
    description: 'The ID assigned by Flexe for the order that should be canceled.',
    inputGuidelines: 'Integer',
    sampleInput: '1234567890'
  },
  {
    name: cancelCsvExternalOrderId,
    requirement: (
      <span className="italics">
        Either this or <span className="bold">{cancelCsvFlexeOrderId}</span> is required
      </span>
    ),
    description: 'The ID assigned by the shipper for the order that should be canceled.',
    inputGuidelines: 'String',
    sampleInput: 'abc-123'
  },
  {
    name: cancelCsvFlexeOrderLineId,
    description: (
      <>
        The ID assigned by Flexe for the order line that should be canceled.
        <br />
        <span className="italics">
          If specified, this must correspond to the order specified in{' '}
          <span className="bold">{cancelCsvFlexeOrderId}</span> or{' '}
          <span className="bold">{cancelCsvExternalOrderId}</span> and only this line will be canceled on the order. If
          left blank, the entire order will be canceled.
        </span>
      </>
    ),
    inputGuidelines: 'Integer',
    sampleInput: '1234567890'
  },
  {
    name: 'Cancellation Reason',
    requirement: true,
    description: 'Specifies the reason for cancelling the order or line.',
    inputGuidelines: (
      <React.Fragment>
        <span>
          Please visit{' '}
          <a
            href="https://developer.flexe.com/doc/public#operation/cancelOrderLine"
            target="_blank"
            rel="noopener noreferrer"
          >
            our API docs
          </a>{' '}
          to see an explanation of each reason code. Must be one of the following:
        </span>
        <ul>
          <li>end_customer_request</li>
          <li>customer_request</li>
          <li>invalid_order_submission</li>
        </ul>
      </React.Fragment>
    ),
    sampleInput: 'customer_request'
  }
];

const buildRequirementValue = (requirement: ReactNode): ReactNode => {
  if (requirement === true) {
    return <span className="bold">Yes</span>;
  }
  if (requirement) {
    return requirement;
  }

  return '';
};

const buildInstructionsTableRows = (spec: CsvSpec): ReactNode[][] => {
  return spec.map((column) => [
    column.name,
    buildRequirementValue(column.requirement),
    column.description,
    column.inputGuidelines,
    column.sampleInput
  ]);
};

const buildHeaders = (spec: CsvSpec): string[][] => {
  return spec.map((column) => [column.name.toLowerCase(), column.requirement === true ? 'Yes' : '']);
};

/* Creation */
export const csvCreateHeadersV1 = getCsvCreateHeadersV1(true);
export const csvCreateFileUploadTypeV1 = 'outbound_order_csv';
export const csvCreateHeadersV2 = getCsvCreateHeadersV2();
export const csvCreateOrderByLotHeaders = getCsvCreateOrderByLotHeaders();
export const csvCreateFileUploadTypeV2 = 'outbound_order_csv_v2';
// TODO move upload paths here from OutboundOrders.tsx
// TODO move instructions from OutboundOrdersInstructionData here

/* Cancellation */
export const csvBulkCancellationInstructions = buildInstructionsTableRows(csvBulkCancellationSpec);
export const csvBulkCancellationHeaders = buildHeaders(csvBulkCancellationSpec);

export interface CSVTemplate {
  filepath: string;
  displayName: string;
}
export const csvBulkCancellationTemplates: CSVTemplate[] = [
  {
    filepath: '/static/files/OutboundOrderBulkCancelLinesByExtID.csv',
    displayName: 'Cancel Lines by External Order ID'
  },
  {
    filepath: '/static/files/OutboundOrderBulkCancelLinesByFlexeID.csv',
    displayName: 'Cancel Lines by Flexe Order ID'
  },
  {
    filepath: '/static/files/OutboundOrderBulkCancelOrdersByExtID.csv',
    displayName: 'Cancel Orders by External Order ID'
  },
  {
    filepath: '/static/files/OutboundOrderBulkCancelOrdersByFlexeID.csv',
    displayName: 'Cancel Orders by Flexe Order ID'
  }
];
