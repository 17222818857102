import {
  GetWaveTemplateRequest,
  GetWaveTemplateResponse,
  GetWaveTemplatesV2Response,
  WaveTemplateV2
} from '../../warehouse/batch-waving/wave-templates/WaveTemplateInterfaces';
import {ApiResponse} from '../CommonInterfaces';
import InternalAPIServiceV2 from './InternalAPIServiceV2';

class WaveTemplateServiceV2 extends InternalAPIServiceV2 {
  constructor() {
    super('/api/v2/wave-service/template');
  }

  public async getWaveTemplate(id: number): Promise<GetWaveTemplateResponse> {
    const query: GetWaveTemplateRequest = {id};
    return this.makeGetRequest(this.baseUrl, query);
  }

  public async updateWaveTemplate(waveTemplate: WaveTemplateV2): Promise<ApiResponse<GetWaveTemplateResponse>> {
    return this.makePutRequest(this.baseUrl, waveTemplate);
  }

  public async createWaveTemplate(waveTemplate: WaveTemplateV2): Promise<ApiResponse<GetWaveTemplateResponse>> {
    return this.makePostRequest(this.baseUrl, waveTemplate);
  }

  /**
   * Fetch all the wave templates for a given warehouse.
   * @param warehouseId ID of the warehouse to fetch templates for.
   * @param continuationToken Continuation token fom the prior response.
   */
  public async getWaveTemplatesV2(
    warehouseId: number,
    continuationToken: string | null
  ): Promise<ApiResponse<GetWaveTemplatesV2Response>> {
    return this.makeGetRequest('/api/v2/wave-service/templates', {
      'warehouse-id': warehouseId,
      'continuation-token': continuationToken
    });
  }
}

export default WaveTemplateServiceV2;
