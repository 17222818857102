import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import FlexeContext, {FlexeContextValues} from '../../contexts/FlexeContext';
import CreateBarcodesIndex from './CreateBarcodesIndex';

interface AppProps {
  authenticityToken: string;
  defaultBarcodes: string;
  printCssUrl: string;
}

function CreateBarcodesApp(props: AppProps) {
  const flexeContextValue: FlexeContextValues = {
    authenticityToken: props.authenticityToken
  };

  return (
    <FlexeContext.Provider value={flexeContextValue}>
      <Router>
        <Switch>
          <Route
            path="/wh/create_barcodes"
            exact
            render={(routeProps) => <CreateBarcodesIndex {...routeProps} {...props} />}
          />
        </Switch>
      </Router>
    </FlexeContext.Provider>
  );
}

export default CreateBarcodesApp;
