import * as React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import {v4} from 'uuid';
import {Column, Loader} from '@flexe/ui-components';
import {Warehouse} from '../shared/CommonInterfaces';
import RoutePlanningService, {
  FetchRouteWaveSummariesResponse,
  RoutableLocation,
  RoutePlanningApiV2Response,
  StateSummary
} from '../shared/services/RoutePlanningService';
import {getDefaultCreatedAfter, RelativeDateOption} from '../shared/relative-date-picker/RelativeDatePicker';
import FlexeContext, {FlexeContextValues} from '../contexts/FlexeContext';
import ReservationDropdown from '../shared/ReservationDropdown';
import WizardModal from './WizardModal';
import CreateRouteSummary from './CreateRouteSummary';
import RouteWavesIndexHeader from './RouteWavesIndexHeader';
import SelectRoutableLocationsTable from './SelectRoutableLocationsTable';
import RouteWavesIntroCard from './RouteWavesIntroCard';
import RouteWaveCard from './RouteWaveCard';

interface RouteWavesIndexProps {
  authenticityToken: string;
  selectedWarehouse: Warehouse;
  activeWarehouses: Warehouse[];
  routePlanningEnabled: boolean;
}

const RouteWavesIndex: React.FC<RouteWavesIndexProps> = ({
  authenticityToken,
  selectedWarehouse,
  activeWarehouses,
  routePlanningEnabled
}) => {
  const routePlanningService = useMemo(() => new RoutePlanningService(authenticityToken), [authenticityToken]);

  const flexeContextValue: FlexeContextValues = {
    authenticityToken
  };

  const [routeWaveSummaries, setRouteWaveSummaries] = useState<StateSummary[]>([]);
  const [loadingRouteWaves, setLoadingRouteWaves] = useState<boolean>(false);
  const [createdAfter, setCreatedAfter] = useState<Date>(getDefaultCreatedAfter(RelativeDateOption.today));
  const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>([]);
  const [routableLocations, setRoutableLocations] = useState<RoutableLocation[]>([]);
  const [selectedReservationId, setSelectedReservationId] = useState<number>(-1);
  const [selectedReservationIdForCreate, setSelectedReservationIdForCreate] = useState<number>(-1);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    refreshRouteWaveSummaries();
  }, [selectedReservationId, createdAfter]);

  const refreshRouteWaveSummaries = () => {
    setLoadingRouteWaves(true);
    getAllRouteWaveSummaries()
      .then((summaries) => {
        setRouteWaveSummaries(summaries);
      })
      .catch(() => {
        setErrors([
          'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.'
        ]);
      })
      .finally(() => {
        setLoadingRouteWaves(false);
      });
  };

  const getAllRouteWaveSummaries = async (): Promise<StateSummary[]> => {
    let allSummaries: StateSummary[] = [];
    let continuationToken: string | null = null;

    do {
      const pageOfSummaries = await getRouteWaveSummariesPage(continuationToken);

      if (pageOfSummaries.errors?.length > 0) {
        throw new Error(pageOfSummaries.errors[0].title);
      }

      allSummaries = allSummaries.concat(pageOfSummaries.data?.results ?? []);
      continuationToken = pageOfSummaries.data.continuationToken;
    } while (continuationToken !== null);

    return allSummaries;
  };

  const getRouteWaveSummariesPage = (
    continuationToken: string
  ): Promise<RoutePlanningApiV2Response<FetchRouteWaveSummariesResponse>> => {
    return routePlanningService.fetchRouteWavesSummary({
      pageSize: 50,
      reservationId: selectedReservationId > 0 ? selectedReservationId : null,
      createdAfter: createdAfter.toISOString(),
      continuationToken
    });
  };

  const toggleCreateRouteWaveModal = () => {
    // if modal is already open, don't load locations
    if (showCreateModal) {
      setShowCreateModal(!showCreateModal);
      return;
    }

    setSelectedLocationIds([]);
    setLoadingLocations(true);
    setRoutableLocations([]);
    routePlanningService.fetchRoutableLocations(selectedWarehouse.id).then((locations) => {
      setRoutableLocations(locations.data?.locations ?? []);
      setLoadingLocations(false);
    });
    setShowCreateModal(!showCreateModal);
  };

  const getSubHeader = (stepIndex: number) => {
    const pageAction = stepIndex === 0 ? 'Select Locations' : 'Route Units at Location(s)';

    return (
      <h6>
        Step {stepIndex + 1} of 2: {pageAction}
      </h6>
    );
  };

  const setCreateFailedError = () => {
    setErrors([
      'An error occurred creating the route wave, please refresh the browser and try again. If the issue persists contact FLEXE support.'
    ]);
  };

  const completeAction = async () => {
    try {
      const response = await routePlanningService.createRouteWave({
        locationIds: selectedLocationIds,
        reservationId: selectedReservationIdForCreate,
        routingRequestId: v4()
      });
      if (response.errors?.length > 0) {
        setCreateFailedError();
      }
      refreshRouteWaveSummaries();
    } catch (error) {
      setCreateFailedError();
    } finally {
      setShowCreateModal(false);
    }
  };

  const selectedLocations = useMemo<RoutableLocation[]>(() => {
    return routableLocations.filter((location) => selectedLocationIds.includes(location.locationId));
  }, [selectedLocationIds]);

  const unitTotal = useMemo<number>(() => {
    return selectedLocations.reduce((total, location) => total + location.lpnCount, 0);
  }, [selectedLocations]);

  const footerInfoElement = (currentStepIndex: number) => {
    if (currentStepIndex === 0) {
      return <p>{selectedLocationIds.length.toLocaleString()} locations selected</p>;
    } else {
      return <p>Routing {unitTotal.toLocaleString()} units</p>;
    }
  };

  return (
    <FlexeContext.Provider value={flexeContextValue}>
      <Router>
        <div id="route-waves-index">
          {errors &&
            errors.map((error, idx) => (
              <div className="alert alert-danger" role="alert" key={`error:${idx}`}>
                {error}
              </div>
            ))}
          <RouteWavesIndexHeader
            selectedWarehouse={selectedWarehouse}
            activeWarehouses={activeWarehouses}
            routePlanningEnabled={routePlanningEnabled}
            createRouteWaveButtonAction={toggleCreateRouteWaveModal}
            selectedReservationIdChanged={setSelectedReservationId}
            onCreatedAfterChanged={setCreatedAfter}
          />

          <WizardModal
            isOpen={showCreateModal}
            title="Create Route Wave"
            toggleModal={toggleCreateRouteWaveModal}
            finalStepButtonText="Route"
            completeAction={completeAction}
            subHeader={getSubHeader}
            footerInfo={footerInfoElement}
            disableNextButton={selectedLocations.length < 1}
          >
            <Column margin="200">
              <ReservationDropdown
                warehouseId={selectedWarehouse.id}
                selectedReservationIdChanged={setSelectedReservationIdForCreate}
                includeAllOption={false}
              />
              <SelectRoutableLocationsTable
                selectedLocationIds={selectedLocationIds}
                data={routableLocations}
                isLoading={loadingLocations}
                handleSelectionModelChange={setSelectedLocationIds}
              />
            </Column>

            <CreateRouteSummary routableLocations={selectedLocations} />
          </WizardModal>
          <div className="container-fluid">
            <Loader loading={loadingRouteWaves} />
            {!loadingRouteWaves && routeWaveSummaries.length < 1 && (
              <RouteWavesIntroCard createRouteWaveButtonAction={toggleCreateRouteWaveModal} />
            )}
            {!loadingRouteWaves &&
              routeWaveSummaries.map((rw) => {
                return <RouteWaveCard key={rw.id} stateSummary={rw} />;
              })}
          </div>
        </div>
      </Router>
    </FlexeContext.Provider>
  );
};

export default RouteWavesIndex;
