import * as React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import Select from 'react-select';
import {forEach} from 'lodash';

interface CarrierSelectProps {
  carrierLabels: {[key: string]: string};
  serviceLevelMap: {[key: string]: string[]};
  savedCarriers?: string[];
  savedServiceLevels?: string[];
}

const CarrierSelect: React.FC<CarrierSelectProps> = ({
  carrierLabels,
  serviceLevelMap,
  savedCarriers,
  savedServiceLevels
}) => {
  const {control, errors, watch} = useFormContext();
  const formatCarriersForSelect = () => {
    return Object.keys(carrierLabels).map((key) => {
      return {value: key, label: carrierLabels[key]};
    });
  };

  const formatServiceLevelsForSelect = (selectedCarrier) => {
    const formattedServiceLevels = [];
    if ((selectedCarrier === undefined || selectedCarrier === null) && savedCarriers) {
      selectedCarrier = {value: savedCarriers[0]};
    }
    if (selectedCarrier) {
      const serviceLevelsForSelectedCarrier = serviceLevelMap[selectedCarrier.value] || [];
      serviceLevelsForSelectedCarrier.forEach((serviceLevel) => {
        formattedServiceLevels.push({value: serviceLevel, label: serviceLevel});
      });
    }
    return formattedServiceLevels;
  };

  const savedCarrier = () => {
    if (savedCarriers?.length > 0) {
      return savedCarriers[0];
    }
    return null;
  };

  const getSelectedCarrier = () => {
    return formatCarriersForSelect().find((carrier) => carrier.value === savedCarrier());
  };

  const getSelectedServiceLevels = () => {
    if (savedServiceLevels) {
      return formatServiceLevelsForSelect(undefined).filter((serviceLevelOption) =>
        savedServiceLevels.includes(serviceLevelOption.value)
      );
    }
    return [];
  };

  const triggerCarrierState = (e) => {
    control.setValue('serviceLevel', []);
    return e;
  };

  return (
    <>
      <div className="form-field">
        <div data-testid="carrier-field">
          <Controller
            name="carrier"
            control={control}
            rules={{required: 'Carrier is required'}}
            defaultValue={getSelectedCarrier()}
            render={({onChange}) => (
              <>
                <label>
                  Carrier and Service Level
                  <br />
                  <span className="input-hint">Specify carrier and service level(s) for this template</span>
                </label>
                <Select
                  id="carrier"
                  name="carrier"
                  className="wave-template-select"
                  placeholder="Select a carrier"
                  options={formatCarriersForSelect()}
                  defaultValue={getSelectedCarrier()}
                  onChange={(carrier) => onChange(triggerCarrierState(carrier))}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className="form-field" data-testid="service-level-field">
        <Controller
          name="serviceLevel"
          control={control}
          defaultValue={getSelectedServiceLevels()}
          render={({onChange, value}) => (
            <Select
              id="serviceLevel"
              name="serviceLevel"
              className="wave-template-select"
              placeholder="Type to select one or more service levels"
              isMulti
              options={formatServiceLevelsForSelect([watch('carrier')][0])}
              defaultValue={getSelectedServiceLevels()}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      {errors.carrier && <span className="required-input validation-msg">{errors.carrier.message}</span>}
    </>
  );
};

export default CarrierSelect;
