import * as React from 'react';
import * as moment from 'moment-timezone';
import {useContext, useState} from 'react';
import {Expander, Loader} from '@flexe/ui-components';
import _ from 'lodash';
import {TransitionStateDisplay} from '../../shared/constants';
import {CreateWaveRequest, Wave} from './WaveInterfaces';
import {WaveContext} from './WaveContext';
import {PickWaveStatus, WaveBatch, WaveRequestFilters} from './WaveBatchInterfaces';
import WaveTableBatchEntry from './WaveTableBatchEntry';
import {waveStatusToTransitionState} from './WaveShared';
import DocumentPrintButton from './shared/DocumentPrintButton';

interface Props {
  wave: Wave;
  batchCount: number;
  displayOrderByField: boolean;
}

const WavesTableEntry: React.FC<Props> = ({wave, batchCount, displayOrderByField}) => {
  const stagingLocDisplayName = wave.stagingLocation.label || '\u00A0';
  const {batchWavingService, reservations, setErrors} = useContext(WaveContext);
  const [expandBatches, setExpandBatches] = useState<boolean>(false);
  const [batches, setBatches] = useState<WaveBatch[]>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const asyncGetBatches = async () => {
    const response = await batchWavingService.getWaveBatches(wave.id);

    if (response.data && response.data.batches) {
      setBatches(response.data.batches);
    }
    if (response.errors && response.errors.length > 0) {
      const getBatchesErrors = [];
      response.errors.forEach(() =>
        getBatchesErrors.push(
          'An error occurred loading the page, please refresh the browser. If the issue persists contact FLEXE support.'
        )
      );
      setErrors(getBatchesErrors);
    }
  };

  const getShipperDisplayName = (reservationId) => {
    return reservations?.find((res) => res.id === reservationId)?.name.split(': ')[1];
  };
  const reservationDisplayName = `Res. ${wave.reservation.id}: ${getShipperDisplayName(wave.reservation.id)}`;
  const waveDate = moment
    .utc(wave.waveRequest.createdAt)
    .tz(moment.tz.guess())
    .format('h:mm a, MMM D');

  const getWaveRequestFilters = (waveRequest: CreateWaveRequest) => {
    const filters: WaveRequestFilters = waveRequest.filters;
    const skus = filters?.skus ? `${filters?.skus.join(', ')}` : null;
    const postalRange = filters?.postal_code_range
      ? `Postal Code Range: ${filters.postal_code_range.start} - ${filters.postal_code_range.end}`
      : null;
    const skuVariety = getSkuVarietyDisplay(filters?.sku_variety);
    const shipmentType = getShipmentTypeDisplay(filters?.shipment_type);
    const cutoffDate = filters?.cutoff_date ? `Ship\u00A0by\u00A0date:\u00A0${filters.cutoff_date}` : null;
    const shipmentIds = filters?.shipment_ids ? 'Manual shipments selection' : null;
    const shipAsIs = filters?.ship_as_is ? 'Ship as is' : null;
    const hazmat = filters?.includes_hazmat ? 'Hazmat' : null;
    return [skus, skuVariety, shipmentType, shipAsIs, hazmat, cutoffDate, shipmentIds, postalRange]
      .filter((n) => n)
      .join(', ');
  };
  const getCarrierFromWaveRequest = (waveRequest: CreateWaveRequest) => {
    const filters = waveRequest.filters;
    const carriers = filters?.carriers?.join(', ') || '\u00A0';
    return carriers;
  };

  const getServiceTypeFromWaveRequest = (waveRequest: CreateWaveRequest) => {
    const filters = waveRequest.filters;
    const serviceTypes = filters?.service_types?.join(', ');
    return serviceTypes;
  };

  async function toggleExpander() {
    if (batches) {
      setExpandBatches(!expandBatches);
    } else {
      setLoading(true);
      setExpandBatches(!expandBatches);
      await asyncGetBatches();
      setLoading(false);
    }
  }

  const getExpanderString = (batchCountInt: number) => {
    if (batchCountInt) {
      if (batchCountInt > 1) {
        return `${batchCountInt} Batches`;
      }

      return '1 Batch';
    }
    return '';
  };

  const waveDocument = wave?.documents[wave.documents.length - 1];

  return (
    <React.Fragment>
      <div className="waves-table-entry">
        <div className="header">
          <div className="flex-child wave-info">
            <b>
              <a style={{margin: '3px'}} href={`/wh/waves/${wave.id}`} target="_blank" rel="noreferrer">
                Wave {wave.id}
              </a>
            </b>
            <span className="reservation-display-label">{reservationDisplayName}</span>
            <div className="status">
              <span className={`dot ${waveStatusToTransitionState(wave.status)}`}></span>
              <span className="status-text">
                {TransitionStateDisplay.get(waveStatusToTransitionState(wave.status))}
              </span>
            </div>
          </div>
          <div className="info-pair template-info" key="template-info">
            <span className="title">Waved by:</span>
            <span className="value">{getWaveRequestFilters(wave.waveRequest)}</span>
            <span className="value">@ {waveDate}</span>
          </div>

          {wave.waveAttributes?.loadGroup && (
            <div className="info-pair template-info">
              <span className="title">Load Group:</span>
              <span className="value">{wave.waveAttributes?.loadGroup}</span>
            </div>
          )}

          {wave.waveAttributes?.destinationTag && (
            <div className="info-pair template-info">
              <span className="title">Destination Id:</span>
              <span className="value">{wave.waveAttributes?.destinationTag}</span>
            </div>
          )}

          <div className="info-pair carrier-info" key="carrier-info">
            <span className="title">Carrier:</span>
            <span className="value">{getCarrierFromWaveRequest(wave.waveRequest)}</span>
            {getServiceTypeFromWaveRequest(wave.waveRequest) && (
              <span className="title">{getServiceTypeFromWaveRequest(wave.waveRequest)}</span>
            )}
          </div>
          <div className="info-pair staging-info" key="staging-info">
            <span className="title">Staging Loc:</span>
            <span className="value">{stagingLocDisplayName}</span>
            {wave.waveAttributes.orderBy && displayOrderByField && (
              <>
                <span className="title">Pick Requirements:</span>
                <span className="value">Yes</span>
              </>
            )}
          </div>
          <div className="flex-child button-container" key="button-container">
            <DocumentPrintButton
              className={'flex-child print-all-batches'}
              document={waveDocument}
              isWaveCancelled={wave.status === PickWaveStatus.cancelled}
              printButtonPrefix={'Print all Documents'}
            />
          </div>
        </div>
      </div>
      <div className="batch-list">
        <Expander
          title={`${getExpanderString(batchCount)}`}
          isOpen={expandBatches}
          onToggle={toggleExpander}
          maxHeight={5000}
        >
          {loading ? (
            <Loader loading />
          ) : (
            _.sortBy(batches, 'id').map((batch) => (
              <WaveTableBatchEntry
                key={`${wave.id}${batch.id}`}
                batchData={batch}
                batchService={batchWavingService}
                isWaveCancelled={wave.status === PickWaveStatus.cancelled}
              />
            ))
          )}
        </Expander>
      </div>
    </React.Fragment>
  );
};

const skuVarietyToDisplay = {
  single_sku: 'Single-SKU only',
  multi_sku: 'Multi-SKU only',
  single_or_multi_sku: 'Both single and multi-SKU'
};

const getSkuVarietyDisplay = (skuVariety?: string): string | undefined => skuVarietyToDisplay[skuVariety] || skuVariety;

const shipmentTypeToDisplay = {
  single_item: 'Single-unit only',
  multi_item: 'Multi-unit only',
  single_or_multi: 'Both single and multi-unit',
  ship_alone: 'Ship alone'
};

const getShipmentTypeDisplay = (shipmentType?: string): string | undefined =>
  shipmentTypeToDisplay[shipmentType] || shipmentType;

export default WavesTableEntry;
