import * as React from 'react';
import {useState} from 'react';
import {cloneDeep, sortBy, sum} from 'lodash';
import * as moment from 'moment-timezone';
import {Loader} from '@flexe/ui-components';
import WarehouseService from '../../shared/services/WarehouseService';
import SelectorWithCheckbox, {SelectOption} from '../../shared/SelectorWithCheckbox';
import {
  CarrierCount,
  OrderFilters,
  OrderResponse,
  SkuSingleLineItem,
  WaveParameters
} from '../ecommerce-batches/BatchInterfaces';
import BatchWavingService from '../../shared/services/BatchWavingService';
import {FilterOption, Warehouse} from '../../shared/CommonInterfaces';
import ShipmentFiltersLabelGroup from './ShipmentFiltersLabelGroup';
import {SkuLeaderboard} from './WaveInterfaces';
import FilterResultsTable from './FilterResultsTable';
import AdditionalShipmentFilters from './AdditionalShipmentFilters';
import {
  MULTI_SKU_LEADER_BOARD,
  MULTI_SKU_SHIPMENT_PROFILE,
  SINGLE_SKU_MULTI_ITEM_LEADER_BOARD,
  SINGLE_SKU_MULTI_ITEM_SHIPMENT_PROFILE,
  SINGLE_SKU_SINGLE_ITEM_LEADER_BOARD,
  SINGLE_SKU_SINGLE_ITEM_SHIPMENT_PROFILE
} from './WaveBatchConstants';
import FilterResultsHVWTable from './FilterResultsHVWTable';
import {WaveTemplate} from './wave-templates/WaveTemplateInterfaces';

interface AsyncWavingByCarriersProps {
  selectedWarehouse: Warehouse;
  wavingService: BatchWavingService;
  carriers: object;
  reservationIdToName: object;
  cutoffDate: string;
  reloadTrigger: boolean;
  isHighVolumeWavingEnabled: boolean;
  isFullPalletPullForSortationEnabled: boolean;
  reservationToSortationEnabled: object;
  warehouseService: WarehouseService;
  setTemplateToWave(template: WaveTemplate);
  showWaveModalAndSetFullPalletPull(isFullPalletPull: boolean, unitsPerPallet: number);
  onWaveSuccess();
  onWaveFailure();
}

const pageSize = 50;

const AsyncWavingByCarriers: React.FC<AsyncWavingByCarriersProps> = (props) => {
  const {
    selectedWarehouse,
    wavingService,
    carriers,
    reservationIdToName,
    cutoffDate,
    reloadTrigger,
    isHighVolumeWavingEnabled,
    setTemplateToWave,
    showWaveModalAndSetFullPalletPull,
    onWaveFailure,
    onWaveSuccess,
    warehouseService
  } = props;
  const userTimeZone = moment()
    .tz(moment.tz.guess())
    .format('z');
  const emptyShipmentFilters = {
    reservationId: 0,
    unbatched: true,
    orderStatus: 'new',
    carriers: [],
    cutoffDate: moment(cutoffDate).format('YYYY-MM-DD')
  } as OrderFilters;
  const skuLeaderboardsSetup = new Map([
    [SINGLE_SKU_SINGLE_ITEM_SHIPMENT_PROFILE, SINGLE_SKU_SINGLE_ITEM_LEADER_BOARD],
    [SINGLE_SKU_MULTI_ITEM_SHIPMENT_PROFILE, SINGLE_SKU_MULTI_ITEM_LEADER_BOARD],
    [MULTI_SKU_SHIPMENT_PROFILE, MULTI_SKU_LEADER_BOARD]
  ]);
  const [carriersList, setCarriersList] = React.useState<CarrierCount[]>([]);
  const [carrierToReservations, setCarrierToReservations] = React.useState<Map<string, Map<number, number>>>(new Map());
  const [reservationOptions, setReservationOptions] = React.useState<SelectOption[]>([]);
  const [shipmentFilters, setShipmentFilters] = React.useState<OrderFilters>(emptyShipmentFilters);
  const [showAdditionalFilters, setShowAdditionalFilters] = React.useState<boolean>(false);
  const [shipmentCount, setShipmentCount] = React.useState<number>(0);
  const [skuSummary, setSkuSummary] = React.useState<SkuSingleLineItem[]>([]);
  const [shipments, setShipments] = React.useState<OrderResponse[]>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [continuationTokenList, setContinuationTokenList] = React.useState<string[]>([]);
  const [serviceTypesMap, setServiceTypesMap] = React.useState<Map<string, FilterOption[]>>(new Map());
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [skuLeaderboards, setSkuLeaderBoards] = React.useState<Map<string, SkuLeaderboard>>(skuLeaderboardsSetup);
  const isInitialMount = React.useRef(true);

  const loadLeaderboard = async (leaderboard: SkuLeaderboard) => {
    const shipmentFiltersCopy = cloneDeep(shipmentFilters);
    shipmentFiltersCopy.shipmentProfile = leaderboard.shipmentProfile;
    const response = await wavingService.getShipments(shipmentFiltersCopy, null, 1);

    if (response && response.errors.length === 0) {
      leaderboard.orderTotal = response.data.counts.new || 0;
      leaderboard.skuSummary = response.data.skuSummary || [];
      leaderboard.dataHasLoaded = true;
      const skuLeaderboardsCopy = cloneDeep(skuLeaderboards);
      skuLeaderboardsCopy.set(leaderboard.shipmentProfile, leaderboard);
      setSkuLeaderBoards(skuLeaderboardsCopy);
    }
  };

  const getShipments = async () => {
    const continuationToken = currentPage > 1 ? continuationTokenList[currentPage - 2] : null;
    if (isHighVolumeWavingEnabled) {
      setIsLoading(true);
      await Promise.all([
        loadLeaderboard(skuLeaderboards.get(SINGLE_SKU_SINGLE_ITEM_SHIPMENT_PROFILE)),
        loadLeaderboard(skuLeaderboards.get(SINGLE_SKU_MULTI_ITEM_SHIPMENT_PROFILE)),
        loadLeaderboard(skuLeaderboards.get(MULTI_SKU_SHIPMENT_PROFILE))
      ]);
      setIsLoading(false);
      let shipmentCountTotal = 0;
      skuLeaderboards.forEach((v) => {
        shipmentCountTotal += v.orderTotal;
      });
      setShipmentCount(shipmentCountTotal);
    } else {
      setIsLoading(true);
      const response = await wavingService.getOrders(shipmentFilters, continuationToken, pageSize);
      setIsLoading(false);
      if (responseHasNoErrors(response) && response.data) {
        if (response.data.continuationToken) {
          continuationTokenList[currentPage - 1] = response.data.continuationToken;
        }
        if (response.data.counts) {
          setShipmentCount(response.data.counts.new);
        }
        setSkuSummary(response.data.skuSummary);
        setShipments(response.data.orders);
      }
    }
  };

  React.useEffect(() => {
    clearFilters();
    getCarrierReservationTotal();
  }, [selectedWarehouse, cutoffDate]);

  React.useEffect(() => {
    // we don't want to re-call these endpoints on initial load
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getCarrierReservationTotal();
      if (shipmentFilters.reservationId > 0 && shipmentFilters.carriers.length > 0) {
        getShipments();
      }
    }
  }, [reloadTrigger]);

  React.useEffect(() => {
    if (shipmentFilters.carriers.length > 0 && shipmentFilters.reservationId > 0) {
      getShipments();
    }
  }, [currentPage, continuationTokenList]);

  React.useEffect(() => {
    if (shipmentFilters.carriers.length > 0 && shipmentFilters.reservationId > 0) {
      setShowAdditionalFilters(true);
      setCurrentPage(1);
      setContinuationTokenList([]);
    } else {
      setShowAdditionalFilters(false);
    }
  }, [shipmentFilters]);

  const getCarrierReservationTotal = async () => {
    const response = await wavingService.getCarrierReservationTotal(selectedWarehouse.id, cutoffDate);
    if (responseHasNoErrors(response) && response.data) {
      let sortedCarrierList = [];
      Object.keys(response.data).forEach((carrierEnum) => {
        sortedCarrierList.push({
          enum: carrierEnum,
          name: carriers[carrierEnum],
          total: sum(Object.keys(response.data[carrierEnum]).map((key) => response.data[carrierEnum][key]))
        });
      });
      sortedCarrierList = sortBy(sortedCarrierList, ['total']).reverse();
      setCarriersList(sortedCarrierList);
      setCarrierToReservations(response.data);
    }
  };

  const carrierOptions = carriersList.map((carrier) => {
    return (
      <label className="carrier-label" key={`label-${carrier.enum}`}>
        <input
          type="checkbox"
          data-testid={`${carrier.enum}-checkbox`}
          className="carrier-checkbox"
          key={`carrier-${carrier.enum}`}
          value={carrier.enum}
          checked={shipmentFilters.carriers[0] === carrier.enum}
          onChange={() => {
            handleCarrierSelect(carrier.enum);
          }}
        />
        {`${carrier.name}: ${carrier.total}`}
      </label>
    );
  });

  const handleCarrierSelect = (selectedCarrier) => {
    getReservationSelectOptions(selectedCarrier);
    // OrderFilters requires reservationId
    // We will be setting this value later. User will not be able to wave with the initial value.
    const waveParametersCopy = cloneDeep(emptyShipmentFilters);
    waveParametersCopy.carriers = [selectedCarrier];
    setShipmentFilters(waveParametersCopy);
  };

  const handleReservationSelect = async (selectedValue) => {
    const waveParametersCopy = cloneDeep(shipmentFilters);
    waveParametersCopy.reservationId = selectedValue;
    setShipmentFilters(waveParametersCopy);

    const carrierInfoResponse = await wavingService.getCarrierInfo(selectedValue);
    if (responseHasNoErrors(carrierInfoResponse) && carrierInfoResponse.data) {
      const carriersToServiceTypesMap = new Map();
      carrierInfoResponse.data.carriers.map((carrier) => {
        carriersToServiceTypesMap.set(
          carrier.id,
          carrier.serviceTypes.map((serviceType) => {
            return {
              displayName: serviceType,
              value: serviceType
            } as FilterOption;
          })
        );
      });
      setServiceTypesMap(carriersToServiceTypesMap);
    }
  };

  const getReservationSelectOptions = (selectedCarrier: string) => {
    const reservationList = Object.keys(carrierToReservations[selectedCarrier]).map((reservation) => {
      return {
        name: `${reservationIdToName[reservation]}`,
        count: parseInt(carrierToReservations[selectedCarrier][reservation], 10),
        value: reservation
      };
    });
    setReservationOptions(reservationList);
  };

  const reservationDropdown = (
    <SelectorWithCheckbox
      options={reservationOptions}
      selected={[shipmentFilters.reservationId.toString()]}
      title={'Select a Reservation'}
      onSelect={(value: string) => handleReservationSelect(value)}
    />
  );

  const additionalFilters = (
    <AdditionalShipmentFilters
      wavingService={props.wavingService}
      shipmentFilters={shipmentFilters}
      serviceTypeOptions={
        serviceTypesMap.get(shipmentFilters.carriers[0]) ? serviceTypesMap.get(shipmentFilters.carriers[0]) : []
      }
      handleShipmentFiltersUpdate={(updatedFilters: OrderFilters) => setShipmentFilters(updatedFilters)}
    />
  );

  const clearFilters = () => {
    setShipmentFilters(emptyShipmentFilters);
  };

  const resetReservationSelected = () => {
    const shipmentFiltersCopy = cloneDeep(emptyShipmentFilters);
    shipmentFiltersCopy.carriers = [shipmentFilters.carriers[0]];
    setShipmentFilters(shipmentFiltersCopy);
  };

  const updateAdditionalFilterSelected = (filterKey: string, value?: string) => {
    const shipmentFiltersCopy = cloneDeep(shipmentFilters);
    switch (filterKey) {
      case 'serviceTypes': {
        const index = shipmentFiltersCopy.serviceTypes.indexOf(value, 0);
        if (index > -1) {
          shipmentFiltersCopy.serviceTypes.splice(index, 1);
        }
        break;
      }
      case 'skus': {
        const index = shipmentFiltersCopy.skus.indexOf(value, 0);
        if (index > -1) {
          shipmentFiltersCopy.skus.splice(index, 1);
        }
        if (shipmentFiltersCopy.skus.length === 0) {
          delete shipmentFiltersCopy.skus;
        }
        break;
      }
      case 'orderType': {
        delete shipmentFiltersCopy.orderType;
        break;
      }
      case 'purchaseOrder': {
        delete shipmentFiltersCopy.purchaseOrder;
        break;
      }
      case 'shipAsIs': {
        delete shipmentFiltersCopy.shipAsIs;
        break;
      }
      case 'includesHazmat': {
        delete shipmentFiltersCopy.includesHazmat;
        break;
      }
      case 'packTimeLabels': {
        delete shipmentFiltersCopy.packTimeLabels;
        break;
      }
      case 'siteToStoreOnly': {
        delete shipmentFiltersCopy.siteToStoreOnly;
        break;
      }
      case 'includesMultiShipmentPo': {
        delete shipmentFiltersCopy.includesMultiShipmentPo;
        break;
      }
    }
    setShipmentFilters(shipmentFiltersCopy);
  };

  const handleShowWaveModal = (
    hvwWaveParameters = {},
    subTotalShipmentsOrPallets: number = 0,
    fullPalletsOnly: boolean = false,
    unitsPerPalletCount: number = 0
  ) => {
    const pretendWaveTemplate = {
      templateName: `Wave by Carrier ${carriers[shipmentFilters.carriers[0]]}`,
      count: subTotalShipmentsOrPallets > 0 ? subTotalShipmentsOrPallets : shipmentCount,
      warehouseId: selectedWarehouse.id,
      waveFilters: Object.assign(getWaveParameterFromShipmentFilters(), hvwWaveParameters)
    } as WaveTemplate;
    setTemplateToWave(pretendWaveTemplate);
    showWaveModalAndSetFullPalletPull(fullPalletsOnly, unitsPerPalletCount);
  };

  const getWaveParameterFromShipmentFilters = (): WaveParameters => {
    const waveParams = {
      reservationId: shipmentFilters.reservationId,
      maxOrders: 0,
      cutoffDate: shipmentFilters.cutoffDate,
      skus: shipmentFilters.skus,
      carriers: shipmentFilters.carriers,
      serviceTypes: shipmentFilters.serviceTypes,
      orderType: shipmentFilters.orderType,
      shipmentType: shipmentFilters.shipmentType,
      purchaseOrder: shipmentFilters.purchaseOrder,
      includesHazmat: shipmentFilters.includesHazmat,
      packTimeLabels: shipmentFilters.packTimeLabels,
      shipAsIs: shipmentFilters.shipAsIs,
      shipmentProfile: shipmentFilters.shipmentProfile,
      siteToStoreOnly: shipmentFilters.siteToStoreOnly,
      includesMultiShipmentPo: shipmentFilters.includesMultiShipmentPo
      // pakaging is replaced with shipasis - true, shipasis - false (as overbox)
      // pickMethod is replaced with orderType
    } as WaveParameters;
    return waveParams;
  };

  const handleFilterResultsTableError = (message, response) => {
    onWaveFailure();
  };

  const handleFilterResultsOnSuccess = () => {
    onWaveSuccess();
    getShipments();
  };

  const carrierEnumToName = () => {
    const carrierMap = new Map<string, string>();
    carriersList.forEach((c) => {
      carrierMap.set(c.enum, c.name);
    });
    return carrierMap;
  };

  const displayFilterResultsTable = () => {
    return (
      !isLoading &&
      shipmentFilters.carriers.length > 0 &&
      shipmentFilters.reservationId > 0 &&
      shipments.length > 0 &&
      !isHighVolumeWavingEnabled
    );
  };

  const displayFilterResultsHVWTable = () => {
    return (
      shipmentFilters.carriers.length > 0 &&
      shipmentFilters.reservationId > 0 &&
      shipmentCount > 0 &&
      isHighVolumeWavingEnabled
    );
  };

  return (
    <div id="async-waving-by-carriers">
      <h2>{'Wave by Carrier and Reservation'}</h2>
      <span className="gray-text">{'1. Select a Carrier'}</span>
      <br />
      <span className="gray-text">{'2. Select a Reservation'}</span>
      <br />
      <span className="gray-text">{'3. Add additional Filters (ie SKU ID)'}</span>
      <div id={'filter-controls'}>
        <div>
          {carriersList.length > 0 ? (
            carrierOptions
          ) : (
            <h5 className="no-shipment-text">{'No shipments found based on your current settings.'}</h5>
          )}
        </div>
        {shipmentFilters.carriers.length > 0 && reservationDropdown}
        {showAdditionalFilters && additionalFilters}
        <ShipmentFiltersLabelGroup
          shipmentFilters={shipmentFilters}
          carriers={carriers}
          hideCutoffDate={true}
          reservationIdToName={reservationIdToName}
          resetCarrierSelected={() => clearFilters()}
          resetReservationSelected={() => resetReservationSelected()}
          updateAdditionalFilterSelected={(filterKey: string, value?: string) =>
            updateAdditionalFilterSelected(filterKey, value)
          }
        />
        {shipmentFilters.carriers.length > 0 && shipmentFilters.reservationId > 0 && (
          <div className="btn-div">
            <a className="btn btn-primary-alt" onClick={() => clearFilters()}>
              Cancel
            </a>
            <button className="btn" onClick={() => handleShowWaveModal()}>
              Wave
            </button>
            {!isLoading && <h4 className="inline">{`${shipmentCount} matching shipments`}</h4>}
          </div>
        )}
      </div>
      {isLoading && <Loader loading={isLoading} />}

      {displayFilterResultsTable() && (
        <FilterResultsTable
          skuSummary={skuSummary}
          shipments={shipments}
          shipmentTotal={shipmentCount}
          carriers={carrierEnumToName()}
          disableCreate={false}
          shipmentFilters={shipmentFilters}
          wavingService={wavingService}
          pageSize={pageSize}
          currentPage={currentPage}
          handlePagination={(page) => setCurrentPage(page)}
          onSuccess={handleFilterResultsOnSuccess}
          handleError={handleFilterResultsTableError}
        />
      )}

      {displayFilterResultsHVWTable() && (
        <FilterResultsHVWTable
          skuLeaderboards={skuLeaderboards}
          handleShowWaveModal={(
            hvwWaveParameters: object,
            subTotalShipmentsOrPallets: number,
            fullPalletsOnly: boolean,
            unitsPerPalletCount: number
          ) => handleShowWaveModal(hvwWaveParameters, subTotalShipmentsOrPallets, fullPalletsOnly, unitsPerPalletCount)}
          canShowFullPalletPull={
            props.isFullPalletPullForSortationEnabled ||
            !props.reservationToSortationEnabled[shipmentFilters.reservationId]
          }
          disableMultiSkuWaving={false}
        />
      )}
    </div>
  );
};

const responseHasNoErrors = (response) => {
  return response && (!response.errors || response.errors.length === 0);
};

export default AsyncWavingByCarriers;
