import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {PostalRange, SkuVariety} from '../../ecommerce-batches/BatchInterfaces';

interface PostalCodeRangeProps {
  savedPostalCodeRange?: PostalRange;
}

const PostalCodeRange: React.FC<PostalCodeRangeProps> = ({savedPostalCodeRange}) => {
  const {errors, getValues, register} = useFormContext();

  return (
    <>
      <div className="form-field">
        <label>
          Postal Code Range (optional)
          <br />
          <span className="input-hint">Include a start and stop range</span>
        </label>
        <div className="postalrange-text">
          <input
            data-testid="postal-code-range-start-field"
            className="wave-template-input text-input"
            name="postalCodeRangeStart"
            placeholder="Start of range"
            defaultValue={savedPostalCodeRange?.start}
            ref={register({
              validate: {
                bothCustomAttrsAreSet: (start) => {
                  const end = getValues('postalCodeRangeEnd');
                  if ((start && !end) || (!start && end)) {
                    return 'Both start and end range must be provided';
                  }
                }
              }
            })}
          />
          <input
            data-testid="postal-code-range-end-field"
            className="wave-template-input text-input"
            name="postalCodeRangeEnd"
            placeholder="End of range"
            defaultValue={savedPostalCodeRange?.end}
            ref={register}
          />
        </div>
      </div>
      {errors.postalCodeRangeStart && (
        <span className="required-input validation-msg">{errors.postalCodeRangeStart.message}</span>
      )}
    </>
  );
};

export default PostalCodeRange;
