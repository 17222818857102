import * as React from 'react';
import {useMemo, useRef} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {GridColumnVisibilityModel} from '@mui/x-data-grid';
import InventoryService, {LocationContent, LpnResponse} from '../shared/services/InventoryService';
import {flexeMuiTheme} from '../shared/mui/default-mui-theme';
import {FlexeDataGrid} from '../shared/mui/flexe-data-grid/FlexeDataGrid';
import {
  LocationContentsGridLocationContent,
  useLocationContentsDataGrid
} from '../shared/mui/flexe-data-grid/location-contents-grid/useLocationContentsGrid';
import ItemMasterService, {Item} from '../shared/services/ItemMasterService';
import {formatDate, formatDateTime} from './date-formatters';

interface LpnContentSummaryTableProps {
  lpnId: number;
  baseUrl: string;
  inventoryService: InventoryService;
  itemMasterService: ItemMasterService;
}
export function LpnContentSummaryTable({
  lpnId,
  baseUrl,
  inventoryService,
  itemMasterService
}: LpnContentSummaryTableProps) {
  const getChildrenPromise = useRef<Promise<LpnResponse[]>>(null);
  const {columns, rows, isLoading} = useLocationContentsDataGrid(fetchContentsPage, baseUrl);

  async function fetchContentsPage(continuationToken) {
    getChildrenPromise.current = getChildrenPromise.current || getLpnChildren(lpnId, inventoryService);
    const childLpns = await getChildrenPromise.current;
    const lpnIds = [lpnId, ...childLpns.map(({id}) => id)];

    const result = await inventoryService.getLocationContentsByLpnIds(lpnIds, continuationToken);
    const itemIds = result.data.contents.map(({inventoryId}) => inventoryId);
    if (itemIds.length === 0) {
      return {
        contents: [],
        continuationToken: null
      };
    } else {
      const itemMasterItems = await getItemMasterItemsByIds(itemIds, itemMasterService);
      const inventoriesById = new Map(itemMasterItems.map((item) => [item.id, item]));
      const childLpnsById = new Map(childLpns.map((lpn) => [lpn.id, lpn]));
      const lpnBarcodesByContentId = new Map(
        result.data.contents.map((content) => [content.id, childLpnsById.get(content.lpnId)?.barcode])
      );

      return {
        contents: result.data.contents.map((content) => mapContents(content, inventoriesById, lpnBarcodesByContentId)),
        continuationToken: result.data.continuationToken
      };
    }
  }

  const columnVisibilityModel = useMemo<GridColumnVisibilityModel>(() => {
    const enableLpnsColumn = rows.some(({lpnBarcode}) => lpnBarcode);
    const enableUomColumn = rows.some(({uomJson}) => uomJson);
    const enableLotCodeColumn = rows.some(({lotCode}) => lotCode);
    const enableExpirationDateColumn = rows.some(({expirationDate}) => expirationDate);
    const enableManufactureDateColumn = rows.some(({manufactureDate}) => manufactureDate);

    // Hide these because they will always be the same for every row.
    const enableReservationIdColumn = false;
    const enableParentLpnBarcodeColumn = false;

    const model = {
      reservationId: enableReservationIdColumn,
      parentLpnBarcode: enableParentLpnBarcodeColumn,
      lpnBarcode: enableLpnsColumn,
      uomJson: enableUomColumn,
      lotCode: enableLotCodeColumn,
      expirationDate: enableExpirationDateColumn,
      manufactureDate: enableManufactureDateColumn
    };
    if (isLoading) {
      // Hide all columns until we know which ones to show
      for (const column of columns) {
        model[column.field] = false;
      }
    }
    return model;
  }, [columns, rows, isLoading]);

  return (
    <ThemeProvider theme={flexeMuiTheme}>
      <FlexeDataGrid
        columns={columns}
        rows={rows}
        loading={isLoading}
        columnVisibilityModel={columnVisibilityModel}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        isRowSelectable={() => false}
      />
    </ThemeProvider>
  );
}

function mapContents(
  content: LocationContent,
  inventoriesById: Map<number, Item>,
  lpnBarcodesByContentId: Map<number, string>
): LocationContentsGridLocationContent {
  const inventoryItem = inventoriesById.get(content.inventoryId);
  const upcBarcode = inventoryItem.properties.find(({packaging}) => packaging === content.quantity.packaging)?.barcode;
  return {
    reservationId: content.reservationId,
    lpnBarcode: lpnBarcodesByContentId.get(content.id),
    lpnId: content.lpnId,
    id: content.id.toString(),
    sku: {id: content.inventoryId, sku: inventoryItem.sku},
    description: inventoryItem.description,
    upc: upcBarcode,
    quantity: content.quantity.amount,
    unit: {
      amount: content.quantity.amount,
      unit: content.quantity.packaging
    },
    uomJson: content.inventoryTrackingData?.uoms,
    lotCode: content.inventoryTrackingData?.lotCode,
    expirationDate: formatDate(content.inventoryTrackingData?.expirationDate),
    manufactureDate: formatDateTime(content.inventoryTrackingData?.manufactureDate)
  };
}

function getItemMasterItemsByIds(itemIds: number[], itemMasterService: ItemMasterService) {
  return fetchAllPages(
    (itemMasterContinuationToken) => itemMasterService.getItems(itemIds, itemMasterContinuationToken),
    ({data}) => ({
      continuationToken: data.continuationToken,
      pageElements: data.items
    })
  );
}

function getLpnChildren(lpnId: number, inventoryService: InventoryService) {
  return fetchAllPages(
    (continuationToken) => inventoryService.getLpnChildren(lpnId, continuationToken),
    ({data}) => ({
      continuationToken: data.continuationToken,
      pageElements: data.lpnDetails
    })
  );
}

async function fetchAllPages<TResult, TResponse>(
  getPage: (continuationToken: string) => Promise<TResponse>,
  mapResponse: (response: TResponse) => {continuationToken: string; pageElements: TResult[]}
): Promise<TResult[]> {
  let currentContinuationToken: string = null;
  let results: TResult[] = [];
  do {
    const response = await getPage(currentContinuationToken);
    const {continuationToken, pageElements} = mapResponse(response);
    currentContinuationToken = continuationToken;
    results = [...results, ...pageElements];
  } while (currentContinuationToken);
  return results;
}
