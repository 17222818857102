import * as React from 'react';
import {FunctionComponent} from 'react';
import {Item} from '../../shared/services/ItemMasterService';
import {ItemPropertiesDetailsModalState, LpnDetailsModalState} from './CycleCountDetail';
import {CycleCountDetails, CycleCountItem, CycleCountStatus} from './CycleCountInterfaces';
import CycleCountItemListItem from './CycleCountItemListItem';
import {isMissingLpn, isUnexpectedLpn} from './helpers';
import LpnDetailsModal from './LpnDetailsModal';
import ItemPropertiesDetailsModal from './ItemPropertiesDetailsModal';

interface Props {
  byLocation: boolean;
  viewByLocation: boolean;
  ccState: string;
  isEditing: boolean;
  isReviewing: boolean;
  forShipper: boolean;
  ccItems: CycleCountItem[];
  showLpnDetailsModal: LpnDetailsModalState;
  showItemPropertiesDetailsModal: ItemPropertiesDetailsModalState;
  disabled: boolean;
  cycleCount: CycleCountDetails;
  activeWorkersExist: boolean;
  enableLpnCycleCounts: boolean;
  renderCurrentWorkers: () => JSX.Element;
  saveLpnMetadataEdits: (data) => Promise<void>;
  toggleLpnDetailsModalWithData: (data: string) => void;
  toggleLpnDetailsModalWithEvent: (event: any) => void;
  toggleItemDetailsModalWithId: (itemId: string, locationId: string) => void;
  onUpdateItem: (item: CycleCountItem) => void;
  enableLpnPropertiesCCButton: boolean;
  inventoryInfos: Item[];
}

const CycleCountItemsList: FunctionComponent<Props> = (props) => {
  const buildRowHeaders = () => {
    if (props.enableLpnCycleCounts) {
      return (
        <tr>
          <th className={'lpn-barcode'}>LPN Barcode</th>
          <th className={'user'}>Counted By</th>
          <th className={'identifier'}>{props.viewByLocation ? 'Item' : 'Location'}</th>
          <th className={'quantity'}>Counted Cartons</th>
          <th className={'quantity'}>Expected Cartons</th>
          <th className={'quantity'}>Counted Eaches</th>
          <th className={'quantity'}>Expected Eaches</th>
          <th className={'quantity'}>Total Counted Eaches</th>
          <th className={'quantity'}>Total Expected Eaches</th>
          <th className={'quantity'}>Difference</th>
        </tr>
      );
    } else {
      return (
        <tr>
          <th className={'identifier'}>{props.viewByLocation ? 'Item' : 'Location'}</th>
          <th className={'user'}>Counted By</th>
          <th className={'quantity'}>Counted Cartons</th>
          <th className={'quantity'}>Expected Cartons</th>
          <th className={'quantity'}>Counted Eaches</th>
          <th className={'expected-eaches'}>Expected Eaches</th>
          <th className={'quantity'}>Total Counted Eaches</th>
          <th className={'tot-expected-eaches'}>Total Expected Eaches</th>
          <th className={'quantity'}>Difference</th>
        </tr>
      );
    }
  };

  const getSortedLpnsList = () => {
    return props.ccItems.sort(compareLpns);
  };

  const compareLpns = (a, b) => {
    return getCcLpnCompareValue(b) - getCcLpnCompareValue(a);
  };

  const getCcLpnCompareValue = (ccItem) => {
    const countedEaches = ccItem.countedQuantities.each;
    const expectedEaches = ccItem.expectedQuantities.each;
    const countedCartons = ccItem.countedQuantities.carton;
    const expectedCartons = ccItem.expectedQuantities.carton;

    if (props.isReviewing && isMissingLpn(countedEaches, expectedEaches, countedCartons, expectedCartons)) {
      return 2;
    } else if (isUnexpectedLpn(countedEaches, expectedEaches, countedCartons, expectedCartons)) {
      return 1;
    }

    return 0;
  };

  const getBackgroundColor = (cci) => {
    const countedEaches = cci.countedQuantities.each;
    const expectedEaches = cci.expectedQuantities.each;
    const countedCartons = cci.countedQuantities.carton;
    const expectedCartons = cci.expectedQuantities.carton;

    if (props.isReviewing && isMissingLpn(countedEaches, expectedEaches, countedCartons, expectedCartons)) {
      return 'bg-red6';
    } else if (isUnexpectedLpn(countedEaches, expectedEaches, countedCartons, expectedCartons)) {
      return 'bg-warning';
    }

    return '';
  };

  return (
    <React.Fragment>
      <table className={'table cycle-count-table'}>
        <thead>{buildRowHeaders()}</thead>
        <tbody>
          {props.enableLpnCycleCounts
            ? getSortedLpnsList().map((cci, i) => {
                const inventoryInfoForCCItem =
                  props.inventoryInfos != null
                    ? props.inventoryInfos.filter((inventoryInfo) => {
                        return inventoryInfo.id === cci.inventory.id;
                      })
                    : null;
                return (
                  <tr key={`${i}-${cci.id}`} className={getBackgroundColor(cci)}>
                    <CycleCountItemListItem
                      item={cci}
                      ccState={props.ccState}
                      isEditing={props.isEditing}
                      byLocation={props.byLocation}
                      viewByLocation={props.viewByLocation}
                      forShipper={props.forShipper}
                      enableLpnCycleCounts={true}
                      toggleLpnDetailsModalWithEvent={props.toggleLpnDetailsModalWithEvent}
                      toggleLpnDetailsModalWithData={props.toggleLpnDetailsModalWithData}
                      toggleItemDetailsModalWithId={props.toggleItemDetailsModalWithId}
                      onUpdateItem={props.onUpdateItem}
                      enableLpnPropertiesCCButton={props.enableLpnPropertiesCCButton}
                      inventoryInfoForCCItem={inventoryInfoForCCItem}
                    />
                    {
                      <LpnDetailsModal
                        activeWorkersExist={props.activeWorkersExist}
                        renderCurrentWorkers={props.renderCurrentWorkers}
                        forShipper={props.forShipper}
                        id={`${cci.location.label}-${cci.inventory.sku}`}
                        toggleLpnDetailsModalWithData={props.toggleLpnDetailsModalWithData}
                        toggleLpnDetailsModalWithEvent={props.toggleLpnDetailsModalWithEvent}
                        showLpnDetailsModal={props.showLpnDetailsModal}
                        disabled={props.disabled}
                        saveLpnMetadataEdits={props.saveLpnMetadataEdits}
                        cycleCountItem={cci}
                        inventoryInfoForCCItem={inventoryInfoForCCItem}
                      />
                    }
                  </tr>
                );
              })
            : props.ccItems.map((cci, i) => {
                const inventoryInfoForCCItem =
                  props.inventoryInfos != null
                    ? props.inventoryInfos.filter((inventoryInfo) => {
                        return inventoryInfo.id === cci.inventory.id;
                      })
                    : null;
                return (
                  <tr key={`${i}-${cci.id}`}>
                    <CycleCountItemListItem
                      item={cci}
                      ccState={props.ccState}
                      isEditing={props.isEditing}
                      byLocation={props.byLocation}
                      viewByLocation={props.viewByLocation}
                      forShipper={props.forShipper}
                      enableLpnCycleCounts={false}
                      toggleLpnDetailsModalWithEvent={props.toggleLpnDetailsModalWithEvent}
                      toggleLpnDetailsModalWithData={props.toggleLpnDetailsModalWithData}
                      toggleItemDetailsModalWithId={props.toggleItemDetailsModalWithId}
                      onUpdateItem={props.onUpdateItem}
                      enableLpnPropertiesCCButton={props.enableLpnPropertiesCCButton}
                      inventoryInfoForCCItem={inventoryInfoForCCItem}
                    />
                    {(cci.metadata ||
                      inventoryInfoForCCItem?.[0]?.lotCodeTrackingEnabled ||
                      inventoryInfoForCCItem?.[0]?.expiryDateTrackingEnabled ||
                      cci.inventory.lotCodeTrackingEnabled ||
                      cci.inventory.expiryDateTrackingEnabled) && (
                      <ItemPropertiesDetailsModal
                        activeWorkersExist={props.activeWorkersExist}
                        renderCurrentWorkers={props.renderCurrentWorkers}
                        forShipper={props.forShipper}
                        id={`${cci.location.label}-${cci.inventory.sku}`}
                        toggleItemDetailsModalWithId={props.toggleItemDetailsModalWithId}
                        showItemPropertiesDetailsModal={props.showItemPropertiesDetailsModal}
                        disabled={props.disabled}
                        saveLpnMetadataEdits={props.saveLpnMetadataEdits}
                        cycleCountItem={cci}
                        inventoryInfoForCCItem={inventoryInfoForCCItem}
                      />
                    )}
                  </tr>
                );
              })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CycleCountItemsList;
