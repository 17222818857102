import {Measurement, Packaging} from '../shared/CommonInterfaces';

export enum LocationStatus {
  active = 'active',
  locked = 'locked',
  inactive = 'inactive'
}

// User readable strings
export enum LocationCategory {
  floor_storage = 'Floor Storage',
  rack_storage = 'Rack Storage',
  bin_storage = 'Bin Storage',
  inbound_staging = 'Inbound Staging',
  outbound_staging = 'Outbound Staging',
  pack_station = 'Pack Station',
  quality_audit = 'Quality Audit',
  transitional = 'Transitional',
  outbound_returns_staging = 'Damaged Hold',
  material_handling_equipment_location = 'Material Handling Equipment',
  crossdock = 'Crossdock',
  dock = 'Dock',
  virtual = 'Virtual',
  lost = 'Lost'
}

export enum LocationCategoryEditableCategories {
  floor_storage = 'Floor Storage',
  rack_storage = 'Rack Storage',
  bin_storage = 'Bin Storage',
  inbound_staging = 'Inbound Staging',
  outbound_staging = 'Outbound Staging',
  pack_station = 'Pack Station',
  quality_audit = 'Quality Audit',
  outbound_returns_staging = 'Damaged Hold',
  crossdock = 'Crossdock',
  dock = 'Dock'
}

export enum LocationPrimaryPickLocation {
  true = 'True',
  false = 'False'
}

export enum LocationAllocatable {
  true = 'Yes',
  false = 'No'
}

// Warehouser server-side string constants
export enum LocationCategoryStrings {
  CATEGORY_FLOOR_STORAGE = 'floor_storage',
  CATEGORY_RACK_STORAGE = 'rack_storage',
  CATEGORY_BIN_STORAGE = 'bin_storage',
  CATEGORY_PACK_STATION = 'pack_station',
  CATEGORY_QUALITY_AUDIT = 'quality_audit',
  CATEGORY_TRANSITIONAL = 'transitional',
  CATEGORY_VIRTUAL = 'virtual',
  CATEGORY_UNCATEGORIZED = 'uncategorized',
  CATEGORY_INBOUND_STAGING = 'inbound_staging',
  CATEGORY_OUTBOUND_STAGING = 'outbound_staging',
  CATEGORY_OUTBOUND_RETURNS_STAGING = 'outbound_returns_staging',
  CATEGORY_MHE = 'material_handling_equipment_location',
  CATEGORY_CROSSDOCK = 'crossdock',
  CATEGORY_DOCK = 'dock',
  CATEGORY_LOST = 'lost'
}

export const SYSTEM_RESERVED_CATEGORIES: string[] = [
  LocationCategoryStrings.CATEGORY_TRANSITIONAL,
  LocationCategoryStrings.CATEGORY_VIRTUAL,
  LocationCategoryStrings.CATEGORY_LOST
];

/**
 * @deprecated Use `LocationService` types, e.g. `LocationContents`, instead when fetching
 * data from `LocationsService`.
 */
export interface Location {
  id: number;
  label: string;
  category: string;
  allocatable: LocationAllocatable;
  state?: LocationStatus;
  lockInfo?: string;
  collapse?: boolean;
  contents?: LocationSku[];
  barcodeText?: string;
  sku?: string;
  createdAt: string;
  updatedAt: string;
  arrivalTime: string;
  warehouse: {
    id: number;
  };
  associatedEntity?: {
    id?: number;
    type?: LocationEntityType;
  };
  sequenceId?: number;
  palletCapacity?: number;
  primaryPickLocation?: boolean;
  putAwayZone: string;
  primarySku?: string;
  primarySkuPackagingType?: string; // this should be updated to use Packaging when the platform fully supports inners
  primarySkuMaxQuantity?: number;
  dimensions?: {
    height: Measurement;
    width: Measurement;
    length: Measurement;
  };
}

export enum LocationEntityType {
  INVENTORY = 'inventory',
  SHIPMENT = 'shipment'
}

export enum LocationContentEntityType {
  LPN = 'lpn'
}

export interface LocationSku {
  reservation: {
    id: number;
  };
  inventory: {
    id: number;
    sku: string;
  };
  inventoryTrackingData?: {
    type: string;
    id: number;
    lotCode: string;
    expirationDate: string;
  };
  entity?: {
    id: string;
    type: LocationContentEntityType;
  };
  quantity: {
    unit: Packaging;
    amount: number;
    conversions: {
      each: {amount: number};
      carton: {amount: number};
      pallet: {amount: number};
    };
  };
}
export interface Company {
  id: number;
  name: string;
}

export interface Inventory {
  id: number;
  sku: string;
}
export interface LocationFilters {
  locationLabel?: string[];
  locationStatus?: LocationStatus[];
  locationCategory?: LocationCategory[];
  sku?: string[];
  reservationId?: string[];
  lpnBarcode?: string[];
  lotCode?: string[];
  serialNumber?: string[];
  hasLpn?: boolean;
  isEmpty?: boolean;
  isPrimaryPickLocation?: boolean;
  isAllocatable?: boolean;
  putAwayZone?: string[];
  expirationDate?: string[];
}

export interface ReservationLocationConfigResponse {
  reservationLocationConfig: ReservationLocationConfig;
}

export interface ReservationLocationConfig {
  id: number;
  reservationId: number;
  warehouseLocationConfigId: number;
  locationsFlag: LocationsFlag;
}

export enum LocationsFlag {
  locations_disabled = 'locations_disabled',
  automove_enabled = 'automove_enabled',
  mobile_enabled = 'mobile_enabled'
}

export interface BulkToggleLocationResponse {
  updated: number[];
}
