import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {useEffect, useState} from 'react';
import WarehouseService from '../../shared/services/WarehouseService';
import BatchWavingService from '../../shared/services/BatchWavingService';
import ShipmentService from '../../shared/services/ShipmentService';
import DocumentsService from '../../shared/services/DocumentsService';
import WaveTemplateService from '../../shared/services/WaveTemplateService';
import {CompanyType, Warehouse} from '../../shared/CommonInterfaces';
import FlexeContext, {FlexeContextValues} from '../../contexts/FlexeContext';
import NewWaveTemplateForm from '../batch-waving/wave-templates/NewWaveTemplateForm';
import ShippingLabelService from '../../shared/services/ShippingLabelService';
import AsyncWaving from '../batch-waving/AsyncWaving';
import BatchDetail from './BatchDetail';
import CompactBatchList from './CompactBatchList';

interface BatchesProps {
  authenticityToken: string;
  carriers: object;
  currentWarehouse: Warehouse;
  warehouses: Warehouse[];
  reservationToSortationEnabled: object;
  hasWhManagerBillingPerms: boolean;
  isFreightWorkflowEnabled: boolean;
  isTrailerLoadingEnabled: boolean;
  highVolumeWavingMap: object;
  whCancelShipmentEnabled: boolean;
  waveTemplateEnhancementsEnabled: boolean;
  waveTemplateFannedLoadingEnabled: boolean;
}

function Batches(props: BatchesProps) {
  const warehouseService = new WarehouseService(props.authenticityToken);
  const batchService = new BatchWavingService(props.authenticityToken);
  const shipmentService = new ShipmentService(props.authenticityToken);
  const documentsService = new DocumentsService(CompanyType.warehouse);
  const shippingLabelService = new ShippingLabelService(props.authenticityToken);
  const waveTemplateService = new WaveTemplateService(props.authenticityToken);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>(props.currentWarehouse);
  batchService.currentWarehouse = Number(selectedWarehouse.id);
  const flexeContextValue: FlexeContextValues = {
    authenticityToken: props.authenticityToken
  };

  const [isSchedulingModalEnabled, setIsSchedulingModalEnabled] = useState<boolean>(false);

  const [enableAddBatchSchedule, setEnableAddBatchSchedule] = useState<boolean>(false);

  const [isHidePrintedFilterEnabled, setIsHidePrintedFilterEnabled] = useState<boolean>(false);

  const [isFullPalletPullForSortationEnabled, setIsFullPalletPullForSortationEnabled] = useState<boolean>(false);

  const getSelectedWarehouse = () => {
    return selectedWarehouse;
  };

  const onSelectedWarehouse = async (warehouse: Warehouse) => {
    if (props.currentWarehouse.id !== warehouse.id) {
      window.location.href = `/wh/fulfillment/ecommerce/waving?warehouse_id=${warehouse.id}`;
    }
  };

  const getFeatureFlagValue = async (flag: string, warehouseId: number = null, reservationId: number = null) => {
    const featureFlagResponse = await warehouseService.getFeatureFlag(flag, warehouseId, reservationId);
    return !!featureFlagResponse.data.value;
  };

  const updateWarehouseFeatureFlagsAsync = async (warehouseId: number) => {
    const scheduledBatchingPage = getFeatureFlagValue('scheduled-batching-page', warehouseId, null);
    const showAddBatchScheduleButton = getFeatureFlagValue('show-add-batch-schedule-button', warehouseId, null);
    const fullPalletPullForSortation = getFeatureFlagValue('full_pallet_pull_for_sortation', warehouseId, null);
    const showHidePrintedBatchesFilter = getFeatureFlagValue('hide-printed-batches-filter-enabled', warehouseId, null);

    setIsSchedulingModalEnabled(await scheduledBatchingPage);
    setEnableAddBatchSchedule(await showAddBatchScheduleButton);
    setIsFullPalletPullForSortationEnabled(await fullPalletPullForSortation);
    setIsHidePrintedFilterEnabled(await showHidePrintedBatchesFilter);
  };

  useEffect(() => {
    // Feature Flags
    updateWarehouseFeatureFlagsAsync(props.currentWarehouse.id);
  }, []);

  const getHighVolumeWavingEnabled = () => {
    const warehouseId = selectedWarehouse.id;
    return props.highVolumeWavingMap[warehouseId];
  };

  return (
    <FlexeContext.Provider value={flexeContextValue}>
      <Router>
        <Switch>
          <Route
            path="/wh/fulfillment/ecommerce"
            exact
            strict
            render={(routeProps) => {
              return (
                <CompactBatchList
                  batchService={batchService}
                  carriers={props.carriers}
                  selectedWarehouse={getSelectedWarehouse()}
                  warehouses={props.warehouses}
                  warehouseService={warehouseService}
                  onWarehouseSelect={(warehouse: Warehouse) => onSelectedWarehouse(warehouse)}
                  isSchedulingModalEnabled={isSchedulingModalEnabled}
                  isAddBatchScheduleEnabled={enableAddBatchSchedule && props.hasWhManagerBillingPerms}
                  isFreightWorkflowEnabled={props.isFreightWorkflowEnabled}
                  isHidePrintedFilterEnabled={isHidePrintedFilterEnabled}
                  isTrailerLoadingEnabled={props.isTrailerLoadingEnabled}
                />
              );
            }}
          />
          <Route
            path="/wh/fulfillment/ecommerce/waving"
            exact
            strict
            render={(routeProps) => (
              <AsyncWaving
                selectedWarehouse={selectedWarehouse}
                onWarehouseSelect={(warehouse: Warehouse) => onSelectedWarehouse(warehouse)}
                {...routeProps}
                {...props}
                reservationToSortationEnabled={props.reservationToSortationEnabled}
                carriers={props.carriers}
                isHighVolumeWavingEnabled={getHighVolumeWavingEnabled()}
                isFullPalletPullForSortationEnabled={isFullPalletPullForSortationEnabled}
                waveTemplateService={waveTemplateService}
                batchWavingService={batchService}
                warehouseService={warehouseService}
                isFreightWorkflowEnabled={props.isFreightWorkflowEnabled}
                waveTemplateEnhancementsEnabled={props.waveTemplateEnhancementsEnabled}
                waveTemplateFannedLoadingEnabled={props.waveTemplateFannedLoadingEnabled}
              />
            )}
          />
          <Route
            path="/wh/fulfillment/ecommerce/waving/new_template"
            exact
            strict
            render={(routeProps) => (
              <NewWaveTemplateForm
                warehouseService={warehouseService}
                selectedWarehouse={selectedWarehouse}
                carrierLabels={props.carriers}
                batchWavingService={batchService}
                waveTemplateService={waveTemplateService}
                reservationToSortationEnabled={props.reservationToSortationEnabled}
              />
            )}
          />
          <Route
            path="/wh/fulfillment/ecommerce/:id"
            exact
            strict
            render={(routeProps) => (
              <BatchDetail
                {...routeProps}
                {...props}
                batchService={batchService}
                warehouseService={warehouseService}
                shipmentService={shipmentService}
                documentsService={documentsService}
                shippingLabelService={shippingLabelService}
              />
            )}
          />
        </Switch>
      </Router>
    </FlexeContext.Provider>
  );
}

export default Batches;
