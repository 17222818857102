import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import WarehouseService from '../shared/services/WarehouseService';
import SelectorWithCheckbox from '../shared/SelectorWithCheckbox';
import FlexeContext from '../contexts/FlexeContext';
import {Reservation} from './CommonInterfaces';

interface ReservationDropdownProps {
  warehouseId: number;
  selectedReservationIdChanged: (reservationId: number) => void;
  includeAllOption?: boolean;
}

interface ReservationOption {
  name: string;
  value: string | null;
}

const ReservationDropdown: React.FC<ReservationDropdownProps> = ({
  warehouseId,
  selectedReservationIdChanged,
  includeAllOption = true
}) => {
  const {authenticityToken} = useContext(FlexeContext);
  const warehouseService = new WarehouseService(authenticityToken);

  const [reservations, setReservations] = useState<Reservation[]>();
  const [selectedReservationId, setSelectedReservationId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getReservations = async () => {
    setIsLoading(true);
    const response = await warehouseService.getReservationsForWarehouse(warehouseId);
    setReservations(response.data?.reservations ?? []);
    setIsLoading(false);
  };

  const memoizedOnSelect = useCallback(
    (value: string) => {
      const selectedId = parseInt(value, 10);
      setSelectedReservationId(selectedId);
      selectedReservationIdChanged(selectedId);
    },
    [selectedReservationIdChanged]
  );

  const reservationOptions = useMemo((): ReservationOption[] => {
    const options: ReservationOption[] =
      reservations?.map((reservation) => {
        return {
          name: reservation.name,
          value: `${reservation.id}`
        };
      }) ?? [];

    if (includeAllOption === true) {
      options.unshift({name: 'All', value: '-1'});
    }
    return options;
  }, [reservations]);

  useEffect(() => {
    getReservations();
  }, [warehouseId, authenticityToken]);

  useEffect(() => {
    const firstResOption = parseInt(reservationOptions[0]?.value, 10) ?? -1;
    selectedReservationIdChanged(firstResOption);
    setSelectedReservationId(firstResOption);
  }, [reservationOptions, selectedReservationIdChanged]);

  return (
    <>
      <SelectorWithCheckbox
        options={reservationOptions}
        selected={[`${selectedReservationId}`]}
        onSelect={memoizedOnSelect}
        prefixText="Reservation: "
        isLoading={isLoading}
      />
    </>
  );
};

export default ReservationDropdown;
