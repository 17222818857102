import * as React from 'react';
import {FunctionComponent} from 'react';
import {LegacyModal} from '@flexe/ui-components';
import DropDown, {DropDownOption, DropDownStyle} from '../DropDown';
import {Item} from '../services/ItemMasterService';
import {CycleCountDetails, CycleCountReasonCode, CycleCountStatus} from './CycleCountInterfaces';
import {calculateDeltaForCountedItem} from './helpers';

interface Props {
  cycleCount: CycleCountDetails;
  inventoryInfos: Item[];
  showCompleteModal: boolean;
  purchaseOrderRequired: boolean;
  purchaseOrderProvided: boolean;
  editNotesProvided: boolean;
  cycleCountReasonCodes: CycleCountReasonCode[];
  selectedOption: number | string;
  toggleCompleteModal: () => void;
  handleComplete: (event: any) => Promise<void>;
  handleDropDownSelect: (reason: DropDownOption) => void;
  handlePurchaseOrderInput: (event: any) => void;
  handleEditNoteChange: (event: any) => void;
}

const CycleCountCompleteModal: FunctionComponent<Props> = (props) => {
  const dropDownOptions: DropDownOption[] = props.cycleCountReasonCodes.map((code) => {
    return {
      name: code.name,
      value: code.value
    };
  });
  const isAdjusted = () => {
    for (const item of props.cycleCount.cycleCountItems) {
      const [, , , delta] = calculateDeltaForCountedItem(item);
      if (delta !== 0) {
        return true;
      }
    }
    return false;
  };
  const getSelectedOption = () => {
    if (isAdjusted()) {
      return dropDownOptions.find((opt) => opt.value === props.selectedOption);
    } else {
      return dropDownOptions.find((opt) => opt.name === 'Cycle Count / Inventory Count');
    }
  };
  const reasonsThatRequirePO: string[] = ['vendor_damaged', 'vendor_overage', 'adjust_past_receipt'];
  const isDisabled = () => {
    if (!isAdjusted()) {
      // If the count is non-discrepant
      return !props.editNotesProvided;
    } else if (!props.selectedOption || !props.editNotesProvided) {
      // If either reasonCode option or editNotes is not provided
      return true;
    } else if (props.purchaseOrderRequired && (!props.purchaseOrderProvided || !props.editNotesProvided)) {
      // If purchaseOrder is required but purchaseOrderId or editNotes is not provided
      return true;
    }
    return false;
  };

  return (
    <LegacyModal
      id="complete-cycle-count-modal"
      title="Complete Cycle Count"
      show={props.showCompleteModal}
      size="small"
      toggleModal={props.toggleCompleteModal}
      footer={
        <div>
          <a onClick={props.toggleCompleteModal}>Cancel</a>
          <button
            data-testid="complete-agree"
            type="button"
            data-target-state={CycleCountStatus.completed}
            className="btn cta"
            disabled={isDisabled()}
            onClick={props.handleComplete}
          >
            Submit
          </button>
        </div>
      }
      disableClose={false}
    >
      <React.Fragment>
        <p>
          On-hand inventory will be immediately updated to match your entries. Please verify that you are submitting
          accurate counts.
        </p>
        <div className="row space-below">
          <div>
            <label>Select reason code (required)</label>
          </div>
          <DropDown
            options={dropDownOptions}
            style={DropDownStyle.modal}
            selected={getSelectedOption()}
            onSelect={props.handleDropDownSelect}
            prefixText={'Reason for Cycle Count: '}
          />
        </div>
        {reasonsThatRequirePO.includes(props.selectedOption as string) && (
          <div className="row space-below">
            <label htmlFor="purchaseOrderId">Purchase order ID (required)</label>
            <input
              type="text"
              className="form-control"
              aria-label="purchaseOrderId"
              placeholder={'Purchase order ID'}
              onChange={props.handlePurchaseOrderInput}
            />
          </div>
        )}
        <div className="row space-below">
          <label htmlFor="editNotes">Notes (required)</label>
          <input
            type="text"
            className="form-control"
            aria-label="editNotes"
            placeholder="Please enter notes about this cycle count ..."
            onChange={props.handleEditNoteChange}
          />
        </div>
      </React.Fragment>
    </LegacyModal>
  );
};

export default CycleCountCompleteModal;
