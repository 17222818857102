import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {FreightShipMode, ParcelShipMode} from '../../../shared/constants';
import {SortOrder, Warehouse} from '../../../shared/CommonInterfaces';
import ManifestContentsService from '../../../shared/services/ManifestContentsService';
import OutboundShipmentService from '../../../shared/services/OutboundShipmentService';
import {FilterKeys, GetShipmentsParams, Shipment, SortByColumns} from '../../outbound-shipments/ShipmentInterfaces';
import OutboundManifestService from '../../../shared/services/OutboundManifestService';
import {LoadDetail} from '../LoadInterfaces';
import {
  LpnType,
  ManifestContents,
  ManifestContentsSearchByManifestFilter,
  ManifestContentsSearchByManifestRequest,
  ManifestLpn
} from '../ManifestInterfaces';
import LoadLpnsTable from './LoadLpnsTable';
import Tabs, {Tab} from './LoadContentsTabs';
import LoadShipmentsTable from './LoadShipmentsTable';

interface LoadContentsProps {
  authenticityToken: string;
  warehouse: Warehouse;
  load: LoadDetail;
  setPalletsLoaded: (num: number) => void;
  isConfiguredForSortProcesses: boolean;
}

enum ActiveTab {
  LPNS = 'LPNs',
  SHIPMENTS = 'SHIPMENTS'
}

const LPN_FETCH_PAGE_SIZE = 50;
const DEFAULT_GRID_PAGE_SIZE = 50;
const FILTER_LENGTH_MINIMUM = 4; // the minimum length a filter can be before we use it to ping the server

const LoadContents: React.FC<LoadContentsProps> = (props) => {
  const shipmentFetcher = new OutboundShipmentService(props.authenticityToken);
  const manifestContentsService = new ManifestContentsService(props.authenticityToken);
  const outboundManifestService = new OutboundManifestService(props.authenticityToken);

  const initialLoadTab: string = props.isConfiguredForSortProcesses ? ActiveTab.LPNS : ActiveTab.SHIPMENTS;
  const [activeTab, setActiveTab] = useState<string>(initialLoadTab);

  const [shipments, setShipments] = useState<Shipment[]>([]);
  const shipmentCount = useRef<number>(null);
  // stores continuation tokens for each page where index = page number
  const shipmentTableContinuationTokens = useRef<string[]>([null]);
  const [shipmentIdFilter, setShipmentFilter] = useState<number | null>(null);
  const [shipmentsLoading, setShipmentsLoading] = useState<boolean>(true);
  // the page that is currently displayed
  const [shipmentTablePageNumber, setShipmentTablePageNumber] = useState<number>(0);
  const [shipmentTablePageSize, setShipmentTablePageSize] = useState<number>(DEFAULT_GRID_PAGE_SIZE);
  const [shipmentTableSortDirection, setShipmentTableSortDirection] = useState<SortOrder>(SortOrder.ASC);
  const shipmentTableSortField = useRef<SortByColumns>(SortByColumns.ShipmentId);
  const shipmentFilters = useRef<GetShipmentsParams>({
    omni_only: true,
    page_size: DEFAULT_GRID_PAGE_SIZE,
    sort_by: SortByColumns.ShipmentId,
    sort_direction: SortOrder.ASC,
    continuation_token: null,
    warehouse_id: props.warehouse.id
  });

  const [lpns, setLpns] = useState<ManifestLpn[]>([]);
  const lpnContinuationToken = useRef<string | null>(null);
  const [lpnsLoading, setLpnsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>(null);

  // LPN's once we have enough information to do so
  useEffect(() => {
    if (props.load?.reservationId != null && props.load.id != null) {
      fetchLpns();
    } else {
      setLpnsLoading(false);
    }
  }, [props.load]);

  // listens for prop changes so that the initial page load occurs when we have enough information
  // and listens for filtering, pagination and sorting to trigger data reloads server-side
  useEffect(() => {
    switch (props.load?.shipMode) {
      case FreightShipMode:
        if (props.load.freightLoadId != null && props.warehouse != null) {
          fetchShipments();
        }
        break;
      case ParcelShipMode:
        if (props.warehouse != null && props.load.id != null) {
          fetchShipments();
        }
        break;
      default:
        break;
    }
  }, [
    props.load,
    props.warehouse,
    shipmentIdFilter,
    shipmentTablePageNumber,
    shipmentTablePageSize,
    shipmentTableSortDirection
  ]);

  const fetchShipments = async () => {
    setShipmentsLoading(true);
    setError(null);
    rebuildShipmentFilters();
    try {
      const response = await shipmentFetcher.getShipmentsDeprecated(shipmentFilters.current);
      const token = response.data.continuation_token;

      // the continuation token we receive is for the next page
      const pageNumber = shipmentTablePageNumber + 1;
      if (!shipmentTableContinuationTokens.current.includes(token, pageNumber)) {
        shipmentTableContinuationTokens.current.splice(pageNumber, 0, token);
      }

      setShipments(response.data.shipments);
      shipmentCount.current = response.data.counts;
    } catch (e) {
      setError(e.toString());
    } finally {
      setShipmentsLoading(false);
    }
  };

  const rebuildShipmentFilters = () => {
    switch (props.load.shipMode) {
      case FreightShipMode:
        shipmentFilters.current = {
          ...shipmentFilters.current,
          freight_load_id: props.load.freightLoadId,
          mode: FreightShipMode,
          page_size: shipmentTablePageSize,
          continuation_token: shipmentTableContinuationTokens.current[shipmentTablePageNumber],
          warehouse_id: props.warehouse.id,
          sort_by: shipmentTableSortField.current, // assumed since it's the only sortable field
          sort_direction: shipmentTableSortDirection,
          [FilterKeys.ShipmentId]: shipmentIdFilter
        };
        break;
      case ParcelShipMode:
        shipmentFilters.current = {
          ...shipmentFilters.current,
          load_id: props.load.id.toString(),
          mode: ParcelShipMode,
          page_size: shipmentTablePageSize,
          continuation_token: shipmentTableContinuationTokens.current[shipmentTablePageNumber],
          warehouse_id: props.warehouse.id,
          sort_by: shipmentTableSortField.current,
          sort_direction: shipmentTableSortDirection,
          [FilterKeys.ShipmentId]: shipmentIdFilter
        };
        break;
      default:
        break;
    }
  };

  const onShipmentIdFilterChange = (id: number | null) => {
    // only trigger a data refresh if we think the user is finished applying/unapplying their filter
    const filterRemoved = id == null && shipmentIdFilter != null;
    const filterApplied = id != null && id.toString().length >= FILTER_LENGTH_MINIMUM;
    if (filterRemoved || filterApplied) {
      invalidateShipmentTableContinuationTokens();
      setShipmentFilter(id);
    }
  };

  const onShipmentTablePageSizeChange = (pageSize: number) => {
    if (pageSize !== shipmentTablePageSize) {
      // refresh and put the user on the first page
      invalidateShipmentTableContinuationTokens();
      setShipmentTablePageSize(pageSize);
      setShipmentTablePageNumber(0);
    }
  };

  const onShipmentDelete = (shipmentId: number) => async () => {
    try {
      if (props.load.shipMode === ParcelShipMode) {
        await unloadParcelShipment(shipmentId);
      } else if (props.load.shipMode === FreightShipMode) {
        setError(`Shipments cannot be manually removed from freight loads`);
        return;
      } else {
        setError(`Cannot remove a shipment from a load that has an unknown type ${props.load.shipMode}`);
        return;
      }

      const updatedShipmentsList = shipments.filter((row) => row.id !== shipmentId);
      setShipments(updatedShipmentsList);
      shipmentCount.current = shipmentCount.current - 1;

      // we deleted all the shipments shown,
      // go back to the first page because our continuation tokens may be invalid now
      if (updatedShipmentsList.length === 0) {
        invalidateShipmentTableContinuationTokens();
        setShipmentTablePageNumber(0);
      }
    } catch (e) {
      setError(e.toString());
    }
  };

  const unloadParcelShipment = async (shipmentId: number) => {
    const response = await outboundManifestService.removeShipment(props.load.id, shipmentId);

    if (response?.errors?.length > 0) {
      throw new Error(response.errors[0].detail);
    }
  };

  const onUnloadLpn = async (lpnBarcode: string) => {
    try {
      const response = await outboundManifestService.removeLpn(props.load.id, lpnBarcode);
      if (response.errors.length > 0) {
        setError(response.errors[0].detail || 'An internal error has occurred');
      } else {
        setLpns((prevLpns) => prevLpns.filter((row) => row.lpn_barcode !== lpnBarcode));
      }
    } catch (e) {
      setError(e.toString());
    }
  };

  const invalidateShipmentTableContinuationTokens = () => {
    shipmentTableContinuationTokens.current = [null];
  };

  const consolidateChildLpnContentToParent = (allLpns: ManifestLpn[]): ManifestLpn[] => {
    const topLevelLpns = allLpns.filter((lpn) => {
      return !lpn.parent_lpn_detail_id;
    });

    return topLevelLpns.map((topLevelLpn) => {
      const childLpns = allLpns.filter((lpn) => {
        return lpn.parent_lpn_detail_id === topLevelLpn.lpn_detail_id;
      });

      if (childLpns.length > 0) {
        // Only accumulate the contents for parent LPNs. Stand alone LPNs will have their own contents.
        let totalLpnManifestContents: ManifestContents[] = [];

        childLpns.forEach((lpn) => {
          totalLpnManifestContents = totalLpnManifestContents.concat(lpn.manifest_contents);
        });
        topLevelLpn.manifest_contents = totalLpnManifestContents;
      }

      return topLevelLpn;
    });
  };

  const fetchLpns = async () => {
    setLpnsLoading(true);
    try {
      const filters: ManifestContentsSearchByManifestFilter = {
        reservationId: props.load.reservationId,
        manifestId: props.load.id,
        topLevelLpnsOnly: false
      };

      let allLpns: ManifestLpn[] = [];
      do {
        const request: ManifestContentsSearchByManifestRequest = {
          continuationToken: lpnContinuationToken.current,
          pageSize: LPN_FETCH_PAGE_SIZE,
          filters
        };
        await manifestContentsService.searchManifestContentsByManifest(request).then((response) => {
          allLpns = allLpns.concat(response.data.manifest_lpns);
          lpnContinuationToken.current = response.data.continuation_token;
        });
      } while (lpnContinuationToken.current != null);
      allLpns = consolidateChildLpnContentToParent(allLpns);
      setLpns(allLpns);
      props.setPalletsLoaded(allLpns.filter((lpn) => lpn.lpn_type === LpnType.pallet).length);
    } catch (e) {
      setError(e.toString());
    } finally {
      setLpnsLoading(false);
    }
  };

  const handleTabEvent = (clickedTabKey) => {
    let newlySelectedTab: ActiveTab;
    switch (clickedTabKey) {
      case 'Shipments':
        newlySelectedTab = ActiveTab.SHIPMENTS;
        break;
      case 'LPNs':
        newlySelectedTab = ActiveTab.LPNS;
        break;
      default:
        newlySelectedTab = ActiveTab.SHIPMENTS;
    }

    setActiveTab(newlySelectedTab);
  };

  const tabs = (): Tab[] => {
    const shipmentsTab = {
      key: 'Shipments',
      title: shipments.length <= 0 ? `Shipments` : `Shipments (${shipments.length})`,
      isActive: activeTab === ActiveTab.SHIPMENTS
    };
    const lpnsTab = {
      key: 'LPNs',
      title: lpns.length <= 0 ? `LPNs` : `LPNs (${lpns.length})`,
      isActive: activeTab === ActiveTab.LPNS
    };

    // only display LPN's for freight loads because warehouses don't consider parcel shipments as LPN's
    if (props.load?.shipMode === FreightShipMode) {
      return [shipmentsTab, lpnsTab];
    } else if (props.isConfiguredForSortProcesses) {
      return [lpnsTab];
    } else {
      return [shipmentsTab];
    }
  };

  const getTable = () => {
    switch (activeTab) {
      case ActiveTab.LPNS:
        return <LoadLpnsTable lpns={lpns} isLoading={lpnsLoading} onUnloadLpn={onUnloadLpn} />;
      case ActiveTab.SHIPMENTS:
        return (
          <LoadShipmentsTable
            shipMode={props.load?.shipMode}
            shipments={shipments}
            isLoading={shipmentsLoading}
            pageNumber={shipmentTablePageNumber}
            pageSize={shipmentTablePageSize}
            rowCount={shipmentCount.current}
            onPageNumberChange={setShipmentTablePageNumber}
            onPageSizeChange={onShipmentTablePageSizeChange}
            onSortDirectionChange={setShipmentTableSortDirection}
            onShipmentIdFilterChange={onShipmentIdFilterChange}
            onShipmentDelete={onShipmentDelete}
          />
        );
    }
  };

  return (
    <section className="load-contents">
      {error != null && (
        <div className="alert alert-danger" role="alert" key={`error:${1}`}>
          {error}
        </div>
      )}
      <h2>Contents</h2>
      <Tabs tabs={tabs()} onTabClick={handleTabEvent} />
      {getTable()}
    </section>
  );
};

export default LoadContents;
