import * as React from 'react';
import {parse as parseDate} from 'date-fns';
import {cloneDeep, Dictionary, groupBy, map, values} from 'lodash';
import {ContainerDeliveryState, LpnContent, PackingListItem, PackingLists} from '../shared/DropoffInterfaces';
import FlexeContext from '../../contexts/FlexeContext';
import LpnService from '../../shared/services/LpnService';
import {LpnStatus} from '../../lpns/LpnsInterfaces';
import LpnContentRow from './LpnContentRow';

interface LpnContentProps {
  lpnReceiveOnly: boolean;
  originalPackingLists: PackingLists;
  lpnStateData: Map<string, LpnStatus>;
  lpnContents: LpnContent[];
  reservationId: number;
  containerDeliveryId: number;
  inProgressDropoffIds: number[];
  showUoms?: boolean;
  isShipper?: boolean;
  isRenderedInDeliverySummary?: boolean;
  containerDeliveryState: ContainerDeliveryState;
  showEditLpnForAsn?: boolean;
  isAsnLpns?: boolean;
  allowLpnTransferInAllInboundStates: boolean;
  recalculatePackingListsBasedOnLpns(lpnContents: LpnContent[]);
}

interface LpnContentState {
  deletedLpnCount: number;
  lpnContentsAsDictionary: Dictionary<LpnContent[]>;
  showSuccessAlert: boolean;
  successAlertMessage: string;
}

class LpnContents extends React.Component<LpnContentProps, LpnContentState> {
  public static contextType = FlexeContext;
  private lpnService: LpnService;

  public constructor(props) {
    super(props);
    this.state = {
      deletedLpnCount: 0,
      lpnContentsAsDictionary: this.groupLpnContentsByLpn(this.props.lpnContents),
      showSuccessAlert: false,
      successAlertMessage: ''
    };
  }

  public componentDidUpdate(prevProps) {
    // In LpnDropoff component, the lpn contents are initially empty.
    // This is to update the this.state.lpnContents when the parent's lpnContent is actually loaded
    if (
      prevProps.lpnContents &&
      prevProps.lpnContents.length === 0 &&
      this.props.lpnContents &&
      this.props.lpnContents.length > 0
    ) {
      const groupedContents = this.groupLpnContentsByLpn(cloneDeep(this.props.lpnContents));
      this.setState({lpnContentsAsDictionary: groupedContents});
    }
  }

  public render() {
    this.lpnService = this.lpnService ? this.lpnService : new LpnService(this.context.authenticityToken);
    const {lpnContentsAsDictionary} = this.state;
    const damagedLpns = [];
    if (this.props.originalPackingLists && this.props.originalPackingLists.damaged.length > 0) {
      this.props.originalPackingLists.damaged.forEach((damagedLpn) => {
        if (damagedLpn.lpn) {
          damagedLpns.push(damagedLpn.lpn.lpnBarcode);
        }
      });
    }
    const receivedLpns = new Set<string>(this.props.originalPackingLists.shippable.map((pl) => this.createKey(pl)));

    // Whether inbounds can be edited depends on reservation configuration and the inbound state
    // Configuration overrides all ASN LPNs can be edited only if FF enabled and inbound state is new or confirmed
    // otherwise, edits are allowed for all states except cancelled and completed
    let enableEdits: boolean = false;
    const unCorrectableInboundStates = [ContainerDeliveryState.completed, ContainerDeliveryState.cancelled];
    if (this.props.allowLpnTransferInAllInboundStates) {
      enableEdits = !this.props.isShipper;
    } else if (this.props.isAsnLpns) {
      if (this.props.showEditLpnForAsn) {
        enableEdits = !unCorrectableInboundStates.includes(this.props.containerDeliveryState) && !this.props.isShipper;
      } else {
        enableEdits = false;
      }
    } else {
      enableEdits = !unCorrectableInboundStates.includes(this.props.containerDeliveryState) && !this.props.isShipper;
    }

    return (
      <div className="lpn-contents-component">
        {this.expectedLpnCount() === this.state.deletedLpnCount ? (
          <p>There are no LPNs received for this inbound yet.</p>
        ) : (
          <div>
            <div className="table">
              <div className="row headers">
                {this.props.isAsnLpns && receivedLpns.size > 0 && (
                  <div className="col-md-1" style={{width: '5%'}}>
                    &nbsp;
                  </div>
                )}
                <div className="col-md-1">LPN</div>
                <div className="col-md-2">SKU</div>
                <div className="col-md-2">PO#</div>
                {this.props.showUoms && <div className="col-md-1">Received Inner/Outer UoM</div>}
                {this.props.isAsnLpns && <div className="col-md-1">Expected Qty</div>}
                <div className="col-md-1">Received Qty</div>
                <div className="col-md-1">Inbound Date</div>
                <div className="col-md-2">Custom Parameters</div>
                {this.props.lpnReceiveOnly && <div className="col-md-1">Damaged?</div>}
              </div>
              {map(lpnContentsAsDictionary, (contents: LpnContent[]) => {
                return contents.map((content, index) => {
                  content.expirationDate = content.expirationDate ? parseDate(content.expirationDate) : null;
                  const lpnIsDamaged = damagedLpns.includes(content.lpnBarcode);
                  const lpnIsArchived = content.txnState === 'archived';

                  return (
                    <LpnContentRow
                      lpnReceiveOnly={this.props.lpnReceiveOnly}
                      lpnIsDamaged={lpnIsDamaged}
                      lpnIsArchived={lpnIsArchived}
                      key={index}
                      index={index}
                      lpnService={this.lpnService}
                      content={content}
                      receivedLpns={receivedLpns}
                      reservationId={this.props.reservationId}
                      handleLpnUpdate={this.handleLpnUpdate}
                      containerDeliveryId={this.props.containerDeliveryId}
                      alertLpnDeleteSuccess={this.alertLpnDeleteSuccess}
                      alertLpnMoveSuccess={this.alertLpnMoveSuccess}
                      inProgressDropoffIds={this.props.inProgressDropoffIds}
                      shouldDisplayLpn={index === 0}
                      enableEdits={enableEdits}
                      isAsnLpns={this.props.isAsnLpns}
                      shouldDisplayUoms={this.props.showUoms}
                    />
                  );
                });
              })}
            </div>

            {this.props.isAsnLpns && (
              <p data-testid="total-received-lpns">
                <b>
                  {receivedLpns.size}/{this.expectedLpnCount()}
                </b>{' '}
                LPNs Received{' '}
              </p>
            )}
          </div>
        )}
        {this.state.showSuccessAlert && (
          <div className="alert alert-success" role="alert">
            <p>
              {this.state.successAlertMessage}
              <button type="button" className="close" onClick={this.closeSuccessAlert}>
                <span aria-hidden="true">&times;</span>
              </button>
            </p>
          </div>
        )}
      </div>
    );
  }

  public createKey = (packingList: PackingListItem): string => {
    return packingList.lpn ? packingList.lpn.lpnBarcode : '';
  };

  public handleLpnUpdate = (content: LpnContent, index: number) => {
    // this is inefficient to ungroup and group the contents. Update this when
    // the permanent solution of multi-sku lpn is implemented.
    const lpnConts: LpnContent[] = [].concat(...values(cloneDeep(this.state.lpnContentsAsDictionary)));
    lpnConts[index] = content;
    this.props.recalculatePackingListsBasedOnLpns(lpnConts);
    this.setState({lpnContentsAsDictionary: groupBy(lpnConts, 'lpnBarcode')});
  };
  public alertLpnDeleteSuccess = (snapshot: LpnContent) => {
    this.setState(
      {
        deletedLpnCount: this.state.deletedLpnCount + 1,
        showSuccessAlert: true,
        successAlertMessage: `LPN ${snapshot.lpnBarcode} has been deleted`
      },
      this.refreshPage
    );
  };
  public alertLpnMoveSuccess = (snapshot: LpnContent, index: number, moveToContainerDropoffId: number) => {
    this.setState(
      {
        deletedLpnCount: this.state.deletedLpnCount + 1,
        showSuccessAlert: true,
        successAlertMessage:
          `LPN ${snapshot.lpnBarcode} added to Inbound #${moveToContainerDropoffId} ` +
          `and removed from Inbound #${this.props.containerDeliveryId}`
      },
      this.refreshPage
    );
  };

  private closeSuccessAlert = () => {
    this.setState({
      showSuccessAlert: false,
      successAlertMessage: ''
    });
  };

  private groupLpnContentsByLpn(lpnContents: LpnContent[]): Dictionary<LpnContent[]> {
    return groupBy(lpnContents, 'lpnBarcode');
  }

  private refreshPage = () => {
    window.location.reload();
  };

  private expectedLpnCount = (): number => {
    const lpnBarcodes = this.props.lpnContents.map((lpnContent) => lpnContent.lpnBarcode);
    return new Set(lpnBarcodes).size;
  };
}

export default LpnContents;
