import * as React from 'react';
import {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Loader} from '@flexe/ui-components';
import WaveTemplateServiceV2 from '../../../shared/services/WaveTemplateServiceV2';
import {Reservation} from '../../../shared/CommonInterfaces';
import WarehouseService from '../../../shared/services/WarehouseService';
import FlexeButton from '../../../shared/FlexeButton';
import {OrderType} from '../../ecommerce-batches/BatchInterfaces';
import {WaveTemplateV2} from './WaveTemplateInterfaces';
import OrderProfile from './OrderProfile';
import SkuMix from './SkuMix';
import PackagingOptions from './PackagingOptions';
import PostalCodeRange from './PostalCodeRange';
import ReservationSelect from './ReservationSelect';
import CustomAttributesInput from './CustomAttributesInput';
import CarrierSelect from './CarrierSelect';
import SkuSelect from './SkuSelect';
import WaveTemplateForm from './WaveTemplateForm';

interface NewWaveTemplateProps {
  templateId?: number;
  warehouseId: number;
  carrierLabels: {[key: string]: string};
  serviceLevelMap: {[key: string]: string[]};
  authenticityToken: string;
}

const NewWaveTemplate: React.FC<NewWaveTemplateProps> = ({
  templateId,
  warehouseId,
  carrierLabels,
  serviceLevelMap,
  authenticityToken
}) => {
  const waveTemplateServiceV2 = new WaveTemplateServiceV2();
  const header = templateId ? `Duplicating Wave Template ${templateId}` : `Creating New Wave Template`;
  const nameSuffix = templateId ? '(copy)' : null;

  return (
    <WaveTemplateForm
      templateId={templateId}
      nameSuffix={nameSuffix}
      warehouseId={warehouseId}
      carrierLabels={carrierLabels}
      serviceLevelMap={serviceLevelMap}
      authenticityToken={authenticityToken}
      header={header}
      saveFunc={async (templateToSave) => {
        return waveTemplateServiceV2.createWaveTemplate(templateToSave);
      }}
    />
  );
};

export default NewWaveTemplate;
