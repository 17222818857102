import * as React from 'react';

interface RouteInfo {
  name: string;
  barcode: string;
}
interface Props {
  routes: RouteInfo[];
}

const RouteSigns: React.FC<Props> = ({routes}) => {
  return (
    <div className="route-sign-container">
      {routes.map((route) => (
        <div className="route-sign-page" key={route.name}>
          <div className="route-sign-label">Sort Code for route:</div>
          <img className="route-sign-barcode" src={`data:image/svg+xml;base64,${route.barcode}`} alt="Flexe" />
          <div className="route-sign-id">{route.name}</div>
          <div className="route-sign-footnote">
            *** Place at Location(s), this route will sort to before sorting begins.
          </div>
        </div>
      ))}
    </div>
  );
};

export default RouteSigns;
