import * as React from 'react';
import {format as formatDate, parse as parseDate} from 'date-fns';
import * as moment from 'moment';
import {stringify as queryStringify} from 'query-string';
import {Loader, Pagination, Table, TableHeader} from '@flexe/ui-components';
import Filters from '../shared/Filters';
import {FilterType} from '../shared/CommonInterfaces';
import {determineLink, displayPackaging, renderItemLink, renderLocationLink, renderLpnLink} from '../../libs/helpers';
import FileDownloadLink from '../shared/FileDownloadLink';
import LocationsService from './LocationsService';
import {FocusedMovementLogData, GetMovementLogRequest, GetMovementLogResponse} from './MovementLogInterfaces';

interface Props {
  warehouseId: number;
  authenticityToken: string;
  locationId?: number;
  lpnBarcode?: string;
  lpnId?: string;
  locationService?: LocationsService;
}

class FocusedMovementLogTable extends React.Component<Props, FocusedMovementLogTableState> {
  private static isLpnArchived(log: FocusedMovementLogData) {
    return log.reason.type === 'LpnDecompose';
  }
  private locationService: LocationsService;
  private pageSize: number;
  private defaultFilters: GetMovementLogRequest;

  constructor(props) {
    super(props);
    this.locationService = props.locationService || new LocationsService(props.authenticityToken);
    this.pageSize = 50;
    this.defaultFilters = this.getDefaultFilters();
    this.state = {
      continuationTokens: [],
      errors: [],
      currentPage: 1,
      filters: this.defaultFilters,
      loading: true,
      logs: [],
      totalLogs: 0
    };
  }

  public async componentDidMount() {
    await this.fetchLogs(this.props.warehouseId);
  }

  public async componentDidUpdate() {
    if (this.state.errors.length > 0) {
      this.setState({errors: []});
    }
  }

  public render() {
    const tableData = this.getTableData();

    return (
      <div className="movement-log-view container-fluid">
        {this.state.errors && this.state.errors.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {this.state.errors.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        )}
        <h2>Inventory History</h2>
        {this.state.loading ? (
          <Loader loading={true} />
        ) : (
          <div>
            <div className={'subheader'}>
              <h6>Moves After filter is required and defaults to data in the past week</h6>

              <FileDownloadLink href={this.getDownloadUrl()} showAsButton text={this.getDownloadResultsText()} />
            </div>
            <div className="row col-sm-12">
              <Filters
                filters={this.getFilters()}
                filterChangeHandler={(filters) => this.handleFilterChange(filters)}
              />
            </div>
            <div className="row">
              {!this.state.loading && tableData.rows.length > 0 && (
                <Table tableClass="table-striped" tableData={tableData} />
              )}
              {!this.state.loading && tableData.rows.length === 0 && (
                <p className="col-sm-12">No movements found. Try using more specific search criteria.</p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  private getTableData() {
    const headers: TableHeader[] = [
      {element: 'SKU'},
      {element: 'LPN'},
      {element: 'Initial Parent LPN'},
      {element: 'Destination Parent LPN'},
      {element: 'Initial Loc'},
      {element: 'Destination Loc'},
      {element: 'Qty Moved'}
    ];

    if (this.props.locationId) {
      headers.push({element: 'Qty After'});
    }

    [
      {element: 'Move Reason'},
      {element: 'Moved By'},
      {element: 'Moved At'},
      {
        className: 'pagination-header',
        element: (
          <Pagination
            page={this.state.currentPage}
            pageSize={this.pageSize}
            paginationHandler={(page) => this.handlePagination(page, this.state.filters)}
            totalCount={this.state.totalLogs}
          />
        )
      }
    ].map((header) => headers.push(header));
    const rows = this.buildRows();
    return {
      headers,
      rows
    };
  }

  private buildRows() {
    if (this.state.logs && this.state.logs.length > 0) {
      const rows = this.state.logs.map((log: FocusedMovementLogData) => {
        const lpnLink = [
          <>
            {renderLpnLink(log.source.lpnBarcode || log.target.lpnBarcode, false, log.source.lpnId || log.target.lpnId)}
          </>
        ];
        if (FocusedMovementLogTable.isLpnArchived(log)) {
          lpnLink.push(<> (Decomposed)</>);
        }
        const sourceParentLpnLink = [<>{renderLpnLink(log.source.parentLpnBarcode, false, log.source.parentLpnId)}</>];
        const targetParentLpnLink = [<>{renderLpnLink(log.target.parentLpnBarcode, false, log.target.parentLpnId)}</>];
        const row = [
          renderItemLink(log.item.id, log.item.sku, false),
          lpnLink,
          sourceParentLpnLink,
          targetParentLpnLink,
          renderLocationLink(log.source.id, log.source.label),
          renderLocationLink(log.target.id, log.target.label),
          `${log.amount.quantity} ${displayPackaging(log.amount.packaging, log.amount.quantity)}`
        ];

        if (this.props.locationId) {
          let afterQty: string;
          if (log.source.id && log.source.id.toString() === this.props.locationId.toString()) {
            const packaging = displayPackaging(log.source.after.packaging, log.source.after.quantity);
            afterQty = `${log.source.after.quantity} ${packaging || 'Eaches'}`;
          } else {
            const packaging = displayPackaging(log.target.after.packaging, log.target.after.quantity);
            afterQty = `${log.target.after.quantity} ${packaging || 'Eaches'}`;
          }
          row.push(afterQty);
        }
        row.push(determineLink(log.reason.id, log.reason.type, log.reason.humanReadableType, false));
        row.push(log.movedBy);
        row.push(formatDate(log.movedAt, 'MM/DD/YY h:mma'));
        return row;
      });
      return rows;
    }
    return [];
  }

  private getFilters() {
    const baseFilters = [];
    if (!this.props.lpnBarcode && !this.props.lpnId) {
      baseFilters.push({
        displayName: 'LPN Barcode (exact match)',
        key: 'lpnBarcodes',
        type: FilterType.String,
        value: this.getFilterValue('lpnBarcodes'),
        allowMultiple: true
      });
    }
    if (!this.props.locationId) {
      baseFilters.push({
        displayName: 'Location (fuzzy match)',
        key: 'locationLabels',
        type: FilterType.String,
        value: this.getFilterValue('locationLabels'),
        allowMultiple: true
      });
    }
    [
      {
        displayName: 'SKU (partial match)',
        key: 'skus',
        type: FilterType.String,
        value: this.getFilterValue('skus'),
        allowMultiple: true
      },
      {
        displayName: 'Batch ID',
        key: 'batchIds',
        type: FilterType.Number,
        value: this.getFilterValue('batchIds'),
        allowMultiple: true
      },
      {
        displayName: 'Shipment ID or PO',
        key: 'shipmentIds',
        type: FilterType.Number,
        value: this.getFilterValue('shipmentIds'),
        allowMultiple: true
      },
      {
        displayName: 'Inbounds Only',
        key: 'inboundsOnly',
        type: FilterType.Present,
        allowMultiple: false,
        value: this.getFilterValue('inboundsOnly')
      },
      {
        displayName: 'Outbounds Only',
        key: 'outboundsOnly',
        type: FilterType.Present,
        allowMultiple: false,
        value: this.getFilterValue('outboundsOnly')
      },
      {
        displayName: 'Adjustments Only',
        key: 'adjustmentsOnly',
        type: FilterType.Present,
        allowMultiple: false,
        value: this.getFilterValue('adjustmentsOnly')
      },
      {
        displayName: 'Moves Only',
        key: 'movesOnly',
        type: FilterType.Present,
        allowMultiple: false,
        value: this.getFilterValue('movesOnly')
      },
      {
        displayName: 'Moves Before',
        key: 'moveDateBefore',
        type: FilterType.Date,
        allowMultiple: false,
        value: this.getFilterValue('moveDateBefore')
      },
      {
        displayName: 'Moves After',
        key: 'moveDateAfter',
        type: FilterType.Date,
        allowMultiple: false,
        value: this.getFilterValue('moveDateAfter')
      },
      {
        displayName: 'Moved By (partial match)',
        key: 'movedBy',
        type: FilterType.String,
        allowMultiple: false,
        value: this.getFilterValue('movedBy')
      }
    ].map((filter) => baseFilters.push(filter));
    return baseFilters;
  }

  private getFilterValue(key: string) {
    // eslint-disable-next-line no-prototype-builtins
    if (this.state.filters.hasOwnProperty(key)) {
      return this.state.filters[key];
    }
    return null;
  }

  private async handleFilterChange(filters) {
    this.setState({continuationTokens: []});
    const filterValues: GetMovementLogRequest = this.getDefaultFilters();
    filters.forEach((selected) => {
      if (['locationLabels', 'lpnBarcodes', 'skus', 'batchIds', 'shipmentIds'].includes(selected.filter.key)) {
        const val = selected.value;
        if (filterValues[selected.filter.key]) {
          if (!filterValues[selected.filter.key].includes(val)) {
            filterValues[selected.filter.key].push(val);
          }
        } else {
          filterValues[selected.filter.key] = [val];
        }
      } else if (['moveDateBefore', 'moveDateAfter'].includes(selected.filter.key)) {
        filterValues[selected.filter.key] = parseDate(selected.value).toISOString();
      } else {
        filterValues[selected.filter.key] = selected.value;
      }
    });

    this.setState({
      continuationTokens: [],
      currentPage: 1,
      filters: filterValues
    });
    await this.fetchLogs(this.props.warehouseId, filterValues);
  }

  private getDownloadUrl() {
    const filterValues: GetMovementLogRequest = this.state.filters;
    const csvDownloadParams = {warehouseId: this.props.warehouseId};
    Object.keys(filterValues).forEach((key) => (csvDownloadParams[key] = this.state.filters[key]));
    const csvDownloadQueryString = queryStringify(csvDownloadParams, {arrayFormat: 'bracket'});
    return '/api/v2/movement_logs.csv?' + csvDownloadQueryString;
  }

  private getDefaultFilters() {
    const lpnBarcodeFilters = this.props.lpnBarcode ? [this.props.lpnBarcode] : null;
    const lpnIdFilters = this.props.lpnId ? [this.props.lpnId] : null;
    const weekago = moment().subtract(1, 'week');
    return {
      locationId: this.props.locationId,
      lpnBarcodes: lpnBarcodeFilters,
      lpnIds: lpnIdFilters,
      moveDateAfter: weekago.format('YYYY-MM-DD'),
      pageSize: this.pageSize
    };
  }

  private async fetchLogs(warehouseId: number, filters?: GetMovementLogRequest, continuationToken?: string) {
    this.setState({loading: true});
    let errors = [];
    try {
      const request: GetMovementLogRequest = filters || this.defaultFilters;
      request.continuationToken = continuationToken;

      const response = await this.locationService.getLocationMovementLog(warehouseId, request);

      if (response.errors && response.errors.length > 0) {
        errors = this.locationService.processErrors(response.errors);
      } else {
        const data: GetMovementLogResponse = response.data;
        const oldTokens = this.state.continuationTokens;
        if (data.continuationToken && this.state.currentPage > this.state.continuationTokens.length) {
          oldTokens.push(data.continuationToken);
        }

        this.setState({
          logs: data.logs,
          totalLogs: data.totalMatchingResults,
          continuationTokens: oldTokens
        });
      }
    } finally {
      this.setState({errors, loading: false});
    }
  }

  private async handlePagination(page: number, filters?: GetMovementLogRequest) {
    this.setState({loading: true});
    const continuationToken = page > 1 ? this.state.continuationTokens[page - 2] : null;
    this.setState({currentPage: page});
    await this.fetchLogs(this.props.warehouseId, filters, continuationToken);
  }

  private getNumberOfAppliedFilters = () =>
    this.getFilters().reduce((runningFilterTotal, currentFilter) => {
      let numberOfAppliedFilters = 0;

      if (currentFilter.value) {
        numberOfAppliedFilters = currentFilter.allowMultiple ? currentFilter.value.length : 1;
      }

      return runningFilterTotal + numberOfAppliedFilters;
    }, 0);

  private getDownloadResultsText = () => {
    const numberOfAppliedFilters = this.getNumberOfAppliedFilters();
    const filterText =
      numberOfAppliedFilters !== 0 ? ` (${numberOfAppliedFilters} filter${numberOfAppliedFilters > 1 ? 's' : ''})` : '';

    return `Download All Matching Results${filterText}`;
  };
}

export interface FocusedMovementLogTableState {
  continuationTokens: string[];
  errors: string[];
  currentPage: number;
  filters: GetMovementLogRequest;
  loading: boolean;
  logs: FocusedMovementLogData[];
  totalLogs: number;
}

export default FocusedMovementLogTable;
