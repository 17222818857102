import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Loader} from '@flexe/ui-components';
import jsBarcode from 'jsbarcode';
import {BarcodeImage} from './CreateBarcodesInterfaces';
import BarcodeCard from './BarcodeCard';

interface CreateBarcodesProps {
  defaultBarcodes: string;
  printCssUrl: string;
}

export const createPrintableBarcodeHtml = (barcode: BarcodeImage): string => {
  return `
  <div class="printable-barcode-page">
    <div class="printable-barcode-container">
      <img src="data:image/svg+xml;base64,${barcode.svg}" alt="Flexe"/>
    </div>
    <p class="printable-barcode-text">${barcode.text}</p>
  </div>
`;
};

export const generatePrintableHtml = (barcodes: BarcodeImage[], printCssUrl: string): string => {
  const barcodeHtml = barcodes.map(createPrintableBarcodeHtml).join('');
  return `
    <html>
      <head>
        <title>Barcodes</title>
        <link rel="stylesheet" href="${printCssUrl}">
      </head>
      <body>
        ${barcodeHtml}
      </body>
    </html>
  `;
};

export const generateBarcodeSvgString = (barcodeText: string): string => {
  const svg = document.createElement('svg');
  jsBarcode(svg, barcodeText, {
    format: 'CODE128',
    displayValue: false
  });
  return btoa(svg.outerHTML);
};

const isValidBarcodeChar = (char: string): boolean => {
  const charCode = char.charCodeAt(0);
  return charCode >= 0 && charCode <= 127;
};

const isValidBarcodeText = (text: string): boolean => {
  return text.split('').every(isValidBarcodeChar);
};

const CreateBarcodesIndex: React.FC<CreateBarcodesProps> = (props) => {
  const [defaultBarcodes, setDefaultBarcodes] = useState<string>(props.defaultBarcodes);
  const [barcodeText, setBarcodeText] = useState<string>('');
  const [createdBarcodes, setCreatedBarcodes] = useState<BarcodeImage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBarcodeTextValid, setIsBarcodeTextValid] = useState<boolean>(true);
  const [barcodeErrorText, setBarcodeErrorText] = useState<string>('');

  useEffect(() => {
    setBarcodeText(defaultBarcodes);
    createBarcodes(defaultBarcodes);
    setIsLoading(false);
  }, [defaultBarcodes]);

  const createBarcodes = async (barcodes: string) => {
    setIsLoading(true);
    setBarcodeErrorText('');
    setCreatedBarcodes([]);
    // the following sleep command is what enables the loading spinner to show instead of locking up the entire UI
    await new Promise((r) => setTimeout(r, 100));
    const enteredBarcodes = barcodes.split('\n').filter((barcode) => barcode.length > 0);
    if (barcodes.length <= 1000) {
      updateUrlWithBarcodes(barcodes);
    } else {
      // if the URL is too large, it causes problems, so don't use this functionality for big queries
      updateUrlWithBarcodes('');
    }

    for (const barcode of enteredBarcodes) {
      if (!isValidBarcodeText(barcode)) {
        setBarcodeErrorText(`Invalid barcode characters detected in: ${barcode}`);
        setIsBarcodeTextValid(false);
        setIsLoading(false);
        return;
      }
    }

    let barcodeImages: BarcodeImage[];
    try {
      barcodeImages = enteredBarcodes.map((barcode) => {
        return {text: barcode, svg: generateBarcodeSvgString(barcode)};
      });
    } catch (e) {
      setBarcodeErrorText(`Error generating barcode`);
      setIsBarcodeTextValid(false);
      setIsLoading(false);
      return;
    }

    setCreatedBarcodes(barcodeImages);
    setIsLoading(false);
  };

  const printBarcodes = () => {
    if (createdBarcodes.length < 1) {
      return;
    }
    const newWindow = window.open('', '_blank');
    if (!newWindow) {
      return;
    }
    try {
      const printableHtml = generatePrintableHtml(createdBarcodes, props.printCssUrl);
      newWindow.document.open();
      newWindow.document.write(printableHtml);
      newWindow.document.close();

      newWindow.onload = () => {
        newWindow.print();
      };

      newWindow.addEventListener('afterprint', () => {
        newWindow.close();
      });
    } catch (error) {
      if (newWindow) {
        newWindow.document.close();
      }
    }
  };

  const updateUrlWithBarcodes = (barcodes: string) => {
    const queryString = `b=${encodeURIComponent(barcodes)}`;
    window.history.pushState({}, '', `?${queryString}`);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsBarcodeTextValid(true);
    setBarcodeText(event.target.value);
  };

  return (
    <div>
      <div className={'title-header'}>
        <h2>
          <b>Warehouse Tools</b>
        </h2>
      </div>
      <div className="create-barcodes-index">
        <h3>
          <b>Create Barcodes</b>
        </h3>
        <div className="multiline-text-box-container">
          <label htmlFor="barcode-text">
            <b>Barcode Text:</b>
          </label>
          <textarea
            id="barcode-text"
            name="barcode-text"
            className="form-control"
            rows={10}
            value={barcodeText}
            onChange={handleTextChange}
            placeholder="Enter barcodes here, each on a new line."
          />
          {<p className="error-text">{barcodeErrorText}</p>}
          <p className="grey-text">* Separate text by row to create separate barcodes</p>
          <p className="grey-text">* 10,000 barcode limit</p>
          <div className="create-barcode-button">
            <Button
              onPress={() => createBarcodes(barcodeText)}
              visualType="primary"
              isDisabled={isLoading || !isBarcodeTextValid}
            >
              Create Barcodes
            </Button>
          </div>
        </div>
        <hr />
        <div className="barcode-result">
          <h5>
            <b>{createdBarcodes.length} Results</b>
          </h5>
          <Button onPress={printBarcodes} visualType="secondary">
            Print All
          </Button>
        </div>
        <Loader loading={isLoading} />
        <div className="barcode-cards-container">
          {createdBarcodes.map((barcodeImage) => {
            return <BarcodeCard barcodeText={barcodeImage.text} barcodeSvgBase64={barcodeImage.svg} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default CreateBarcodesIndex;
