import * as React from 'react';
import {Expander, Loader} from '@flexe/ui-components';
import RoutePlanningService, {RouteDetails} from '../shared/services/RoutePlanningService';
import FlexeContext from '../contexts/FlexeContext';
import {ResponseErrorV2} from '../shared/CommonInterfaces';
import {ErrorCard} from '../warehouse/outbound-shipments/shared/ErrorCard';
import RouteList from './RouteList';

interface RouteListExpandoProps {
  routingRequestId: string;
}

const RouteListExpando: React.FC<RouteListExpandoProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [expandRoutes, setExpandRoutes] = React.useState<boolean>(false);
  const [routes, setRoutes] = React.useState<RouteDetails[]>(null);
  const [routeCount, setRouteCount] = React.useState<number>(null); // Track route count by itself to prevent flash on reload
  const [errors, setErrors] = React.useState<ResponseErrorV2[]>(null);

  const authenticityToken = React.useContext(FlexeContext).authenticityToken;
  const routePlanningService = React.useMemo(() => new RoutePlanningService(authenticityToken), [authenticityToken]);

  const toggleExpander = async (isOpen: boolean) => {
    setExpandRoutes(isOpen);
    // Let reopening the card refresh data as there is no detail view for the user to go to
    if (isOpen) {
      setIsLoading(true);
      setRoutes(null);
      await loadRoutes();
      setIsLoading(false);
    }
  };

  const loadRoutes = async () => {
    try {
      const response = await routePlanningService.fetchRouteRequestSortProgress({
        routingRequestId: props.routingRequestId
      });
      if (response?.errors?.length > 0) {
        setRouteCount(null);
        setErrors(response.errors);
      } else if (response?.data?.progressSummaries === undefined || response?.data?.progressSummaries === null) {
        setRouteCount(null);
        setErrors([
          {
            code: '',
            title: '',
            detail: `Error loading routes. Failed to find Routes for Wave.`,
            source: null
          }
        ]);
      } else {
        setRouteCount(response.data.progressSummaries.length);
        setRoutes(response.data.progressSummaries);
      }
    } catch (error) {
      setErrors([
        {
          code: '',
          title: '',
          detail: `Error loading routes. Message: ${error.message}`,
          source: null
        }
      ]);
    }
  };

  let routesText: string;
  if (routeCount === null) {
    routesText = 'Routes';
  } else if (routeCount !== 1) {
    routesText = `${routeCount} Routes`;
  } else {
    routesText = '1 Route';
  }

  return (
    <div id="route-card-list-expander">
      <Expander title={routesText} isOpen={expandRoutes} onToggle={toggleExpander} maxHeight={5000}>
        {isLoading ? (
          <Loader loading={isLoading} />
        ) : errors !== null ? (
          errors.map((error, index) => {
            return (
              <ErrorCard key={index}>
                <p>{error.detail}</p>
              </ErrorCard>
            );
          })
        ) : (
          <RouteList routeDetailsList={routes} />
        )}
      </Expander>
    </div>
  );
};

export default RouteListExpando;
