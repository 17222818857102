import * as React from 'react';
import {FC, useContext, useEffect, useState} from 'react';
import {difference} from 'lodash';
import {LpnsResponse} from '../../../lpns/LpnsInterfaces';
import {renderLocationLink, renderLpnLink} from '../../../../libs/helpers';
import {Location, Shipment} from '../ShipmentInterfaces';
import PickConsolidationService from '../../../shared/services/PickConsolidationService';
import {WarningCard} from '../shared/WarningCard';
import {useManifestLpns} from './UseManifestLpns';
import {ShipmentDetailsContext} from './ShipmentDetailsContext';

type TLpnBarcode = string;

const useInvalidLpnsInStagingLocation = (shipmentId: number) => {
  const {authenticityToken} = useContext(ShipmentDetailsContext);
  const [badLpnsInLocation, setBadLpnsInLocation] = useState<TLpnBarcode[]>([]);

  useEffect(() => {
    new PickConsolidationService(authenticityToken).invalidLpnsAtLocation(shipmentId).then((resp) => {
      if (resp.errors.length > 0) {
        return;
      }

      setBadLpnsInLocation(resp.data.invalid_lpns);
    });
  }, [authenticityToken, setBadLpnsInLocation, shipmentId]);

  return badLpnsInLocation;
};

const useInvalidLpns = (
  shipmentId: number,
  ShipmentLpnsDeprecated: TLpnBarcode[],
  lpnsInStagingLocation: TLpnBarcode[],
  manifestLpnBarcodes: TLpnBarcode[]
) => {
  const invalidLpnsInStagingLocation = useInvalidLpnsInStagingLocation(shipmentId);

  return {
    invalidLpnsInStagingLocation,
    missingLpns: difference(ShipmentLpnsDeprecated, lpnsInStagingLocation, manifestLpnBarcodes)
  };
};

const filterParentLpns = (lpn: LpnsResponse) => {
  return lpn.contents !== null && lpn.contents !== undefined && lpn.contents.length > 0;
};

interface Props {
  isFreightTrailerLoadingEnabled: boolean;
  shipment: Shipment;
  lpnsOnShipment: LpnsResponse[];
  lpnsInStagingLocation: string[];
  isValid: boolean;
  onValidChanged: (isValid: boolean) => void;
  stagingLocation: Location;
}

export const ShipmentInvalidStagingLocation: FC<Props> = (props) => {
  const {isFreightTrailerLoadingEnabled, lpnsInStagingLocation, lpnsOnShipment, shipment, stagingLocation} = props;
  const [invalidLpns, setInvalidLpns] = useState({
    invalidLpnsInStagingLocation: [] as TLpnBarcode[],
    missingLpns: [] as TLpnBarcode[]
  });
  const {manifestLpns} = useManifestLpns(shipment, isFreightTrailerLoadingEnabled);
  const {invalidLpnsInStagingLocation, missingLpns} = useInvalidLpns(
    shipment.id,
    lpnsOnShipment.filter(filterParentLpns).map((_) => _.lpnBarcode),
    lpnsInStagingLocation,
    manifestLpns.map((_) => _.lpn_barcode)
  );

  React.useEffect(() => {
    setInvalidLpns({
      invalidLpnsInStagingLocation,
      missingLpns
    });
  }, [JSON.stringify(invalidLpnsInStagingLocation), JSON.stringify(missingLpns)]);

  React.useEffect(() => {
    checkIsValid();
  }, [invalidLpns, props.onValidChanged]);

  const checkIsValid = () => {
    const isCurrentValid =
      invalidLpns.invalidLpnsInStagingLocation.length === 0 && invalidLpns.missingLpns.length === 0;

    if (isCurrentValid !== props.isValid) {
      props.onValidChanged(isCurrentValid);
    }
  };

  const renderLocationLpnErrorsDom = (
    suggestion: React.ReactFragment,
    lpnBarcodes: string[],
    includeLocationLink = false
  ) => {
    if (lpnBarcodes.length === 0) {
      return null;
    }

    const locationLpnMap = lpnsOnShipment.reduce(
      (map, lpn) => ({
        ...map,
        [lpn.lpnBarcode]: lpn.location
      }),
      {} as Record<string, LpnsResponse['location']>
    );

    return (
      <div>
        <p>{suggestion}</p>
        <ol>
          {lpnBarcodes.map((lpnBarcode) => (
            <li key={lpnBarcode}>
              {includeLocationLink ? (
                <>
                  {renderLpnLink(lpnBarcode.trim(), false)}
                  {', '}
                  {renderLocationLink(locationLpnMap[lpnBarcode].id, locationLpnMap[lpnBarcode].label)}
                </>
              ) : (
                renderLpnLink(lpnBarcode.trim(), false)
              )}
            </li>
          ))}
        </ol>
      </div>
    );
  };

  if (props.stagingLocation == null || props.stagingLocation.id == null || Number.isNaN(props.stagingLocation.id)) {
    return (
      <WarningCard>
        <div data-testid="no-staging-location-warning-text">
          <b>No units in staging.</b>
          This Shipment can’t be shipped until it is in a Staging Location, which are locations categorized as Outbound
          Staging, Pack Station, or Dock.
        </div>
      </WarningCard>
    );
  }

  if (props.isValid) {
    return null;
  }

  const issueCount = invalidLpns.invalidLpnsInStagingLocation.length + invalidLpns.missingLpns.length;
  // eslint-disable-next-line no-extra-boolean-cast
  const stagingLocationDom = !!stagingLocation.id
    ? renderLocationLink(stagingLocation.id, stagingLocation.name)
    : 'a staging location';

  return (
    <div id="shipment-invalid-staging-location">
      <div className="content">
        <h4>Unable to complete this shipment!</h4>
        <p>{issueCount === 1 ? 'There is 1 issue.' : `There are ${issueCount} issues.`}</p>
        {renderLocationLpnErrorsDom(
          <>
            These LPNs are in {stagingLocationDom} but don't belong on this shipment. They should be moved out of the
            staging location.
          </>,
          invalidLpns.invalidLpnsInStagingLocation
        )}
        {renderLocationLpnErrorsDom(
          <>
            These LPNs have been picked, but are not at the staging location. They should be moved to{' '}
            {stagingLocationDom}.
          </>,
          invalidLpns.missingLpns,
          true
        )}
      </div>
    </div>
  );
};

export default ShipmentInvalidStagingLocation;
