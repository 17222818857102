import * as React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import BatchWavingService from '../../../shared/services/BatchWavingService';
import {Reservation} from '../../../shared/CommonInterfaces';

interface SkuSelectProps {
  savedSkus?: string[];
  reservationId: number | null;
  authenticityToken: string;
  errorCallback: (errorMsg: string) => void;
  clearErrorsCallback: () => void;
}

const SkuSelect: React.FC<SkuSelectProps> = ({
  savedSkus,
  reservationId,
  authenticityToken,
  errorCallback,
  clearErrorsCallback
}) => {
  const batchWavingService = new BatchWavingService(authenticityToken);
  const {control} = useFormContext();

  const skuPromiseOptions = async (query) => {
    if (query.length > 1) {
      try {
        clearErrorsCallback();
        const response = await batchWavingService.skuTypeAhead(reservationId, query);
        const formattedResult = [];
        if (!response || response.errors?.length > 0) {
          errorCallback('There was an error fetching SKUs.');
          return [];
        }
        if (response && response.data && response.data.skus) {
          response.data.skus.forEach((sku) => {
            formattedResult.push({value: sku.replace('&amp;', '&'), label: sku.replace('&amp;', '&')});
          });
        }
        return formattedResult;
      } catch (error) {
        errorCallback('There was an error fetching SKUs.');
      }
    }
    return [];
  };

  function getDefaultOptions() {
    if (savedSkus) {
      return savedSkus.map((sku) => {
        return {label: sku, value: sku};
      });
    }
    return [];
  }

  return (
    <div data-testid="sku-select">
      <Controller
        name="skus"
        control={control}
        defaultValue={getDefaultOptions()}
        render={({onChange, value}) => (
          <AsyncSelect
            id="skus"
            className="async-select"
            name="skus"
            placeholder="Type to select a SKU"
            isMulti
            cacheOptions
            defaultOptions={getDefaultOptions()}
            loadOptions={skuPromiseOptions}
            onChange={onChange}
            isDisabled={!reservationId}
            value={value}
          />
        )}
      />
    </div>
  );
};

export default SkuSelect;
