import * as React from 'react';
import {Table, Tabs} from '@flexe/ui-components';
import CustomKebabMenu, {KebabMenuOption} from '../../../shared/CustomKebabMenu';
import {Reservation} from '../../../reservations/SharedInterfaces';
export default function AccountTable({
  reservations,
  showAccountReference,
  accounts,
  handleDeleteAccount,
  handleUpdateAccount,
  handleFetchShippingLabel,
  handleViewAccount,
  showEnableReservationModal,
  connectWithCarrier
}) {
  const [selectedTab, setSelectedTab] = React.useState('All');
  const connectCarrierAccountMessage =
    "Carrier Account Disconnected! Please connect the carrier account using the 'Connect Carrier' button on the 'Actions' menu " +
    'for the carrier account and then generate a sample shipping label to enable the account';

  // TODO expose interfaces from ui-components so we can rely on them here instead of `any`
  const carrierMap = {All: 1};
  accounts.map((x) => {
    if (!carrierMap[x.carrierDisplayName]) {
      carrierMap[x.carrierDisplayName] = 1;
    }
  });
  const tabs: any[] = Object.keys(carrierMap).map((carrierName) => {
    return {
      active: selectedTab === carrierName,
      key: carrierName,
      subTitle: carrierName
    };
  });

  const tableHeaders = [
    {element: 'Carrier'},
    {className: 'id-header', element: 'Account Number'},
    {element: 'Status'},
    {element: 'Description'},
    {element: 'Reservations'},
    {className: 'action-header', element: 'Actions'}
  ];

  //insert in between Status and Description at index 3
  if (showAccountReference) {
    tableHeaders.splice(3, 0, {element: 'Reference'});
  }

  const dropdownOptions = (carrierAccount) => {
    const previewShippingLabelOptionDisplayText =
      carrierAccount.txnState === 'enabled' ? 'Preview Shipping Label' : 'Validate Carrier Account';
    const options: KebabMenuOption[] = [
      {optionText: previewShippingLabelOptionDisplayText, optionAction: () => handleFetchShippingLabel(carrierAccount)},
      {optionText: 'Enable Reservation', optionAction: () => showEnableReservationModal(carrierAccount)}
    ];
    // See OMS-21911 for details
    if (carrierAccount.carrier === 'ups') {
      options.unshift({
        optionText: 'Connect Carrier',
        optionAction: () => connectWithCarrier(carrierAccount)
      });
    }
    if (carrierAccount.shipperOwned) {
      // as of this writing you can only create fedex, ups, and dhl accounts via the UI. Other accounts are created
      // by flexe engineers behind the scenes. Only display extra fields for an account that's owned by the shipper
      // and was created through the UI.
      if (['ups', 'fedex', 'dhl'].includes(carrierAccount.carrier)) {
        options.push({
          optionText: 'View Account',
          optionAction: () => handleViewAccount(carrierAccount)
        });
        options.push({optionText: 'Edit Account', optionAction: () => handleUpdateAccount(carrierAccount)});
      }
      options.push({
        optionText: 'Delete Account',
        optionAction: () => handleDeleteAccount(carrierAccount),
        isActionDangerous: true
      });
    }
    return options;
  };

  const tableRows = accounts
    .filter((account) => selectedTab === 'All' || account.carrierDisplayName === selectedTab)
    .map((carrierAccount) => {
      const row = [
        <div>
          {carrierAccount.carrierDisplayName}
          {carrierAccount.txnState !== 'enabled' &&
            carrierAccount.currentProblems && <br /> &&
            carrierAccount.currentProblems.map((problem) => (
              <p>
                <i className="fa fa-xs fa-exclamation-triangle" />
                {problem.replace(/^"(.*?)"$/, '$1')}
              </p>
            ))}
          {carrierAccount.oauthStatus && carrierAccount.oauthStatus !== 'connected' && (
            <p>
              <i className="fa fa-xs fa-exclamation-triangle" />
              {connectCarrierAccountMessage}
            </p>
          )}
        </div>,
        <div>
          {carrierAccount.shipperOwned && carrierAccount.accountNumber ? (
            <a onClick={() => handleViewAccount(carrierAccount)}>{carrierAccount.accountNumber}</a>
          ) : (
            'NA'
          )}
        </div>,
        <div>{carrierAccount.txnState}</div>,
        <div>{carrierAccount.description}</div>,
        <div>
          {carrierAccount.reservations.length > 0 && reservations.length > 0
            ? carrierAccount.reservations
                .map((x) => x.id + '-' + findWarehouseName(x.id, reservations))
                .sort()
                .join(', ')
            : 'NA'}
        </div>,
        <div style={{position: 'relative'}}>
          <CustomKebabMenu options={dropdownOptions(carrierAccount)} displayBeside={true} />
        </div>
      ];
      if (showAccountReference) {
        row.splice(3, 0, <div>{carrierAccount.accountReference}</div>);
      }
      return row;
    });

  return (
    <div>
      <Tabs tabs={tabs} tabEvent={setSelectedTab} data-testid="account-tabs" />
      <Table tableData={{headers: tableHeaders, rows: tableRows}} data-testid="account-table" />
    </div>
  );
}

function findWarehouseName(reservationID: number, reservations: Reservation[]): string {
  return reservations.find((x) => x.id === reservationID).warehouseName;
}
