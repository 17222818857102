import {CookiesProvider} from 'react-cookie';
import * as React from 'react';
import {Warehouse} from '../shared/CommonInterfaces';
import WarehouseSelector from '../shared/WarehouseSelector';
import MovementTasks from '../movements/MovementTasks';
import WarehouseTasks from './WarehouseTasks';

interface TasksPageContainerProps {
  authenticityToken: string;
  warehouse: Warehouse;
  activeWarehouses: Warehouse[];
  inactiveWarehouses: Warehouse[];
  sfsEnabled: boolean;
  movementsPageSize?: number;
}

interface TasksPageContainerState {
  authenticityToken: string;
  warehouse: Warehouse;
  activeWarehouses: Warehouse[];
  inactiveWarehouses: Warehouse[];
  useOmniUi: boolean;
  firstLoadPending: boolean;
  containerHeader: string;
}

/**
 * Container component for the tasks and movement tables. This will choose one of the two component options
 * depending on the FF values
 */
class TasksPageContainer extends React.Component<TasksPageContainerProps, TasksPageContainerState> {
  constructor(props) {
    super(props);

    this.state = {
      authenticityToken: props.authenticityToken,
      warehouse: props.warehouse,
      activeWarehouses: props.activeWarehouses,
      inactiveWarehouses: props.inactiveWarehouses,
      useOmniUi: props.useOmniUi || false,
      firstLoadPending: true,
      containerHeader: props.containerHeader || 'Tasks'
    };
  }

  public async componentDidMount() {
    this.setState({
      firstLoadPending: false
    });
  }

  public render() {
    const {activeWarehouses, inactiveWarehouses} = this.props;

    return (
      <div className="container-fluid">
        <h1>{this.state.containerHeader}</h1>

        <h4 className="warehouse-selector">
          <CookiesProvider>
            <WarehouseSelector
              selectedWarehouse={this.state.warehouse}
              activeWarehouses={activeWarehouses}
              inactiveWarehouses={inactiveWarehouses}
              onSelect={this.handleWarehouseSelected}
            />
          </CookiesProvider>
        </h4>

        {/*
          These components' lifecycles are set up to fetch the selected warehouse's tasks on component mount,
          and it will take more nuanced work to correct their lifecycles to properly switch warehouses on prop change.
          Forcibly re-render the tasks table if the selected warehouse changes.
        */}
        <React.Fragment key={this.state.warehouse.id}>
          {this.state.useOmniUi ? (
            <WarehouseTasks authenticityToken={this.state.authenticityToken} warehouse={this.state.warehouse} />
          ) : (
            <MovementTasks
              authenticityToken={this.props.authenticityToken}
              warehouse={this.state.warehouse}
              sfsEnabled={this.props.sfsEnabled}
              movementsPageSize={this.props.movementsPageSize}
            />
          )}
        </React.Fragment>
      </div>
    );
  }

  private handleWarehouseSelected = async (selectedWarehouse: Warehouse) => {
    this.setState({
      warehouse: selectedWarehouse
    });
  };
}

export default TasksPageContainer;
