import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import * as moment from 'moment';
import {ConfirmationFormModal} from '../../shared/confirmation-form/ConfirmationFormModal';
import {ConfirmationFormRowString} from '../../shared/confirmation-form/ConfirmationFormRowString';
import ConfirmationFormRow from '../../shared/confirmation-form/ConfirmationFormRow';
import {ResponseError, SortOrder} from '../../shared/CommonInterfaces';
import {ConfirmationFormFooter} from '../../shared/confirmation-form/ConfirmationFormFooter';
import {ConfirmationFormError} from '../../shared/confirmation-form/ConfirmationFormError';
import {renderLocationLink} from '../../../libs/helpers';
import {isNullOrEmpty} from '../../shared/StringHelper';
import ErrorBoundary from '../../shared/utilities/ErrorBoundary';
import {WarningCard} from '../outbound-shipments/shared/WarningCard';
import {FreightShipMode, ShipMode} from '../../shared/constants';
import FlexeContext from '../../contexts/FlexeContext';
import {GetShipmentsParams, Shipment, SortByColumns} from '../outbound-shipments/ShipmentInterfaces';
import OutboundShipmentService from '../../shared/services/OutboundShipmentService';
import {ShipOnTimeReasonCodeError} from '../ship-on-time-reason-code/ShipOnTimeReasonCodeError';
import {LoadStatusMap} from './LoadConstants';
import {UpsertLoadState} from './LoadInterfaces';

export interface Props {
  loadStatus: string;
  loadData: UpsertLoadState; // load id, scac, pro #, trailer #, seal #, dock location, destination tag
  loadGroup?: string;
  palletsLoaded: number;
  shipMode: ShipMode;
  onEditLoad: (upsert: UpsertLoadState) => Promise<ResponseError>;
  onConfirm: () => Promise<ResponseError>;
  toggle: () => void;
}

const SHIPMENT_FETCHER_PAGE_SIZE = 500;

export const LoadShipConfirmation: React.FC<Props> = (props) => {
  const {authenticityToken} = useContext(FlexeContext);
  const [enableShip, setEnableShip] = useState<boolean>(false);
  const [error, setError] = useState<ResponseError>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const outboundShipmentsService = new OutboundShipmentService(authenticityToken);
  const [missedSlaReasonCodeProvided, setMissedSlaReasonCodeProvided] = useState<boolean>(false);
  const [shipmentsLoading, setShipmentsLoading] = useState<boolean>(false);
  const [shipmentsOutOfSla, setShipmentsOutOfSla] = useState<Shipment[]>([]);

  const statusObject = LoadStatusMap.get(props.loadStatus);
  const statusClassName = `shipment-status-dot ${statusObject?.className}`;
  const shipmentFilters = useRef<GetShipmentsParams>({
    omni_only: true,
    page_size: SHIPMENT_FETCHER_PAGE_SIZE,
    sort_by: SortByColumns.ShipmentId,
    sort_direction: SortOrder.ASC,
    warehouse_id: props.loadData?.warehouseId,
    freight_load_id: props.loadData?.freightLoadId
  });
  useEffect(() => {
    if (props.shipMode === FreightShipMode) {
      getShipments();
    }
  }, []);

  useEffect(() => {
    if (!shipmentsLoading) {
      checkShipEligibility();
    }
  }, [props.loadData, shipmentsLoading, missedSlaReasonCodeProvided]);

  const getAllShipmentsForLoad = async () => {
    try {
      let continuationToken: string = null;
      let responseShipments = [];
      do {
        const response = await outboundShipmentsService.getShipmentsDeprecated({
          ...shipmentFilters.current,
          continuation_token: continuationToken
        });
        continuationToken = response.data.continuation_token;
        responseShipments = responseShipments.concat(response.data.shipments);
      } while (continuationToken != null);
      return responseShipments as Shipment[];
    } catch (e) {
      if (e instanceof Error) {
        setError({detail: e?.message} as ResponseError);
      }
      if (e instanceof String) {
        setError({detail: e.toString()} as ResponseError);
      }
    }
  };

  const getShipments = async () => {
    setShipmentsLoading(true);
    try {
      const shipments = await getAllShipmentsForLoad();
      const shipmentsOutOfSlaTemp = [];
      shipments.forEach((shipment: Shipment) => {
        if (moment(shipment.ship_by).isBefore(new Date(Date.now()), 'day')) {
          shipmentsOutOfSlaTemp.push(shipment);
        }
      });
      setShipmentsOutOfSla(shipmentsOutOfSlaTemp);
    } catch (e) {
      if (e instanceof Error) {
        setError({detail: e?.message} as ResponseError);
      }
      if (e instanceof String) {
        setError({detail: e.toString()} as ResponseError);
      }
    }
    setShipmentsLoading(false);
  };

  /**
   * Data edited in this modal is saved by the parent.
   * Pass T/F around so everyone knows when a failure occurs so that the value remains in sync across components.
   *
   * For each editable field, we check that the data actually changed before sending the edit to the server
   * We can assume that nulls will be passed in place of empty strings.
   */

  const onSaveScac = async (newScac: string): Promise<boolean> => {
    if (newScac === props.loadData.scac) {
      return true;
    }
    const upsert = {...props.loadData, scac: newScac};
    return await upsertLoad(upsert);
  };

  const onSaveTrailerNumber = async (newTrailerNumber: string): Promise<boolean> => {
    if (newTrailerNumber === props.loadData.trailerNumber) {
      return true;
    }
    const upsert = {...props.loadData, trailerNumber: newTrailerNumber};
    return await upsertLoad(upsert);
  };

  const onSaveProNumber = async (newProNumber: string): Promise<boolean> => {
    if (newProNumber === props.loadData.proNumber) {
      return true;
    }
    const upsert = {...props.loadData, proNumber: newProNumber};
    return await upsertLoad(upsert);
  };

  const onSaveSealNumber = async (newSealNumber: string): Promise<boolean> => {
    if (newSealNumber === props.loadData.sealNumber) {
      return true;
    }
    const upsert = {...props.loadData, sealNumber: newSealNumber};
    return await upsertLoad(upsert);
  };

  const upsertLoad = async (upsert: UpsertLoadState): Promise<boolean> => {
    setError(null);
    const upsertError = await props.onEditLoad(upsert);

    if (upsertError) {
      setError(upsertError);
      return false;
    } else {
      checkShipEligibility();
      return true;
    }
  };

  const onConfirm = async () => {
    setError(null);
    setSubmitting(true);
    setEnableShip(false);
    const confirmError = await props.onConfirm();
    if (confirmError) {
      setError(confirmError);
    }
    setEnableShip(true);
    setSubmitting(false);
  };

  const checkShipEligibility = () => {
    if (!props.loadData) {
      setEnableShip(false);
      return;
    }

    const {destinationTag, ...requiredLoadData} = props.loadData;
    const isValidString = (str: string): boolean => str != null && str.length > 0;
    const isValidNumber = (num: number): boolean => num != null && num > 0;

    // these requirements must remain in sync with ConfirmationFormRows rendered with the 'requiresAction' class name
    const requirements: boolean[] = [];
    requirements.push(isValidString(requiredLoadData.scac));
    requirements.push(isValidString(requiredLoadData.proNumber));
    requirements.push(isValidString(requiredLoadData.trailerNumber));
    requirements.push(isValidString(requiredLoadData.sealNumber));
    requirements.push(isValidNumber(requiredLoadData.dockLocationId));
    requirements.push(isValidNumber(props.palletsLoaded));
    requirements.push(shipmentsOutOfSla.length <= 0 || missedSlaReasonCodeProvided);
    setEnableShip(requirements.every((req) => req === true));
  };

  return (
    <React.Fragment>
      <ConfirmationFormModal title={`Complete Load ${props.loadData?.loadId}`} toggle={props.toggle}>
        <ConfirmationFormRow name={'Status'} requiresAction={false}>
          <div>
            <span className={statusClassName} />
            {statusObject?.text}
          </div>
        </ConfirmationFormRow>
        <ConfirmationFormRow name={'Location'} requiresAction={props.loadData?.dockLocationId === null}>
          <div>
            <span className={'link'}>
              {renderLocationLink(props.loadData?.dockLocationId, props.loadData?.dockLocationLabel)}
            </span>
          </div>
        </ConfirmationFormRow>
        <ConfirmationFormRow name={'Carrier (SCAC)'} requiresAction={isNullOrEmpty(props.loadData?.scac)}>
          <ConfirmationFormRowString
            value={props.loadData?.scac}
            placeholderValue={'Add SCAC'}
            isEditable={true}
            onSave={onSaveScac}
          />
        </ConfirmationFormRow>
        <ConfirmationFormRow name={'PRO #'} requiresAction={isNullOrEmpty(props.loadData?.proNumber)}>
          <ConfirmationFormRowString
            value={props.loadData?.proNumber}
            placeholderValue={'Add PRO #'}
            isEditable={true}
            onSave={onSaveProNumber}
          />
        </ConfirmationFormRow>
        <ConfirmationFormRow name={'Trailer #'} requiresAction={isNullOrEmpty(props.loadData?.trailerNumber)}>
          <ConfirmationFormRowString
            value={props.loadData?.trailerNumber}
            placeholderValue={'Add Trailer #'}
            isEditable={true}
            onSave={onSaveTrailerNumber}
          />
        </ConfirmationFormRow>
        <ConfirmationFormRow name={'Seal #'} requiresAction={isNullOrEmpty(props.loadData?.sealNumber)}>
          <ConfirmationFormRowString
            value={props.loadData?.sealNumber}
            placeholderValue={'Add Seal #'}
            isEditable={true}
            onSave={onSaveSealNumber}
          />
        </ConfirmationFormRow>
        <ConfirmationFormRow name={'Destination ID'} requiresAction={false}>
          <ConfirmationFormRowString value={props.loadData?.destinationTag} placeholderValue={'-'} isEditable={false} />
        </ConfirmationFormRow>
        <ConfirmationFormRow name={'Load Group'} requiresAction={false}>
          <ConfirmationFormRowString value={props.loadGroup} placeholderValue={'-'} isEditable={false} />
        </ConfirmationFormRow>
        <ConfirmationFormRow name={'Pallets Loaded'} requiresAction={props.palletsLoaded <= 0}>
          <ConfirmationFormRowString
            value={props.palletsLoaded?.toString()}
            placeholderValue={'0'}
            isEditable={false}
          />
        </ConfirmationFormRow>
        {shipmentsOutOfSla.length > 0 && (
          <ErrorBoundary fallbackComponent={<WarningCard>There was an issue setting reason code.</WarningCard>}>
            <ShipOnTimeReasonCodeError
              shipments={shipmentsOutOfSla}
              authenticityToken={authenticityToken}
              setMissedSlaReasonCode={setMissedSlaReasonCodeProvided}
            >
              <b>The ship by date for Shipment(s) {shipmentsOutOfSla?.map((s) => s.id).join(', ')} has passed.</b>{' '}
              Please enter a reason before completing.
            </ShipOnTimeReasonCodeError>
          </ErrorBoundary>
        )}
        <ConfirmationFormError error={error} />
        <ConfirmationFormFooter
          buttonText={'Ship Load'}
          footerText={'*Carrier, Trailer #, Pro # and Seal # are required to ship this load.'}
          enableButton={enableShip}
          onConfirm={onConfirm}
          submitting={submitting}
        />
      </ConfirmationFormModal>
    </React.Fragment>
  );
};
