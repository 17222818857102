import * as React from 'react';
import * as moment from 'moment-timezone';
import {Button, Icon} from '@flexe/ui-components';
import StatusDot, {DotStatus} from '../shared/StatusDot';
import {RouteWaveState, StateSummary} from '../shared/services/RoutePlanningService';
import RouteListExpando from './RouteListExpando';

interface RouteCardProps {
  stateSummary: StateSummary;
}

const RouteWaveCard: React.FC<RouteCardProps> = ({stateSummary}) => {
  const dataKeyValuePair = (name: string, data: string | number) => {
    return (
      <div className="column-flex">
        <span>{name}:</span>
        <span className="bold">{data ? data : '--'}</span>
      </div>
    );
  };

  function dateTimeFormat(date: string): string {
    return moment
      .utc(date)
      .tz(moment.tz.guess())
      .format('h:mm a, MMM D');
  }

  function getDotStatus(): DotStatus {
    switch (stateSummary.state) {
      case RouteWaveState.REQUESTED:
      case RouteWaveState.CREATED:
        return 'support';
      case RouteWaveState.COMPLETE:
        return 'valid';
      default:
        return 'neutral';
    }
  }

  function getStatusText(): string {
    switch (stateSummary.state) {
      case RouteWaveState.REQUESTED:
      case RouteWaveState.CREATED:
        return 'Routing';
      case RouteWaveState.COMPLETE:
        return 'Routed';
      default:
        return 'Unknown';
    }
  }

  function printRouteSigns(): void {
    window.open('/wh/route_planning/route_signs?routeRequestId=' + stateSummary.routingRequestId);
  }

  const spinner = () => {
    return (
      <div className="column-flex">
        <div style={{textAlign: 'center'}}>
          <Icon icon="spinner" size="3x" animation="spin" label="Requesting Route Information" />
        </div>
        <p>Requesting Routes...</p>
      </div>
    );
  };

  return (
    <div id="route-card">
      <div className="card">
        <div className="split-body">
          <div className="left-content">
            <div className="header">
              <h4>Route Wave {stateSummary.routingRequestId}</h4>
              <div className="center-items">
                <StatusDot status={getDotStatus()} />
                <p>{getStatusText()}</p>
              </div>
            </div>
            <div className="roomy-flex-row">
              {dataKeyValuePair('Routed', dateTimeFormat(stateSummary.createdAt))}
              {dataKeyValuePair(
                'Total Units',
                stateSummary.numberOfLpns > 0 ? stateSummary.numberOfLpns?.toLocaleString() : 0
              )}
            </div>
          </div>
          <div className="right-content">
            {stateSummary.state !== RouteWaveState.COMPLETE ? (
              spinner()
            ) : (
              <Button onPress={printRouteSigns} visualType="secondary">
                Print Routes
              </Button>
            )}
          </div>
        </div>
        {stateSummary.state === RouteWaveState.COMPLETE && (
          <RouteListExpando routingRequestId={stateSummary.routingRequestId} />
        )}
      </div>
    </div>
  );
};

export default RouteWaveCard;
