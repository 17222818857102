import * as React from 'react';
import {Link} from 'react-router-dom';
import LpnService from '../shared/services/LpnService';
import FocusedMovementLogTable from '../locations/FocusedMovementLogTable';
import LocationsService from '../locations/LocationsService';
import InventoryService from '../shared/services/InventoryService';
import ItemMasterService from '../shared/services/ItemMasterService';
import {LpnMetadata} from './LpnMetadata';
import {LpnContentDetail, LpnDetails} from './LpnsInterfaces';
import {formatDate, formatDateTime} from './date-formatters';
import {LpnContentSummaryTable} from './LpnContentSummaryTable';

interface Props {
  authenticityToken: string;
  isNewTableEnabled: boolean;
  warehouseId?: number;
  match?: {
    params: {
      id: string;
    };
  };
}

interface State {
  editMode: boolean;
  errorMessage?: string;
  lpnLoading: boolean;
  lpn: LpnDetails;
}

class LpnDetail extends React.Component<Props, State> {
  private lpnService: LpnService;
  private locationsService: LocationsService;
  private inventoryService: InventoryService;
  private lpnBarcodeParam: string;
  private isShipper: boolean = true; // Initialize to TRUE to protect the initial render
  private lpnId: string;

  constructor(props: Props) {
    super(props);

    this.lpnService = new LpnService(props.authenticityToken);
    this.locationsService = new LocationsService(props.authenticityToken);
    this.inventoryService = new InventoryService(props.authenticityToken);

    this.state = {
      errorMessage: null,
      editMode: false,
      lpnLoading: false,
      lpn: {
        lpnId: null,
        lpnType: null,
        parentLpnId: null,
        parentLpnBarcode: '',
        lpnBarcode: '',
        category: '',
        dropoffId: null,
        receivedDate: '',
        receivedBy: {
          id: null,
          name: ''
        },
        timeInLocation: null,
        reservation: null,
        upcBarcodes: {
          each: '',
          carton: '',
          pallet: ''
        },
        state: null,
        location: {
          id: null,
          label: ''
        },
        manufactureDate: '',
        shipByDate: '',
        warehouseExitByDate: '',
        asnNumber: '',
        poNumber: '',
        customRef1: '',
        customRef2: '',
        countryOfOrigin: '',
        originSite: '',
        contents: [],
        loadId: null,
        shipmentId: null,
        referenceId: null,
        updatedAt: ''
      }
    };
  }

  public async componentDidMount() {
    this.lpnBarcodeParam = this.props.match.params.id;
    this.isShipper = this.props.match.params[0] === 's';

    const queryParams = new URLSearchParams(window.location.search);
    this.lpnId = queryParams.get('lpnId');

    await this.getLpn(this.lpnBarcodeParam, this.lpnId);
  }

  public render() {
    return (
      <>
        <header id="topline-details" className="container-fluid">
          LPN Detail
          <div className="row">
            <div className="col-md-6">
              <h2>LPN: {this.state.lpn.lpnBarcode ? this.state.lpn.lpnBarcode : ''}</h2>
            </div>
            <div className="col-md-6 align-right">
              {!this.isShipper && (
                <Link
                  to={`/api/v2/lpns/generate/1.pdf?regenerate=${this.state.lpn.lpnBarcode}`}
                  target="_blank"
                  className="btn btn-primary"
                >
                  Print Label
                </Link>
              )}
            </div>
          </div>
        </header>
        <LpnMetadata
          lpn={this.state.lpn}
          isLpnParentEnabled={this.props.isNewTableEnabled}
          isLpnArchived={this.state.lpn.state === 'archived'}
          isShipper={this.isShipper}
          inventoryService={this.inventoryService}
          locationsService={this.locationsService}
        />
        <div className="container-fluid">
          <h4>Content Summary</h4>
          {this.props.isNewTableEnabled ? (
            <div className="lpn-detail-content-summary-grid">
              {this.state.lpn.lpnId ? (
                <LpnContentSummaryTable
                  itemMasterService={new ItemMasterService()}
                  inventoryService={new InventoryService(null)}
                  lpnId={this.state.lpn.lpnId}
                  baseUrl={this.isShipper ? '/s' : '/wh'}
                />
              ) : null}
            </div>
          ) : (
            <LegacyContentSummaryTable lpn={this.state.lpn} baseUrl={this.isShipper ? '/s' : '/wh'} />
          )}
        </div>
        {!this.isShipper && (
          <FocusedMovementLogTable
            warehouseId={this.props.warehouseId}
            lpnBarcode={this.props.match.params.id}
            lpnId={this.lpnId}
            authenticityToken={this.props.authenticityToken}
            locationService={this.locationsService}
          />
        )}
      </>
    );
  }

  private async getLpn(lpnBarcodeParam: string, lpnId: string) {
    this.setState({lpnLoading: true});

    const response = await this.lpnService.getLpns(lpnBarcodeParam, 50, null, lpnId);
    if (response && response.errors && response.errors.length === 0) {
      this.setState({
        lpn: response.data,
        lpnLoading: false
      });
    } else if (response.errors.length > 0) {
      const errorMessage: string = this.lpnService.processErrors(response.errors).join(', ');
      this.setState({errorMessage, lpnLoading: false});
    }
  }
}

function LegacyContentSummaryTable({lpn, baseUrl}: {lpn: LpnDetails; baseUrl: string}) {
  let isLpnUom = false;
  return lpn.contents && lpn.contents.length ? (
    <table className="table">
      <thead>
        <tr>
          <th>Item</th>
          <th>UPC Barcode</th>
          {lpn.contents.map((thing) => {
            if (thing.trackingData.uoms !== null && thing.trackingData.uoms !== undefined) {
              isLpnUom = true;
              return <th>Inner/Outer UoM</th>;
            }
          })}
          <th>Quantity</th>
          <th>LPN Attributes</th>
        </tr>
      </thead>
      <tbody>
        {lpn.contents.map((thing, i) => (
          <tr key={`${thing.item.sku}`}>
            <td key={`${thing.item.sku}-desc`}>
              <span>{thing.item.description}</span>
              <br />
              {thing.item.sku && thing.item.id ? (
                <span>
                  SKU:&nbsp;
                  <Link to={`${baseUrl}/inventories/${thing.item.id}`} target="_blank">
                    {thing.item.sku}
                  </Link>
                </span>
              ) : (
                <span>&mdash;</span>
              )}
            </td>
            <td key={`${thing.item.sku}-barcode`}>{thing.barcode ? thing.barcode : <span>&mdash;</span>}</td>
            {thing.trackingData.uoms ? (
              <td key={`${thing.item.sku}-inOutUom`}>
                {parseCustomUoms(thing).map((entity, num) => {
                  return (
                    <div key={num}>
                      <span>{entity.value}</span>
                    </div>
                  );
                })}
              </td>
            ) : isLpnUom ? (
              <td></td>
            ) : (
              <></>
            )}
            <td key={`${thing.item.sku}-quantity`}>
              {thing.quantity.amount && thing.quantity.unit ? (
                `${thing.quantity.amount} ${thing.quantity.unit}${
                  thing.quantity.amount !== 1 ? (thing.quantity.unit === 'each' ? 'es' : 's') : ''
                }`
              ) : (
                <span>&mdash;</span>
              )}
            </td>
            <td key={`${thing.item.sku}-dates`}>
              <dl className="dl-horizontal left-aligned">
                {contentDatesFor(thing).map((entity, j) => {
                  return (
                    <div key={entity.title + j}>
                      <dt>{entity.title}:</dt>
                      <dd>{entity.value}</dd>
                    </div>
                  );
                })}
              </dl>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>This lpn has no contents</p>
  );
}

function parseCustomUoms(lpnContent: LpnContentDetail) {
  const uoms = lpnContent.trackingData.uoms;
  if (uoms == null || uoms === undefined) {
    return [];
  }
  if (uoms !== null && uoms !== undefined) {
    const eachesPerInner = uoms.eaches_per_inner_pack;
    const eachesPerOuterPack = uoms.eaches_per_outer_case;
    return [{value: eachesPerInner + ' each/inner pack'}, {value: eachesPerOuterPack + ' each/outer case'}];
  }
  return [];
}

function contentDatesFor(content: LpnContentDetail) {
  return [
    {title: 'Lot Code', value: parseLotCode(content) || '--'},
    {title: 'Manufacture Date', value: parseManufactureDate(content) || '--'},
    {title: 'Expiration Date', value: parseExpirationDate(content) || '--'}
  ];
}

function parseExpirationDate(lpnContent: LpnContentDetail) {
  return formatDate(lpnContent.trackingData.expirationDate);
}

function parseLotCode(lpnContent: LpnContentDetail) {
  return lpnContent.trackingData.lotCode;
}

function parseManufactureDate(lpnContent: LpnContentDetail) {
  const d = formatDateTime(lpnContent.trackingData.manufactureDate);
  return d;
}

export default LpnDetail;
