import * as React from 'react';
import {cloneDeep} from 'lodash';
import {format as formatDate} from 'date-fns';
import {LegacyModal, Loader, Table, TableHeader} from '@flexe/ui-components';
import classNames from 'classnames';
import FulfillmentService from '../../ecommerce-shipments/FulfillmentService';
import {Company, Packaging, TransitionState} from '../../../shared/CommonInterfaces';
import {renderShipment} from '../../../../libs/helpers';
import {OMSReservation} from '../../ecommerce-orders/OrdersAppInterfaces';
import {
  AllocationError,
  OrderLine,
  OrderLineReceipt,
  OutboundOrder,
  UpdateLineParams
} from '../OutboundOrdersInterfaces';
import OutboundOrdersService from '../OutboundOrdersService';
import ReservationDropDown from '../inputs/ReservationDropDown';
import {
  getLotExpirationDateForDisplay,
  getOrderLineStatusDisplayLabel,
  isLineCancelable,
  isLineEditable
} from '../helpers/OrderLines';
import {shouldShowLotInfo} from '../helpers/FeatureToggles';
import {getCalculatedShipByDateForDisplay} from '../helpers/Receipts';
import CurrencyDropdown from '../inputs/CurrencyDropdown';

const tsFormat = 'MM/DD/YY h:mma';
const shipmentTypeRfo = 'retail_fulfillment_order';
const customerItemNumber = 'customer_itemnumber';
const skuDescription = 'skudescription';
const requestedQuantity = 'requested_quantity';
const customerUom = 'customer_uom';

// linkToRfoShipments is used as a flag to determine whether shipmentId links should be ecommerce or retail
// true: don't make api call to get order information, and link to RFO (retail) shipments
// false: don't make api call to get order information, and link to ecommerce shipments
// null/undefined: make api call to get order information, and set linkToRfoShipments state based on order labels.
interface Props {
  line: OrderLine;
  authenticityToken: string;
  fulfillmentService?: FulfillmentService;
  currentCompany: Company;
  reservations: OMSReservation[];
  order?: OutboundOrder;
  distributionByLotReservationIds: string[];
  showCalculatedShipByDate: boolean;
  toggleOrderLineModal(event);
  reloadOrderLine();
}

interface ItemPriceInfo {
  itemPrice?: number;
  itemCurrency?: string;
}

interface State {
  errors: string[];
  showConfirmCancel: boolean;
  loadingErrors: boolean;
  loadingReceipts: boolean;
  loadingOrder: boolean;
  updatedSku: string;
  updatedLotCode: string;
  updatedExpDate: string;
  updatedQuantity: number;
  updatedReservationId: string;
  updatedItemPriceInfo: ItemPriceInfo;
  allocationErrors: AllocationError[];
  receipts: OrderLineReceipt[];
  editingSku: boolean;
  editingLotCode: boolean;
  editingExpDate: boolean;
  editingQuantity: boolean;
  editingReservation: boolean;
  editingItemPriceInfo: boolean;
  skuUpdateInProgress: boolean;
  lotCodeUpdateInProgress: boolean;
  expDateUpdateInProgress: boolean;
  quantityUpdateInProgress: boolean;
  reservationUpdateInProgress: boolean;
  itemPriceInfoUpdateInProgress: boolean;
  updateLineParams: UpdateLineParams;
  order: OutboundOrder | null;
}

class OrderLineDetailModal extends React.Component<Props, State> {
  private outboundOrdersService: OutboundOrdersService;
  private fulfillmentService: FulfillmentService;

  constructor(props) {
    super(props);
    this.outboundOrdersService = props.outboundOrdersService || new OutboundOrdersService();
    this.fulfillmentService = props.fulfillmentService || new FulfillmentService(props.authenticityToken);
    this.state = {
      errors: null,
      showConfirmCancel: false,
      loadingErrors: true,
      loadingReceipts: true,
      loadingOrder: true,
      allocationErrors: null,
      receipts: null,
      editingSku: false,
      editingLotCode: false,
      editingExpDate: false,
      editingQuantity: false,
      editingReservation: false,
      editingItemPriceInfo: false,
      skuUpdateInProgress: false,
      lotCodeUpdateInProgress: false,
      expDateUpdateInProgress: false,
      quantityUpdateInProgress: false,
      reservationUpdateInProgress: false,
      itemPriceInfoUpdateInProgress: false,
      updatedSku: props.line.sku,
      updatedLotCode: props.line.inventoryLotControl?.lotCode,
      updatedExpDate: props.line.inventoryLotControl?.expirationDate,
      updatedQuantity: props.line.quantity,
      updatedReservationId: props.line.reservationId,
      updatedItemPriceInfo: {
        itemPrice: props.line.itemPrice,
        itemCurrency: props.line.itemCurrency
      },
      updateLineParams: {
        sku: props.line.sku,
        quantity: props.line.quantity,
        unitOfMeasure: props.line.unitOfMeasure,
        labels: props.line.labels,
        reservationId: props.line.reservationId,
        inventoryLotControl: props.line.inventoryLotControl,
        inventoryReferenceControl: props.line.inventoryReferenceControl,
        itemPrice: props.line.itemPrice,
        itemCurrency: props.line.itemCurrency
      },
      order: null
    };
  }

  public async componentDidMount() {
    this.loadAllocationErrors();
    this.loadReceipts();
    this.loadOrderAsync();
  }

  public render() {
    const {line} = this.props;
    const {order, errors, showConfirmCancel, allocationErrors, loadingErrors, loadingReceipts, receipts} = this.state;
    const showLotInfo = shouldShowLotInfo(order, [line], this.props.distributionByLotReservationIds);
    return (
      <LegacyModal
        id="order-line-detail-modal"
        title={`Order Line #${line.id}`}
        show={true}
        size="medium"
        toggleModal={this.props.toggleOrderLineModal}
        disableClose={false}
      >
        <div>
          <div className="row modal-detail-header">
            <div className="col-xs-6 no-padding">
              <h5>
                <i className="fa fa-list-ul"></i>
                &nbsp; Line Details
              </h5>
            </div>
            <div className="col-xs-6 no-padding">
              {isLineCancelable(line) && (
                <div className="pull-right">
                  <a className="btn cta" onClick={this.toggleShowConfirmCancel}>
                    Cancel
                  </a>
                  <i
                    className="fa fa-lg fa-question-circle"
                    data-toggle="tooltip"
                    aria-hidden="true"
                    title={
                      'By cancelling, we will attempt to cancel this order line and all corresponding shipments. ' +
                      'Shipments that have been waved or shipped can no longer be cancelled. ' +
                      "If any of an order line's shipments are cancelled, we will mark the order line as cancelled, " +
                      'even if some of its shipments have not been cancelled.'
                    }
                  ></i>
                </div>
              )}
            </div>
            {showConfirmCancel && (
              <div className="col-xs-12 confirm-blade">
                <p className="pull-left">Are you sure you want to cancel this line and its shipments?</p>
                <a className="btn pull-right" onClick={this.cancelOrderLine}>
                  Yes
                </a>
                <a className="btn pull-right" onClick={this.toggleShowConfirmCancel}>
                  No
                </a>
              </div>
            )}
          </div>
          <div className="row modal-detail-header">
            {errors && errors.length > 0 && (
              <div className="alert alert-danger">
                <ul>
                  {' '}
                  {errors.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}{' '}
                </ul>
              </div>
            )}
            {this.buildHeaderTable(line)}
          </div>
          <div className="row space-above-lg">
            <h4>Contents</h4>
            {this.buildContentsList(showLotInfo)}
          </div>
          {allocationErrors && allocationErrors.length > 0 && (
            <div className="row">
              <h4>Errors</h4>
              <Table tableData={this.getErrorsTableData()} />
            </div>
          )}
          <Loader loading={loadingErrors} />
          {receipts && receipts.length > 0 && (
            <div className="row">
              <h4>Shipments</h4>
              <Table tableData={this.getReceiptsTableData()} />
            </div>
          )}
          <Loader loading={loadingReceipts} />
        </div>
      </LegacyModal>
    );
  }

  private buildHeaderTable(line: OrderLine): JSX.Element {
    const rowClassName = 'tight borderless';
    const textClassName = 'subtle-hover';

    return (
      <table className="table outbound-orders-shared-table">
        <tbody>
          <tr className={rowClassName}>
            <td className="header-25pct">
              <b>Status:</b>
            </td>
            <td className="header-25pct">{getOrderLineStatusDisplayLabel(line)}</td>
            <td className="header-25pct">
              <b>FLEXE Order ID:</b>
            </td>
            <td className="header-remainder">
              <div className={textClassName}>{line.orderId}</div>
            </td>
          </tr>
          <tr className={rowClassName}>
            <td>
              <b>FLEXE Line ID:</b>
            </td>
            <td>
              <div className={textClassName}>{line.id}</div>
            </td>
            <td>
              <b>Created:</b>
            </td>
            <td>
              <div className={textClassName}>{formatDate(line.createdAt, tsFormat)}</div>
            </td>
          </tr>
          <tr className={rowClassName}>
            <td>
              <b>External Line ID:</b>
            </td>
            <td>
              <div className={textClassName}>{line.externalId}</div>
            </td>
            {this.buildCalculatedShipByDateCells(textClassName)}
          </tr>
          <tr className={rowClassName}>{this.buildReservationCells(line)}</tr>
        </tbody>
      </table>
    );
  }

  private buildReservationCells(line: OrderLine) {
    let reservationValueCell: JSX.Element;
    if (this.state.editingReservation) {
      reservationValueCell = (
        <div className="no-hover">
          <ReservationDropDown
            order={this.state.order}
            reservations={this.props.reservations}
            initialReservationId={this.props.line.reservationId}
            onReservationUpdated={this.handleReservationIdUpdated}
          />
          <div className="update-button-container">
            <a
              className={this.getButtonClassName(true, this.state.reservationUpdateInProgress)}
              onClick={this.updateReservation}
              aria-disabled={this.state.reservationUpdateInProgress}
            >
              Update
            </a>
            <a
              className={this.getButtonClassName(false, this.state.reservationUpdateInProgress)}
              onClick={this.cancelEditReservation}
              aria-disabled={this.state.reservationUpdateInProgress}
            >
              Cancel
            </a>
          </div>
        </div>
      );
    } else {
      reservationValueCell = (
        <>
          <span>{line.reservationId && line.reservationId.length > 0 ? line.reservationId : 'not specified'}</span>
          {isLineEditable(line) && !this.state.loadingOrder && (
            <a onClick={this.toggleEditingReservation}>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
          )}
        </>
      );
    }

    return (
      <>
        <td>
          <b>Requested Reservation:</b>
        </td>
        <td colSpan={3}>{reservationValueCell}</td>
      </>
    );
  }

  private buildCalculatedShipByDateCells(textClassName: string) {
    if (!this.props.showCalculatedShipByDate) {
      return undefined;
    }
    return (
      <>
        <td>
          <b>Calculated Ship by Date:</b>
        </td>
        <td>
          <div className={textClassName}>{getCalculatedShipByDateForDisplay(this.state.receipts)}</div>
        </td>
      </>
    );
  }

  private getButtonClassName = (isBlue, isDisabled) => classNames('btn', {cta: isBlue}, {disabled: isDisabled});

  private loadReceipts = async () => {
    let errors = cloneDeep(this.state.errors) || [];
    this.setState({loadingReceipts: true});
    const {id, orderId} = this.props.line;
    const receiptsResponse = await this.outboundOrdersService.getLineReceipts(orderId, id);
    if (receiptsResponse && receiptsResponse.errors && receiptsResponse.errors.length > 0) {
      const newErrors = this.outboundOrdersService.processErrors(receiptsResponse.errors);
      errors = errors.concat(newErrors);
    }
    const receipts = receiptsResponse.receipts || ([] as OrderLineReceipt[]);
    this.setState({receipts, loadingReceipts: false, errors});
  };

  private loadAllocationErrors = async () => {
    let errors = cloneDeep(this.state.errors) || [];
    this.setState({loadingErrors: true});
    const line = this.props.line;
    const errorsResponse = await this.outboundOrdersService.getLineErrors(line.orderId, line.id);
    if (errorsResponse && errorsResponse.errors && errorsResponse.errors.length > 0) {
      const newErrors = this.outboundOrdersService.processErrors(errorsResponse.errors);
      errors = errors.concat(newErrors);
    }
    this.setState({
      allocationErrors: errorsResponse.allocationErrors,
      loadingErrors: false,
      errors
    });
  };

  private loadOrderAsync = async () => {
    if (!this.props.order) {
      let errors = this.state.errors || [];
      this.setState({loadingOrder: true});
      const orderResponse = await this.outboundOrdersService.getOrders({}, null, 1, this.props.line.orderId);
      if (orderResponse?.errors?.length > 0) {
        const newErrors = this.outboundOrdersService.processErrors(orderResponse.errors);
        errors = errors.concat(newErrors);
      }
      const order = orderResponse.outboundOrders ? orderResponse.outboundOrders[0] : (null as OutboundOrder);
      this.setState({
        order,
        loadingOrder: false,
        errors
      });
    } else {
      this.setState({order: this.props.order, loadingOrder: false});
    }
  };

  private toggleShowConfirmCancel = async (event) => {
    this.setState({showConfirmCancel: !this.state.showConfirmCancel});
  };

  private cancelOrderLine = async () => {
    let errors;
    const line = this.props.line;
    const cancelResponse = await this.outboundOrdersService.cancelOrderLine(line.orderId, line.id);
    if (cancelResponse && cancelResponse.errors && cancelResponse.errors.length > 0) {
      errors = this.outboundOrdersService.processErrors(cancelResponse.errors);
    } else {
      this.setState({showConfirmCancel: false}, this.props.reloadOrderLine);
    }
    this.setState({errors});
  };

  private getErrorsTableData() {
    const headers = [{className: 'id-header', element: 'Code'}, {element: 'Details'}] as TableHeader[];
    const rows = this.buildErrorsRows();
    return {
      headers,
      rows
    };
  }

  private buildErrorsRows() {
    if (this.state.allocationErrors && this.state.allocationErrors.length > 0) {
      return this.state.allocationErrors.map((e) => {
        return [<span className="red2">{e.code}</span>, <span className="red2">{e.details}</span>];
      });
    }
    return [];
  }

  private toggleEditingSku = () => {
    this.setState({editingSku: !this.state.editingSku});
  };

  private toggleEditingLotCode = () => {
    this.setState({editingLotCode: !this.state.editingLotCode});
  };

  private toggleEditingExpDate = () => {
    this.setState({editingExpDate: !this.state.editingExpDate});
  };

  private toggleEditingQuantity = () => {
    this.setState({editingQuantity: !this.state.editingQuantity});
  };

  private toggleEditingItemPriceInfo = () => {
    this.setState({editingItemPriceInfo: !this.state.editingItemPriceInfo});
  };

  private cancelEditSku = () => {
    this.setState({
      updatedSku: this.props.line.sku
    });
    this.toggleEditingSku();
  };

  private cancelEditLotCode = () => {
    this.setState({
      updatedLotCode: this.props.line.inventoryLotControl?.lotCode
    });
    this.toggleEditingLotCode();
  };

  private cancelEditExpDate = () => {
    this.setState({
      updatedExpDate: this.props.line.inventoryLotControl?.expirationDate
    });
    this.toggleEditingExpDate();
  };

  private cancelEditQuantity = () => {
    this.setState({
      updatedQuantity: this.props.line.quantity
    });
    this.toggleEditingQuantity();
  };

  private cancelEditItemPriceInfo = () => {
    this.setState({
      updatedItemPriceInfo: {
        itemPrice: this.props.line.itemPrice,
        itemCurrency: this.props.line.itemCurrency
      }
    });
    this.toggleEditingItemPriceInfo();
  };

  private handleReservationIdUpdated = (reservationId: string) => {
    this.setState({updatedReservationId: reservationId});
  };

  private updateReservation = async () => {
    this.setState({reservationUpdateInProgress: true});
    const updated = this.state.updatedReservationId;
    if (updated !== this.props.line.reservationId) {
      const updatedParams = {...this.state.updateLineParams, reservationId: updated};
      await this.updateOrderLine(updatedParams);
    }
    this.toggleEditingReservation();
    this.setState({reservationUpdateInProgress: false});
  };

  private cancelEditReservation = () => {
    this.setState({
      updatedReservationId: this.props.line.reservationId
    });
    this.toggleEditingReservation();
  };

  private toggleEditingReservation = () => {
    this.setState({editingReservation: !this.state.editingReservation});
  };

  private updateSkuParam = async (event) => {
    let value = event.currentTarget.value;
    if (value.trim() === '' || (event.currentTarget.type === 'checkbox' && !event.currentTarget.checked)) {
      value = null;
    }
    this.setState({updatedSku: value});
  };

  private updateLotCodeParam = async (event) => {
    let value = event.currentTarget.value;
    if (value.trim() === '' || (event.currentTarget.type === 'checkbox' && !event.currentTarget.checked)) {
      value = null;
    }
    this.setState({updatedLotCode: value});
  };

  private updateExpDateParam = async (event) => {
    let value = event.currentTarget.value;
    if (value.trim() === '' || (event.currentTarget.type === 'checkbox' && !event.currentTarget.checked)) {
      value = null;
    }
    this.setState({updatedExpDate: value});
  };

  private updateQuantityParam = async (event) => {
    const value = Number(event.currentTarget.value);
    this.setState({updatedQuantity: value});
  };

  private updateItemPriceParam = (event) => {
    const rawValue = event.currentTarget.value;
    const value = rawValue ? Number(rawValue) : undefined;
    this.setState({updatedItemPriceInfo: {...this.state.updatedItemPriceInfo, itemPrice: value}});
  };

  private updateItemCurrencyParam = (newCurrency?: string) => {
    this.setState({updatedItemPriceInfo: {...this.state.updatedItemPriceInfo, itemCurrency: newCurrency}});
  };

  private updateOrderLine = async (updateLineParams) => {
    let errors;
    const response = await this.outboundOrdersService.updateOrderLine(
      this.props.line.orderId,
      this.props.line.id,
      updateLineParams
    );
    if (response && response.errors && response.errors.length > 0) {
      errors = this.outboundOrdersService.processErrors(response.errors);
    } else {
      this.setState({updateLineParams});
    }
    this.props.reloadOrderLine();
    this.setState({errors});
  };

  private updateSku = async () => {
    this.setState({skuUpdateInProgress: true});
    const updated = this.state.updatedSku.trim();
    if (updated !== this.props.line.sku) {
      const updatedParams = {...this.state.updateLineParams, sku: updated};
      await this.updateOrderLine(updatedParams);
    }
    this.toggleEditingSku();
    this.setState({skuUpdateInProgress: false});
  };

  private updateLotCode = async () => {
    this.setState({lotCodeUpdateInProgress: true});
    const updated = this.state.updatedLotCode?.trim();
    if (updated !== this.props.line.inventoryLotControl?.lotCode) {
      const updatedParams = {
        ...this.state.updateLineParams,
        inventoryLotControl: {...this.props.line.inventoryLotControl, lotCode: updated}
      };
      await this.updateOrderLine(updatedParams);
    }
    this.toggleEditingLotCode();
    this.setState({lotCodeUpdateInProgress: false});
  };

  private updateExpDate = async () => {
    this.setState({expDateUpdateInProgress: true});
    const updated = this.state.updatedExpDate?.trim();
    if (updated !== this.props.line.inventoryLotControl?.expirationDate) {
      const updatedParams = {
        ...this.state.updateLineParams,
        inventoryLotControl: {...this.props.line.inventoryLotControl, expirationDate: updated}
      };
      await this.updateOrderLine(updatedParams);
    }
    this.toggleEditingExpDate();
    this.setState({expDateUpdateInProgress: false});
  };

  private updateQuantity = async () => {
    this.setState({quantityUpdateInProgress: true});
    const updated = this.state.updatedQuantity;
    if (updated !== this.props.line.quantity) {
      const updatedParams = {...this.state.updateLineParams, quantity: updated};
      await this.updateOrderLine(updatedParams);
    }
    this.toggleEditingQuantity();
    this.setState({quantityUpdateInProgress: false});
  };

  private updateItemPriceInfo = async () => {
    this.setState({itemPriceInfoUpdateInProgress: true});
    const {itemPrice, itemCurrency} = this.state.updatedItemPriceInfo;
    if (itemPrice !== this.props.line.itemPrice || itemCurrency !== this.props.line.itemCurrency) {
      const updatedParams = {...this.state.updateLineParams, itemPrice, itemCurrency};
      await this.updateOrderLine(updatedParams);
    }
    this.toggleEditingItemPriceInfo();
    this.setState({itemPriceInfoUpdateInProgress: false});
  };

  private buildContentsList = (showLotInfo: boolean) => {
    const {line} = this.props;
    const {
      editingSku,
      editingQuantity,
      editingItemPriceInfo,
      updatedSku,
      updatedQuantity,
      updatedItemPriceInfo,
      editingLotCode,
      updatedLotCode,
      editingExpDate,
      updatedExpDate
    } = this.state;

    const rowClassName = 'tight borderless';
    const valueClassName = 'subtle-hover';

    let lotInfoRows: JSX.Element;
    if (showLotInfo) {
      lotInfoRows = (
        <>
          <tr className={rowClassName}>
            <td>
              <b>Lot Code:</b>
            </td>
            <td>
              <div className={valueClassName}>{this.buildLotCodeCell(line, editingLotCode, updatedLotCode)}</div>
            </td>
          </tr>
          <tr className={rowClassName}>
            <td>
              <b>Expiration Date:</b>
            </td>
            <td>
              <div className={valueClassName}>{this.buildExpDateCell(line, editingExpDate, updatedExpDate)}</div>
            </td>
          </tr>
        </>
      );
    }

    let inventoryReferenceRows: JSX.Element;
    if (line.inventoryReferenceControl?.inventoryReference) {
      inventoryReferenceRows = (
        <>
          <tr className={rowClassName}>
            <td>
              <b>Inventory Reference:</b>
            </td>
            <td>
              <div className={valueClassName}>{line.inventoryReferenceControl?.inventoryReference}</div>
            </td>
          </tr>
        </>
      );
    }

    return (
      <table className="table outbound-orders-shared-table">
        <tbody>
          <tr className={rowClassName}>
            <td className="header-10pct">
              <b>SKU:</b>
            </td>
            <td className="header-25pct">{this.buildSkuCell(line, editingSku, updatedSku)}</td>
          </tr>
          {lotInfoRows}
          {inventoryReferenceRows}
          <tr className={rowClassName}>
            <td>
              <b>Quantity:</b>
            </td>
            <td>
              <div className={valueClassName}>{this.buildQuantityCell(line, editingQuantity, updatedQuantity)}</div>
            </td>
          </tr>
          <tr className={rowClassName}>
            <td>
              <b>Unit of Measure:</b>
            </td>
            <td>
              <div className={valueClassName}>{line.unitOfMeasure ? line.unitOfMeasure : Packaging.each}</div>
            </td>
          </tr>
          <tr className={rowClassName}>
            <td>
              <b>Item Price per Unit & Currency:</b>
            </td>
            <td>
              <div className={valueClassName}>
                {this.buildItemPriceCell(line, editingItemPriceInfo, updatedItemPriceInfo)}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  private buildLotCodeCell = (line: OrderLine, isEditingLotCode: boolean, updatedLotCode: string) => {
    if (isEditingLotCode) {
      return (
        <div>
          <input
            type="text"
            placeholder="Lot Code"
            name="lotCode"
            onChange={this.updateLotCodeParam}
            value={updatedLotCode}
          />
          <div className="no-hover update-button-container">
            <a
              className={this.getButtonClassName(true, this.state.lotCodeUpdateInProgress)}
              onClick={this.updateLotCode}
              aria-disabled={this.state.lotCodeUpdateInProgress}
            >
              Update
            </a>
            <a
              className={this.getButtonClassName(false, this.state.lotCodeUpdateInProgress)}
              onClick={this.cancelEditLotCode}
              aria-disabled={this.state.lotCodeUpdateInProgress}
            >
              Cancel
            </a>
          </div>
        </div>
      );
    }

    return (
      <div>
        <span>
          <span>{line.inventoryLotControl?.lotCode}</span>
          {isLineEditable(line) && (
            <a onClick={this.toggleEditingLotCode}>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
          )}
        </span>
      </div>
    );
  };

  private buildExpDateCell = (line: OrderLine, isEditingExpDate: boolean, updatedExpDate: string) => {
    if (isEditingExpDate) {
      return (
        <div>
          <input
            name="expDate"
            value={updatedExpDate}
            onChange={this.updateExpDateParam}
            className="form-control"
            type="date"
            date-format="mm/dd/yyyy"
          />
          <div className="no-hover update-button-container">
            <a
              className={this.getButtonClassName(true, this.state.expDateUpdateInProgress)}
              onClick={this.updateExpDate}
              aria-disabled={this.state.expDateUpdateInProgress}
            >
              Update
            </a>
            <a
              className={this.getButtonClassName(false, this.state.expDateUpdateInProgress)}
              onClick={this.cancelEditExpDate}
              aria-disabled={this.state.expDateUpdateInProgress}
            >
              Cancel
            </a>
          </div>
        </div>
      );
    }

    return (
      <div>
        <span>
          <span>{getLotExpirationDateForDisplay(line.inventoryLotControl)}</span>
          {isLineEditable(line) && (
            <a onClick={this.toggleEditingExpDate}>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
          )}
        </span>
      </div>
    );
  };

  private buildSkuCell = (line: OrderLine, editingSku: boolean, updatedSku: string) => {
    if (editingSku) {
      return (
        <div>
          <input type="text" placeholder="SKU" name="sku" onChange={this.updateSkuParam} value={updatedSku} />
          <div className="no-hover update-button-container">
            <a
              className={this.getButtonClassName(true, this.state.skuUpdateInProgress)}
              onClick={this.updateSku}
              aria-disabled={this.state.skuUpdateInProgress}
            >
              Update
            </a>
            <a
              className={this.getButtonClassName(false, this.state.skuUpdateInProgress)}
              onClick={this.cancelEditSku}
              aria-disabled={this.state.skuUpdateInProgress}
            >
              Cancel
            </a>
          </div>
        </div>
      );
    }

    return (
      <div>
        <span>
          <span>{line.sku}</span>
          {isLineEditable(line) && (
            <a onClick={this.toggleEditingSku}>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
          )}
        </span>
      </div>
    );
  };

  private buildQuantityCell = (line: OrderLine, editingQuantity: boolean, updatedQuantity: number) => {
    if (editingQuantity) {
      return (
        <div>
          <input
            type="number"
            placeholder="Quantity"
            name="quantity"
            onChange={this.updateQuantityParam}
            value={updatedQuantity}
          />
          <div className="no-hover update-button-container">
            <a
              className={this.getButtonClassName(true, this.state.quantityUpdateInProgress)}
              onClick={this.updateQuantity}
              aria-disabled={this.state.quantityUpdateInProgress}
            >
              Update
            </a>
            <a
              className={this.getButtonClassName(false, this.state.quantityUpdateInProgress)}
              onClick={this.cancelEditQuantity}
              aria-disabled={this.state.quantityUpdateInProgress}
            >
              Cancel
            </a>
          </div>
        </div>
      );
    }

    return (
      <div>
        <span>{line.quantity}</span>
        {isLineEditable(line) && (
          <a onClick={this.toggleEditingQuantity}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </a>
        )}
      </div>
    );
  };

  private buildItemPriceCell = (line: OrderLine, inEditMode: boolean, updatedItemPriceInfo: ItemPriceInfo) => {
    if (inEditMode) {
      return (
        <div>
          <input
            type="number"
            placeholder="Price"
            name="price"
            min={0}
            step={0.01}
            onChange={this.updateItemPriceParam}
            value={updatedItemPriceInfo.itemPrice}
          />
          &nbsp;
          <CurrencyDropdown
            selectedCurrencyValue={updatedItemPriceInfo.itemCurrency}
            onCurrencyUpdated={this.updateItemCurrencyParam}
          />
          <div className="no-hover update-button-container">
            <a
              className={this.getButtonClassName(true, this.state.itemPriceInfoUpdateInProgress)}
              onClick={this.updateItemPriceInfo}
              aria-disabled={this.state.itemPriceInfoUpdateInProgress}
            >
              Update
            </a>
            <a
              className={this.getButtonClassName(false, this.state.itemPriceInfoUpdateInProgress)}
              onClick={this.cancelEditItemPriceInfo}
              aria-disabled={this.state.itemPriceInfoUpdateInProgress}
            >
              Cancel
            </a>
          </div>
        </div>
      );
    }

    const displayValue =
      line.itemPrice || line.itemCurrency ? (
        <>
          {line.itemPrice} {line.itemCurrency}
        </>
      ) : (
        <i>not specified</i>
      );

    return (
      <div>
        <span>{displayValue}</span>
        {isLineEditable(line) && (
          <a onClick={this.toggleEditingItemPriceInfo}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </a>
        )}
      </div>
    );
  };

  private shouldIncludePriceData = () => this.state.receipts.some((r) => r.itemPrice != null && r.itemCurrency != null);

  private buildReceiptsTableHeaders = (includePriceData: boolean) => {
    const itemPriceDataCols = includePriceData ? [{element: 'Item Price'}, {element: 'Item Currency'}] : [];
    return [
      {className: 'id-header', element: 'FLEXE Shipment ID'},
      {element: 'Reservation'},
      {element: 'Status'},
      {element: 'Carrier'},
      {element: 'Tracking Number'},
      ...itemPriceDataCols,
      {element: 'Shipped At'}
    ] as TableHeader[];
  };

  private getReceiptsTableData = () => {
    const includePriceData = this.shouldIncludePriceData();
    const headers = this.buildReceiptsTableHeaders(includePriceData);
    let rows = [];
    if (this.state.receipts && this.state.receipts.length > 0) {
      rows = this.state.receipts.map((r: OrderLineReceipt) => {
        const shipmentLink = r.shipmentId
          ? renderShipment(r.shipmentId, true, r.shipmentType === shipmentTypeRfo, false, true)
          : undefined;
        let state;
        if (r.shippedAt) {
          state = TransitionState.Completed;
        } else if (r.cancelledAt) {
          state = TransitionState.Cancelled;
        } else if (r.wavedAt) {
          state = TransitionState.Confirmed;
        } else {
          state = TransitionState.New;
        }
        const itemPriceData = includePriceData ? [<div>{r.itemPrice}</div>, <div>{r.itemCurrency}</div>] : [];
        return [
          <div>{shipmentLink}</div>,
          r.reservationId,
          <span className={`label ${state}`}>{state}</span>,
          <div>{r.serviceLevel || 'N/A'}</div>,
          <div>{r.trackingNumber || 'N/A'}</div>,
          ...itemPriceData,
          r.shippedAt ? formatDate(r.shippedAt, tsFormat) : ''
        ];
      });
    }
    return {headers, rows};
  };
}

export default OrderLineDetailModal;
