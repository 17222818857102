import * as React from 'react';
import * as moment from 'moment';
import {sortBy} from 'lodash';
import {useEffect, useState} from 'react';
import {EmailAddress, Loader} from '@flexe/ui-components';
import WarehouseSelector from '../../shared/WarehouseSelector';
import {BatchingMethod, Reservation, Warehouse, WarehouseConfig} from '../../shared/CommonInterfaces';
import BatchWavingService from '../../shared/services/BatchWavingService';
import WaveTemplateService from '../../shared/services/WaveTemplateService';
import WarehouseService from '../../shared/services/WarehouseService';
import SelectorWithCheckbox, {SelectOption} from '../../shared/SelectorWithCheckbox';
import WarehouseReservationUtil from '../../shared/utilities/WarehouseReservationUtil';
import WaveTemplateServiceV2 from '../../shared/services/WaveTemplateServiceV2';
import ManagedWaveTemplateList from './ManagedWaveTemplateList';
import AsyncBatchCreator from './AsyncBatchCreator';
import AsyncWavingByCarriers from './AsyncWavingByCarriers';
import WaveTemplateList from './WaveTemplateList';
import ShipmentStatusSummary from './ShipmentStatusSummary';
import AlertWavingIssues from './AlertWavingIssues';
import PageReloader from './PageReloader';
import {WaveTemplate} from './wave-templates/WaveTemplateInterfaces';

interface AsyncWavingProps {
  selectedWarehouse: Warehouse;
  warehouses: Warehouse[];
  reservationToSortationEnabled: object;
  carriers: object;
  batchWavingService: BatchWavingService;
  warehouseService: WarehouseService;
  waveTemplateService: WaveTemplateService;
  isHighVolumeWavingEnabled: boolean;
  isFullPalletPullForSortationEnabled: boolean;
  isFreightWorkflowEnabled: boolean;
  waveTemplateEnhancementsEnabled: boolean;
  onWarehouseSelect(warehouse: Warehouse);
  waveTemplateFannedLoadingEnabled: boolean;
}

const AsyncWaving: React.FC<AsyncWavingProps> = (props) => {
  const todayDate = new Date(Date.now());
  const todayFormatted = moment(todayDate).format('YYYY-MM-DD');
  const todayTimeFormatted = todayDate.toISOString();

  const dateStringToSelectOption = (date: string): SelectOption => {
    let nameStr = moment(date).format('MMMM DD');
    const tomorrowDate = new Date(Date.now()).setDate(todayDate.getDate() + 1);
    const tomorrowFormatted = moment(tomorrowDate).format('YYYY-MM-DD');
    if (date === todayFormatted) {
      nameStr = 'Today - ' + nameStr;
    } else if (date === tomorrowFormatted) {
      nameStr = 'Tomorrow - ' + nameStr;
    }
    return {name: nameStr, value: date} as SelectOption;
  };

  const {selectedWarehouse, carriers, reservationToSortationEnabled, waveTemplateService} = props;
  const [showWaveModal, setShowWaveModal] = useState<boolean>(false);
  const [templateToWave, setTemplateToWave] = useState<WaveTemplate>();
  const [pastDueTemplates, setPastDueTemplates] = useState<WaveTemplate[]>([]);
  const [waveTemplates, setWaveTemplates] = useState<WaveTemplate[]>([]);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [shipOnDate, setShipOnDate] = useState<string>(todayFormatted);
  const [shipOnDates, setShipOnDates] = useState<SelectOption[]>([]);
  const [reloadChildrenTrigger, setReloadChildrenTrigger] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timerStartsAt, setTimerStartsAt] = useState<Date>(new Date());
  const [warehouseConfig, setWarehouseConfig] = useState<WarehouseConfig>();
  const isInitialMount = React.useRef(true);
  const [fullPalletsOnly, setFullPalletsOnly] = useState<boolean>(false);
  const [unitsPerPalletCount, setUnitsPerPalletCount] = useState<number>(0);

  useEffect(() => {
    loadForWarehouse();
  }, [selectedWarehouse.id]);

  // reload wave templates when ship on date change
  useEffect(() => {
    if (!isInitialMount.current) {
      loadWaveTemplates();
    }
  }, [shipOnDate]);

  useEffect(() => {
    if (!showWaveModal) {
      setFullPalletsOnly(false);
      setUnitsPerPalletCount(0);
    }
  }, [showWaveModal]);
  const loadForWarehouse = async () => {
    setTimerStartsAt(new Date(Date.now()));
    setIsLoading(true);
    // we don't want to reload children components on initial load
    if (!isInitialMount.current) {
      setReloadChildrenTrigger(!reloadChildrenTrigger);
    }
    const allErrors = await Promise.all([
      getShipOnDates(),
      loadWaveTemplates(),
      loadPastDues(),
      loadReservations(),
      loadWarehouseConfig()
    ]);
    setIsLoading(false);
    const loadErrors = [].concat(...allErrors);
    handleErrors(loadErrors);

    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  };

  const loadReservations = async () => {
    const response = await props.warehouseService.getReservationsForWarehouse(selectedWarehouse.id);
    if (responseHasNoErrors(response) && response.data && response.data.reservations) {
      setReservations(response.data.reservations);
    }
    return response.errors;
  };

  const loadPastDues = async () => {
    const response = await waveTemplateService.getPastDue(selectedWarehouse.id, null, todayTimeFormatted);

    if (responseHasNoErrors(response)) {
      const sortedPastDueTemplates = sortBy(response.data, ['count']).reverse();
      setPastDueTemplates(sortedPastDueTemplates);
    }
    return response.errors;
  };

  const loadWaveTemplates = async () => {
    if (props.waveTemplateFannedLoadingEnabled) {
      return [];
    }

    const response = await waveTemplateService.getWaveTemplates(selectedWarehouse.id, shipOnDate);
    if (responseHasNoErrors(response)) {
      const sortedTemplates = sortBy(response.data, ['count']).reverse();
      setWaveTemplates(sortedTemplates);
    }
    return response.errors;
  };

  const handleErrors = (responseErrors) => {
    setErrors(responseErrors.map((e) => e.detail));
  };

  const getReservationIdForSelectedTemplate = () => {
    return templateToWave ? templateToWave.waveFilters.reservationId : 0;
  };

  const getShipOnDates = async () => {
    const response = await props.batchWavingService.getActiveShipOnDates(props.batchWavingService.currentWarehouse);
    if (responseHasNoErrors(response) && response.data) {
      const dataDates = response.data.shipOnDates;
      if (!dataDates.includes(todayFormatted)) {
        dataDates.push(todayFormatted);
      }
      const shipDates: SelectOption[] = dataDates
        .sort()
        .reverse()
        .map((date) => dateStringToSelectOption(date));
      setShipOnDates(shipDates);
    }
    return response.errors;
  };

  const loadWarehouseConfig = async () => {
    const getWarehouseConfigResponse = await props.warehouseService.getWarehouseConfig(
      props.batchWavingService.currentWarehouse
    );
    if (getWarehouseConfigResponse && getWarehouseConfigResponse.errors.length === 0) {
      setWarehouseConfig(getWarehouseConfigResponse.data.warehouseFulfillmentConfig);
    }
    return getWarehouseConfigResponse.errors;
  };

  const onWaveSuccess = async () => {
    setShowSuccessAlert(true);
    loadForWarehouse();
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const surfaceErrors = (err) => {
    setErrors(err);
  };

  const isWarehouseConfiguredForManualWaving = () => {
    return warehouseConfig && warehouseConfig.batchingType === BatchingMethod.MANUAL;
  };

  const showWaveModalAndSetFullPalletPull = (isFullPalletPull: boolean = false, unitsPerPallet: number = 0) => {
    setFullPalletsOnly(isFullPalletPull);
    setUnitsPerPalletCount(unitsPerPallet);
    setShowWaveModal(true);
  };

  const wavingName = () => {
    if (props.isFreightWorkflowEnabled) {
      return 'Waving';
    } else {
      return 'eComm Waving';
    }
  };

  return (
    <div id="async-waving">
      {isLoading && <Loader loading={isLoading} />}
      {(!isWarehouseConfiguredForManualWaving() && (
        // Show only warehouse selector if warehouse is not configured for manual waving
        <div className={'title-header'}>
          <WarehouseSelector
            selectedWarehouse={props.selectedWarehouse}
            activeWarehouses={props.warehouses}
            onSelect={(warehouse: Warehouse) => props.onWarehouseSelect(warehouse)}
          />
          <h4>
            This warehouse is not configured for manual waving, please select a different warehouse or talk to{' '}
            <EmailAddress localPart={'support'} />
          </h4>
        </div>
      )) || ( // Show async waving page if selected warehouse is configured for manual waving
        <div>
          {errors && errors.length > 0 && (
            <div className="col-md-12 space-below">
              <div className="alert error-banner">
                <div className="error-banner-text">
                  {errors.join(', ')}&nbsp;
                  <a onClick={refreshPage}>
                    <strong>
                      <u>Refresh and try again</u>
                    </strong>
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="title-header">
            <div className={'title-header-controls'}>
              <div>
                <WarehouseSelector
                  selectedWarehouse={props.selectedWarehouse}
                  activeWarehouses={props.warehouses}
                  onSelect={(warehouse: Warehouse) => props.onWarehouseSelect(warehouse)}
                />
                <div>
                  <h2>{wavingName()}</h2>
                  <SelectorWithCheckbox
                    options={shipOnDates}
                    selected={[shipOnDate]}
                    onSelect={(value) => setShipOnDate(value.toString())}
                    prefixText="Ship By: "
                  />
                </div>
              </div>
              <div className={'top-right-controls'}>
                <PageReloader
                  timerUpdateFrequency={6000}
                  timerStartsAt={timerStartsAt}
                  onReload={() => loadForWarehouse()}
                />
                <AlertWavingIssues
                  warehouseId={selectedWarehouse.id}
                  batchWavingService={props.batchWavingService}
                  reloadTrigger={reloadChildrenTrigger}
                />
              </div>
            </div>

            <ShipmentStatusSummary
              warehouseId={selectedWarehouse.id}
              batchWavingService={props.batchWavingService}
              shipOnDate={shipOnDate}
              reloadTrigger={reloadChildrenTrigger}
            />
          </div>

          {Array.isArray(pastDueTemplates) && pastDueTemplates.length > 0 && (
            <WaveTemplateList
              isPastDue={true}
              waveTemplates={pastDueTemplates}
              reservationToSortationEnabled={reservationToSortationEnabled}
              reservationIdToName={WarehouseReservationUtil.getReservationIdToName(reservations)}
              carriers={carriers}
              setShowWaveModal={setShowWaveModal}
              setTemplateToWave={setTemplateToWave}
              loadForWarehouse={loadForWarehouse}
              waveTemplateService={waveTemplateService}
              errorCatapult={surfaceErrors}
              waveTemplateEnhancementsEnabled={false}
            />
          )}
          {props.waveTemplateFannedLoadingEnabled ? (
            <ManagedWaveTemplateList
              warehouseId={selectedWarehouse.id}
              waveTemplateService={waveTemplateService}
              reservationToSortationEnabled={reservationToSortationEnabled}
              reservationIdToName={WarehouseReservationUtil.getReservationIdToName(reservations)}
              carriers={carriers}
              setShowWaveModal={setShowWaveModal}
              setTemplateToWave={setTemplateToWave}
              loadForWarehouse={loadForWarehouse}
              errorCatapult={surfaceErrors}
              waveTemplateEnhancementsEnabled={props.waveTemplateEnhancementsEnabled}
              shipOnDate={shipOnDate}
              waveTemplateServiceV2={new WaveTemplateServiceV2()}
            />
          ) : (
            <WaveTemplateList
              isPastDue={false}
              waveTemplates={waveTemplates}
              reservationToSortationEnabled={reservationToSortationEnabled}
              reservationIdToName={WarehouseReservationUtil.getReservationIdToName(reservations)}
              carriers={carriers}
              setShowWaveModal={setShowWaveModal}
              setTemplateToWave={setTemplateToWave}
              loadForWarehouse={loadForWarehouse}
              waveTemplateService={waveTemplateService}
              errorCatapult={surfaceErrors}
              waveTemplateEnhancementsEnabled={props.waveTemplateEnhancementsEnabled}
            />
          )}

          <AsyncWavingByCarriers
            wavingService={props.batchWavingService}
            selectedWarehouse={props.selectedWarehouse}
            carriers={props.carriers}
            cutoffDate={shipOnDate}
            setTemplateToWave={(template: WaveTemplate) => setTemplateToWave(template)}
            reloadTrigger={reloadChildrenTrigger}
            showWaveModalAndSetFullPalletPull={showWaveModalAndSetFullPalletPull}
            reservationIdToName={WarehouseReservationUtil.getReservationIdToName(reservations)}
            onWaveSuccess={() => setShowSuccessAlert(true)}
            onWaveFailure={() => setShowErrorAlert(true)}
            isHighVolumeWavingEnabled={props.isHighVolumeWavingEnabled}
            isFullPalletPullForSortationEnabled={props.isFullPalletPullForSortationEnabled}
            reservationToSortationEnabled={props.reservationToSortationEnabled}
            warehouseService={props.warehouseService}
          />
          {showWaveModal && templateToWave && (
            <AsyncBatchCreator
              templateToWave={templateToWave}
              fullPalletsOnly={fullPalletsOnly}
              unitsPerPalletCount={unitsPerPalletCount}
              showModal={showWaveModal}
              isSortationEnabled={props.reservationToSortationEnabled[getReservationIdForSelectedTemplate()]}
              wavingService={props.batchWavingService}
              carriers={carriers}
              reservationIdToName={WarehouseReservationUtil.getReservationIdToName(reservations)}
              isSfsEnabled={selectedWarehouse.isSfsEnabled}
              toggleModal={() => setShowWaveModal(!showWaveModal)}
              onWaveSuccess={() => onWaveSuccess()}
              onWaveFailure={() => setShowErrorAlert(true)}
            />
          )}
          {showSuccessAlert && (
            <div className="alert alert-success" data-testid="wave-success-alert" role="alert">
              <p>
                {`Creating ${selectedWarehouse.isSfsEnabled ? 'waves' : 'batches'}... this may take up to 5 minutes`}
                <button type="button" className="close" onClick={() => setShowSuccessAlert(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          )}
          {showErrorAlert && (
            <div className="alert alert-error" data-testid="wave-failure-alert" role="alert">
              <p>
                {`Wave failed. Please try again. If you get this message repeatedly,
                    please contact FLEXE support.`}
                <button type="button" className="close" onClick={() => setShowErrorAlert(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const responseHasNoErrors = (response) => {
  return response && (!response.errors || response.errors.length === 0);
};

export default AsyncWaving;
