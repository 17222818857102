import {ResponseErrorV2} from '../CommonInterfaces';
import InternalAPIService from './InternalAPIService';
import {Mhe} from './MheService';

export interface WaveServiceApiResponse<T> {
  data: T | null;
  errors: ResponseErrorV2[];
}

export interface PickMhesForWaveResponse {
  mhes: Mhe[];
  continuationToken: string;
}

class WaveService extends InternalAPIService {
  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/wave-service');
  }

  public async pickMhesForWave(
    waveId: number,
    warehouseId: number,
    continuationToken: string
  ): Promise<WaveServiceApiResponse<PickMhesForWaveResponse>> {
    const data = {
      waveId,
      warehouseId,
      continuationToken
    };
    return await this.makeBasicPostRequest(`${this.baseUrl}/pick-mhes-for-wave`, data);
  }
}

export default WaveService;
