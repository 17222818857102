import * as React from 'react';
import {LegacyModal, Loader} from '@flexe/ui-components';
import {LpnSearchDetails} from '../../../lpns/LpnsInterfaces';
import {Packaging} from '../../../shared/CommonInterfaces';
import {getQTYUoM} from '../../../shared/utilities/DataFormatting';
import {renderLpnLink} from '../../../../libs/helpers';
import {TerminalShipmentStates} from '../ShipmentConstants';

interface LpnContentsModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  shipmentId: number;
  lpns: LpnSearchDetails[];
  shipmentStatus: string;
}

const LpnContentsModal: React.FC<LpnContentsModalProps> = (props) => {
  const getModalTitle = () => {
    return (
      <div>
        <h4>LPNs Picked for Shipment #{props.shipmentId}</h4>
        <p>More LPNs may be assigned to, or removed from, this Shipment as it nears completion.</p>
      </div>
    );
  };

  const getQtyAndUom = (lpn: LpnSearchDetails) => {
    const qtyUomMap = new Map<Packaging, number>();
    let qtyUomString = '';
    lpn.contents.forEach((content) => {
      const uom = content.quantity.unit;
      const quantityToAdd = content.quantity.amount;
      if (qtyUomMap.has(uom)) {
        qtyUomMap.set(uom, qtyUomMap.get(uom) + quantityToAdd);
      } else {
        qtyUomMap.set(uom, quantityToAdd);
      }
    });
    qtyUomMap.forEach((quantity, unit) => {
      qtyUomString = qtyUomString + getQTYUoM(quantity, unit, true);
    });
    return qtyUomString;
  };

  return (
    <LegacyModal
      id="shipment-details-modal"
      size="medium"
      title={getModalTitle()}
      show={props.showModal}
      toggleModal={() => {
        props.setShowModal(false);
      }}
    >
      <div>
        {!props.lpns && <Loader loading={true} />}
        <table className="table table-striped">
          <thead>
            <tr>
              <th>LPN</th>
              <th>SKUs</th>
              {!TerminalShipmentStates.includes(props.shipmentStatus) && <th>QTY/UoM</th>}
            </tr>
          </thead>
          <tbody>
            {props.lpns &&
              props.lpns.map((lpn, index) => {
                return (
                  <tr key={index}>
                    <td>{renderLpnLink(lpn.lpnBarcode, false, lpn.lpnId)}</td>
                    <td>{lpn.contents.length}</td>
                    {!TerminalShipmentStates.includes(props.shipmentStatus) && <td>{getQtyAndUom(lpn)}</td>}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </LegacyModal>
  );
};

export default LpnContentsModal;
