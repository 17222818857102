/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import tokens from '@flexe/ui-tokens';
import * as React from 'react';

const requiredStyles = css({
  color: tokens.color.base.red.v300.value
});

export const instructionsTableHeaders: React.ReactNode[] = [
  'Column Header',
  'Required?',
  'Description of Use',
  'Input Guidelines',
  'Sample Input'
];

export const composableInstructionsTableHeaders: React.ReactNode[] = [
  'Column Header',
  'Sample Input',
  'Description',
  'Guidelines'
];

export const composableInstructionsTableRows: React.ReactNode[][] = [
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      purchaseOrder
    </React.Fragment>,
    'PO56879008',
    'Purchase order reference',
    'Alphanumeric string, special characters allowed'
  ],
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      moveDate
    </React.Fragment>,
    '03/15/2023',
    'Date the inventory arrives at warehouse',
    'MM/DD/YY, must be a future date'
  ],
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      sku
    </React.Fragment>,
    'SKU-224113',
    'Must be enabled in Item Master',
    'Alphanumeric string, special characters allowed'
  ],
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      quantity
    </React.Fragment>,
    '600',
    'Number of items per SKU',
    'Whole numbers only'
  ],
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      packaging
    </React.Fragment>,
    'carton',
    'Unit of measure',
    <React.Fragment>
      Must be either <span className="italics">each</span> or <span className="italics">carton</span>
    </React.Fragment>
  ],
  ['valueOfGoods', '6000', 'Estimated value, USD', 'Whole numbers only'],
  ['vendor', 'Best Widget Co', 'Supplier', 'Text only'],
  ['trailerNumber', '1234567', 'Trailer ID number', 'Alphanumeric string, special characters allowed'],
  ['sealNumber', '5678901', 'Seal ID number', 'Alphanumeric string, special characters allowed'],
  ['instructions', 'Ship this with the special boxes', 'Instructions for the warehouse', 'Text only']
];

export const composableInboundPackagingEnabledInstructionsTableRows: React.ReactNode[][] = [
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      inboundPackagingType
    </React.Fragment>,
    'floor_loaded',
    'Packaging in which the goods will arrive at the warehouse',
    <React.Fragment>
      Must be <span className="italics">floor_loaded</span>, <span className="italics">palletized</span> or{' '}
      <span className="italics">unknown</span>
    </React.Fragment>
  ],
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      purchaseOrder
    </React.Fragment>,
    'PO56879008',
    'Purchase order reference',
    'Alphanumeric string, special characters allowed'
  ],
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      moveDate
    </React.Fragment>,
    '03/15/2023',
    'Date the inventory arrives at warehouse',
    'MM/DD/YY, must be a future date'
  ],
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      sku
    </React.Fragment>,
    'SKU-224113',
    'Must be enabled in Item Master',
    'Alphanumeric string, special characters allowed'
  ],
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      quantity
    </React.Fragment>,
    '600',
    'Number of items per SKU',
    'Whole numbers only'
  ],
  [
    <React.Fragment>
      <span css={requiredStyles}>* </span>
      packaging
    </React.Fragment>,
    'carton',
    'Unit of measure',
    <React.Fragment>
      Must be either <span className="italics">each</span> or <span className="italics">carton</span>
    </React.Fragment>
  ],
  ['valueOfGoods', '6000', 'Estimated value of goods in USD', 'Whole numbers only'],
  ['vendor', 'Best Widget Co', 'Supplier / Vendor', 'Text only'],
  ['trailerNumber', '1234567', 'Trailer ID number', 'Alphanumeric string, special characters allowed'],
  ['sealNumber', '5678901', 'Seal ID number', 'Alphanumeric string, special characters allowed'],
  ['instructions', 'Ship this with the special boxes', 'Instructions for the warehouse', 'Text only']
];

// TODO: PORT-94 double-check all of this info
export const instructionsTableRows: React.ReactNode[][] = [
  [
    'purchaseOrder',
    <span className="bold">Yes</span>,
    'Customer purchase order reference',
    'Alphanumeric string, special characters allowed',
    'PO56879008'
  ],
  [
    'moveDate',
    <span className="bold">Yes</span>,
    'Date when inventory will arrive at the warehouse',
    'Must be in the future, formatted as MM/DD/YYYY',
    '03/15/2023'
  ],
  ['valueOfGoods', 'No', 'Estimated value of goods in USD', 'Number, whole numbers only', '6000'],
  ['vendor', 'No', 'Supplier / Vendor', 'Text', 'Best Widget Co'],
  ['trailerNumber', 'No', '', 'Alphanumeric string, special characters allowed', '1234567'],
  ['sealNumber', 'No', '', 'Alphanumeric string, special characters allowed', '5678901'],
  ['instructions', 'No', 'Instructions for the warehouse', 'Text', 'Ship this with the special boxes'],
  [
    'sku',
    <span className="bold">Yes</span>,
    <React.Fragment>
      SKU
      <br />
      <span className="italics">Must be enabled in Flexe Item Master</span>
    </React.Fragment>,
    'Alphanumeric string, special characters allowed',
    'SKU-224113'
  ],
  ['quantity', <span className="bold">Yes</span>, 'Number of items per SKU', 'Number, whole numbers only', '600'],
  [
    'packaging',
    <span className="bold">Yes</span>,
    'Unit of Measure',
    <React.Fragment>
      Must be one of the following:
      <br />
      <span className="italics">each, carton</span>
    </React.Fragment>,
    'carton'
  ]
];

// TODO: PORT-48 double-check all of this info
export const inboundPackagingEnabledInstructionsTableRows: React.ReactNode[][] = [
  [
    'purchaseOrder',
    <span className="bold">Yes</span>,
    'Customer purchase order reference',
    'Alphanumeric string, special characters allowed',
    'PO56879008'
  ],
  [
    'moveDate',
    <span className="bold">Yes</span>,
    'Date when inventory will arrive at the warehouse',
    'Must be in the future, formatted as MM/DD/YYYY',
    '03/15/2023'
  ],
  ['valueOfGoods', 'No', 'Estimated value of goods in USD', 'Number, whole numbers only', '6000'],
  ['vendor', 'No', 'Supplier / Vendor', 'Text', 'Best Widget Co'],
  ['trailerNumber', 'No', '', 'Alphanumeric string, special characters allowed', '1234567'],
  ['sealNumber', 'No', '', 'Alphanumeric string, special characters allowed', '5678901'],
  ['instructions', 'No', 'Instructions for the warehouse', 'Text', 'Ship this with the special boxes'],
  [
    'sku',
    <span className="bold">Yes</span>,
    <React.Fragment>
      SKU
      <br />
      <span className="italics">Must be enabled in Flexe Item Master</span>
    </React.Fragment>,
    'Alphanumeric string, special characters allowed',
    'SKU-224113'
  ],
  ['quantity', <span className="bold">Yes</span>, 'Number of items per SKU', 'Number, whole numbers only', '600'],
  [
    'packaging',
    <span className="bold">Yes</span>,
    'Unit of Measure',
    <React.Fragment>
      Must be one of the following:
      <br />
      <span className="italics">each, carton</span>
    </React.Fragment>,
    'carton'
  ],
  [
    'inboundPackagingType',
    <span className="bold">Yes</span>,
    'Packaging in which the goods will arrive at the warehouse',
    <React.Fragment>
      Must be one of the following:
      <br />
      <span className="italics">floor_loaded, palletized, unknown</span>
    </React.Fragment>,
    'floor_loaded'
  ]
];

export const composableLpnInstructionsTableRows: React.ReactNode[][] = [
  [
    'serialNumbers',
    'SN111:SN112:SN113',
    'Serial Numbers',
    'Must be separated using ":" if multiple values are present'
  ],
  ['lpnBarcode', '01-FX-LPN1', 'LPN Barcode', 'Alphanumeric string, must not used previously'],
  ['lotCode', 'LOT-1', 'LOT Code to be used in LPN deliveries', 'Alphanumeric string. LPN barcode has to be present'],
  ['expirationDate', '04/20/2023', 'Expiration Date', 'MM/DD/YYYY, LPN barcode has to be present'],
  ['manufactureDate', '04/20/2023', 'Manufacture Date', 'MM/DD/YYYY, LPN barcode has to be present'],
  ['referenceId', 'REF-LPN1', 'LPN Reference id', 'Alphanumeric string']
];
