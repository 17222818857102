import {unionBy, uniq} from 'lodash';
import {ApiResponse, FilterOption, ResponseError} from '../../shared/CommonInterfaces';
import {
  CycleCountBatchesResponse,
  CycleCountDetailResponse,
  CycleCountItemMasterResponse,
  CycleCountItemParams,
  CycleCountListState,
  CycleCountMobileUserResponse,
  CycleCountsResponse,
  CycleCountWriteResponse,
  ReservationFilterOption
} from '../../shared/cycle-counts/CycleCountInterfaces';
import {ParseError} from '../../../libs/CsvValidator';
import InternalAPIService from './InternalAPIService';

// We need to use require() to load Papa because otherwise, TypeScript doesn't allow Papa.LocalChunkSize to be changed.
declare function require(name: string);
/* eslint-disable-next-line */
const Papa = require('papaparse');

export interface BulkCreateCycleCountParams {
  warehouseId: string | number;
  locationIds: number[];
  locationCategories: string[];
  numberOfCounts: number;
  referenceId: string;
  reservationId?: number;
  skus?: string[];
  numberOfLocPerCount?: number;
  locationLabels?: string[];
}

class CycleCountsService extends InternalAPIService {
  public currentWarehouse?: number;
  public selectedWarehouse?: FilterOption;
  public currentReservation?: number;
  public selectedReservation?: ReservationFilterOption;

  private listState: CycleCountListState = {
    continuationTokens: [],
    errors: null,
    currentPage: 1,
    filters: {},
    loading: true,
    cycleCounts: [],
    totalCounts: 0,
    reservations: [],
    selectedWarehouse: null
  };

  constructor(authenticityToken: string) {
    super(authenticityToken, '/api/v2/cycle_counts/');
  }

  public async createCycleCount(
    warehouseId: string | number,
    reservationId: string | number,
    skus: string[],
    locationIds: number[]
  ): Promise<ApiResponse<CycleCountWriteResponse>> {
    const data = {warehouseId, reservationId, skus, locationIds};
    return await this.makePostRequest(`${this.baseUrl}create`, data);
  }

  public async bulkCreateLocationCounts(data: BulkCreateCycleCountParams): Promise<ApiResponse<number[]>> {
    return await this.makePostRequest(`${this.baseUrl}bulk_create`, data);
  }

  public async getCycleCounts(
    pageSize: number,
    continuationToken: string = null,
    filters: any = {}
  ): Promise<ApiResponse<CycleCountsResponse>> {
    const data = {filters, pageSize, continuationToken};
    const ret = await this.makePostRequest(`${this.baseUrl}retrieve`, data);
    return ret;
  }

  public async postGenerateBulkReport(filters: any = {}): Promise<ApiResponse<any>> {
    const data = {filters};
    const res = await this.makePostRequest(
      `${this.baseUrl}generate_bulk_report.csv`,
      data,
      'text/csv',
      'cycle_counts_report.csv'
    );

    return res;
  }

  public async postStartCounts(filters: any = {}): Promise<ApiResponse<any>> {
    const data = {filters};
    const res = await this.makePostRequest(`${this.baseUrl}bulk_start`, data);

    return res;
  }

  public async getCycleCount(cycleCountId: number): Promise<ApiResponse<CycleCountDetailResponse>> {
    const params = {authenticity_token: this.authenticityToken};
    const ret = await this.makeGetRequest(`${this.baseUrl}${cycleCountId}`, params);
    return ret;
  }

  public async cancelCycleCount(cycleCountId: number, version: number): Promise<ApiResponse<CycleCountWriteResponse>> {
    const data = {version};
    return await this.makePatchRequest(`${this.baseUrl}${cycleCountId}/cancel`, data);
  }

  public async confirmCycleCount(cycleCountId: number, version: number): Promise<ApiResponse<CycleCountWriteResponse>> {
    const data = {version};
    return await this.makePatchRequest(`${this.baseUrl}${cycleCountId}/confirm`, data);
  }

  public async startCycleCount(
    cycleCountId: number,
    version: number,
    mobileUserId?: number | string
  ): Promise<ApiResponse<CycleCountWriteResponse>> {
    const data = {version, mobileUserId};
    return await this.makePatchRequest(`${this.baseUrl}${cycleCountId}/start`, data);
  }

  public async updateCycleCountItems(
    cycleCountId: number,
    version: number,
    warehouseId: number,
    cycleCountItems: CycleCountItemParams[]
  ): Promise<ApiResponse<CycleCountWriteResponse>> {
    const data = {cycleCountItems, version, warehouseId};
    return await this.makePatchRequest(`${this.baseUrl}${cycleCountId}/update_items`, data);
  }

  public async submitCycleCount(
    cycleCountId: number,
    version: number,
    userComments: string
  ): Promise<ApiResponse<CycleCountWriteResponse>> {
    const data = {userComments, version};
    return await this.makePatchRequest(`${this.baseUrl}${cycleCountId}/submit`, data);
  }

  public async completeCycleCount(
    cycleCountId: number,
    version: number,
    reasonCode?: string | number,
    editNote?: string,
    purchaseOrderId?: string
  ): Promise<ApiResponse<CycleCountWriteResponse>> {
    const data = {version, reasonCode, editNote, purchaseOrderId};
    return await this.makePatchRequest(`${this.baseUrl}${cycleCountId}/complete`, data);
  }

  public async getItemMasterData(
    skuFilter: string,
    warehouseId: string | number
  ): Promise<ApiResponse<CycleCountItemMasterResponse>> {
    const params = {skuFilter, warehouseId, pageSize: 25};
    return await this.makeGetRequest(`${this.baseUrl}item_data`, params);
  }

  public async getMobileUsers(warehouseId: string | number): Promise<ApiResponse<CycleCountMobileUserResponse>> {
    const params = {warehouseId};
    return await this.makeGetRequest(`${this.baseUrl}mobile_users`, params);
  }

  public async getAllBatches(cycleCountId: number): Promise<ApiResponse<CycleCountBatchesResponse>> {
    const params = {authenticity_token: this.authenticityToken};
    return await this.makeGetRequest(`${this.baseUrl}${cycleCountId}/get_all_batches`, params);
  }

  public async closeAllBatches(cycleCountId: number): Promise<ApiResponse<any>> {
    return await this.makePostRequest(`${this.baseUrl}${cycleCountId}/close_all_batches`, {});
  }

  public processErrors = (errors: any[]) => {
    return errors.map((error) => error.detail || error.title);
  };

  public setListCache(state: CycleCountListState) {
    this.listState = state;
  }

  public getListCache(): CycleCountListState {
    return this.listState;
  }

  public prepareCsvChunkForBulkCreateCycleCount(csvChunk, csvChunkErrors) {
    // Extract all the invalid row indexes and sort them in descending order
    // subtract 2 from each index since the error rows we produce in CsvValidator
    // assume the Csv header is row 1
    const invalidRowIndexes = uniq(csvChunkErrors.map((error) => error.row - 2)).sort((a: number, b: number) => b - a);

    /* Now we can remove invalid rows before sending to the server. Because sortedInvalidRowIndexes is sorted
     * in descending order, splicing from chunk.data does not change the indexes of each successive row to remove.
     */
    invalidRowIndexes.forEach((invalidRowIdx) => csvChunk.data.splice(invalidRowIdx, 1));

    // Format the cycle count data for sending to the server
    return csvChunk.data.map((row) => {
      const bulkCycleCount = {
        locationLabel: row.locationLabel,
        sku: row.sku
      };
      return bulkCycleCount;
    });
  }

  public createInvalidRowsCsv(totalCsvErrors: ParseError[]) {
    if (totalCsvErrors.length) {
      // Create a csv with just the invalid rows
      const uniqueInvalidRows = unionBy(totalCsvErrors, (csvError) => csvError.row).map((csvError) => {
        return {
          ...csvError.rowData,
          // Concatenate all the errors for a row so it shows up as a new column in the csv file
          Errors: totalCsvErrors
            .filter((parseError) => parseError.row === csvError.row)
            .reduce((prevStr, currentParseError) => {
              return prevStr + ' ' + currentParseError.message;
            }, '')
        };
      });
      return Papa.unparse(uniqueInvalidRows);
    }
  }
}

export default CycleCountsService;
