import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {OrderType, WaveParameters} from '../../ecommerce-batches/BatchInterfaces';
import {WaveTemplateFilters} from './WaveTemplateInterfaces';

interface PackagingOptionsProps {
  savedWaveFilters?: WaveTemplateFilters;
}

const PackagingOptions: React.FC<PackagingOptionsProps> = ({savedWaveFilters}) => {
  const {register} = useFormContext();

  function isPackingRequirementsSelected(option: string) {
    switch (option) {
      case 'ship-alone':
        return savedWaveFilters?.shipmentType === OrderType.ShipAlone;
      case 'overbox':
        return savedWaveFilters?.shipAsIs === false;
      case 'ship-as-is':
        return savedWaveFilters?.shipAsIs === true;
      case 'hazmat':
        return savedWaveFilters?.includesHazmat === true;
      case 'site-to-store':
        return savedWaveFilters?.siteToStoreOnly === true;
      case 'none-selected':
        return (
          savedWaveFilters?.siteToStoreOnly !== true &&
          savedWaveFilters?.includesHazmat !== true &&
          savedWaveFilters?.shipAsIs !== true &&
          savedWaveFilters?.shipAsIs !== false &&
          savedWaveFilters?.shipmentType !== OrderType.ShipAlone
        );
    }
    return false;
  }

  return (
    <div className="form-field">
      <label>
        Packaging Requirements (optional)
        <br />
        <span className="input-hint"> Add packing and/or labeling requirements</span>
      </label>
      <div className="rbtn-group">
        <input
          className="wave-template-input"
          type="radio"
          name="packaging"
          id="none-selected"
          data-testid="none-selected"
          value={'none-selected'}
          defaultChecked={isPackingRequirementsSelected('none-selected')}
          ref={register}
        />
        <label htmlFor="none-selected">None</label>
        <input
          className="wave-template-input"
          type="radio"
          name="packaging"
          id="ship-alone"
          data-testid="ship-alone"
          value={'ship-alone'}
          defaultChecked={isPackingRequirementsSelected('ship-alone')}
          ref={register}
        />
        <label htmlFor="ship-alone">Ship Alone</label>
        <input
          className="wave-template-input"
          type="radio"
          name="packaging"
          id="overbox"
          value={'overbox'}
          defaultChecked={isPackingRequirementsSelected('overbox')}
          ref={register}
        />
        <label htmlFor="overbox">Overbox Required</label>
        <input
          className="wave-template-input"
          type="radio"
          name="packaging"
          id="ship-as-is"
          value={'ship-as-is'}
          defaultChecked={isPackingRequirementsSelected('ship-as-is')}
          ref={register}
        />
        <label htmlFor="ship-as-is">Ship As-Is</label>
        <input
          className="wave-template-input"
          type="radio"
          name="packaging"
          id="hazmat"
          value={'hazmat'}
          defaultChecked={isPackingRequirementsSelected('hazmat')}
          ref={register}
        />
        <label htmlFor="hazmat">Hazmat</label>
        <input
          className="wave-template-input"
          type="radio"
          name="packaging"
          id="site-to-store"
          value={'site-to-store'}
          defaultChecked={isPackingRequirementsSelected('site-to-store')}
          ref={register}
        />
        <label htmlFor="site-to-store">Site to Store</label>
      </div>
    </div>
  );
};

export default PackagingOptions;
