import {ItemQuantity, QuantityInAllUnits, Warehouse} from '../shared/CommonInterfaces';
import {FilterOption} from '../shared/CommonInterfaces';

export enum LpnStatus {
  stored = 'stored',
  archived = 'archived'
}

export enum LpnStatusDisplay {
  stored = 'Stored',
  archived = 'Archived'
}

export enum ShipmentType {
  containerDelivery = 'ContainerDelivery',
  rfo = 'RetailFulfillmentOrder',
  ecomm = 'EcommOrder'
}

export enum ShipmentTypeName {
  containerDelivery = 'Container Delivery',
  rfo = 'Retail Order',
  ecomm = 'Ecommerce Order'
}

export enum LpnType {
  pallet = 'pallet',
  carton = 'carton'
}

export interface Shipment {
  id: number;
  type: ShipmentType;
}

export interface UpcBarcodes {
  each: string;
  carton: string;
  pallet: string;
}

export interface LpnsResponse {
  lpnId: number;
  lpnBarcode: string;
  lpnType: string;
  parentLpnId: number;
  category: string;
  dropoffId: number;
  // purchaseOrderId: string;
  receivedDate: string;
  receivedBy: {
    id: number;
    name: string;
  };
  timeInLocation: string;
  reservation: number;
  upcBarcodes: UpcBarcodes;
  state: LpnStatus;
  location: {
    id: number;
    label: string;
  };
  manufactureDate: string;
  shipByDate: string;
  warehouseExitByDate: string;
  asnNumber: string;
  poNumber: string;
  customRef1: string;
  customRef2: string;
  countryOfOrigin: string;
  originSite: string;
  contents: LpnContentDetail[];
  loadId?: number;
  shipmentId?: number;
  referenceId?: string;
  updatedAt: string;
}

export interface LpnDetails {
  lpnId: number;
  lpnBarcode: string;
  category: string;
  lpnType: string;
  parentLpnId: number;
  parentLpnBarcode?: string;
  childLpnIds?: number[];
  dropoffId: number;
  // purchaseOrderId: string;
  receivedDate: string;
  receivedBy: {
    id: number;
    name: string;
  };
  timeInLocation: string;
  reservation: number;
  upcBarcodes: UpcBarcodes;
  state: LpnStatus;
  location: {
    id: number;
    label: string;
  };
  manufactureDate: string;
  shipByDate: string;
  warehouseExitByDate: string;
  asnNumber: string;
  poNumber: string;
  customRef1: string;
  customRef2: string;
  countryOfOrigin: string;
  originSite: string;
  contents: LpnContentDetail[];
  loadId?: number;
  shipmentId?: number;
  referenceId?: string;
  updatedAt: string;
}

export interface LpnContentDetail {
  item: {
    description: string;
    id: number;
    sku: string;
  };
  quantity: ItemQuantity;
  quantityInAllUnits: QuantityInAllUnits;
  barcode: string;
  trackingData: {
    expirationDate: string;
    lotCode: string;
    manufactureDate: string;
    uoms: CustomUoms;
  };
}

export interface CustomUoms {
  eaches_per_inner_pack: number;
  eaches_per_outer_case: number;
}

export interface LpnSearchRequest {
  ReservationIds: number[];
  ShipmentIds: number[];
  LpnBarcodes: string[];
  Skus: string[];
  IncludeArchived?: boolean;
}

export interface LpnSearchResponse {
  lpns: LpnSearchDetails[];
}

export interface LpnSearchDetails extends LpnDetails {
  shipmentId: number;
}

export const LPN_TYPE_TO_UI_STRINGS = {
  pallet: 'Pallet',
  carton: 'Carton',
  flexible: 'Flexible'
};

export const LPN_STATUS_TO_UI_STRINGS = {
  archived: 'Archived',
  stored: 'Stored'
};
